import { useMemo } from 'react';

import { type Resource, useService as useBaseService } from './Base';
import { Service as DeletableResourceService } from './DeletableResource';
import { requestFormData } from './util';

export type FileMapping = Partial<Record<ContactField, string>>;

export enum ContactField {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    PHONE_NUMBER = 'phoneNumber',
    COMPANY_NAME = 'companyName',
    ADDRESS_LINE_1 = 'addressLine1',
    ADDRESS_LINE_2 = 'addressLine2',
    JOB_TITLE = 'jobTitle',
    CITY = 'city',
    POSTAL_OR_ZIP = 'postalOrZip',
    PROVINCE_OR_STATE = 'provinceOrState',
    COUNTRY_CODE = 'countryCode',
}

export enum ProcessStatus {
    VALIDATING = 'validating',
    COMPLETED = 'completed',
    CHANGES_REQUIRED = 'changes_required',
}

export enum FileType {
    CSV = 'csv',
}

export enum NoteType {
    TRUNACTED_TEST_FILE = 'truncated_test_file',
}

export interface VerificationStatusCount {
    verifiedCount: number;
    correctedCount: number;
    failedCount: number;
}

export enum ProcessErrorType {
    NO_VALID_CONTACTS_ERROR = 'no_valid_contacts_error',
    MULTIPLE_COUNTRIES_ERROR = 'multiple_countries_error',
    INVALID_CONTACT_COUNT_ERROR = 'invalid_contact_count_error',
    INTERNAL_SERVICE_ERROR = 'internal_service_error',
}

export interface Note {
    type: NoteType;
    message: string;
}

export interface ProcessError {
    type: ProcessErrorType;
    message: string;
}

export interface ContactFile {
    receiverAddressMapping: FileMapping;
    senderAddressMapping?: FileMapping;
    receiverMergeVariableMapping?: Record<string, string>;
    senderMergeVariableMapping?: Record<string, string>;
    fileType: FileType;
    url: string;
}

export interface MailingListImport extends Resource {
    object: 'mailing_list_import';
    status: ProcessStatus;
    invalidRowCount: number;
    validRowCount: number;
    receiverStatusCount: VerificationStatusCount;
    senderStatusCount: VerificationStatusCount;
    notes: Note[];
    file: ContactFile;
    errors: ProcessError[];
    reportURL?: string;
}

interface CreateParams {
    fileType: FileType;
    file: File | string;
    receiverAddressMapping: FileMapping;
    senderAddressMapping?: FileMapping;
    receiverMergeVariableMapping?: Record<string, string>;
    senderMergeVariableMapping?: Record<string, string>;
    description?: string;
    metadata?: Record<string, any>;
}

interface UpdateParams {
    description?: string;
    metadata?: Record<string, any>;
}

export class Service extends DeletableResourceService<MailingListImport> {
    create(data: CreateParams) {
        const formData = requestFormData(data);
        formData.set('metadata[postgrid_dashboard]', '');

        return this.base.fetchAPI<MailingListImport>(`/${this.route}`, {
            method: 'POST',
            body: formData,
        });
    }

    update(data: UpdateParams) {
        return this.base.fetchAPI<MailingListImport>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }
}

export const useService = () => {
    const base = useBaseService();
    return useMemo(() => new Service(base, 'mailing_list_imports'), [base]);
};
