import React, { createContext, useContext, useState, useCallback } from 'react';
import { BankAccount } from '../services/BankAccounts';
import { OrderExtraService, OrderMailingClass } from '../services/Base';
import { ChequeSize, CurrencyCode, LetterSettings } from '../services/Cheques';
import { Contact } from '../services/Contacts';
import { Template } from '../services/Templates';
import { minDate } from '../components/SendDate';

interface State {
    description: string;
    toContacts: Contact[];
    fromContact: Contact | null;
    account: BankAccount | null;
    number: number | undefined;
    amount: number;
    currencyCode: CurrencyCode;
    memo: string;
    message: string;
    mergeVars: Record<string, string>;
    uploadedCSV: boolean;
    template: Template | null;
    file: File | null;
    loading: boolean;
    express: boolean;
    attachLetter: boolean;
    letterSettings: LetterSettings | null;
    sendDate: Date;
    extraService: '' | OrderExtraService;
    mailingClass: OrderMailingClass;
    size: '' | ChequeSize;
}

type StateWithSetters = State & Setters;

interface IContext extends StateWithSetters {
    resetState: () => void;
}

type Setters = {
    [Prop in keyof State as `set${Capitalize<Prop>}`]: React.Dispatch<
        React.SetStateAction<State[Prop]>
    >;
};

const Context = createContext<IContext | undefined>(undefined);

// TODO: Fix `undefined`/`null` defaults for form data
export const CreateChequeProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [description, setDescription] = useState('');
    const [toContacts, setToContacts] = useState<Contact[]>([]);
    const [fromContact, setFromContact] = useState<Contact | null>(null);
    const [account, setAccount] = useState<BankAccount | null>(null);
    const [number, setNumber] = useState<number | undefined>(undefined);
    const [amount, setAmount] = useState(0);
    const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
        CurrencyCode.USD
    );
    const [memo, setMemo] = useState('');
    const [message, setMessage] = useState('');
    const [mergeVars, setMergeVars] = useState<Record<string, string>>({});
    const [uploadedCSV, setUploadedCSV] = useState(false);
    const [template, setTemplate] = useState<Template | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [mailingClass, setMailingClass] = useState<OrderMailingClass>(
        OrderMailingClass.FIRST_CLASS
    );
    const [extraService, setExtraService] = useState<'' | OrderExtraService>(
        ''
    );
    const [express, setExpress] = useState(false);
    const [attachLetter, setAttachLetter] = useState(false);
    const [letterSettings, setLetterSettings] = useState<LetterSettings | null>(
        null
    );
    const [sendDate, setSendDate] = useState(minDate());
    const [size, setSize] = useState<ChequeSize | ''>('');

    const resetState = useCallback(() => {
        setDescription('');
        setToContacts([]);
        setFromContact(null);
        setAccount(null);
        setCurrencyCode(CurrencyCode.USD);
        setNumber(undefined);
        setAmount(0);
        setMemo('');
        setMessage('');
        setExtraService('');
        setMailingClass(OrderMailingClass.FIRST_CLASS);
        setMergeVars({});
        setUploadedCSV(false);
        setTemplate(null);
        setFile(null);
        setLoading(false);
        setMergeVars({});
        setExpress(false);
        setAttachLetter(false);
        setLetterSettings(null);
        setSendDate(minDate());
        setSize('');

        // don't need to re-do function
        // eslint-disable-next-line
    }, []);

    const value: IContext = {
        account,
        amount,
        currencyCode,
        description,
        file,
        fromContact,
        loading,
        memo,
        message,
        number,
        mergeVars,
        mailingClass,
        extraService,
        setExtraService,
        setMailingClass,
        setMergeVars,
        resetState,
        setAccount,
        setAmount,
        setCurrencyCode,
        setDescription,
        setFile,
        setFromContact,
        setLoading,
        setMemo,
        setMessage,
        setNumber,
        setTemplate,
        setToContacts,
        setUploadedCSV,
        express,
        setExpress,
        template,
        toContacts,
        uploadedCSV,
        attachLetter,
        setAttachLetter,
        letterSettings,
        setLetterSettings,
        sendDate,
        setSendDate,
        size,
        setSize,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCreateCheque = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'useCreateCheque can only be used within a CreateChequeProvider'
        );
    }
    return context;
};
