import React from 'react';

import { useService as usePostcardsService } from '../services/Postcards';

import OrdersPage from '../components/OrdersPage';

import CreatePostcard from './CreatePostcard';
import ViewPostcard from './ViewPostcard';

const Postcards = () => {
    const service = usePostcardsService();

    return (
        <OrdersPage
            service={service}
            object="postcard"
            Create={CreatePostcard}
            View={ViewPostcard}
        />
    );
};

export default Postcards;
