import React, { PropsWithoutRef, useState } from 'react';

import { useService as useBillingService } from '../services/Billing';

import ConfirmActionDialog from './ConfirmActionDialog';

const MissingPaymentMethodDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: () => void;
        text: string;
    }>
) => {
    const billingService = useBillingService();

    const [loading, setLoading] = useState(false);

    const confirm = async () => {
        try {
            setLoading(true);

            const session =
                await billingService.createMailingsMethodSetupSession({
                    successURL: `${
                        window.location.origin + '/dashboard/payment'
                    }?mailings_method_session={CHECKOUT_SESSION_ID}`,
                    cancelURL:
                        window.location.origin + window.location.pathname,
                });

            window.open(session.url, '_self');
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <ConfirmActionDialog
            title="Missing Payment Method"
            actionLabel="Add"
            confirm={confirm}
            disabled={loading}
            {...props}
        />
    );
};

export default MissingPaymentMethodDialog;
