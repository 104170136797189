import { useMemo } from 'react';

import { createURLParams, ListParams, ListResponse } from './util';
import { Service as BaseService, useService as useBaseService } from './Base';

// TODO Once we have Stripe.js installed, we can just export those interfaces
export interface Price {
    id: string;
    nickname: string;
    recurring: {
        interval: 'month' | 'year';
    };
    unit_amount: number;
    currency: string;
    metadata: {
        monthly_limit: string;
        pdn_type: 'standard' | 'premium' | 'priority';
        sla?: string;

        // Can be used to override what we display in the
        // plan cards.
        presentment_currency?: string;
        presentment_amount?: string;
    };
}

export interface Invoice {
    id: string;
    created: number;
    account_name: string;
    customer_name: string;
    amount_due: number;
    amount_paid: number;
    status: string;
    invoice_pdf: string;
    currency: string;
}

// TODO Make card optional once we actually allow other method types
export interface PaymentMethod {
    id: string;

    card?: {
        exp_month: string;
        exp_year: string;
        last4: string;
    };

    us_bank_account?: {
        last4: string;
    };
}

export interface PaymentMethods {
    mailingsPaymentMethod: PaymentMethod;
    usMailingsPaymentMethod: PaymentMethod;
    subscriptionPaymentMethod: PaymentMethod;
}

export interface Subscription {
    id: string;
    created: number;
    items: {
        data: {
            price: Price;
        }[];
    }[];
    // Equivalent to price id
    plan: {
        id: string;
    };
}

export interface Session {
    id: string;
    url: string;

    subscription?: Subscription;
}

interface CreateSetupSessionParams {
    successURL: string;
    cancelURL: string;
}

interface CreateOrUpdateSubscriptionParams {
    stripeSubscriptionPaymentMethod: string;
    stripePrice: string;
}

interface CreateSubscriptionSessionParams {
    stripePrice: string;
    successURL: string;
    cancelURL: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async listPrices() {
        return await this.base.fetchAPI<ListResponse<Price>>('/billing/prices');
    }

    async list(params: ListParams) {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI<ListResponse<Invoice>>(
            `/billing/invoices?${urlParams.toString()}`
        );
    }

    async getPaymentMethods() {
        return await this.base.fetchAPI<PaymentMethods>(
            '/billing/payment_methods'
        );
    }

    async createSubscriptionMethodSetupSession(
        params: CreateSetupSessionParams
    ) {
        return await this.base.fetchAPI<Session>(
            '/billing/subscription_method_setup_sessions',
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async completeSubscriptionMethodSetupSession(id: string) {
        return await this.base.fetchAPI<Session>(
            `/billing/subscription_method_setup_sessions/${id}`,
            {
                method: 'POST',
            }
        );
    }

    async createMailingsMethodSetupSession(params: CreateSetupSessionParams) {
        return await this.base.fetchAPI<Session>(
            '/billing/mailings_method_setup_sessions',
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async completeMailingsMethodSetupSession(id: string) {
        return await this.base.fetchAPI<Session>(
            `/billing/mailings_method_setup_sessions/${id}`,
            {
                method: 'POST',
            }
        );
    }

    async getSubscription() {
        return await this.base.fetchAPI<Subscription>('/billing/subscription');
    }

    async tryGetSubscription() {
        return await this.base.fetchAPIAllow404<Subscription>(
            '/billing/subscription'
        );
    }

    async createOrUpdateSubscription(data: CreateOrUpdateSubscriptionParams) {
        return await this.base.fetchAPI<Subscription>('/billing/subscription', {
            method: 'POST',
            body: data,
        });
    }

    async createSubscriptionSession(params: CreateSubscriptionSessionParams) {
        return await this.base.fetchAPI<Session>(
            '/billing/subscription_sessions',
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async completeSubscriptionSession(id: string) {
        return await this.base.fetchAPI<Session>(
            `/billing/subscription_sessions/${id}`,
            {
                method: 'POST',
            }
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
