import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

type TooltipWrapperProps = TooltipProps & {
    disabled?: boolean;
};

const TooltipWrapper = ({
    children,
    disabled,
    ...props
}: TooltipWrapperProps) => {
    if (disabled) {
        return <Tooltip {...props}>{children}</Tooltip>;
    } else return <>{children}</>;
};

export default TooltipWrapper;
