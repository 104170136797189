import React, { ElementType } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const iconStyles = makeStyles((theme) => ({
    root: {
        fontSize: 30,
        color: theme.palette.grey.A700,
    },
}));

const HeaderIcon = (props: { icon: ElementType }) => {
    const classes = iconStyles();

    return (
        <span style={{ marginRight: '7px' }}>
            <props.icon classes={{ root: classes.root }} />
        </span>
    );
};

export default HeaderIcon;
