import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ButtonProps } from '@mui/material/Button';
import Button from './Button';

type LinkButtonProps = {
    to: string;
} & ButtonProps;

const LinkButton = ({ to, children, ...props }: LinkButtonProps) => {
    return (
        <RouterLink to={to} style={{ all: 'inherit' }}>
            <Button variant="contained" color="primary" {...props}>
                {children}
            </Button>
        </RouterLink>
    );
};

export default LinkButton;
