import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';
import { Service as ResourceService } from './DeletableResource';

export enum BankAccountCountryCode {
    CA = 'CA',
    US = 'US',
}

export interface BankAccount extends Resource {
    bankName: string;
    bankPrimaryLine: string;
    bankSecondaryLine: string;
    bankCountryCode: BankAccountCountryCode;

    transitNumber?: string;
    routeNumber?: string;

    routingNumber?: string;

    signatureText?: string;

    accountNumberLast4: string;
}

// TODO Currently we do not allow setting metadata via the frontend so since the
// create endpoint *requires* an uploaded file, I'm ignoring it.
type CreateParams = Omit<
    Omit<BankAccount, 'accountNumberLast4' | keyof Resource> &
        ResourceCreateParams & {
            accountNumber: string;
            signatureImage?: Blob;
        },
    'metadata'
>;

export class Service extends ResourceService<BankAccount> {
    async create(params: CreateParams) {
        const data = new FormData();

        for (const [key, value] of Object.entries(params)) {
            data.set(key, value!);
        }

        if (params.bankCountryCode === BankAccountCountryCode.CA) {
            // Make sure that we don't pass along routingNumber when creating Canadian accounts
            data.delete('routingNumber');
        } else {
            data.delete('routeNumber');
            data.delete('transitNumber');
        }

        if (params.signatureText) {
            data.delete('signatureImage');
        } else {
            data.delete('signatureText');
        }

        return await this.base.fetchAPI<BankAccount>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }
}

export const displayAccountNumber = (account: BankAccount) =>
    '*'.repeat(6) + account.accountNumberLast4;

export const label = (account: BankAccount) => {
    return `${account.bankName} - ${displayAccountNumber(account)}`;
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'bank_accounts'), [base]);
};
