import React, { FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import {
    useService as useWebhookService,
    WebhookPayloadFormat,
} from '../services/Webhooks';

import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import TopNav from '../components/TopNav';
import { WebhookEventType } from '../services/Webhooks';
import Field from '../components/Field';
import WebhookEvents from '../components/WebhookEvents';

import Grid from '@mui/material/Grid';
import { addToSet, removeFromSet } from '../services/util';
import CreateResourceActions from '../components/CreateResourceActions';
import WebhookPayloadSelector from '../components/WebhookPayloadSelector';

const WEBHOOK_GUIDE_TEXT = [
    'To learn more about the different webhook options, read our dedicated webhook guide ',
    <a
        href="https://postgrid.readme.io/reference/webhooks"
        target="_blank"
        rel="noreferrer"
    >
        here
    </a>,
    '.',
];

const CreateWebhook = () => {
    const history = useHistory();

    const service = useWebhookService();

    const { dispatch } = useContext(NotificationContext);

    const [events, setEvents] = useState(new Set<WebhookEventType>());
    const [payloadFormat, setPayloadFormat] = useState(
        WebhookPayloadFormat.JWT
    );
    const [url, setUrl] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleEvents = (event: WebhookEventType) => {
        if (events.has(event)) {
            removeFromSet(event, setEvents);
        } else {
            addToSet(event, setEvents);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const eventTypes = Array.from(events);

        if (eventTypes.length <= 0) {
            dispatch({
                type: MessageType.ERROR,
                message: 'You must select at least one event type.',
            });
            return;
        }
        try {
            setLoading(true);
            await service.create({
                description: description.length > 0 ? description : undefined,
                enabledEvents: eventTypes,
                url,
                payloadFormat,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Created webhook',
            });
            history.push('/dashboard/webhooks');
        } catch (err) {
            console.error('Create Webhook Error:', err);
        }
        setLoading(false);
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <PageHeader
                    title="Create Webhook"
                    testAlertText={[
                        'You are in test mode, this will be a test mode webhook that will only work for test mode orders. ',
                        ...WEBHOOK_GUIDE_TEXT,
                    ]}
                    liveAlertText={WEBHOOK_GUIDE_TEXT}
                />
                <Grid item>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    label="Description"
                                    value={description}
                                    setValue={setDescription}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    label="URL"
                                    value={url}
                                    setValue={setUrl}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <WebhookPayloadSelector
                                    onChange={setPayloadFormat}
                                    format={payloadFormat}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WebhookEvents onChange={handleEvents} />
                            </Grid>
                            <Grid item xs={12}>
                                <CreateResourceActions disabled={loading} />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateWebhook;
