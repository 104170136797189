import React from 'react';

const ExpressExtraServiceToolTip = () => {
    return (
        <span>
            You can only select one of <strong>extra services</strong> or{' '}
            <strong>express delivery</strong>.
        </span>
    );
};

export default ExpressExtraServiceToolTip;
