import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

import Folder from '@mui/icons-material/Folder';

import ObjectTable from './ObjectTable';
import AccordionSummary from './AccordionSummary';

export const PREFIX_TO_OBJECT = {
    contact_: 'contact',
    template_: 'template',
    letter_: 'letter',
    postcard_: 'postcard',
    bank_: 'bank_account',
    cheque_: 'cheque',
    api_log_: 'api_log',
    webhook_: 'webhook',
    user_: 'user',
};

const RawData = (props: { obj: any }) => {
    return (
        <Accordion
            variant="outlined"
            style={{ borderRadius: 10 }}
            data-testid="raw-data"
        >
            <AccordionSummary label="Raw Data" icon={Folder} />
            <AccordionDetails
                style={{
                    padding: '20px 20px 30px',
                    borderTop: '1px solid #EFEFEF',
                }}
            >
                <ObjectTable obj={props.obj} />
            </AccordionDetails>
        </Accordion>
    );
};

export default RawData;
