import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

interface ToolTipIconButtonProps {
    onClick?: (...args: any) => any;
    disabled?: boolean;
    title: string;
    color?: string;
    size?: 'small' | 'medium';
    icon: React.ElementType;
}

const ToolTipIconButton = ({
    icon: Icon,
    onClick,
    disabled,
    title,
    color,
    size,
}: ToolTipIconButtonProps) => {
    return (
        <Tooltip title={title} style={{ outline: 0 }}>
            <IconButton size={size} onClick={onClick} disabled={disabled}>
                <Icon
                    style={{
                        color,
                    }}
                />
            </IconButton>
        </Tooltip>
    );
};
export default ToolTipIconButton;
