import React, { ElementType } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles({
    root: {
        padding: '8px 28px',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
});

const NavItemLink = (props: {
    icon: ElementType;
    primary?: string;
    to: string;
    iconColor?: string;
}) => {
    const classes = useStyles();

    const { primary, to } = props;

    return (
        <ListItem
            button
            disableRipple
            component="a"
            classes={classes}
            href={to}
            target="_blank"
        >
            <ListItemIcon style={{ marginBottom: '-5px' }}>
                <SvgIcon>
                    <props.icon color={props.iconColor} />
                </SvgIcon>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant="body2"
                        style={{ fontFamily: 'Poppins' }}
                    >
                        {primary}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default NavItemLink;
