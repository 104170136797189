import React from 'react';

import { Contact } from '../services/Contacts';

// @ts-ignore
import { getEmojiFlag } from 'countries-list';

import Grid from '@mui/material/Grid';
import LocationOn from '@mui/icons-material/LocationOn';

import AddressStatusIndicator from './AddressStatusIndicator';
import DetailCell from './DetailCell';
import DetailWrapper from './DetailWrapper';
import Box from '@mui/material/Box';

interface AddressInfoProps {
    contact: Contact;
    title?: string;
}

const AddressInfo = ({ contact, title }: AddressInfoProps) => {
    return (
        <DetailWrapper
            icon={LocationOn}
            title={title ? title : 'Address'}
            adornment={
                <Grid container spacing={1}>
                    {contact.countryCode && (
                        <Grid item>
                            <Box
                                alignContent="center"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                display="flex"
                            >
                                {getEmojiFlag(contact.countryCode)}
                            </Box>
                        </Grid>
                    )}
                    <Grid item>
                        <AddressStatusIndicator
                            status={contact.addressStatus}
                        />
                    </Grid>
                </Grid>
            }
            direction="column"
            spacing={1}
        >
            <DetailCell
                right="Line 1"
                left={contact.addressLine1}
                testIdPrefix="line-one"
            />
            <DetailCell
                right="Line 2"
                left={contact.addressLine2}
                testIdPrefix="line-two"
            />
            <DetailCell right="City" left={contact.city} testIdPrefix="city" />
            <DetailCell
                right="Province/State"
                left={contact.provinceOrState}
                testIdPrefix="province-state"
            />
            <DetailCell
                right="Postal/ZIP Code"
                left={contact.postalOrZip}
                testIdPrefix="postal-zip"
            />
        </DetailWrapper>
    );
};

export default AddressInfo;
