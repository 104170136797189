import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from './Button';
import makeStyles from '@mui/styles/makeStyles';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import MuiPaper, { PaperProps } from '@mui/material/Paper';
import CopyToClipboard from './CopyToClipboard';

interface TestContactDescriptionDialogProps {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline',
        fontWeight: 500,
    },
    rounded: {
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: 5,
    },
    corrected: {
        color: theme.palette.warning.main,
        backgroundColor: '#fff2de',
    },
    failed: {
        color: theme.palette.error.main,
        backgroundColor: '#ffdedb',
    },
    verified: {
        color: theme.palette.success.main,
        backgroundColor: '#e3ffe4',
    },
    value: {
        backgroundColor: '#ebebeb',
        display: 'inline-block',
    },
}));

const Important = (props: PropsWithChildren<{}>) => {
    return (
        <Box fontWeight={500} display="inline">
            {props.children}
        </Box>
    );
};
const Failed = () => {
    const classes = useStyles();
    return (
        <Box className={`${classes.failed} ${classes.root} ${classes.rounded}`}>
            failed
        </Box>
    );
};
const Verified = () => {
    const classes = useStyles();
    return (
        <Box
            className={`${classes.verified} ${classes.root} ${classes.rounded}`}
        >
            verified
        </Box>
    );
};
const Corrected = () => {
    const classes = useStyles();
    return (
        <Box
            className={`${classes.corrected} ${classes.root} ${classes.rounded}`}
        >
            corrected
        </Box>
    );
};

const Paper = (props: PaperProps) => {
    return (
        <MuiPaper
            {...props}
            style={{ backgroundColor: '#fcfcfc', borderRadius: 10 }}
        >
            <Table>{props.children}</Table>
        </MuiPaper>
    );
};

const Value = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();
    return (
        <Box
            className={`${classes.value} ${classes.rounded}`}
            fontStyle="italic"
            fontWeight={300}
        >
            {props.children}
        </Box>
    );
};

const CopyValue = ({ text }: { text: string }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'center',
                alignItems: 'center',
            }}
        >
            <CopyToClipboard value={text} />
            <Value>{text}</Value>
        </Box>
    );
};

const TestContactDescriptionDialog = ({
    onClose,
    open,
}: TestContactDescriptionDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Altering A Test Mode Contact's Address Status
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    By default, test mode contacts always have an{' '}
                    <Important>address status</Important> of <Verified />. When
                    creating a contact in test mode, you can choose to force a
                    contact to have an address status of <Failed /> or{' '}
                    <Corrected />.
                </DialogContentText>
                <DialogContentText component="div">
                    To force a different <Important>address status</Important>,
                    you must supply a specific value for the{' '}
                    <Important>description</Important> of the contact.
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <Table component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description Value</TableCell>
                            <TableCell>Address Status Result</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <CopyValue text="test failed" />
                            </TableCell>
                            <TableCell>
                                <Failed />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <CopyValue text="test corrected" />
                            </TableCell>
                            <TableCell>
                                <Corrected />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Value>any other value will be verified</Value>
                            </TableCell>
                            <TableCell>
                                <Verified />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TestContactDescriptionDialog;
