import React from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import Button, { ButtonProps } from './Button';

const ButtonDisabledTooltip = (
    props: ButtonProps & {
        TooltipProps: Omit<TooltipProps, 'children'>;
    }
) => {
    if (props.disabled) {
        return (
            <Tooltip {...props.TooltipProps}>
                <span>
                    <Button {...props}></Button>
                </span>
            </Tooltip>
        );
    }

    return <Button {...props} />;
};

export default ButtonDisabledTooltip;
