import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { SSORoutes } from '../routes';
import { Context, ActionType } from '../context/Auth';
import { useService as useSessionsService } from '../services/Sessions';

import LoginWrapper from '../components/LoginWrapper';
import GraphicWrapper from '../components/GraphicWrapper';

const LoginSSO = () => {
    const {
        isAuthenticated,
        getAccessTokenSilently,
        isLoading,
        loginWithPopup,
        user,
        logout,
    } = useAuth0();

    const service = useSessionsService();
    const { dispatch } = useContext(Context);
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // TODO:  Fix so user doesn't have to re-enter details every time
        // Problem: If user signs up and comes to login page, they are still
        // `isAuthenticated` so it trys to login as the signed up user
        // and gives an error stating they need to finish registering
        await loginWithPopup({ prompt: 'login' });
    };

    useEffect(() => {
        if (isAuthenticated === false) {
            return;
        }

        (async () => {
            try {
                setLoading(true);

                const accessToken = await getAccessTokenSilently();
                const res = await service.login({ accessToken });

                dispatch({
                    type: ActionType.LOGIN,
                    payload: res,
                });

                history.push('/dashboard');
            } catch (err) {
                // Clear local session state
                logout({ localOnly: true });
                setLoading(false);
            }
        })();
    }, [
        logout,
        user,
        isAuthenticated,
        isLoading,
        dispatch,
        getAccessTokenSilently,
        history,
        service,
    ]);

    return (
        <GraphicWrapper>
            <LoginWrapper
                signupLink={SSORoutes.SIGNUP}
                submitDisabled={isLoading || loading}
                onSubmit={handleSubmit}
                buttonText="Login With SSO"
            />
        </GraphicWrapper>
    );
};

export default LoginSSO;
