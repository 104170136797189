import React, { useState, useContext } from 'react';
import { ActionType, Context as AuthContext } from '../context/Auth';
import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';
import { useService as useUsersService } from '../services/Users';

import { useDialogStyles } from '../pages/Settings';

import Button from '../components/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const RotateAPIKeyDialog = (props: {
    open: boolean;
    onClose: () => void;
    isLiveKey: boolean;
}) => {
    const { state, dispatch } = useContext(AuthContext);
    const { dispatch: notifDispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);
    const classes = useDialogStyles();

    const service = useUsersService();

    const handleRotateAPIKey = async () => {
        setLoading(true);

        try {
            //  API call to rotate API key
            const updatedUser = await service.update(state.user!.id, {
                rotateTestKey: !props.isLiveKey,
                rotateLiveKey: props.isLiveKey,
            });

            // Update user in context
            dispatch({
                type: ActionType.UPDATE_USER,
                user: updatedUser,
            });

            notifDispatch({
                type: MessageType.SUCCESS,
                message:
                    'Your API key has been successfully rotated. Your old key will remain active for the next 24 hours',
            });

            props.onClose();
        } catch (err) {
            console.log(err);
            notifDispatch({
                type: MessageType.ERROR,
                message:
                    'An error occurred while rotating your API key. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog {...props} classes={classes} onClose={props.onClose}>
            <DialogContent>
                <DialogTitle>Rotate API Key</DialogTitle>
                <Box height={10}></Box>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1" color="textSecondary">
                            Are you sure you want to{' '}
                            <b>
                                rotate your {props.isLiveKey ? 'live' : 'test'}{' '}
                                API key
                            </b>
                            ? Your previous key will remain active for the next
                            24 hours, but will be removed after that.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={props.onClose}
                            disabled={loading}
                            size="large"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleRotateAPIKey}
                            size="large"
                            disabled={loading}
                            fullWidth
                        >
                            Rotate
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default RotateAPIKeyDialog;
