import { useMemo } from 'react';
import { Resource, useService as useBaseService } from './Base';
import { Service as ResourceService } from './Resources';

export interface APILog extends Resource {
    object: 'api_log';
    responseTimeMS: number;
    responseBody: string;
    responseStatusCode: number;
    requestBody?: string;
    requestHeaders?: string;
    path: string;
    httpMethod: 'POST' | 'DELETE';
}

export type DeleteResponse = {
    deletedCount: number;
};

export class Service extends ResourceService<APILog> {
    async deleteAll(): Promise<DeleteResponse> {
        return this.base.fetchAPI(`/${this.route}`, { method: 'DELETE' });
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'api_logs'), [base]);
};
