import React from 'react';
import TableDisplay from './TableDisplay';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { label } from '../services/Contacts';
import Checkbox from '@mui/material/Checkbox';
import { useBulkCancelOrdersContext } from '../context/BulkCancelOrders';

const BulkCancelOrdersTable = () => {
    const {
        rowsPerPage,
        setRowsPerPage,
        page,
        setPage,
        count,
        orders,
        preserveOrder,
        handlePreserveOrder,
        object,
    } = useBulkCancelOrdersContext();

    return (
        <TableDisplay
            columns={['Cancel', 'Description', 'Recipient', 'Address']}
            show={false}
            noDataMessage={`Could not find any ${object}s that matched the search criteria`}
            pagination={{ count, page, rowsPerPage, setPage, setRowsPerPage }}
        >
            {orders.map((order) => {
                return (
                    <TableRow key={order.id}>
                        <TableCell>
                            <Checkbox
                                color="primary"
                                onClick={() => handlePreserveOrder(order.id)}
                                checked={!preserveOrder.has(order.id)}
                                data-testid={`checkbox-${order.id}`}
                            />
                        </TableCell>
                        <TableCell>{order.description}</TableCell>
                        <TableCell>{label(order.to)}</TableCell>
                        <TableCell>{order.to.addressLine1}</TableCell>
                    </TableRow>
                );
            })}
        </TableDisplay>
    );
};

export default BulkCancelOrdersTable;
