import Grid from '@mui/material/Grid';
import React from 'react';
import Button from './Button';
import GoBackButton from './GoBackButton';

export interface CreateResourceActionsProps {
    disabled: boolean;
    submitText?: string;
    cancelText?: string;
    onCancel?: () => void;
}

/**
 * A collection of a `submit` button and a `goBack` button
 */
const CreateResourceActions = ({
    disabled,
    submitText,
    cancelText,
    onCancel,
}: CreateResourceActionsProps) => {
    return (
        <Grid item container spacing={2} data-testid="create-resource-actions">
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disabled}
                    size="large"
                    fullWidth
                    data-testid="create-resource-submit"
                >
                    {submitText ? submitText : 'Create'}
                </Button>
            </Grid>
            <Grid item xs={2}>
                <GoBackButton
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    data-testid="create-resource-cancel"
                    onClick={onCancel}
                >
                    {cancelText ? cancelText : 'Cancel'}
                </GoBackButton>
            </Grid>
        </Grid>
    );
};

export default CreateResourceActions;
