import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    unstable_registerShapeComponent,
    unstable_registerShapeModel,
} from 'polotno/config';
import { StoreType } from 'polotno/model/store';
import { Image as KonvaImage } from 'react-konva';

export const TRACKER_IMAGE = 'tracker_image';
export const TRACKER_BASE_SIZE = 200;

unstable_registerShapeModel({
    type: TRACKER_IMAGE,
    fill: 'white',
    src: '',
    trackerID: 'tracker_',
    size: 200,
});

// HACK: Taken from polonto files
type ElementProps = {
    onClick: Function;
    element: any;
    store: StoreType;
};

const TrackerTemplateElement = observer(({ element, store }: ElementProps) => {
    const ref = React.useRef(null);
    const image = new Image();
    image.src = element.src;

    const handleChange = (e: any) => {
        const node = e.currentTarget;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);
        element.set({
            x: node.x(),
            y: node.y(),
            rotation: e.target.rotation(),
            size: element.size * scaleX * scaleY,
        });
    };

    const selectElement = () => {
        store.selectElements([element.id]);
    };

    return (
        <KonvaImage
            ref={ref}
            name="tracker-element"
            id={element.id}
            image={image}
            x={element.x}
            y={element.y}
            width={element.size}
            height={element.size}
            rotation={element.rotation}
            opacity={element.opacity}
            draggable={!element.locked}
            onDragMove={handleChange}
            onTransform={handleChange}
            onClick={selectElement}
        />
    );
});

unstable_registerShapeComponent(
    TRACKER_IMAGE,
    // @ts-ignore
    TrackerTemplateElement
);

export default TrackerTemplateElement;
