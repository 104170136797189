import React, {
    PropsWithoutRef,
    useState,
    useEffect,
    useCallback,
    useContext,
} from 'react';

import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { Organization, useOrganizationLoader } from '../services/Organization';

import {
    useService as useBillingService,
    PaymentMethod,
    PaymentMethods,
} from '../services/Billing';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import cardBg from '../img/card-bg.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: cardBg,
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px',
        borderColor: theme.palette.primary.main,
        padding: '18px 18px 25px',
        '&>.MuiCardContent-root': {
            padding: '0 16px',
        },
    },
}));

const PaymentMethodPreview = (
    props: PropsWithoutRef<{
        method: PaymentMethod;
        org?: Organization;
    }>
) => {
    const usedForMailings =
        props.method.id === props.org?.stripeMailingsPaymentMethod ||
        props.method.id === props.org?.usStripeMailingsPaymentMethod;

    const usedForSubs =
        props.method.id === props.org?.stripeSubscriptionPaymentMethod;

    const Info = () => {
        if (usedForSubs && usedForMailings) {
            return (
                <Typography>
                    Charged for your <em>subscription</em> and <em>mailings</em>
                    .
                </Typography>
            );
        }

        if (usedForSubs) {
            return (
                <Typography>
                    Charged for your <em>subscription</em>.
                </Typography>
            );
        }

        if (usedForMailings) {
            return (
                <Typography>
                    Charged for your <em>mailings</em>.
                </Typography>
            );
        }

        return <Typography>Not charged.</Typography>;
    };

    const classes = useStyles();

    return (
        <Card variant="outlined" classes={classes}>
            <CardContent>
                <Typography variant="subtitle1" style={{ fontSize: '24px' }}>
                    Payment Method
                </Typography>
                <Box my={1}>
                    <Divider />
                </Box>

                {props.method.card && (
                    <Typography>
                        Card ending in {props.method.card.last4}.
                    </Typography>
                )}
                {props.method.us_bank_account && (
                    <Typography>
                        Bank account ending in{' '}
                        {props.method.us_bank_account.last4}.
                    </Typography>
                )}

                <Info />
            </CardContent>
        </Card>
    );
};

const Payment = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();

    const { org, load: reloadOrg } = useOrganizationLoader([history.location]);
    const billingService = useBillingService();

    const { dispatch } = useContext(NotificationContext);

    const [methods, setMethods] = useState<PaymentMethods>();
    const [addingMethod, setAddingMethod] = useState(false);

    const loadPaymentMethods = useCallback(async () => {
        try {
            const methods = await billingService.getPaymentMethods();

            setMethods(methods);
        } catch (err) {
            console.error(err);
        }
    }, [billingService]);

    useEffect(() => {
        loadPaymentMethods();
    }, [billingService, history.location, loadPaymentMethods]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const mailingsSession = params.get('mailings_method_session');
        const subSession = params.get('subscription_method_session');

        if (!mailingsSession && !subSession) {
            return;
        }

        setMethods(undefined);

        (async () => {
            try {
                if (mailingsSession) {
                    await billingService.completeMailingsMethodSetupSession(
                        mailingsSession
                    );

                    dispatch({
                        type: MessageType.SUCCESS,
                        message: 'Successfully set mailings payment method.',
                    });
                }

                if (subSession) {
                    await billingService.completeSubscriptionMethodSetupSession(
                        subSession
                    );

                    dispatch({
                        type: MessageType.SUCCESS,
                        message:
                            'Successfully set subscription payment method.',
                    });
                }

                await Promise.all([reloadOrg(), loadPaymentMethods()]);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [billingService, location, dispatch, loadPaymentMethods, reloadOrg]);

    const addMailingsPaymentMethod = useCallback(async () => {
        try {
            setAddingMethod(true);

            const session =
                await billingService.createMailingsMethodSetupSession({
                    successURL: `${
                        window.location.origin + window.location.pathname
                    }?mailings_method_session={CHECKOUT_SESSION_ID}`,
                    cancelURL:
                        window.location.origin + window.location.pathname,
                });

            window.open(session.url, '_self');
        } catch (err) {
            console.error(err);
            setAddingMethod(false);
        }
    }, [billingService]);

    const addSubscriptionPaymentMethod = useCallback(async () => {
        try {
            setAddingMethod(true);

            const session =
                await billingService.createSubscriptionMethodSetupSession({
                    successURL: `${
                        window.location.origin + window.location.pathname
                    }?subscription_method_session={CHECKOUT_SESSION_ID}`,
                    cancelURL:
                        window.location.origin + window.location.pathname,
                });

            window.open(session.url, '_self');
        } catch (err) {
            console.error(err);
            setAddingMethod(false);
        }
    }, [billingService]);

    const mailingsPaymentMethod = methods
        ? methods.usMailingsPaymentMethod ?? methods.mailingsPaymentMethod
        : null;

    const noPaymentMethods =
        methods && !mailingsPaymentMethod && !methods.subscriptionPaymentMethod;

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <TopNav />
                <GridPaper container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5">
                                    Payment Methods
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={addMailingsPaymentMethod}
                                            disabled={!methods || addingMethod}
                                        >
                                            {mailingsPaymentMethod
                                                ? 'Change'
                                                : 'Add'}{' '}
                                            Mailings Payment Method
                                        </Button>
                                    </Grid>
                                    {org?.stripeSubscriptionPaymentMethod && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={
                                                    addSubscriptionPaymentMethod
                                                }
                                                disabled={
                                                    !methods || addingMethod
                                                }
                                            >
                                                Change Subscription Payment
                                                Method
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Alert color="info" variant="outlined">
                            These are all the payment methods associated with
                            your organization. You can have separate methods for
                            your mailings and subscription payments.
                        </Alert>
                    </Grid>
                    <Grid item>
                        {noPaymentMethods ? (
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                style={{ minHeight: 564 }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        You have no payment methods added.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : methods ? (
                            <Grid container spacing={2}>
                                {mailingsPaymentMethod && (
                                    <Grid item xs={4}>
                                        <PaymentMethodPreview
                                            org={org}
                                            method={mailingsPaymentMethod}
                                        />
                                    </Grid>
                                )}
                                {methods?.subscriptionPaymentMethod && (
                                    <Grid item xs={4}>
                                        <PaymentMethodPreview
                                            org={org}
                                            method={
                                                methods.subscriptionPaymentMethod
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                style={{ minHeight: 564 }}
                            >
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        style={{ minHeight: '30vh' }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </GridPaper>
            </Route>
        </Switch>
    );
};

export default Payment;
