import React, { useCallback, useContext, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import DetailCell from '../components/DetailCell';
import DetailWrapper from '../components/DetailWrapper';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import TopNav from '../components/TopNav';
import { Context as NotificationContext } from '../context/Notification';
import { useFetchResource } from '../hooks/useFetchResource';
import {
    ReturnEnvelopeOrder,
    ReturnEnvelopeOrderStatus as Statuses,
    useService,
} from '../services/ReturnEnvelopes';
import CircularProgress from '@mui/material/CircularProgress';
import Mail from '@mui/icons-material/Mail';
import { ReturnEnvelopeRoutes } from '../routes';
import PersonalInfo from '../components/PersonalInfo';
import RawData from '../components/RawData';
import { FillButton } from '../components/FillButton';
import Button from '../components/Button';
import { useModal } from '../hooks/useModal';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import { ReturnEnvelopeOrderStatus } from '../components/ReturnEnvelopeOrderStatusCell';
import TooltipWrapper from '../components/TooltipWrapper';

const ViewReturnEnvelopeOrder = () => {
    const service = useService();
    const [loading, setloading] = useState<boolean>(false);
    const { dispatchSuccess } = useContext(NotificationContext);
    const fallbackRoute = useMemo(() => ReturnEnvelopeRoutes.HOME, []);
    const { isModalOpen, toggleModal } = useModal();

    const fetch = useCallback(
        (id: string, orderId: string) => service.getOrder(id, orderId),
        [service]
    );

    const { data, fetching, setData } = useFetchResource<ReturnEnvelopeOrder>(
        fallbackRoute,
        fetch,
        undefined
    );

    // if we get no data we navigate to a different page
    // so keeping this displayed is fine as there won't be
    // a jump in UI for 0.1seconds while we navigate away
    if (!data || fetching)
        return (
            <>
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader
                        title="Return Envelope Order Details"
                        testAlertText="You are in test mode.  This is not a live order."
                    />
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </GridPaper>
            </>
        );

    const isButtonDisabled = loading || data?.status !== Statuses.PLACED;

    const handleFill = async () => {
        try {
            setloading(true);
            const order = await service.fillTestOrder(
                data.returnEnvelope.id,
                data.id
            );
            setData((prev) => {
                return { ...order, returnEnvelope: prev!.returnEnvelope };
            });
            dispatchSuccess('Your order has successfully been filled.');
        } catch (err: any) {
            console.error('Fill Return Envelope Order Error:', err);
        } finally {
            setloading(false);
        }
    };

    const handleCancel = async () => {
        try {
            setloading(true);
            const order = await service.cancelOrder(
                data.returnEnvelope.id,
                data.id
            );
            setData((prev) => {
                return { ...order, returnEnvelope: prev!.returnEnvelope };
            });

            dispatchSuccess('Your order has been cancelled.');
        } catch (err: any) {
            console.error('Canceling Return Envelope Order Error:', err);
        } finally {
            setloading(false);
        }
    };

    return (
        <>
            <ConfirmDeleteDialog
                onClose={toggleModal}
                open={isModalOpen}
                confirm={handleCancel}
                title="Cancel Return Envelope Order"
                text="Are you sure you want to cancel this order of return envelopes?"
            />
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <PageHeader
                    title="Return Envelope Order Details"
                    testAlertText="You are in test mode.  This is not a live order."
                >
                    <FillButton
                        onClick={handleFill}
                        disabled={isButtonDisabled}
                    />
                    <Grid item>
                        <TooltipWrapper
                            disabled={isButtonDisabled}
                            title="You can only cancel an order that has a status of placed"
                        >
                            <div>
                                <Button
                                    color="secondary"
                                    onClick={toggleModal}
                                    disabled={isButtonDisabled}
                                >
                                    Cancel Order
                                </Button>
                            </div>
                        </TooltipWrapper>
                    </Grid>
                </PageHeader>
                <Grid item container spacing={2}>
                    <Grid item xs={7}>
                        <DetailWrapper
                            title="Order Details"
                            direction="column"
                            spacing={2}
                            icon={Mail}
                        >
                            <DetailCell
                                right="Description"
                                left={data.description}
                            />
                            <DetailCell
                                right="Quantity Ordered"
                                left={data.quantityOrdered}
                            />
                            <DetailCell
                                right="Quantity Filled"
                                left={data.quantityFilled ?? 0}
                            />
                            <DetailCell
                                right="Status"
                                left={
                                    <ReturnEnvelopeOrderStatus
                                        status={data.status}
                                    />
                                }
                            />
                        </DetailWrapper>
                    </Grid>
                    <Grid item xs={5}>
                        <PersonalInfo
                            contact={data.returnEnvelope.to}
                            title="Return To"
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <RawData obj={data} />
                </Grid>
            </GridPaper>
        </>
    );
};

export default ViewReturnEnvelopeOrder;
