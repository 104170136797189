import React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Inbox from '@mui/icons-material/InboxTwoTone';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

interface EmptyTableDisplayProps {
    noDataMessage?: string;
    colSpan: number;
}

const EmptyTableDisplay = ({
    colSpan,
    noDataMessage,
}: EmptyTableDisplayProps) => {
    return (
        <TableRow>
            <TableCell
                colSpan={colSpan}
                style={{ backgroundColor: 'rgba(220,220,220, 0.03)' }}
                data-testid="empty-table-display"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    gap={8}
                >
                    <Inbox fontSize="large" color="disabled" />
                    <Typography color="textSecondary" component={'div'}>
                        <Box fontWeight={400}>{noDataMessage ?? 'No data'}</Box>
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default EmptyTableDisplay;
