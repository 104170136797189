import React, { PropsWithoutRef, useCallback } from 'react';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import { PERIODS, PeriodKey, useService } from '../services/LogisticsAnalytics';
import { useFetchResource } from '../hooks/useFetchResource';

import { UserRoutes } from '../routes';

import DateSelector from './DateSelector';
import EnumSelect from './EnumSelect';
import { minDate } from './SendDate';

// TODO: this is hardcoded based on time, but will need to be set up as a service
export const VIEWS: [string, string][] = [['order_pipeline', 'Order Pipeline']];

const ConfigureView = (
    props: PropsWithoutRef<{
        viewType?: string;
        setViewType: (view: string) => void;
        dateRange: [Date, Date];
        setDateRange: (dateRange: [Date, Date]) => void;
        dateRangeError: string | null;
        period: PeriodKey;
        setPeriod: (period: PeriodKey | '') => void;
        planName: string;
        setPlanName: (view: string) => void;
    }>
) => {
    const service = useService();
    const getCapabilities = useCallback(
        () => service.getCapabilities(),
        [service]
    );
    const { fetching, data: capabilities } = useFetchResource(
        UserRoutes.HOME,
        getCapabilities
    );

    if (!capabilities || fetching) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                height="20vh"
            >
                <Grid item>
                    <CircularProgress style={{ alignSelf: 'center' }} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <EnumSelect
                    title="View Type"
                    value={props.viewType ?? ''}
                    valueLabels={capabilities.views.map((view) => [
                        view,
                        view
                            .split('_')
                            .map(
                                (frag) =>
                                    frag.charAt(0).toUpperCase() + frag.slice(1)
                            )
                            .join(' '),
                    ])}
                    onChange={props.setViewType}
                    required
                />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={6}>
                    <DateSelector
                        label="Start Date"
                        onChange={(value: Date) =>
                            props.setDateRange([value, props.dateRange[1]])
                        }
                        value={props.dateRange[0]}
                        minDate={dayjs('2023-09-01').endOf('day').toDate()}
                        maxDate={minDate()}
                        displayFormat="YYYY-MM-DD"
                        {...props}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateSelector
                        label="End Date"
                        onChange={(value: Date) =>
                            props.setDateRange([props.dateRange[0], value])
                        }
                        value={props.dateRange[1]}
                        minDate={dayjs('2023-09-01').endOf('day').toDate()}
                        maxDate={minDate()}
                        displayFormat="YYYY-MM-DD"
                        {...props}
                    />
                </Grid>
                <Grid item xs={12}>
                    {props.dateRangeError && (
                        <FormHelperText error>
                            {props.dateRangeError}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <EnumSelect
                    title="Period"
                    value={props.period}
                    valueLabels={PERIODS}
                    onChange={props.setPeriod}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <EnumSelect
                    title="Plan Name"
                    value={props.planName}
                    valueLabels={capabilities.plans.map((plan) => [
                        plan.name,
                        plan.name,
                    ])}
                    onChange={props.setPlanName}
                    undefinedLabel="All Plans"
                />
            </Grid>
        </Grid>
    );
};

export default ConfigureView;
