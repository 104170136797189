import React, { useState, useContext, FormEvent, SetStateAction } from 'react';
import posthog from 'posthog-js';
import { Link as RouterLink } from 'react-router-dom';

import { APIErrorType } from '../services/util';
import { Context as AuthContext, ActionType } from '../context/Auth';
import { useService as useSessionService } from '../services/Sessions';
import { AV_AUTH_ENDPOINT_URL, AV_LOGIN_URL } from '../config';

import logo from '../logo.png';
import loginGraphic from '../img/login-image.png';
import Button from '../components/Button';
import ConfirmActionDialog from '../components/ConfirmActionDialog';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

type FormFieldProps = TextFieldProps & {
    label: string;
};

type PasswordFieldProps = FormFieldProps & {
    showPassword: boolean;
    setShowPassword?: (value: SetStateAction<boolean>) => void;
};

export const FormField = ({ label, ...props }: FormFieldProps) => {
    return (
        <>
            <Typography color="textSecondary">{label}</Typography>
            <TextField variant="outlined" size="small" fullWidth {...props} />
        </>
    );
};

export const PasswordField = ({
    showPassword,
    setShowPassword,
    ...props
}: PasswordFieldProps) => {
    return (
        <FormField
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: setShowPassword && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword!((prev) => !prev)}
                            size="large"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

// TODO Mostly copied from Signup page. Maybe make a CenteredCard component for this.
const Login = (props: { allowImpersonation?: boolean }) => {
    const service = useSessionService();

    const { dispatch: authDispatch } = useContext(AuthContext);

    const { dispatch: notify } = useContext(NotificationContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOTP] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [impersonatedEmail, setImpersonatedEmail] = useState<string>();

    const [otpRequired, setOTPRequired] = useState(false);
    const [showLoginRedirect, setShowLoginRedirect] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            try {
                const res = await service.login(
                    {
                        email: email.toLowerCase().trim(),
                        password,
                        impersonatedEmail,
                        oneTimePassword: otpRequired ? otp : undefined,
                    },
                    true
                );

                authDispatch({
                    type: ActionType.LOGIN,
                    payload: res,
                });

                posthog.capture('Login', {
                    email: email.toLowerCase().trim(),
                    impersonatedEmail,
                });
            } catch (err: any) {
                setOTP('');

                if (err.type === APIErrorType.ONE_TIME_PASSWORD_REQUIRED) {
                    setOTPRequired(true);
                } else if (
                    err.type === APIErrorType.INVALID_CREDENTIALS &&
                    AV_AUTH_ENDPOINT_URL &&
                    AV_LOGIN_URL
                ) {
                    setOTPRequired(false);

                    // Try authenticating with AV
                    // If this fails, we revert to the original error. No need to catch
                    // errors here since they would be misleading.
                    try {
                        const res = await fetch(AV_AUTH_ENDPOINT_URL, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                email: email.toLowerCase().trim(),
                                password,
                            }),
                        });

                        if (res.status === 200) {
                            setShowLoginRedirect(true);
                        } else {
                            notify({
                                type: MessageType.ERROR,
                                message: err.message,
                            });
                        }
                    } catch {}
                } else {
                    setOTPRequired(false);

                    notify({
                        type: MessageType.ERROR,
                        message: err.message,
                    });
                }

                throw err;
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <ConfirmActionDialog
                title="Wrong Login Page"
                actionLabel="Take me there"
                confirm={() => {
                    window.location.assign(AV_LOGIN_URL);
                }}
                open={showLoginRedirect}
                onClose={() => setShowLoginRedirect(false)}
                text="You may be trying to sign into the Address Verification dashboard."
            />
            <Grid container alignItems="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <img
                        src={loginGraphic}
                        alt="Login Graphic"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h6">
                                    Log in to the Print & Mail Dashboard
                                </Typography>
                            </Grid>

                            {!otpRequired ? (
                                <>
                                    <Grid item>
                                        <FormField
                                            required
                                            label="Email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            type="email"
                                            autoFocus
                                        />
                                    </Grid>

                                    <Grid item>
                                        <PasswordField
                                            required
                                            label="Password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            showPassword={showPassword}
                                            setShowPassword={setShowPassword}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Typography align="right">
                                            <Link
                                                component={RouterLink}
                                                to="/forgot_password"
                                            >
                                                Forgot your password?
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item>
                                        <Typography align="left">
                                            Please check your email for a one
                                            time password.
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <FormField
                                            required
                                            label="One Time Password"
                                            value={otp}
                                            onChange={(e) =>
                                                setOTP(e.target.value)
                                            }
                                            type="text"
                                            autoFocus
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Typography align="right">
                                            <Link component={RouterLink} to="/">
                                                Didn't receive an email? Try
                                                again
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {props.allowImpersonation && (
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        type="email"
                                        label="Login As"
                                        value={impersonatedEmail}
                                        onChange={(e) => {
                                            setImpersonatedEmail(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    style={{ height: '55px' }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Don't have an account?{' '}
                                    <Link component={RouterLink} to="/signup">
                                        Sign up now
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
