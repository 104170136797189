import React, { PropsWithoutRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { PeriodKey } from '../services/LogisticsAnalytics';

import ConfigureView from './ConfigureView';
import Button from './Button';

const AddViewDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: () => void;
        createAnalyticsView: (props: {
            view: string;
            startDate: Date;
            endDate: Date;
            period: PeriodKey;
            planName: string;
        }) => Promise<void>;
    }>
) => {
    const [viewType, setViewType] = useState<string>('order_pipeline');
    const [dateRange, setDateRange] = useState<[Date, Date]>([
        new Date(),
        new Date(),
    ]);
    const [period, setPeriod] = useState<PeriodKey>('week');
    const [loading, setLoading] = useState(false);
    const [planName, setPlanName] = useState('');

    const dateRangeError = (() => {
        const [startDate, endDate] = dateRange;

        if (!startDate || !endDate) {
            return 'Select both a start and end date.';
        }

        if (startDate.getTime() > endDate.getTime()) {
            return 'End date must be later than start date';
        }

        return null;
    })();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            data-testid="add-view-dialog"
            PaperProps={{
                // @ts-ignore https://github.com/mui/material-ui/issues/27703
                component: 'form',
                onSubmit: async (e) => {
                    e.preventDefault();

                    const [startDate, endDate] = dateRange;

                    setLoading(true);

                    try {
                        await props.createAnalyticsView({
                            view: viewType,
                            startDate,
                            endDate,
                            period: period,
                            planName,
                        });
                    } catch (err) {
                        console.error(err);
                    }

                    setLoading(false);

                    props.onClose();
                },
            }}
        >
            <DialogContent style={{ minHeight: '300px' }}>
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        Create Analytics View
                    </Typography>
                </DialogTitle>
                <ConfigureView
                    viewType={viewType}
                    setViewType={setViewType}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    dateRangeError={dateRangeError}
                    period={period}
                    setPeriod={(period: PeriodKey | '') =>
                        setPeriod(period === '' ? 'week' : period)
                    }
                    planName={planName}
                    setPlanName={setPlanName}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="right" spacing={2}>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={props.onClose}
                            size="large"
                            fullWidth
                            disabled={loading}
                            data-testid="cancel-add-analytics-view-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            fullWidth
                            data-testid="add-analytics-view-button"
                            disabled={loading}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default AddViewDialog;
