import React, { useState, useContext, useCallback, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import { Context as ModeContext } from '../context/Mode';

import {
    CreateParams,
    Postcard,
    useService as usePostcardsService,
} from '../services/Postcards';

import { Contact } from '../services/Contacts';

import {
    emptyKeysRemoved,
    formatMergeVariables,
    unflatten,
} from '../services/util';
import { useOrganization } from '../services/Organization';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import SelectTemplate from '../components/SelectTemplate';
import GoBackButton from '../components/GoBackButton';
import FileUpload from '../components/FileUpload';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import ContactInput from '../components/ContactInput';
import ContactOrCSV from '../components/ContactOrCSV';
import BatchSend, {
    BatchSendStatus,
    BatchSendState,
    BatchSendProps,
} from '../components/BatchSend';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import MailingClassSelector from '../components/MailingClassSelector';
import SendDate from '../components/SendDate';
import { useCreatePostcard } from '../context/CreatePostcard';

import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';

import { PostcardRoutes } from '../routes';
import SelectPostcardSize from '../components/SelectPostcardSize';

const CreatePostcard = () => {
    const history = useHistory();

    const org = useOrganization([history.location]);

    const service = usePostcardsService();

    const { live } = useContext(ModeContext);
    const { dispatch } = useContext(NotificationContext);
    const {
        backTemplate,
        description,
        express,
        file,
        fromContact,
        frontTemplate,
        loading,
        mailingClass,
        mergeVars,
        size,
        setSize,
        setBackTemplate,
        setDescription,
        setExpress,
        setFile,
        setFromContact,
        setFrontTemplate,
        setLoading,
        setMailingClass,
        setMergeVars,
        setToContacts,
        setUploadedCSV,
        toContacts,
        resetState,
        sendDate,
        setSendDate,
    } = useCreatePostcard();

    useRegisterCreateOrderResetFunction(resetState);

    const [batchSendState, setBatchSendState] = useState<
        BatchSendState<Postcard>
    >({
        processedCount: 0,
        status: BatchSendStatus.READY,
        results: [],
    });

    const defaultVars = useDefaultVars(toContacts, fromContact);
    const templateVars = useTemplateVars(frontTemplate, backTemplate);

    const createBatch = useCallback(
        async (
            params: Parameters<BatchSendProps<Postcard>['createBatch']>[0]
        ) => {
            const mergeVariables = mergeVars && unflatten(mergeVars);

            return await service.createBatch({
                frontTemplate: frontTemplate ? frontTemplate.id : undefined,
                backTemplate: backTemplate ? backTemplate.id : undefined,
                pdf: file ?? undefined,
                size,
                sendDate,
                data: params.toContacts.map((c) => ({
                    description: description || c.description,
                    from: fromContact?.id,
                    to: c.id,
                    mergeVariables: emptyKeysRemoved({
                        ...mergeVariables,
                        ...c.metadata,
                        to: {
                            ...mergeVariables?.to,
                            ...c,
                        },
                    }),
                    metadata: { postgrid_dashboard: '' },
                })),
                express,
                handleProgress: params.handleProgress,
                mailingClass: express ? undefined : mailingClass,
            });
        },
        [
            description,
            service,
            frontTemplate,
            backTemplate,
            file,
            fromContact,
            size,
            mergeVars,
            express,
            sendDate,
            mailingClass,
        ]
    );

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            toContacts.length === 0 ||
            ((!frontTemplate || !backTemplate) && !file)
        ) {
            // This should be impossible given that all of the fields above are required
            return;
        }

        try {
            setLoading(true);

            const send = async (toContact: Contact) => {
                const postcard: CreateParams = {
                    description: description || toContact.description,
                    size,
                    to: toContact.id,
                    from: fromContact?.id,
                    express,
                    mailingClass: express ? undefined : mailingClass,
                    sendDate: sendDate,
                };

                const mergeVariables = formatMergeVariables(mergeVars);

                if (frontTemplate && backTemplate) {
                    return await service.create({
                        ...postcard,
                        frontTemplate: frontTemplate.id,
                        backTemplate: backTemplate.id,
                        mergeVariables,
                    });
                }

                return await service.createUploadPDF({
                    ...postcard,
                    pdf: file!,
                });
            };

            if (toContacts.length === 1) {
                await send(toContacts[0]);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Created postcard.',
                });

                history.push(PostcardRoutes.HOME);
            } else {
                setBatchSendState((state) => ({
                    ...state,
                    status: BatchSendStatus.IN_PROGRESS,
                }));
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    // TODO: Refactor using ref context and common order components for batches
    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Create a Postcard
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={3.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {live ? (
                                    <Alert variant="outlined" color="warning">
                                        You are in live mode so this postcard
                                        will be printed and delivered.
                                    </Alert>
                                ) : (
                                    <Alert variant="outlined" color="info">
                                        You are in test mode so this postcard
                                        will not actually get sent out.
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={onSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box height={13}></Box>
                                            <TextField
                                                variant="outlined"
                                                label="Description"
                                                fullWidth
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(
                                                        e.target.value
                                                    );
                                                }}
                                                inputProps={{
                                                    'data-testid':
                                                        'postcard-description',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectPostcardSize
                                                size={size}
                                                onChange={setSize}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactOrCSV
                                                label="To Contact"
                                                contacts={toContacts}
                                                setContacts={setToContacts}
                                                setUploadedCSV={setUploadedCSV}
                                                textFieldTestId="postcard-to-contact"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactInput
                                                label="From Contact"
                                                contact={fromContact}
                                                setContact={setFromContact}
                                                textFieldTestId="postcard-from-contact"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Front Template"
                                                        template={frontTemplate}
                                                        setTemplate={
                                                            setFrontTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            file ? true : false
                                                        }
                                                        textFieldTestId="postcard-front-template"
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Back Template"
                                                        template={backTemplate}
                                                        setTemplate={
                                                            setBackTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            file ? true : false
                                                        }
                                                        textFieldTestId="postcard-back-template"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FileUpload
                                                accept="application/pdf"
                                                label="Upload a PDF"
                                                file={file}
                                                setFile={setFile}
                                                required={
                                                    !frontTemplate &&
                                                    !backTemplate
                                                }
                                                disabled={
                                                    !!frontTemplate ||
                                                    !!backTemplate
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Collapse
                                                in={templateVars.length > 0}
                                            >
                                                <MergeVariablesInput
                                                    templateVars={templateVars}
                                                    mergeVars={mergeVars}
                                                    setMergeVars={setMergeVars}
                                                    defaultVars={defaultVars}
                                                />
                                            </Collapse>
                                        </Grid>

                                        <MailingClassSelector
                                            xs={6}
                                            mailingClass={mailingClass}
                                            onChange={setMailingClass}
                                            disabled={express}
                                            selectTestID="postcard-mailing-class"
                                        />
                                        <SendDate
                                            xs={6}
                                            setSendDate={setSendDate}
                                            sendDate={sendDate}
                                            showSubscriptionPopup={
                                                !org?.stripeSubscription
                                            }
                                        />

                                        <ExpressDeliveryCheckbox
                                            checked={express}
                                            setChecked={setExpress}
                                            checkboxTestID="postcard-express"
                                        />

                                        {batchSendState.status !==
                                            BatchSendStatus.READY && (
                                            <Grid item xs={12}>
                                                <BatchSend
                                                    state={batchSendState}
                                                    setState={setBatchSendState}
                                                    toContacts={toContacts}
                                                    createBatch={createBatch}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {batchSendState.status ===
                                                    BatchSendStatus.READY && (
                                                    <Grid item xs={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={loading}
                                                            size="large"
                                                            fullWidth
                                                        >
                                                            Create
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item xs={2}>
                                                    <GoBackButton
                                                        color="primary"
                                                        variant="outlined"
                                                        disabled={
                                                            loading ||
                                                            batchSendState.status ===
                                                                BatchSendStatus.IN_PROGRESS
                                                        }
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        {batchSendState.status ===
                                                            BatchSendStatus.COMPLETED ||
                                                        batchSendState.status ===
                                                            BatchSendStatus.ERROR
                                                            ? 'Back'
                                                            : 'Cancel'}
                                                    </GoBackButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreatePostcard;
