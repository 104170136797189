import React, { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface DetailCellProps {
    right: ReactNode;
    left: ReactNode;
    testIdPrefix?: string;
}

/**
 * Displays data in a tabular fashion using Mui grid
 * @param right Data to be displayed on right side
 * @param left Data to be displayed on the left
 * @returns
 */
const DetailCell = ({ right, left, testIdPrefix }: DetailCellProps) => {
    return (
        <Grid
            container
            item
            xs
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item xs={6}>
                <Typography
                    color="primary"
                    data-testid={testIdPrefix && `${testIdPrefix}-title`}
                >
                    {right}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                {typeof left === 'string' ? (
                    <Typography
                        style={{
                            whiteSpace: 'pre-line',
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                        }}
                        data-testid={testIdPrefix && `${testIdPrefix}-value`}
                    >
                        {left}
                    </Typography>
                ) : (
                    left
                )}
            </Grid>
        </Grid>
    );
};

export default DetailCell;
