import React, { useContext, FormEvent, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { useCreateCheque } from '../context/CreateCheque';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import { formatMergeVariables } from '../services/util';
import { Contact } from '../services/Contacts';
import {
    CreateParams,
    useService as useChequesService,
} from '../services/Cheques';
import { findVariables } from '../services/Templates';
import { useOrganization } from '../services/Organization';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import {
    Context as BackgroundSendContext,
    ActionType,
} from '../context/BackgroundSend';

import { Context as LiveApprovedContext } from '../context/LiveApproved';

import { CurrencyCode } from '../services/Cheques';

import Field from '../components/Field';
import SelectBankAccount from '../components/SelectBankAccount';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import ContactInput from '../components/ContactInput';
import AttachChequeLetter from '../components/AttachChequeLetter';
import ExtraServiceSelector from '../components/ExtraServiceSelector';
import MailingClassSelector from '../components/MailingClassSelector';
import MergeVariablesInput from '../components/MergeVariablesInput';
import StrictEnumSelect from '../components/StrictEnumSelect';
import ContactOrCSV from '../components/ContactOrCSV';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import CreateResourceActions from '../components/CreateResourceActions';
import SendDate from '../components/SendDate';
import SelectChequeSize from '../components/SelectChequeSize';
import CurrencyTextField from '../components/CurrencyTextField';

import { ChequeRoutes } from '../routes';

const braceCount = (s: string) => {
    let total = 0;

    for (const ch of s) {
        if (ch === '{' || ch === '}') {
            ++total;
        }
    }

    return total;
};

const CreateCheque = () => {
    const history = useHistory();

    const org = useOrganization([history.location]);

    const service = useChequesService();

    const { dispatch } = useContext(NotificationContext);
    const { dispatch: backgroundSendDispatch } = useContext(
        BackgroundSendContext
    );
    const { checkLiveApproved } = useContext(LiveApprovedContext);

    const {
        loading,
        setLoading,
        account,
        amount,
        currencyCode,
        letterSettings,
        description,
        file,
        fromContact,
        memo,
        mergeVars,
        message,
        number,
        express,
        resetState,
        setAccount,
        setAmount,
        setCurrencyCode,
        setLetterSettings,
        setDescription,
        setFile,
        setFromContact,
        setMemo,
        setExpress,
        setMergeVars,
        setMessage,
        setNumber,
        setTemplate,
        setToContacts,
        setUploadedCSV,
        mailingClass,
        setExtraService,
        setMailingClass,
        extraService,
        template,
        toContacts,
        uploadedCSV,
        attachLetter,
        setAttachLetter,
        sendDate,
        setSendDate,
        size,
        setSize,
    } = useCreateCheque();
    useRegisterCreateOrderResetFunction(resetState, true);

    useEffect(() => {
        checkLiveApproved(
            'Fill out our KYC form to get access to cheques in live mode.'
        );
    }, [history.location, checkLiveApproved]);

    const messageBraceCount = braceCount(message);

    // Rather than having our template vars update every time message changes,
    // we only update it when the user adds or removes braces. Otherwise performance suffers.
    const messageVars = useMemo(
        () => findVariables(message),
        // eslint-disable-next-line
        [messageBraceCount]
    );

    const defaultVars = useDefaultVars(toContacts, fromContact);
    const templateVars = useTemplateVars(template);

    const totalVars = useMemo(
        () => Array.from(new Set([...messageVars, ...templateVars])),
        [messageVars, templateVars]
    );

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!account || !size || toContacts.length === 0 || !fromContact) {
            // Not possible
            return;
        }

        try {
            setLoading(true);

            const send = async (to: Contact) => {
                const mergeVariables = formatMergeVariables(mergeVars, {
                    toMetadata: to.metadata,
                    uploadedCSV,
                });

                const cheque: Omit<CreateParams, 'amount'> = {
                    description: description || to.description,
                    to: to.id,
                    from: fromContact.id,
                    message,
                    bankAccount: account.id,
                    currencyCode,
                    letterSettings: attachLetter
                        ? letterSettings ?? undefined
                        : undefined,
                    mergeVariables,
                    letterTemplate: attachLetter ? template?.id : undefined,
                    letterPDF: attachLetter ? file ?? undefined : undefined,
                    extraService:
                        extraService !== '' ? extraService : undefined,
                    express,
                    mailingClass: express ? undefined : mailingClass,
                    sendDate: sendDate,
                    size,
                };

                if (!uploadedCSV) {
                    return await service.create({
                        ...cheque,
                        number: number ?? undefined,
                        // TODO Do not assume everything has a 100 denominator
                        amount: Math.round(amount * 100),
                        memo,
                    });
                }

                if (!to.metadata?.amount) {
                    throw new Error(`Field 'Amount' must not be empty`);
                }

                return await service.create({
                    ...cheque,
                    number: to.metadata?.chequeNumber,
                    // NOTE: Amount is converted in the mailing list import
                    amount: to.metadata.amount,
                    memo: to.metadata?.memo,
                });
            };

            if (toContacts.length === 1) {
                await send(toContacts[0]);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Created cheque.',
                });
                history.push(ChequeRoutes.HOME);
            } else {
                backgroundSendDispatch({
                    type: ActionType.SEND,
                    send,
                    to: toContacts,
                });

                history.push('/dashboard/background_sends');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Create a Cheque
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={3}>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Alert color="warning" variant="outlined">
                                        Cheque number will be generated
                                        automatically if not specified.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        value={description}
                                        setValue={setDescription}
                                        fullWidth
                                        label="Description"
                                        inputProps={{
                                            'data-testid': 'cheque-description',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactOrCSV
                                        required
                                        label="To Contact"
                                        contacts={toContacts}
                                        setContacts={setToContacts}
                                        setUploadedCSV={setUploadedCSV}
                                        sampleURL="https://docs.google.com/spreadsheets/d/1EDaYiq2ylQSX5xkUF1-egpaNSSxvEP2qKkbm2-uCYGI/edit?usp=sharing"
                                        textFieldTestId="cheque-to-contact"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactInput
                                        required
                                        label="From Contact"
                                        contact={fromContact}
                                        setContact={setFromContact}
                                        textFieldTestId="cheque-from-contact"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectBankAccount
                                        account={account}
                                        setAccount={(account) => {
                                            setAccount(account);
                                            setCurrencyCode(
                                                account?.bankCountryCode ===
                                                    'US'
                                                    ? CurrencyCode.USD
                                                    : CurrencyCode.CAD
                                            );
                                        }}
                                        label="Select a Bank Account"
                                        textFieldTestId="cheque-bank-account"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CurrencyTextField
                                        label="Amount"
                                        variant="outlined"
                                        value={amount}
                                        onChange={(_e, value) =>
                                            setAmount(value as number)
                                        }
                                        currencySymbol="$"
                                        minimumValue="0"
                                        fullWidth
                                        required
                                        disabled={uploadedCSV}
                                        textAlign="right"
                                        inputProps={{
                                            'data-testid': 'cheque-amount',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <StrictEnumSelect
                                        title="Currency"
                                        valueLabels={[
                                            [CurrencyCode.CAD, 'CAD'],
                                            [CurrencyCode.USD, 'USD'],
                                        ]}
                                        onChange={setCurrencyCode}
                                        value={currencyCode}
                                        selectTestID="cheque-currency"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        type="number"
                                        inputProps={{
                                            min: 0,
                                            step: 1,
                                            'data-testid': 'cheque-number',
                                        }}
                                        value={number ?? undefined}
                                        setValue={(value) =>
                                            setNumber(parseInt(value))
                                        }
                                        label="Cheque Number"
                                        disabled={uploadedCSV}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={memo}
                                        setValue={setMemo}
                                        label="Memo"
                                        disabled={uploadedCSV}
                                        inputProps={{
                                            'data-testid': 'cheque-memo',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        label="Message HTML"
                                        value={message}
                                        setValue={setMessage}
                                        multiline
                                        rows={8}
                                        inputProps={{
                                            'data-testid':
                                                'cheque-message-html',
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <SelectChequeSize
                                        label="Page Size"
                                        size={size}
                                        onChange={setSize}
                                        destinationCountryCode={
                                            toContacts[0]?.countryCode
                                        }
                                        selectTestID="cheque-size"
                                        xs={3}
                                    />
                                    <ExtraServiceSelector
                                        extraService={extraService}
                                        onChange={setExtraService}
                                        disabled={!!express}
                                        selectTestID="cheque-extra-service"
                                        xs={3}
                                    />
                                    <MailingClassSelector
                                        mailingClass={mailingClass}
                                        onChange={setMailingClass}
                                        disabled={express}
                                        selectTestID="cheque-mailing-class"
                                        xs={3}
                                    />
                                    <SendDate
                                        xs={3}
                                        setSendDate={setSendDate}
                                        sendDate={sendDate}
                                        showSubscriptionPopup={
                                            !org?.stripeSubscription
                                        }
                                    />
                                </Grid>
                                {
                                    // HACK taken directly from CreateLetter
                                }
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={attachLetter}
                                                    onChange={(e) => {
                                                        setAttachLetter(
                                                            e.target.checked
                                                        );
                                                        if (!e.target.checked) {
                                                            setTemplate(null);
                                                            setFile(null);
                                                            setLetterSettings(
                                                                null
                                                            );
                                                        } else {
                                                            setLetterSettings({
                                                                color: false,
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Attach a Letter"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ExpressDeliveryCheckbox
                                            checked={express}
                                            setChecked={setExpress}
                                            disabled={!!extraService}
                                            checkboxTestID="cheque-express"
                                        />
                                    </Grid>
                                </Grid>
                                <AttachChequeLetter textFieldTestID="cheque-template" />
                                <Grid item xs={12}>
                                    <Collapse in={totalVars.length > 0}>
                                        <MergeVariablesInput
                                            templateVars={totalVars}
                                            mergeVars={mergeVars}
                                            setMergeVars={setMergeVars}
                                            defaultVars={defaultVars}
                                        />
                                    </Collapse>
                                </Grid>
                                <Grid item xs={12}>
                                    <CreateResourceActions
                                        disabled={
                                            (attachLetter &&
                                                !(template || file)) ||
                                            loading
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateCheque;
