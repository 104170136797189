import React from 'react';

import Grid from '@mui/material/Grid';
import Button from './Button';
import AuthorizationHeader, {
    AuthorizationHeaderProps,
} from './AuthorizationHeader';

export interface AuthorizationWrapperProps extends AuthorizationHeaderProps {
    children?: React.ReactNode[];
    onSubmit:
        | ((e: React.FormEvent<HTMLFormElement>) => Promise<void>)
        | ((e: React.FormEvent<HTMLFormElement>) => void);
    buttonText: string;
    submitDisabled?: boolean;
}

const AuthorizationWrapper = ({
    onSubmit,
    headerText,
    buttonText,
    submitDisabled,
    children,
}: AuthorizationWrapperProps) => {
    return (
        <form onSubmit={onSubmit}>
            <AuthorizationHeader headerText={headerText} />
            <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
            >
                {children?.map((child, i) => (
                    <Grid item key={i}>
                        {child}
                    </Grid>
                ))}
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitDisabled}
                        size="large"
                        fullWidth
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AuthorizationWrapper;
