import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

import { useService as useInviteService } from '../services/Invites';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CompleteSignupInfoSSO from '../components/CompleteSignupInfoSSO';
import GraphicWrapper from '../components/GraphicWrapper';
import SignupWithSSO from '../components/SignupWithSSO';
import AuthorizationHeader from '../components/AuthorizationHeader';

// TODO:  Change this cuz its the exact same as Regular signup basically
// TODO:  Check if user exists when entering page
const CompleteSignupSSO = () => {
    const { id } = useParams<{ id: string }>();
    const { isAuthenticated } = useAuth0();
    const inviteService = useInviteService();

    const [loading, setLoading] = useState(false);
    const email = useRef('');

    // Check if an invite for the ID is pending
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const user = await inviteService.get(id);
                email.current = user.email;
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [inviteService, id]);

    // Loading indicator while we check if ID is valid
    if (loading) {
        return (
            <GraphicWrapper>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item>
                        <AuthorizationHeader />
                    </Grid>
                    <Grid
                        item
                        style={{
                            alignSelf: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Grid>
                </Grid>
            </GraphicWrapper>
        );
    }

    // If there is a currently logged in Auth0 user display finish screen
    if (isAuthenticated) {
        return <CompleteSignupInfoSSO id={id} />;
    }

    return <SignupWithSSO invited email={email.current} />;
};

export default CompleteSignupSSO;
