import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// TODO: Remove value and required as they are not used
export interface KeyValueInputProps {
    keys: string[];
    defaultValues?: Record<string, any>;
    value?: Record<string, string>;
    setValue: (value: Record<string, string>) => void;
    required?: boolean;
}

const KeyValueInput = ({
    keys,
    setValue,
    defaultValues,
}: KeyValueInputProps) => {
    const [pairs, setPairs] = useState<[string, string][]>([]);

    const updatePairValue = (key: string, value: string) => {
        const p = pairs.map(([k, v]) => (k === key ? [k, value] : [k, v]));
        setValue(Object.fromEntries(p));
        setPairs(p as [string, string][]);
    };

    useEffect(() => {
        const p = keys.map((k) => [k, defaultValues?.[k] || '']);
        setPairs(p as [string, string][]);
        setValue(Object.fromEntries(p));
        // adding `setValue` causes infinite re-renders
        // due to the props being updated and then `setValue`
        // being re-created again
        // eslint-disable-next-line
    }, [keys, defaultValues]);

    return (
        <Grid container direction="column" spacing={1}>
            {pairs.map(([key, value], i) => (
                <Grid container item alignItems="center" key={i}>
                    <Grid item xs={2}>
                        <Typography color="textSecondary" variant="body2">
                            {key}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={value}
                            onChange={(e) => {
                                updatePairValue(key, e.target.value);
                            }}
                            disabled={defaultValues?.[key] !== undefined}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default KeyValueInput;
