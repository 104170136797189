import React, { useState, useEffect, type ChangeEvent } from 'react';
import { Icon, NumericInput, Switch, Alignment } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { svgToURL } from 'polotno/utils/svg';

/**
 *
 * The majority of this code is adapted from the example from their documentation: https://polotno.com/docs/demo-grid/
 * and https://codesandbox.io/p/sandbox/polotno-grid-generation-xojpsf?file=%2Fsrc%2Findex.js%3A53%2C1-68%2C2
 *
 */

type Store = any;

// "dot" is a fake svg elements with no content
// but polotno will use it as snap point
function createDot(store: Store, x: number, y: number) {
    const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"></svg>`;
    const url = svgToURL(template);
    store.activePage.addElement({
        x,
        y,
        type: 'svg',
        width: 1,
        height: 1,
        src: url,
        name: 'grid',
        selectable: false,
    });
}

function clearGrid(store: Store) {
    const gridElements = store.activePage.children.filter(
        (child: any) => child.name === 'grid'
    );
    const ids = gridElements.map((el: any) => el.id);
    store.deleteElements(ids);
}

function generateGrid(store: Store, rows: number, cols: number) {
    clearGrid(store);
    const { width, height } = store;
    const dx = width / cols;
    const dy = height / rows;
    const rowArr = new Array(rows).fill(null);
    const colArr = new Array(cols).fill(null);

    // generate svg data for grid image
    const template = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">
    ${colArr
        .map(
            (_, index) =>
                `<line x1="${dx * (index + 1)}" y1="0" x2="${
                    dx * (index + 1)
                }" y2="${height}" stroke="blue" stroke-width="2"/>`
        )
        .join('')}
      ${rowArr
          .map(
              (_, index) =>
                  `<line x1="0" y1="${dy * (index + 1)}" x2="${width}" y2="${
                      dy * (index + 1)
                  }" stroke="blue" stroke-width="2"/>`
          )
          .join('')}
  </svg>`;

    // add grid image into the page
    const url = svgToURL(template);
    store.activePage.addElement({
        type: 'svg',
        width,
        height,
        src: url,
        name: 'grid',
        selectable: false,
        opacity: 0.2,
        alwaysOnTop: true,
        custom: { skipHTML: true },
    });

    // add fake elemtns to snap on grid
    rowArr.forEach((_, index) => {
        createDot(store, dx * (index + 1), 0);
    });
    colArr.forEach((_, index) => {
        createDot(store, 0, dy * (index + 1));
    });
}

const GridPanel = observer(({ store }: { store: Store }) => {
    const [visible, setVisible] = useState(false);
    const [rows, setRows] = useState(5);
    const [cols, setCols] = useState(5);

    useEffect(() => {
        if (!visible) {
            clearGrid(store);
        } else {
            generateGrid(store, rows, cols);
        }
    }, [rows, cols, visible, store]);

    return (
        <div>
            <div>
                <Switch
                    checked={visible}
                    onChange={(val: ChangeEvent<HTMLInputElement>) => {
                        setVisible(val.target.checked);
                    }}
                    alignIndicator={Alignment.RIGHT}
                    style={{
                        marginTop: '8px',
                        marginBottom: '25px',
                    }}
                >
                    Show grid
                </Switch>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    Rows:
                </div>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    <NumericInput
                        fill
                        value={rows}
                        onValueChange={(rows) => {
                            setRows(rows || 1);
                        }}
                        min={1}
                        max={100}
                        selectAllOnFocus
                    />
                </div>
            </div>
            <div style={{ paddingTop: '10px' }}>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    Cols:
                </div>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    <NumericInput
                        fill
                        value={cols}
                        onValueChange={(cols) => {
                            setCols(cols || 1);
                        }}
                        min={1}
                        max={100}
                        selectAllOnFocus
                    />
                </div>
            </div>
        </div>
    );
});

export const GridSection = {
    name: 'grid',
    Tab: (() => {
        const fn = (props: any) => (
            <SectionTab name="Grid" {...props}>
                <Icon icon="grid" />
            </SectionTab>
        );

        fn.displayName = 'Grid';
        return fn;
    })(),
    Panel: GridPanel,
};
