import React, { PropsWithChildren, useCallback } from 'react';

import {
    Template,
    useService as useTemplatesService,
} from '../services/Templates';

import SearchSelect, { SearchSelectProps } from './SearchSelect';

const SelectTemplate = (
    props: PropsWithChildren<{
        label: string;
        template: Template | null;
        setTemplate: (template: Template) => void;
        required?: boolean;
        size?: SearchSelectProps['size'];
        disabled?: boolean;
        textFieldTestId?: SearchSelectProps['textFieldTestId'];
    }>
) => {
    const service = useTemplatesService();

    const search = useCallback(
        async (text?: string) => {
            const res = await service.list({
                skip: 0,
                limit: 10,
                search: text,
            });

            return res.data;
        },
        [service]
    );

    return (
        <SearchSelect
            label={props.label}
            value={props.template}
            setValue={(value) => props.setTemplate(value)}
            getOptionLabel={(value) => value.description}
            getOptionSelected={(option, value) => option.id === value.id}
            required={props.required}
            size={props.size}
            search={search}
            disabled={props.disabled}
            textFieldTestId={props.textFieldTestId}
            resourceName="template"
        />
    );
};

export default SelectTemplate;
