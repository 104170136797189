import React, { PropsWithoutRef, useEffect, useContext } from 'react';

import { useHistory } from 'react-router';

import { Context as AuthContext } from '../context/Auth';

import { handleNotApprovedLiveMode } from '../services/util';
import { useOrganization } from '../services/Organization';

import ConfirmActionDialog from './ConfirmActionDialog';

const NotApprovedForLiveDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: () => void;
        text: string;
    }>
) => {
    const history = useHistory();

    const { state: authState } = useContext(AuthContext);

    const org = useOrganization([history.location]);

    useEffect(() => {
        (async () => {
            // We want to log when/how this occurs
            await handleNotApprovedLiveMode({
                name: authState.user?.name ?? '(Not Logged In)',
                email: authState.user?.email ?? '(Not Logged In)',
                organizationName: org?.name ?? '(Unknown)',
            });
        })();
    }, [org, authState]);

    const confirm = () => {
        window.open('https://www.postgrid.com/kyc', '_blank');
    };

    return (
        <ConfirmActionDialog
            title="Access Live Mode"
            actionLabel="Open Form"
            confirm={confirm}
            {...props}
        />
    );
};

export default NotApprovedForLiveDialog;
