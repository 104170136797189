import { useState, useCallback } from 'react';

export const useModal = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = useCallback(() => {
        setIsModalOpen((prev) => !prev);
    }, []);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return { isModalOpen, toggleModal, openModal, closeModal, setIsModalOpen };
};
