import React from 'react';

import { TextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { useModal } from '../hooks/useModal';

import RotateAPIKeyDialog from './RotateAPIKeyDialog';
import HideableTextField from './HideableTextField';
import CopyToClipboard from './CopyToClipboard';

type APIKeyProps = Omit<TextFieldProps, 'value'> & {
    title: string;
    value?: string;
};

const APIKey = ({ title, value, ...props }: APIKeyProps) => {
    const {
        openModal,
        closeModal,
        isModalOpen: isRotateDialogOpen,
    } = useModal();

    if (!value) return null;

    return (
        <Grid item xs={6}>
            <HideableTextField
                variant="outlined"
                label={title}
                id={title.toLocaleLowerCase().replace(/ /g, '-')}
                size="small"
                fullWidth
                inputProps={{
                    spellCheck: false,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <CopyToClipboard value={value} />

                            <IconButton
                                onClick={openModal}
                                aria-label="rotate api key"
                                size="small"
                            >
                                <RotateRightIcon />
                            </IconButton>
                        </>
                    ),
                }}
                value={value}
                {...props}
            />
            <RotateAPIKeyDialog
                open={isRotateDialogOpen}
                onClose={closeModal}
                isLiveKey={title.toLowerCase() === 'live'}
            />
        </Grid>
    );
};

export default APIKey;
