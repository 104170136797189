import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    unstable_registerShapeComponent,
    unstable_registerShapeModel,
} from 'polotno/config';
import { StoreType } from 'polotno/model/store';
import { Path as KonvaPath } from 'react-konva';

export const GUIDELINE = 'guideline';
interface ElementProps {
    onClick: Function;
    element: any;
    store: StoreType;
}

unstable_registerShapeModel({
    type: GUIDELINE,
    color: 'green',
    width: 500,
    height: 500,
    x: 0,
    y: 0,
});

interface Coords {
    width: number;
    height: number;
}
const rectanglePath = <T extends Coords>({
    height,
    width,
}: Pick<T, keyof Coords>) => {
    // M: Move to position
    // h: Horizontal line (distance from current spot cuz lowercase)
    // v: Vertical distance (from current spot cuz lowercase)
    // Z: Draw line to start position
    return `
        M 0 0 h ${width} v ${height} h ${-width}  Z
    `;
};

const GuidelineElement = observer(({ element }: ElementProps) => {
    return (
        <KonvaPath
            name="element"
            data={rectanglePath(element)}
            stroke={element.color}
            strokeWidth={element.strokeWidth ?? 2}
            width={element.width}
            height={element.height}
            dash={element.dash ?? [10, 5]}
            dashEnabled={element.dashEnabled ?? true}
            // NOTE(Apaar): Anton (Polotno dev) told us that we should probably be using
            // SVG elements for this use case but if we insist, setting this `listening` property
            // to false prevents it from absorbing mouse clicks:
            // https://discord.com/channels/782978534028214314/783479214364033034/1220436124644802652
            listening={false}
            x={element.x}
            y={element.y}
        />
    );
});

// @ts-ignore
unstable_registerShapeComponent(GUIDELINE, GuidelineElement);
export default GuidelineElement;
