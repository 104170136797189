import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AuthorizationWrapper, {
    AuthorizationWrapperProps,
} from './AuthorizationWrapper';
import GraphicWrapper from './GraphicWrapper';

export interface SignupWrapperProps extends AuthorizationWrapperProps {
    invited?: boolean;
    loginLink: string;
}

const useStyles = makeStyles((theme) => ({
    redirectLogin: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
}));

const SignupWrapper = ({
    onSubmit,
    buttonText,
    invited,
    submitDisabled,
    loginLink,
    children,
}: SignupWrapperProps) => {
    const classes = useStyles();

    return (
        <GraphicWrapper>
            <Grid container direction="column" alignItems="center">
                <AuthorizationWrapper
                    headerText={
                        invited
                            ? 'You were invited!'
                            : 'Sign up to get access to the dashboard.'
                    }
                    buttonText={buttonText ?? 'Sign Up'}
                    onSubmit={onSubmit}
                    submitDisabled={submitDisabled}
                    children={children}
                />
                <Grid item className={classes.redirectLogin}>
                    <Typography align="center" variant="body1">
                        Already have an account?{' '}
                        <Link component={RouterLink} to={loginLink}>
                            Sign in
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </GraphicWrapper>
    );
};

export default SignupWrapper;
