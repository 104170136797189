import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import TextField, { TextFieldProps } from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '7px',
            '& fieldset': {
                borderColor: '#D1D1D1',
            },
        },
        '& .MuiInputBase-root': {
            fontWeight: 300,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));

const Field = (
    props: TextFieldProps & {
        setValue: (s: string) => void;
    }
) => {
    const { setValue, id, ...otherProps } = props;

    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            fullWidth
            onChange={(e) => {
                setValue(e.target.value);
            }}
            InputLabelProps={{ ...otherProps.InputLabelProps, htmlFor: id }}
            inputProps={{ ...otherProps.inputProps, id: id }}
            {...otherProps}
            classes={classes}
        />
    );
};

export default Field;
