import React, { useContext } from 'react';
import { Context } from '../context/Mode';
import Grid from '@mui/material/Grid';
import Button from './Button';
import TooltipWrapper from './TooltipWrapper';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

interface FillButtonProps {
    onClick: () => void;
    disabled?: boolean;
}
export const FillButton = ({ disabled, onClick }: FillButtonProps) => {
    const { live } = useContext(Context);
    if (!live)
        return (
            <Grid item>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <Tooltip
                            title="Fills all outstanding orders for return envelopes.  This is only available in test mode."
                            data-testid="fill-description-tooltip"
                        >
                            <HelpIcon
                                fontSize="small"
                                color="disabled"
                                style={{ display: 'flex', alignSelf: 'center' }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <TooltipWrapper
                            title={
                                <span>
                                    You can only fill orders that have a status
                                    of <strong>placed</strong>.
                                </span>
                            }
                            disabled={disabled}
                        >
                            <Button onClick={onClick} disabled={disabled}>
                                Fill Test Order
                            </Button>
                        </TooltipWrapper>
                    </Grid>
                </Grid>
            </Grid>
        );
    return null;
};
