// Ensures a value for the environment variable exists before running the app
const ensureValueExists = (name: string) => {
    const env = process.env[name];
    if (env === undefined) {
        throw new Error(`You must have the ${name} environment variable set.`);
    }
    return env;
};

export const AV_AUTH_ENDPOINT_URL = process.env
    .REACT_APP_AV_AUTH_ENDPOINT_URL as string;
export const AV_LOGIN_URL = process.env.REACT_APP_AV_LOGIN_URL as string;

export const GTM_ID = process.env.REACT_APP_GTM_ID as string;
export const POSTCARDS_ENABLED =
    process.env.REACT_APP_POSTCARDS_ENABLED === 'true';
export const SIGNUP_WEBHOOK_URL = process.env
    .REACT_APP_SIGNUP_WEBHOOK_URL as string;
export const LIVE_APPROVAL_WEBHOOK_URL = process.env
    .REACT_APP_LIVE_APPROVAL_WEBHOOK_URL as string;
export const APP_MODE = process.env.REACT_APP_MODE as string;
export const RECAPTCHA_SITE_KEY = process.env
    .REACT_APP_RECAPTCHA_SITE_KEY as string;
export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY as string;

export const OAUTH_DOMAIN = ensureValueExists('REACT_APP_OAUTH_DOMAIN');
export const OAUTH_AUDIENCE = ensureValueExists('REACT_APP_OAUTH_AUDIENCE');
export const OAUTH_CLIENT_ID = ensureValueExists('REACT_APP_OAUTH_CLIENT_ID');

export const S3_IMAGE_BUCKET = ensureValueExists('REACT_APP_S3_IMAGE_BUCKET');

export const ZAPIER_CLIENT_ID = process.env.REACT_APP_ZAPIER_CLIENT_ID;
