import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useService as useTrackerService } from '../services/Trackers';
import { ListParams } from '../services/util';
import { usePagination } from '../hooks/usePagination';

import { SectionTab, ElementsSection } from 'polotno/side-panel';
import { InputGroup } from '@blueprintjs/core';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableDisplay from './TableDisplay';
import './TrackerTemplateToolbar';
import { TRACKER_BASE_SIZE, TRACKER_IMAGE } from './TrackerTemplateElement';

type SidePanelSection = typeof ElementsSection;

const POSTGRID_URL =
    'https://pg-prod-bucket-1.s3.amazonaws.com/assets/postgridqrcode.png';

const TrackersPanel = observer(({ store }: { store: any }) => {
    const service = useTrackerService();

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: trackers,
        loading,
        searchQuery,
        searchText,
        pagination,
    } = usePagination(paginationFunc, { defaultRowsPerPage: 100 });

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <InputGroup
                style={{
                    marginBottom: '20px',
                }}
                leftIcon="search"
                placeholder="Search..."
                onChange={(e) => {
                    searchQuery(e.target.value);
                }}
                value={searchText}
            />
            <TableDisplay
                columns={['Tracker Redirect URL']}
                show={loading}
                pagination={pagination}
                showEmptyTable
                size="small"
                hideFooter
            >
                {trackers.map((tracker) => {
                    return (
                        <TableRow
                            key={tracker.id}
                            hover
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                store.activePage.addElement({
                                    type: TRACKER_IMAGE,
                                    trackerID: tracker.id,
                                    width: TRACKER_BASE_SIZE,
                                    height: TRACKER_BASE_SIZE,
                                    src: POSTGRID_URL,
                                    x: store.width / 2 - TRACKER_BASE_SIZE / 2,
                                    y: store.height / 2 - TRACKER_BASE_SIZE / 2,
                                });
                            }}
                        >
                            <TableCell>
                                <Tooltip title={tracker.id} placement="right">
                                    <span style={{ overflowWrap: 'anywhere' }}>
                                        {tracker.redirectURLTemplate}
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableDisplay>
        </div>
    );
});

export const TrackersSection: SidePanelSection = {
    name: 'trackers',

    // @ts-ignore HACK
    Tab: (props) => (
        <SectionTab name="Trackers" {...props}>
            <QrCodeScanner />
        </SectionTab>
    ),
    Panel: TrackersPanel,
};

export default TrackersPanel;
