import React, {
    createContext,
    useState,
    useCallback,
    useContext,
    PropsWithChildren,
} from 'react';

import { useHistory } from 'react-router-dom';

import posthog from 'posthog-js';

import { useService as useOrgService } from '../services/Organization';

import MissingPaymentMethodDialog from '../components/MissingPaymentMethodDialog';

import { Context as AuthContext } from './Auth';

export const Context = createContext<{
    live: boolean;
    setLive: (value: boolean) => void;
}>({
    live: false,
    setLive: () => {},
});

export const Store = (props: PropsWithChildren<{}>) => {
    const history = useHistory();

    const wasLive = localStorage.getItem('live') === 'true';

    const { state: authState } = useContext(AuthContext);

    const service = useOrgService();

    const [missingPaymentMethodOpen, setMissingPaymentMethodOpen] =
        useState(false);

    const [live, setLiveInternal] = useState(wasLive);

    const setLiveStore = useCallback(
        (value: boolean) => {
            localStorage.setItem('live', value ? 'true' : 'false');
            setLiveInternal(value);

            // Send the user back to the overview because they might be in a bad state.
            history.push('/dashboard');
        },
        [setLiveInternal, history]
    );

    const setLive = useCallback(
        async (value: boolean) => {
            if (!authState.user) {
                return;
            }

            // Pre-emptively update but set back to false after the request
            setLiveStore(value);

            const org = await service.get();

            if (
                value &&
                !org.stripeMailingsPaymentMethod &&
                !org.usStripeMailingsPaymentMethod &&
                !org.externalPaymentMethod
            ) {
                setLiveStore(false);
                setMissingPaymentMethodOpen(true);

                return;
            }

            if (value) {
                posthog.capture('Accessed Live Mode', {
                    $set: {
                        accessedLiveMode: true,
                    },
                });
            }
        },
        [service, setLiveStore, authState]
    );

    return (
        <Context.Provider value={{ live, setLive }}>
            <MissingPaymentMethodDialog
                open={missingPaymentMethodOpen}
                onClose={() => {
                    setMissingPaymentMethodOpen(false);
                }}
                text="You need to attach a payment method in order to access live mode."
            />
            {props.children}
        </Context.Provider>
    );
};

export const useModeContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            '`useModeContext` can only be used within a `<Store />` provider.'
        );
    }

    return context;
};
