import logo from '../logo.png';

import loginGraphic from '../img/login-image.png';

import React, {
    FormEvent,
    useState,
    useRef,
    useEffect,
    useContext,
} from 'react';

import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { useService as useUsersService } from '../services/Users';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import Button from '../components/Button';

import { PasswordField } from './Login';

const ResetPassword = () => {
    const history = useHistory();

    const { token } = useParams<{ token: string }>();

    const service = useUsersService();

    const { dispatch } = useContext(NotificationContext);

    const confirmRef = useRef<HTMLInputElement>();

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);

    // HACK Copied from signup page but works literally the same way
    useEffect(() => {
        const match = password === confirmPassword;

        if (confirmRef.current) {
            confirmRef.current.setCustomValidity(
                match ? '' : 'Passwords do not match.'
            );
        }
    }, [password, confirmPassword]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            return;
        }

        try {
            setLoading(true);

            const res = await service.resetPassword(token, {
                password,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: `Successfully reset password for ${res.email}`,
            });

            history.push('/login');
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container alignItems="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <img
                        src={loginGraphic}
                        alt="Signup Graphic"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h4">
                                    <Box fontSize={24}>
                                        Enter your new password.
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <PasswordField
                                    label="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    showPassword={showPassword}
                                    setShowPassword={setShowPassword}
                                />
                            </Grid>
                            <Grid item>
                                <PasswordField
                                    inputRef={confirmRef}
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    showPassword={showPassword}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    size="large"
                                    fullWidth
                                >
                                    Reset Password
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Remembered your password?{' '}
                                    <Link component={RouterLink} to="/login">
                                        Sign in
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default ResetPassword;
