import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';
import { formatTableDate, ListParams } from '../services/util';

import InvocationOrderIDCell from './InvocationOrderIDCell';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import StatusCodeCell from './StatusCodeCell';
import TableDisplay from './TableDisplay';
import { Service } from '../services/Webhooks';

interface WebhookInvocationsTableProps {
    service: Service;
}

const WebhookInvocationsTable = ({ service }: WebhookInvocationsTableProps) => {
    const params = useParams<{ id: string }>();

    const paginationFunc = useCallback(
        (listParams: ListParams) => {
            return service.getInvocations(params.id, listParams);
        },
        [service, params.id]
    );

    const {
        data: invocations,
        loading,
        pagination,
    } = usePagination(paginationFunc);

    return (
        <>
            <Grid item style={{ marginTop: 20 }}>
                <Typography
                    variant="h5"
                    style={{ textTransform: 'capitalize' }}
                >
                    Invocations
                </Typography>
            </Grid>
            <Grid item style={{ width: '100%' }}>
                <TableDisplay
                    columns={[
                        'ID',
                        'Status Code',
                        'Type',
                        'Order ID',
                        'Created At',
                    ]}
                    show={loading}
                    pagination={pagination}
                    noDataMessage="This webhook has not been invoked yet."
                    wrapped
                >
                    {invocations.map((invocation) => {
                        return (
                            <TableRow key={invocation.id} hover>
                                <TableCell>{invocation.id}</TableCell>
                                <StatusCodeCell code={invocation.statusCode} />
                                <TableCell>{invocation.type}</TableCell>
                                <InvocationOrderIDCell
                                    orderID={invocation.orderID}
                                />
                                <TableCell>
                                    {formatTableDate(invocation.createdAt)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableDisplay>
            </Grid>{' '}
        </>
    );
};

export default WebhookInvocationsTable;
