import React, {
    type PropsWithoutRef,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { type LocalFile, type ParseStepResult, parse } from 'papaparse';

import { useNotificationContext } from '../context/Notification';
import {
    type MailingListImport,
    FileType,
    ProcessStatus as MailingListImportProcessStatus,
    useService as useMailingListImportService,
} from '../services/MailingListImports';
import {
    type MailingList,
    ProcessStatus as MailingListProcessStatus,
    useService as useMailingListService,
} from '../services/MailingLists';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';

import FileUpload from './FileUpload';
import RowErrors from './RowErrors';

export interface Row {
    Description?: string;
    'First Name'?: string;
    'Last Name'?: string;
    Email?: string;
    'Phone Number'?: string;
    'Company Name'?: string;
    'Job Title'?: string;
    Address?: string;
    'Address 2'?: string;
    City?: string;
    'Province or State'?: string;
    'Postal or Zip'?: string;
    'Country Code'?: string;
}

export const ADDRESS_MAPPING = {
    description: 'Description',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    companyName: 'Company Name',
    jobTitle: 'Job Title',
    addressLine1: 'Address',
    addressLine2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province or State',
    postalOrZip: 'Postal or Zip',
    countryCode: 'Country Code',
} as const;

const ADDITIONAL_ADDRESS_MAPPING = {
    amount: 'Amount',
    memo: 'Memo',
    chequeNumber: 'Cheque Number',
} as const;

const normalizeKey = (key: string) => key.toLowerCase().replace(/\s+/g, '');

const isValidRow = (r: Row) => {
    // Find keys for which there are valid non-empty values
    const normKeys = Object.entries(r)
        .filter(([_, v]) => typeof v === 'string' && v.trim().length !== 0)
        .map(([k, _]) => normalizeKey(k));

    return (
        (normKeys.includes('firstname') || normKeys.includes('companyname')) &&
        normKeys.includes('address') &&
        normKeys.includes('countrycode')
    );
};

const validateHeader = (
    header: string,
    uniqueValue: string,
    uniqueHeaderIndex: number,
    headerIndex: number
) => {
    if (header.toLocaleLowerCase().includes(uniqueValue)) {
        // A value of 0 means we have not seen the value before
        // as the values are initialized to 0
        if (uniqueHeaderIndex === 0) {
            // Return the index + 1 because the header could be at
            // position 0 and then our checks would not work for checking
            // if the index is 0 or negative.
            return headerIndex + 1;
        } else {
            return -1;
        }
    }
    return uniqueHeaderIndex;
};

const updateHeaders = (
    headers: string[],
    value: string,
    first: number,
    second?: number
) => {
    // Subtract 1 from the index when we check the position because we
    // add 1 to the index when we validate the header
    if (second !== undefined) {
        if (first > 0 && second === 0) {
            headers[first - 1] = value;
        }
        if (second > 0 && first === 0) {
            headers[second - 1] = value;
        }

        return;
    }

    if (first > 0) {
        headers[first - 1] = value;
    }
};

export const parseHeaders = (headers: string[]) => {
    // Store a number to check the index of the header.
    //
    // Store the index of the header + 1 in the number if it is the first time
    // we have seen the unique header (value is 0).
    // Add +1 to the index to be able to check the case of the first time we
    // are seeing the unique value ('name' set to 0 initially) and that
    // value is the first in the header list (index 0).
    // When we insert the value into the list, subtract 1 from the index value
    // to account for that.
    // If we have seen the header before (value is greater than 0), then we
    // set the value to a negative value to indicate that this value is not
    // unique.
    let nameIndex = 0;
    let provinceIndex = 0;
    let stateIndex = 0;
    let countryIndex = 0;
    let zipIndex = 0;
    let postalIndex = 0;

    for (const [i, header] of headers.entries()) {
        nameIndex = validateHeader(header, 'name', nameIndex, i);
        provinceIndex = validateHeader(header, 'province', provinceIndex, i);
        stateIndex = validateHeader(header, 'state', stateIndex, i);
        countryIndex = validateHeader(header, 'country', countryIndex, i);
        zipIndex = validateHeader(header, 'zip', zipIndex, i);
        postalIndex = validateHeader(header, 'postal', postalIndex, i);
    }

    updateHeaders(headers, 'Company Name', nameIndex);
    updateHeaders(headers, 'Province or State', provinceIndex, stateIndex);
    updateHeaders(headers, 'Country Code', countryIndex);
    updateHeaders(headers, 'Postal or Zip', postalIndex, zipIndex);

    return headers;
};

interface CSVData {
    validRowCount: number;
    invalidRowCount: number;
    headers: string[];
}

export const parseCSV = (file: File): Promise<CSVData> => {
    return new Promise((resolve) => {
        let headers: string[] = [];
        let invalidRowCount = 0;
        let validRowCount = 0;
        let first = true;

        parse(file as LocalFile, {
            // Allows many different delimeters, restrict to only commas as
            // our backend currently only supports this
            delimiter: ',',
            step: (row: ParseStepResult<string[]>) => {
                // First row will be the headers
                if (first) {
                    first = false;
                    headers = parseHeaders(row.data);
                    return;
                }

                // Create a `row` object from the headers
                const _row: Record<string, string> = {};
                for (const [i, key] of headers.entries()) {
                    _row[key] = row.data[i];
                }

                if (isValidRow(_row)) {
                    ++validRowCount;
                } else {
                    ++invalidRowCount;
                }
            },
            complete: () => {
                resolve({
                    validRowCount,
                    invalidRowCount,
                    headers,
                });
            },
        });
    });
};

export interface CompletedUploadData {
    mailingList: MailingList;
    mailingListImport: MailingListImport;
}

type UploadContactsDialogProps = PropsWithoutRef<{
    open: boolean;
    onClose: (e: {}) => void;
    onCompleted: (d: CompletedUploadData) => void;
    sampleURL?: string;
}>;

export const generateReceiverMappings = (userHeaders: string[]) => {
    const additionalMappingKeys = Object.keys(ADDITIONAL_ADDRESS_MAPPING);

    const userNormalizedHeaderToHeader = new Map(
        userHeaders.map((key) => {
            return [normalizeKey(key), key];
        })
    );

    // Normalize the headers and check if they are present within the
    // user's normailzed header map. If they are, extract the headers out
    // for them so they can use customized headers.
    const receiverAddressMapping: Record<string, string> = {};

    for (const [mappingKey, columnHeader] of Object.entries(ADDRESS_MAPPING)) {
        const mappedHeader = userNormalizedHeaderToHeader.get(
            normalizeKey(columnHeader)
        );

        if (mappedHeader) {
            receiverAddressMapping[mappingKey] = mappedHeader;
        }
    }

    const receiverMergeVariableMapping = Object.fromEntries(
        userHeaders
            .filter(
                (header) =>
                    !Object.values(receiverAddressMapping).includes(header)
            )
            .map((header) => {
                for (const key of additionalMappingKeys) {
                    if (normalizeKey(header) === normalizeKey(key)) {
                        return [key, header];
                    }
                }

                return [header, header];
            })
    );

    return { receiverAddressMapping, receiverMergeVariableMapping };
};

const UploadContactsDialog = ({
    onClose,
    open,
    sampleURL,
    onCompleted,
}: UploadContactsDialogProps) => {
    const { dispatchError, dispatchInfo } = useNotificationContext();
    const listImportService = useMailingListImportService();
    const listService = useMailingListService();

    const [file, setFile] = useState<File | null>(null);
    const [processingStage, setProcessingStage] = useState<
        'verifying_addresses' | 'creating_contacts' | null
    >(null);
    const [csvData, setCSVData] = useState<CSVData>({
        invalidRowCount: 0,
        validRowCount: 0,
        headers: [],
    });

    useEffect(() => {
        if (file) {
            parseCSV(file).then(setCSVData);
        }
    }, [file]);

    const createMailingListImport = useCallback(async () => {
        if (!file) {
            // We know we should have a file at this point, assert as so
            throw new Error(
                'Something went wrong while processing your mailing list import.'
            );
        }

        const { receiverAddressMapping, receiverMergeVariableMapping } =
            generateReceiverMappings(csvData.headers);

        let mailingListImport = await listImportService.create({
            file,
            fileType: FileType.CSV,
            receiverAddressMapping,
            receiverMergeVariableMapping,
        });

        // Approx 2 hours of time
        const MAX_ATTEMPTS = 15_000;
        for (let i = 0; i < MAX_ATTEMPTS; ++i) {
            mailingListImport = await listImportService.get(
                mailingListImport.id
            );

            if (
                mailingListImport.status ===
                MailingListImportProcessStatus.COMPLETED
            ) {
                return mailingListImport;
            }

            if (
                mailingListImport.status ===
                MailingListImportProcessStatus.CHANGES_REQUIRED
            ) {
                throw new Error(
                    mailingListImport.errors
                        .map((err) => err.message)
                        .join('\n')
                );
            }

            await new Promise((res) => setTimeout(res, 250));
        }

        throw new Error(
            'Mailing list processing took too long. Please try again later.'
        );
    }, [listImportService, file, csvData.headers]);

    const processRows = useCallback(async () => {
        if (!file || !csvData.validRowCount) {
            dispatchError('A file containing valid contacts is required.');
        }

        setProcessingStage('verifying_addresses');

        try {
            const mailingListImport = await createMailingListImport();

            if (mailingListImport.notes.length) {
                dispatchInfo(
                    mailingListImport.notes
                        .map((note) => note.message)
                        .join('\n')
                );
            }

            setProcessingStage('creating_contacts');

            const mailingList = await listService.create({
                metadata: { postgrid_dashboard: '' },
            });
            await listService.createJob(mailingList.id, {
                addMailingListImports: [mailingListImport.id],
            });

            const MAX_ATTEMPTS = 15_000;
            for (let i = 0; i < MAX_ATTEMPTS; ++i) {
                const processingList = await listService.get(mailingList.id);

                if (
                    processingList.status === MailingListProcessStatus.COMPLETED
                ) {
                    onCompleted({
                        mailingListImport,
                        mailingList,
                    });

                    setFile(null);
                    setProcessingStage(null);
                    return;
                }

                await new Promise((res) => setTimeout(res, 250));
            }

            throw new Error(
                'Mailing list processing took too long. Please try again later.'
            );
        } catch (e) {
            dispatchError((e as Error).message);
            setFile(null);
            setProcessingStage(null);
        }
    }, [
        createMailingListImport,
        csvData,
        file,
        dispatchError,
        listService,
        onCompleted,
    ]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-testid="upload-contacts-dialog"
        >
            <DialogContent>
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        Upload a CSV File
                    </Typography>
                </DialogTitle>
                <DialogContentText>
                    <Typography component="span">
                        You can create a large number of contacts at once by
                        uploading a CSV file. Download a sample CSV file{' '}
                        <Link
                            rel="noopener"
                            target="_blank"
                            href={
                                sampleURL ||
                                'https://docs.google.com/spreadsheets/d/1Ke7m8X-IyTVTyZ0vpVgJokdtSrsIxKv-o5SjeRPHSYU/edit?usp=sharing'
                            }
                        >
                            here
                        </Link>
                        . Note that you can add your own columns and those will
                        be supplied as variable data to your orders.
                    </Typography>
                </DialogContentText>
                <FileUpload
                    label="Upload CSV File"
                    accept="text/csv"
                    file={file}
                    setFile={setFile}
                    disabled={!!processingStage}
                />
                {file && (
                    <RowErrors
                        validRowCount={csvData.validRowCount}
                        invalidRowCount={csvData.invalidRowCount}
                    />
                )}
                {processingStage && (
                    <>
                        <Typography
                            color="primary"
                            sx={{
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            {processingStage === 'verifying_addresses'
                                ? 'Verifying Addresses'
                                : 'Creating Contacts'}
                        </Typography>
                        <LinearProgress color="primary" />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            size="large"
                            fullWidth
                            disabled={!!processingStage}
                            data-testid="cancel-upload-contacts-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async (e) => {
                                await processRows();
                                onClose(e);
                            }}
                            size="large"
                            fullWidth
                            disabled={
                                !!processingStage ||
                                csvData.validRowCount <= 0 ||
                                !file
                            }
                            data-testid="upload-contacts-button"
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default UploadContactsDialog;
