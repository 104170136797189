import { useMemo } from 'react';
import { Service as BaseService, useService as useBaseService } from './Base';
import { LoginPayload } from '../context/Auth';

interface LoginParams {
    email?: string;
    password?: string;
    accessToken?: string;
    impersonatedEmail?: string;
    oneTimePassword?: string;
}

export class Service {
    base: BaseService;
    route: string;

    constructor(service: BaseService, route: string) {
        this.base = service;
        this.route = route;
    }

    async login(data: LoginParams, silent?: boolean) {
        return await this.base.fetchAPI<LoginPayload>(
            `${this.route}?expand[]=user`,
            {
                method: 'POST',
                body: data,
                silent,
            }
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, '/sessions'), [base]);
};
