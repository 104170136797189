import React from 'react';
import { observer } from 'mobx-react-lite';
import { unstable_registerToolbarComponent } from 'polotno/config';
import { Navbar, Alignment, NumericInput, InputGroup } from '@blueprintjs/core';
import { TRACKER_IMAGE } from './TrackerTemplateElement';
import Typography from '@mui/material/Typography';
import Button from './Button';
import { TrackerRoutes } from '../routes';

const TrackerTemplateToolbar = observer(({ store }: { store: any }) => {
    const element = store.selectedElements[0];

    return (
        <Navbar.Group align={Alignment.LEFT}>
            <Typography style={{ padding: '0px 5px 0px 5px' }}>ID:</Typography>
            <InputGroup
                type="text"
                id="tracker-id"
                placeholder="ID"
                style={{ padding: '0px 5px 0px 5px' }}
                onChange={(e) => {
                    e.preventDefault();
                }}
                value={element.trackerID}
            />
            <Typography style={{ padding: '0px 5px 0px 10px' }}>
                Size:
            </Typography>
            <NumericInput
                onValueChange={(size) => {
                    element.set({ size: size });
                }}
                value={element.size}
                style={{ width: '56px' }}
                min={40}
                max={400}
            />
            <Button
                style={{ margin: '0px 10px 0px 10px' }}
                variant="contained"
                color="primary"
                size="small"
                href={`${TrackerRoutes.HOME}/${element.trackerID}`}
                rel="noopener noreferrer"
                target="_blank"
            >
                Open Tracker
            </Button>
        </Navbar.Group>
    );
});

unstable_registerToolbarComponent(TRACKER_IMAGE, TrackerTemplateToolbar);
export default TrackerTemplateToolbar;
