import React from 'react';

import { useHistory } from 'react-router-dom';

import ConfirmActionDialog from '../components/ConfirmActionDialog';

import { UpgradeRoutes } from '../routes';

const SubscribeDialog = (props: {
    popup: boolean;
    setPopup: (p: boolean) => void;
}) => {
    const history = useHistory();

    const confirm = () => {
        history.push(UpgradeRoutes.HOME);
    };

    return (
        <ConfirmActionDialog
            title="Not Subscribed"
            actionLabel="Subscribe"
            confirm={confirm}
            open={props.popup}
            onClose={() => props.setPopup(false)}
            text="Please subscribe in order to schedule orders on the dashboard."
        />
    );
};

export default SubscribeDialog;
