import React from 'react';

import { WebhookPayloadFormat } from '../services/Webhooks';

import Grid from '@mui/material/Grid';
import TooltipWrapper from './TooltipWrapper';
import BaseEnumSelect from './BaseEnumSelect';

interface WebhookPayloadFormatProps {
    onChange: (format: WebhookPayloadFormat) => void;
    format: WebhookPayloadFormat;
}

const FORMAT_OPTION_LABELS: [WebhookPayloadFormat, string][] = Object.entries(
    WebhookPayloadFormat
).map(([key, value]) => [value, key]);

const WebhookPayloadSelector = ({
    onChange,
    format,
}: WebhookPayloadFormatProps) => {
    return (
        <TooltipWrapper title="Payload Format">
            <Grid item>
                <BaseEnumSelect
                    title="Payload Format"
                    valueLabels={FORMAT_OPTION_LABELS}
                    selectTestID={format}
                    onChange={onChange}
                    value={format}
                />
            </Grid>
        </TooltipWrapper>
    );
};

export default WebhookPayloadSelector;
