import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';

export const PERIODS = [
    ['year', 'Year'],
    ['quarter', 'Quarter'],
    ['month', 'Month'],
    ['week', 'Week'],
    ['day', 'Day'],
] as const;

export type PeriodKey = typeof PERIODS[number][0];

export interface BaseLogisticsAnalytics {
    startDate: Date;
    endDate: Date;
    period: PeriodKey;
    planName?: string;
}

export interface OrderPipelineView {
    date: string;
    totalOrders: number;
    sentOrders: number;
    pausedOrders: number;
    cancelledOrders: number;
    trackedOrders: number;
}

export interface OrderPipelineLogisticsAnalytics
    extends BaseLogisticsAnalytics {
    view: 'order_pipeline';
    data: OrderPipelineView[];
}

export interface VolumeView {
    date: string;
    volume: number;
}

export interface VolumeLogisticsAnalytics extends BaseLogisticsAnalytics {
    view: 'volume';
    data: VolumeView[];
}

export interface VolumeByCountryView {
    date: string;
    countryVolumes: Record<string, number>;
}

export interface VolumeByCountryLogisticsAnalytics
    extends BaseLogisticsAnalytics {
    view: 'volume_by_country';
    data: VolumeByCountryView[];
}

export interface CustomerView {
    date: string;
    total: number;
}

export interface TotalCustomersLogisticsAnalytics
    extends BaseLogisticsAnalytics {
    view: 'total_customers';
    data: CustomerView[];
}

export interface NewCustomersLogisticsAnalytics extends BaseLogisticsAnalytics {
    view: 'new_customers';
    data: CustomerView[];
}

export interface RevenueView {
    date: string;
    revenue: number;
}

export interface RevenueLogisticsAnalytics extends BaseLogisticsAnalytics {
    view: 'revenue';
    data: RevenueView[];
}

export interface ProfitOrLossView {
    date: string;
    profitOrLoss: number;
}

export interface ProfitOrLossLogisticsAnalytics extends BaseLogisticsAnalytics {
    view: 'profit_or_loss';
    data: ProfitOrLossView[];
}

export interface ProfitMarginView {
    date: string;
    profitMargin: number;
}

export interface ProfitMarginLogisticsAnalytics extends BaseLogisticsAnalytics {
    view: 'profit_margin';
    data: ProfitMarginView[];
}

export type LogisticsAnalytics =
    | OrderPipelineLogisticsAnalytics
    | VolumeLogisticsAnalytics
    | VolumeByCountryLogisticsAnalytics
    | TotalCustomersLogisticsAnalytics
    | NewCustomersLogisticsAnalytics
    | RevenueLogisticsAnalytics
    | ProfitOrLossLogisticsAnalytics
    | ProfitMarginLogisticsAnalytics;

export interface LogisticsAnalyticsCapabilities {
    views: string[];
    plans: {
        id: number;
        name: string;
        limit: number;
    }[];
}

export interface GetViewParams {
    // TODO(Apaar): Change this to strictly typed options
    view: string;
    startDate: Date;
    endDate: Date;
    period: PeriodKey;
    planName?: string;
}

export class Service {
    constructor(public base: BaseService) {}

    async getView(params: GetViewParams): Promise<LogisticsAnalytics> {
        const urlParams = new URLSearchParams();

        urlParams.set('view', params.view);
        urlParams.set(
            'startDate',
            dayjs(params.startDate).format('YYYY-MM-DD')
        );
        urlParams.set('endDate', dayjs(params.endDate).format('YYYY-MM-DD'));
        urlParams.set('period', params.period);

        if (params.planName) {
            urlParams.set('planName', params.planName);
        }

        return await this.base.fetchAPI(
            `/logistics/analytics?${urlParams.toString()}`
        );
    }

    async getCapabilities(): Promise<LogisticsAnalyticsCapabilities> {
        return await this.base.fetchAPI('/logistics/analytics/capabilities');
    }
}

export const useService = (): Service => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
