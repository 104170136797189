import logo from '../logo.png';

import loginGraphic from '../img/login-image.png';

import React, {
    FormEvent,
    useState,
    useRef,
    useEffect,
    useContext,
} from 'react';

import posthog from 'posthog-js';

import {
    useHistory,
    useLocation,
    useParams,
    Link as RouterLink,
} from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { RECAPTCHA_SITE_KEY } from '../config';

import { handleSignup } from '../services/util';
import { useService as useUsersService } from '../services/Users';
import { useService as useInvitesService } from '../services/Invites';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { ActionType, Context as AuthContext } from '../context/Auth';

import Button from '../components/Button';
import CountrySelect from '../components/CountrySelect';

import { FormField, PasswordField } from './Login';
import TermsAndConditons from '../components/TermsAndConditions';

// Simple regex allowing for only one '@'
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Signup = () => {
    const history = useHistory();
    const location = useLocation();
    const { userID: inviteUserID } = useParams<{ userID: string }>();

    const service = useUsersService();
    const invService = useInvitesService();

    const { dispatch } = useContext(NotificationContext);
    const { dispatch: authDispatch } = useContext(AuthContext);

    const queryParams = new URLSearchParams(location.search);

    const [name, setName] = useState(queryParams.get('uname') || '');
    const [email, setEmail] = useState(queryParams.get('email') || '');

    const locale = new Intl.Locale(navigator.language);
    const [countryCode, setCountryCode] = useState(locale.region ?? 'CA');

    const [phoneNumber, setPhoneNumber] = useState(
        queryParams.get('pnumber') || ''
    );

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [organizationName, setOrganizationName] = useState(
        queryParams.get('company') || ''
    );

    const [loading, setLoading] = useState(!!inviteUserID);

    const confirmRef = useRef<HTMLInputElement>();
    const emailRef = useRef<HTMLInputElement>();

    const recaptchaRef = useRef<any>();

    // Sign out the user when they hit this page
    useEffect(() => {
        authDispatch({
            type: ActionType.LOGOUT,
        });
    }, [history.location, authDispatch]);

    useEffect(() => {
        if (inviteUserID) {
            (async () => {
                try {
                    setLoading(true);

                    const res = await invService.get(inviteUserID);

                    setName(res.name);
                    setEmail(res.email);
                } catch (err) {
                    console.error(err);
                }

                setLoading(false);
            })();
        }
    }, [inviteUserID, invService]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== confirmPassword || !recaptchaRef.current) {
            return;
        }

        try {
            setLoading(true);

            // Check the recaptcha
            const recaptchaToken = await recaptchaRef.current.executeAsync();

            if (!inviteUserID) {
                const fixedEmail = email.toLowerCase().trim();

                const event = {
                    name,
                    email: fixedEmail,
                    organizationName,
                    phoneNumber,
                    countryCode,
                };

                await handleSignup(event);

                await service.createWithOrganization({
                    ...event,
                    password,
                    recaptchaToken,
                });

                posthog.capture('Signup', event);

                dispatch({
                    type: MessageType.SUCCESS,
                    message:
                        'Signed up successfully. Please check your inbox for a verification email.',
                });

                history.push(
                    `/signup_check_email?email=${encodeURIComponent(
                        fixedEmail
                    )}`
                );
            } else {
                await invService.complete(inviteUserID, {
                    name,
                    password,
                });

                history.push(`/login`);
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        const match = password === confirmPassword;

        if (confirmRef.current) {
            confirmRef.current.setCustomValidity(
                match ? '' : 'Passwords do not match.'
            );
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        const valid = EMAIL_REGEX.test(email);
        if (emailRef.current) {
            emailRef.current.setCustomValidity(
                valid
                    ? ''
                    : `Please enter an email in the form of 'name@example.com'`
            );
        }
    }, [email]);

    return (
        <form onSubmit={onSubmit}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_SITE_KEY}
            />

            <Grid container alignItems="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={6} style={{ height: '100vh' }}>
                    <img
                        src={loginGraphic}
                        alt="Signup Graphic"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h4">
                                    <Box fontSize={24}>
                                        {inviteUserID
                                            ? 'You were invited!'
                                            : 'Sign up to get access to the dashboard.'}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Box mt={1}>
                                    <FormField
                                        required
                                        type="text"
                                        label="Full Name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        autoFocus
                                        inputProps={{ siqatrib: 'name' }}
                                    />
                                </Box>
                            </Grid>

                            {!inviteUserID && (
                                <Grid item>
                                    <FormField
                                        required
                                        type="text"
                                        label="Company Name"
                                        value={organizationName}
                                        onChange={(e) =>
                                            setOrganizationName(e.target.value)
                                        }
                                        inputProps={{ siqatrib: 'company' }}
                                    />
                                </Grid>
                            )}

                            <Grid item>
                                <FormField
                                    required
                                    type="email"
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={!!inviteUserID}
                                    inputProps={{ siqatrib: 'email' }}
                                    inputRef={emailRef}
                                />
                            </Grid>

                            <Grid item>
                                <FormField
                                    type="tel"
                                    label="Phone Number"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <CountrySelect
                                    size="small"
                                    field={FormField}
                                    countryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                />
                            </Grid>

                            <Grid item>
                                <PasswordField
                                    label="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    showPassword={showPassword}
                                    setShowPassword={setShowPassword}
                                />
                            </Grid>
                            <Grid item>
                                <PasswordField
                                    inputRef={confirmRef}
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    showPassword={showPassword}
                                />
                            </Grid>

                            <Grid item>
                                <TermsAndConditons />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    size="large"
                                    fullWidth
                                >
                                    Sign Up
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Already have an account?{' '}
                                    <Link component={RouterLink} to="/login">
                                        Sign in
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        </form>
    );
};

export default Signup;
