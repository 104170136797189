import React, { ReactNode } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table, { TableProps } from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableBodyLoader from './TableBodyLoader';
import ResetTablePagination, {
    ResetTablePaginationProps,
} from './ResetTablePagination';
import PaperWrapper from './PaperWrapper';
import EmptyTableDisplay from './EmptyTableDisplay';

export interface TableDisplayProps {
    // TODO: Change name from `show` is a bit confusing since this shows
    // the loader not the actual data
    show: boolean;
    columns: ReactNode[];
    pagination?: ResetTablePaginationProps;
    showEmptyTable?: boolean;
    noDataMessage?: string;
    wrapped?: boolean;
    size?: TableProps['size'];
    containerStyle?: TableProps['style'];
    stickyHeader?: boolean;
    children?: React.ReactNode;
    hideFooter?: boolean;
}

const TableComponent = ({
    columns,
    show,
    pagination,
    showEmptyTable = false,
    noDataMessage,
    wrapped,
    children,
    size,
    containerStyle,
    stickyHeader,
    hideFooter,
}: TableDisplayProps) => {
    const showEmptyComponent = (() => {
        if (
            !show &&
            React.Children.count(children) <= 0 &&
            showEmptyTable === false
        )
            return true;
        return false;
    })();
    return (
        <TableContainer data-testid="table-display" style={containerStyle}>
            <Table stickyHeader={stickyHeader} size={size}>
                <TableHead>
                    <TableRow>
                        {columns.map((name) => {
                            if (!name) return null;
                            // HACK: Idk why someone would use the same name twice
                            // in a table so this should be good
                            return (
                                <TableCell key={name.toString()}>
                                    {name}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBodyLoader
                    show={show}
                    columns={columns.length}
                    data-testid="table-body"
                >
                    {showEmptyComponent ? (
                        <EmptyTableDisplay
                            colSpan={columns.length}
                            noDataMessage={noDataMessage}
                        />
                    ) : (
                        children
                    )}
                </TableBodyLoader>
                {!hideFooter && pagination && (
                    <TableFooter>
                        <TableRow>
                            <ResetTablePagination
                                wrapped={wrapped}
                                {...pagination}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

const TableDisplay = (props: TableDisplayProps) => {
    if (props.wrapped) {
        return (
            <PaperWrapper>
                <TableComponent {...props} />
            </PaperWrapper>
        );
    }

    return <TableComponent {...props} />;
};

export default TableDisplay;
