import React from 'react';

import { WebhookEventType } from '../services/Webhooks';

import DetailWrapper from './DetailWrapper';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

interface WebhookEventsProps {
    onChange: (event: WebhookEventType) => void;
    events?: Set<WebhookEventType>;
}

type ExtractedWebhookTypesList = Array<{
    key: string;
    data: ExtractedWebhookType;
}>;

type ExtractedWebhookType = { key: string; value: WebhookEventType }[];

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.grey[700],
    },
}));

/**
 * Creates a 2D array of objects representing the webhook event types.
 * Separates the data this way so you can set all types in their own container.
 * @returns An array of separated webhook event types
 */
const extractWebhookTypes = () => {
    let ret: ExtractedWebhookTypesList = [];
    let current: ExtractedWebhookType = [];
    let currentType = '';
    for (const [key, value] of Object.entries(WebhookEventType)) {
        const [eventType] = value.split('.');
        // New type encountered
        if (eventType !== currentType) {
            if (current.length !== 0)
                ret.push({ key: currentType, data: current });
            current = [];
            currentType = eventType;
        }
        current.push({ key, value });
    }
    ret.push({ key: currentType, data: current });
    return ret;
};

const WebhookEvents = ({ onChange, events }: WebhookEventsProps) => {
    const classes = useStyles();
    return (
        <FormGroup>
            <DetailWrapper title="Event Types">
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    justifyContent="center"
                >
                    {extractWebhookTypes().map(({ key, data }) => {
                        return (
                            <Grid item key={key}>
                                <Grid container direction="column">
                                    {data.map(({ key: typeKey, value }) => {
                                        return (
                                            <Grid item key={typeKey}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={() =>
                                                                onChange(value)
                                                            }
                                                            checked={events?.has(
                                                                value
                                                            )}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={
                                                                classes.label
                                                            }
                                                        >
                                                            {value}
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </DetailWrapper>
        </FormGroup>
    );
};

export default WebhookEvents;
