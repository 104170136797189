import React from 'react';

import { getCodes, getName } from 'country-list';

import Box from '@mui/material/Box';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormField } from '../pages/Login';

const countryCodes = getCodes();

const CountrySelect = (props: {
    size: 'small' | 'medium';
    field: typeof TextField | typeof FormField;
    countryCode: string;
    setCountryCode: (s: string) => void;
    label?: string;
}) => {
    return (
        <Autocomplete
            options={countryCodes}
            autoHighlight
            getOptionLabel={(code) => getName(code) || ''}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                        alt=""
                    />{' '}
                    {getName(option)}
                </Box>
            )}
            renderInput={(params) => (
                <props.field
                    {...params}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        // Disable autofill
                        autoComplete: 'new-password',
                        id: 'country',
                    }}
                    InputLabelProps={{
                        htmlFor: 'country',
                    }}
                    label={props.label ?? 'Country'}
                    required
                />
            )}
            value={props.countryCode}
            onChange={(_, v) => props.setCountryCode(v !== null ? v : '')}
            size={props.size}
            fullWidth
        />
    );
};

export default CountrySelect;
