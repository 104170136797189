import React, { useState, PropsWithoutRef } from 'react';

import {
    type Contact,
    useService as useContactService,
} from '../services/Contacts';
import { useNotificationContext } from '../context/Notification';
import { useModal } from '../hooks/useModal';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ContactInput from './ContactInput';
import UploadContactsDialog, {
    CompletedUploadData,
} from './UploadContactsDialog';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import { Alert } from '@mui/material';

const ContactOrCSV = (
    props: PropsWithoutRef<{
        label: string;
        contacts: Contact[];
        setContacts: (c: Contact[]) => void;
        setUploadedCSV?: (c: boolean) => void;
        required?: boolean;
        sampleURL?: string;
        textFieldTestId?: string;
    }>
) => {
    const {
        label,
        contacts,
        setContacts,
        setUploadedCSV,
        required,
        sampleURL,
        textFieldTestId,
    } = props;
    const { dispatchSuccess, dispatchError } = useNotificationContext();
    const contactService = useContactService();
    const { isModalOpen, closeModal, openModal } = useModal();

    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedSingleContact, setSelectedSingleContact] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [error, setError] = useState('');

    const handleComplete = async (data: CompletedUploadData) => {
        // HACK: Replace this in a later PR as the other components expect a
        // list of contacts and not mailing lists
        const contacts = [];
        const search = JSON.stringify({ mailingLists: data.mailingList.id });
        const limit = 100;
        let skip = 0;

        for (;;) {
            try {
                const fetched = await contactService.list({
                    limit,
                    skip,
                    search,
                });

                if (!fetched.data.length) {
                    break;
                }

                contacts.push(...fetched.data);

                if (contacts.length === fetched.totalCount) {
                    break;
                }

                // HACK: TS gets mad using `contacts.length` in the list params
                skip = contacts.length;
            } catch (e) {
                console.error(e);
                contacts.length = 0;
                setContacts([]);
                setUploadedFile(false);
                setError(
                    'Error retrieving contacts. Please refresh the page and try again.'
                );
                dispatchError(
                    'Error retrieving contacts. Please refresh the page and try again.'
                );
                return;
            }
        }

        setUploadedFile(true);
        setContacts(contacts);
        setUploadedCSV && setUploadedCSV(true);
        dispatchSuccess('Successfully uploaded contacts');
    };

    return (
        <>
            <UploadContactsDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onCompleted={handleComplete}
                sampleURL={sampleURL}
            />
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={closeModal}
                text="Are you sure you want to remove the uploaded recipients?"
                title="Remove Uploaded Recipients"
                confirm={() => {
                    setUploadedFile(false);
                    setContacts([]);
                }}
            />
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={7}>
                    <ContactInput
                        label={label}
                        contact={contacts.length === 1 ? contacts[0] : null}
                        setContact={(c) => {
                            if (c) {
                                setContacts([c]);
                                setSelectedSingleContact(true);
                                setUploadedCSV && setUploadedCSV(false);
                            } else {
                                setContacts([]);
                                setSelectedSingleContact(false);
                            }
                        }}
                        required={required && contacts.length === 0}
                        disabled={uploadedFile || !!error}
                        textFieldTestId={textFieldTestId}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography align="center">or</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color={error ? 'error' : 'primary'}
                        size="large"
                        fullWidth
                        disabled={selectedSingleContact || !!error}
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            if (uploadedFile) {
                                openModal();
                            } else {
                                setUploadOpen(true);
                            }
                        }}
                    >
                        {uploadedFile
                            ? 'Remove Uploaded Recipients'
                            : 'Upload Recipients'}
                    </Button>
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ContactOrCSV;
