import React, { useCallback } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useService as useTemplatesService } from '../services/Templates';

import { formatTableDate, ListParams } from '../services/util';

import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import Button from '../components/Button';

import CreateTemplate from './CreateTemplate';
import ViewTemplate from './ViewTemplate';
import EditTemplate from './EditTemplate';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import { TemplateRoutes } from '../routes';
import { CreateOrderOriginProvider } from '../context/CreateOrderOrigin';

const Templates = () => {
    const history = useHistory();

    const service = useTemplatesService();

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: templates,
        loading,
        searchQuery,
        searchText,
        pagination,
    } = usePagination(paginationFunc);

    return (
        <CreateOrderOriginProvider>
            <Switch>
                <Route exact path={TemplateRoutes.HOME}>
                    <TopNav
                        showSearch
                        searchText={searchText}
                        searchQuery={searchQuery}
                    />
                    <GridPaper direction="column" spacing={2}>
                        <PageHeader title="Templates">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        history.push(TemplateRoutes.CREATE);
                                    }}
                                >
                                    Create Template
                                </Button>
                            </Grid>
                        </PageHeader>
                        <Grid item>
                            <TableDisplay
                                columns={[
                                    'Description',
                                    'Template ID',
                                    'Created At',
                                ]}
                                show={loading}
                                pagination={pagination}
                                showEmptyTable
                            >
                                {templates.map((template) => {
                                    return (
                                        <TableRow
                                            key={template.id}
                                            hover
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                history.push(
                                                    `${TemplateRoutes.HOME}/${template.id}`
                                                );
                                            }}
                                        >
                                            <TableCell>
                                                {template.description || ''}
                                            </TableCell>
                                            <TableCell>
                                                <Box
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    style={{
                                                        cursor: 'text',
                                                        userSelect: 'text',
                                                    }}
                                                    fontFamily="monospace"
                                                >
                                                    {template.id || ''}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {formatTableDate(
                                                    template.createdAt
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableDisplay>
                        </Grid>
                    </GridPaper>
                </Route>
                <Route
                    path={TemplateRoutes.CREATE}
                    component={CreateTemplate}
                />
                <Route path={TemplateRoutes.EDIT}>
                    <EditTemplate fullScreen={true} />
                </Route>
                <Route path={TemplateRoutes.VIEW} component={ViewTemplate} />
            </Switch>
        </CreateOrderOriginProvider>
    );
};

export default Templates;
