import React, { ElementType, ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HeaderIcon from './HeaderIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from './PaperWrapper';

type EditableSectionWrapperProps = {
    title: string;
    icon?: ElementType;
    item?: boolean;
    children: ReactNode | ReactNode[];
};

/**
 * @param title The title of the field `required`
 * @param icon An icon to display next to the header `optional`
 * @param item Specify if you want to have this component wrapped in a Mui Grid item component `optional`
 * @param TextFieldProps Any Mui TextFieldProps `optional`
 */
const EditableSectionWrapper = ({
    title,
    icon,
    item,
    children,
}: EditableSectionWrapperProps) => {
    const element = (
        <Paper>
            <Box p={2} display="flex">
                {icon && <HeaderIcon icon={icon} />}
                <Typography variant="h6">{title}</Typography>
            </Box>
            <Divider />
            {children}
        </Paper>
    );

    if (item) {
        return <Grid item>{element}</Grid>;
    }

    return element;
};

export default EditableSectionWrapper;
