import React from 'react';

import Grid from '@mui/material/Grid';

import FileUpload from './FileUpload';
import SelectTemplate from './SelectTemplate';
import { useCreateCheque } from '../context/CreateCheque';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const AttachChequeLetter = ({
    textFieldTestID,
}: {
    textFieldTestID?: string;
}) => {
    const {
        file,
        setFile,
        setTemplate,
        template,
        attachLetter,
        letterSettings,
        setLetterSettings,
    } = useCreateCheque();

    if (!attachLetter) {
        return null;
    }

    return (
        <>
            <Grid item xs={6}>
                <SelectTemplate
                    label="Select a Template"
                    template={template}
                    setTemplate={setTemplate}
                    disabled={file ? true : false}
                    textFieldTestId={textFieldTestID}
                />
            </Grid>
            <Grid item xs={6}>
                <Grid container justifyContent="center" alignItems="center">
                    <FileUpload
                        accept="application/pdf"
                        label="Upload a PDF"
                        file={file}
                        setFile={setFile}
                        disabled={template ? true : false}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={!!letterSettings?.color}
                            onChange={(e) => {
                                setLetterSettings({ color: e.target.checked });
                            }}
                            inputProps={
                                {
                                    'data-testid': 'letter-color',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                            }
                        />
                    }
                    label="Color"
                />
            </Grid>
        </>
    );
};
export default AttachChequeLetter;
