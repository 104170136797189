import { useCallback, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Context, ActionType } from '../context/Auth';

const useLogout = () => {
    const { isAuthenticated, logout: auth0Logout } = useAuth0();
    const { dispatch } = useContext(Context);

    return useCallback(() => {
        if (isAuthenticated) {
            auth0Logout({
                localOnly: true,
            });
        }

        dispatch({ type: ActionType.LOGOUT });
    }, [dispatch, isAuthenticated, auth0Logout]);
};

export default useLogout;
