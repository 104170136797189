import { useMemo } from 'react';

import { ResourceCreateBody, Service as ResourceService } from './Resources';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';

import { Contact, label as contactLabel } from './Contacts';
import { createURLParams, ListParams, ListResponse } from './util';

export interface ReturnEnvelope extends Resource {
    to: Contact;
    available: number;
}

export enum ReturnEnvelopeOrderStatus {
    PLACED = 'placed',
    FILLED = 'filled',
    CANCELLED = 'cancelled',
}

export interface ReturnEnvelopeOrder extends Resource {
    returnEnvelope: ReturnEnvelope;
    status: ReturnEnvelopeOrderStatus;
    quantityOrdered: number;
    quantityFilled?: number;
}

export type CreateParams = Omit<
    ReturnEnvelope,
    keyof Resource | 'available' | 'to'
> &
    ResourceCreateParams & {
        to: string;
    };

export interface CreateOrderBody extends ResourceCreateBody {
    quantityOrdered: number;
}

export class Service extends ResourceService<ReturnEnvelope> {
    async create(data: CreateParams): Promise<ReturnEnvelope> {
        return await this.base.fetchAPI(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async getOrders(
        params: ListParams,
        id: string
    ): Promise<ListResponse<ReturnEnvelopeOrder>> {
        const urlParams = createURLParams(params);
        return await this.base.fetchAPI(
            `/${this.route}/${id}/orders?${urlParams.toString()}`
        );
    }

    async getOrder(id: string, orderId: string): Promise<ReturnEnvelopeOrder> {
        return await this.base.fetchAPI(
            `/${this.route}/${id}/orders/${orderId}?expand[]=returnEnvelope
            `
        );
    }

    async createOrder(
        data: CreateOrderBody,
        id: string
    ): Promise<ReturnEnvelopeOrder> {
        return await this.base.fetchAPI(`/${this.route}/${id}/orders`, {
            method: 'POST',
            body: data,
        });
    }

    async cancelOrder(
        envelopeId: string,
        orderId: string
    ): Promise<ReturnEnvelopeOrder> {
        return await this.base.fetchAPI(
            `/${this.route}/${envelopeId}/orders/${orderId}?expand[]=returnEnvelope`,
            {
                method: 'DELETE',
            }
        );
    }

    async fillTestOrder(
        id: string,
        orderId: string
    ): Promise<ReturnEnvelopeOrder> {
        return await this.base.fetchAPI(
            `/${this.route}/${id}/orders/${orderId}/fills`,
            {
                method: 'POST',
            }
        );
    }
}

export const label = (re: ReturnEnvelope) => {
    return `${contactLabel(re.to)} (${re.available} available)`;
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'return_envelopes'), [base]);
};
