import { useHistory as useH } from 'react-router-dom';

interface ExtendedHistory {
    from?: string;
}

/**
 * Extends react router doms useHistory
 * to allow for a `from` type on the `state`
 * of history.location.
 *
 * This will be used to check what the previous
 * page was.
 */
export const useHistory = () => {
    return useH<ExtendedHistory>();
};
