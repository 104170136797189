import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useService as useInviteService } from '../services/Invites';
import { SSORoutes } from '../routes';
import { Context, MessageType } from '../context/Notification';

import FormField from '../components/FormField';
import TermsAndConditons from '../components/TermsAndConditions';
import SignupWrapper from '../components/SignupWrapper';

interface CompleteSSOSignupInfoProps {
    id: string;
}

const CompleteSignupInfoSSO = ({ id }: CompleteSSOSignupInfoProps) => {
    const { user, isAuthenticated, getAccessTokenSilently, isLoading } =
        useAuth0();
    const service = useInviteService();
    const history = useHistory();
    const { dispatch } = useContext(Context);

    const [name, setName] = useState(user?.name || '');
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || '');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isAuthenticated) {
            return;
        }

        try {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();

            await service.complete(id, {
                accessToken,
                name,
                phoneNumber,
            });

            // TODO:  We already have auth0 user do we just give tokens and move
            // to dash from here?
            dispatch({
                type: MessageType.SUCCESS,
                message:
                    'Signed up successfully.  Please log in to access the dashboard.',
            });

            history.replace(SSORoutes.LOGIN);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // TODO:  Do we need the 'already have an account?' text if they are invited
    return (
        <SignupWrapper
            onSubmit={handleSubmit}
            loginLink={SSORoutes.LOGIN}
            buttonText="Complete SSO Signup"
            submitDisabled={loading || isLoading}
            invited
        >
            <FormField
                label="Full Name"
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                inputProps={{ siqatrib: 'name' }}
            />
            <FormField
                label="Email"
                required
                type="text"
                value={user?.email}
                disabled={!!user?.email}
                inputProps={{ siqatrib: 'email' }}
            />
            <FormField
                label="Phone Number"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                inputProps={{ siqatrib: 'phone' }}
            />
            <TermsAndConditons />
        </SignupWrapper>
    );
};

export default CompleteSignupInfoSSO;
