import React, { FormEvent, useContext, useState } from 'react';

import { Context as AuthContext } from '../context/Auth';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { useService as useUsersService } from '../services/Users';

import { useDialogStyles } from '../pages/Settings';

import changePassGraphic from '../img/change-pass-graphic.png';
import Button from '../components/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Alert from '@mui/lab/Alert';

export const ChangeEmailDialog = (props: {
    open: boolean;
    onClose: () => void;
}) => {
    const { state } = useContext(AuthContext);
    const service = useUsersService();
    const { dispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [formError, setFormError] = useState('');

    const classes = useDialogStyles();

    // clear dialog contents if dialog is closed
    const resetDialog = () => {
        setNewEmail('');
        setFormError('');
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFormError('');
        setLoading(true);

        // format user email input
        const formattedEmail = newEmail.trim().toLowerCase();

        // ensure new email is not the same as current email
        if (state.user!.email.trim().toLowerCase() === formattedEmail) {
            setFormError('New email cannot be the same as your current email');
            setLoading(false);
            return;
        }

        try {
            await service.update(state.user!.id, {
                email: formattedEmail,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: `Sent verification email to ${formattedEmail}, please check your inbox and spam folders.`,
            });
            handleClose();
        } catch (err) {
            console.log(err);
            setFormError('An error occured, please refresh and try again');
        }
        setLoading(false);
    };

    const handleClose = () => {
        resetDialog();
        props.onClose();
    };

    return (
        <Dialog {...props} classes={classes} onClose={handleClose}>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <img
                        src={changePassGraphic}
                        alt="Change password graphic"
                        style={{
                            margin: '10px auto',
                            display: 'block',
                            transform: 'translate(10%, 0)',
                        }}
                    />
                    <DialogTitle>Change Email</DialogTitle>
                    <Box height={10}></Box>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                required
                                fullWidth
                                disabled={loading}
                            />
                        </Grid>
                        {formError && (
                            <Grid item>
                                <Alert variant="outlined" color="warning">
                                    {formError}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <Box height={15}></Box>
                <DialogActions>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={5}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                disabled={loading}
                                fullWidth
                            >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};
