import React from 'react';
import { observer } from 'mobx-react-lite';
import { ImagesGrid, SectionTab } from 'polotno/side-panel';
import { TextSection } from 'polotno/side-panel';
import { Icon } from '@blueprintjs/core';
import {
    MERGE_VARIABLE_IMAGE,
    MERGE_VAR_BASE_DIMENSIONS,
} from './MergeVariableImageElement';
import './MergeVariableImageToolbar';
type SidePanelSection = typeof TextSection;

const images = [
    {
        type: MERGE_VARIABLE_IMAGE,
        url: 'https://pg-prod-bucket-1.s3.amazonaws.com/assets/image_merge_variable_placeholder.png',
        thumbnail:
            'https://pg-prod-bucket-1.s3.amazonaws.com/assets/image_merge_variable_thumbnail.png',
    },
];

const MergeVariablePanel = observer(({ store }: { store: any }) => {
    return (
        <ImagesGrid
            images={images}
            getPreview={(i) => i.thumbnail}
            isLoading={false}
            onSelect={(img, pos) => {
                store.activePage.addElement({
                    type: MERGE_VARIABLE_IMAGE,
                    width: MERGE_VAR_BASE_DIMENSIONS.width,
                    height: MERGE_VAR_BASE_DIMENSIONS.height,
                    src: img.url,
                    x: pos
                        ? pos.x
                        : store.width / 2 - MERGE_VAR_BASE_DIMENSIONS.width / 2,
                    y: pos
                        ? pos.y
                        : store.height / 2 -
                          MERGE_VAR_BASE_DIMENSIONS.height / 2,
                });
            }}
        />
    );
});

export const MergeVariableSection: SidePanelSection = {
    name: 'merge_variables',
    // TODO: Figure out how to change height of tab
    // @ts-ignore HACK: for now idk it doesn't like its own types
    Tab: (props) => (
        <SectionTab name="Merge Variables" {...props}>
            <Icon icon="variable" />
        </SectionTab>
    ),
    Panel: MergeVariablePanel,
};

export default MergeVariablePanel;
