import React, { useCallback } from 'react';

import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useService as useTrackerService } from '../services/Trackers';

import { formatTableDate, ListParams } from '../services/util';

import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import Button from '../components/Button';

import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import { TrackerRoutes } from '../routes';
import CreateTracker from './CreateTracker';
import ViewTracker from './ViewTracker';

const Trackers = () => {
    const history = useHistory();

    const service = useTrackerService();

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: trackers,
        loading,
        searchQuery,
        searchText,
        pagination,
    } = usePagination(paginationFunc);

    return (
        <Switch>
            <Route exact path={TrackerRoutes.HOME}>
                <TopNav
                    showSearch
                    searchText={searchText}
                    searchQuery={searchQuery}
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader title="Trackers">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.push(TrackerRoutes.CREATE);
                                }}
                            >
                                Create Tracker
                            </Button>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            columns={[
                                'Description',
                                'Tracker ID',
                                'Redirect URL',
                                'Created At',
                            ]}
                            show={loading}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {trackers.map((tracker) => {
                                return (
                                    <TableRow
                                        key={tracker.id}
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `${TrackerRoutes.HOME}/${tracker.id}`
                                            );
                                        }}
                                    >
                                        <TableCell>
                                            {tracker.description ?? ''}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                style={{
                                                    cursor: 'text',
                                                    userSelect: 'text',
                                                }}
                                                fontFamily="monospace"
                                            >
                                                {tracker.id}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {tracker.redirectURLTemplate}
                                        </TableCell>
                                        <TableCell>
                                            {formatTableDate(tracker.createdAt)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={TrackerRoutes.CREATE} component={CreateTracker} />
            <Route path={TrackerRoutes.VIEW} component={ViewTracker} />
        </Switch>
    );
};

export default Trackers;
