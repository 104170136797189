import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export type FormFieldProps = TextFieldProps & {
    label: string;
};

// TODO:  Replace existing occurences of `FormField` from `Login` page with
// this component
const FormField = ({ label, ...props }: FormFieldProps) => {
    return (
        <>
            <Typography color="textSecondary">{label}</Typography>
            <TextField variant="outlined" size="small" fullWidth {...props} />
        </>
    );
};

export default FormField;
