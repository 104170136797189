import { useMemo } from 'react';

import { Order, Service as OrdersService, undefinedIfToday } from './Orders';

import { APIErrorResponse, asyncMapChunks, retryBatchWrapper } from './util';
import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';

export enum PostcardSize {
    SIZE_6X4 = '6x4',
    SIZE_9X6 = '9x6',
    SIZE_11X6 = '11x6',
    SIZE_9X6_REDUCED = '9x6_reduced',
    SIZE_11X6_REDUCED = '11x6_reduced',
}

export interface Postcard extends Order {
    object: 'postcard';

    size: PostcardSize;

    frontHTML?: string;
    backHTML?: string;

    frontTemplate?: string;
    backTemplate?: string;

    uploadedPDF?: string;
}

export type CreateParams = Omit<
    Postcard,
    'sendDate' | 'to' | 'from' | 'status' | 'carrierTracking' | keyof Resource
> &
    ResourceCreateParams & {
        to: string;
        from?: string;
        sendDate?: Date;
    };

// HACK Copied from the Letters service
// Omitting metadata until we properly set up FormData with nested fields
type CreateUploadPDFParams = Omit<
    CreateParams,
    | 'frontTemplate'
    | 'backTemplate'
    | 'frontHTML'
    | 'backHTML'
    | 'mergeVariables'
    | 'to'
    | 'from'
    | 'metadata'
> & {
    pdf: File;
};

type CreateBatchDatum = Omit<
    CreateParams,
    | 'frontTemplate'
    | 'backTemplate'
    | 'frontHTML'
    | 'backHTML'
    | 'pdf'
    | 'size'
    | 'from'
    | 'to'
> & { to: string; from?: string };

export type CreateBatchParams = Pick<
    CreateParams,
    | 'frontTemplate'
    | 'backTemplate'
    | 'frontHTML'
    | 'backHTML'
    | 'size'
    | 'express'
    | 'sendDate'
    | 'mailingClass'
> & {
    pdf?: File | string;
    data: CreateBatchDatum[];
    handleProgress: (count: number) => void;
};

interface CreateBatchResponse {
    object: 'batch';
    data: (Postcard | APIErrorResponse)[];
}

interface PostcardCapabilities {
    sizes: PostcardSize[];
}

export class Service extends OrdersService<Postcard> {
    async create(data: CreateParams) {
        return await this.base.fetchAPI<Postcard>(`/${this.route}`, {
            method: 'POST',
            body: {
                ...data,
                sendDate: undefinedIfToday(data.sendDate),
                metadata: { postgrid_dashboard: '' },
            },
        });
    }

    async createUploadPDF(params: CreateUploadPDFParams) {
        const data = new FormData();
        data.set('metadata[postgrid_dashboard]', '');

        for (let [key, value] of Object.entries(params)) {
            if (value !== undefined) {
                data.set(key, value as any);
            }
        }

        return await this.base.fetchAPI<Postcard>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async createBatch(params: CreateBatchParams) {
        const batchRequest = async (
            batch: CreateBatchDatum[]
        ): Promise<CreateBatchResponse> => {
            const res = await this.base.fetchAPI<CreateBatchResponse>(
                `/${this.route}/batch`,
                {
                    method: 'POST',
                    body: {
                        ...params,
                        sendDate: undefinedIfToday(params.sendDate),
                        pdf: await (async () => {
                            if (
                                params.pdf === undefined ||
                                typeof params.pdf === 'string'
                            ) {
                                return params.pdf;
                            }

                            // Convert to base64 data URI: https://stackoverflow.com/a/18650249
                            return await new Promise((resolve) => {
                                const reader = new FileReader();
                                reader.onloadend = () => resolve(reader.result);
                                reader.readAsDataURL(params.pdf as File);
                            });
                        })(),
                        data: batch,
                        handleProgress: undefined,
                    },
                }
            );

            params.handleProgress(batch.length);

            return res;
        };

        return (
            await asyncMapChunks(
                params.data,
                250,
                retryBatchWrapper(batchRequest)
            )
        ).flatMap((d) => d.data);
    }

    async capabilities() {
        return await this.base.fetchAPI<PostcardCapabilities>(
            `/${this.route}/capabilities`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'postcards'), [base]);
};
