import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {
    default as MUIButton,
    ButtonProps as MUIButtonProps,
} from '@mui/material/Button';

export type ButtonProps = MUIButtonProps;

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '5px',
        outline: 'none',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: theme.typography.body1.fontFamily,
    },
}));

const Button = (props: MUIButtonProps & { target?: string }) => {
    const classes = useStyles();

    return (
        <MUIButton
            variant={props.variant ? props.variant : 'contained'}
            color={props.color ? props.color : 'primary'}
            {...props}
            classes={{ root: classes.root }}
        />
    );
};

export default Button;
