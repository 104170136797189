import React, { PropsWithoutRef, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDebouncedValue } from '../services/util';

export type SearchSelectProps = PropsWithoutRef<{
    label: string;
    search: (text?: string) => Promise<any[]>;
    value: any | null;
    setValue: (value: any) => void;
    getOptionLabel: (value?: any) => string;
    getOptionSelected: (option: any, value: any) => boolean;
    debounceTimeout?: number;
    size?: TextFieldProps['size'];
    required?: boolean;
    disabled?: boolean;
    resourceName?: string;
    textFieldTestId?: string;
}>;

const noOptionslabel = (resource: string) => {
    return `+ Add new ${resource}`.replace(/_/g, ' ');
};

const SearchSelect = (props: SearchSelectProps) => {
    const history = useHistory();

    const { search } = props;

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<any[]>();

    const debouncedInputValue = useDebouncedValue(
        inputValue,
        props.debounceTimeout || 100
    );

    useEffect(() => {
        // Prevent prior requests from overwriting current requests by setting
        // active to false when this effect is retriggered.
        let active = true;

        (async () => {
            try {
                setOptions(undefined);

                let options = await search(debouncedInputValue);

                if (!active) {
                    return;
                }

                if (props.resourceName) {
                    options = [
                        {
                            _id: null,
                        },
                    ].concat(options);
                }

                setOptions(options);
            } catch (err) {
                console.error(err);
            }
        })();

        return () => {
            active = false;
        };
    }, [search, props.resourceName, debouncedInputValue]);

    return (
        <Autocomplete
            fullWidth
            data-testid="autocomplete"
            options={options || []}
            getOptionLabel={(value) => {
                if (props.resourceName && value._id === null) {
                    return noOptionslabel(props.resourceName);
                }
                return props.getOptionLabel(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    size={props.size || 'medium'}
                    variant="outlined"
                    required={props.required}
                    inputProps={{
                        ...params.inputProps,
                        'data-testid': props.textFieldTestId,
                    }}
                />
            )}
            inputValue={inputValue}
            onInputChange={(_e, value) => {
                setInputValue(value);
            }}
            value={props.value}
            onChange={(_e, value) => {
                if (value && value._id === null) {
                    return history.push(
                        `/dashboard/${props.resourceName}s/create`,
                        {
                            from: history.location.pathname,
                        }
                    );
                }
                props.setValue(value);
            }}
            isOptionEqualToValue={props.getOptionSelected}
            loading={options === undefined}
            filterOptions={(options, _state) => options}
            disabled={props.disabled}
            popupIcon={<ExpandMoreIcon />}
        />
    );
};

export default SearchSelect;
