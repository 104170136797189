import React, { useCallback, useContext, useEffect, useState } from 'react';
// Libraries
import { useHistory } from 'react-router-dom';
// Hooks
import { Context, type User } from '../context/Auth';
import { useFetchResource } from '../hooks/useFetchResource';
import { useModal } from '../hooks/useModal';
import { useNotificationContext } from '../context/Notification';
import useRoles from '../hooks/useRoles';
// Services
import type { Role } from '../services/Roles';
import { useService as useUserSerivce } from '../services/Users';
import { useService as useInviteService } from '../services/Invites';
import { useOrganization } from '../services/Organization';
// Utils
import { UserRoutes } from '../routes';
// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
// MUI Components
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Our Components
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import DetailWrapper from '../components/DetailWrapper';
import DetailCell from '../components/DetailCell';
import RawData from '../components/RawData';
import ToolTipIconButton from '../components/ToolTipIconButton';
import SelectUserRoles from '../components/SelectUserRoles';
import Button from '../components/Button';

interface UserInfoProps {
    user: User;
}

const UserInfo = ({ user }: UserInfoProps) => {
    return (
        <DetailWrapper direction="column" title="User Information" spacing={1}>
            <DetailCell right="Name" left={user.name} />
            <DetailCell right="Email" left={user.email} />
            {user.phoneNumber && (
                <DetailCell right="Phone Number" left={user.phoneNumber} />
            )}
        </DetailWrapper>
    );
};

interface InviteActionsProps {
    disabled: boolean;
    resendInvite: (...args: any) => any;
    deleteInvite: (...args: any) => any;
}

const InviteActions = ({
    disabled,
    resendInvite,
    deleteInvite,
}: InviteActionsProps) => {
    return (
        <>
            <ToolTipIconButton
                icon={EmailIcon}
                title="Re-send invite"
                onClick={resendInvite}
                disabled={disabled}
            />
            <ToolTipIconButton
                icon={DeleteIcon}
                title="Delete invite"
                onClick={deleteInvite}
                disabled={disabled}
            />
        </>
    );
};

interface PendingInvitedUserProps {
    user: User;
}

const PendingInvitedUser = ({ user }: PendingInvitedUserProps) => {
    // hooks
    const history = useHistory();
    const inviteService = useInviteService();
    const { isModalOpen, toggleModal } = useModal();
    const { dispatchSuccess, dispatchError } = useNotificationContext();
    // state
    const [loading, setLoading] = useState(false);
    const service = useInviteService();

    const resendInvite = async () => {
        if (loading) {
            return;
        }

        try {
            setLoading(true);

            await service.create(user);

            dispatchSuccess('Invited user.');

            history.push(UserRoutes.HOME);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    const deleteInvite = async () => {
        if (loading || !user.pendingInvite) {
            return;
        }

        try {
            setLoading(true);
            await inviteService.delete(user.id);
            dispatchSuccess('Deleted invite.');
            history.replace(UserRoutes.HOME);
        } catch (err) {
            console.error(err);
            dispatchError('Failed to delete invite.');
            setLoading(false);
        }
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={toggleModal}
                title="Delete Invite"
                text="Are you sure you want to delete this Invite?"
                confirm={deleteInvite}
            />
            <Grid item xs={12}>
                <Alert
                    severity="warning"
                    action={
                        <InviteActions
                            disabled={loading}
                            deleteInvite={toggleModal}
                            resendInvite={resendInvite}
                        />
                    }
                >
                    <AlertTitle>Invite Not Accepted</AlertTitle>
                    <strong>{user.name}</strong> was invited on{' '}
                    {user.createdAt.toDateString()} but has not accepted their
                    invite yet.
                </Alert>
            </Grid>
        </>
    );
};

const compareArrays = (a: string[], b: string[]) =>
    a.length === b.length && a.every((element) => b.includes(element));

const ViewUser = () => {
    // hooks
    const userService = useUserSerivce();
    const history = useHistory();
    const { dispatchSuccess, dispatchError } = useNotificationContext();
    const getUser = useCallback(
        (id: string) => userService.get(id),
        [userService]
    );
    const {
        fetching,
        data: user,
        setData: setUser,
    } = useFetchResource(UserRoutes.HOME, getUser);

    const roles = useRoles();
    const [userRoles, setUserRoles] = useState<Role[]>([]);
    const [changed, setChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const org = useOrganization([]);
    const { state: authState } = useContext(Context);

    useEffect(() => {
        if (!user) {
            return;
        }

        const roleIDs = new Set<string>(user.roles);
        const rolesToSet = roles.filter((role) => roleIDs.has(role.id));
        setUserRoles(rolesToSet);
    }, [roles, user]);

    useEffect(() => {
        if (!user) {
            setChanged(false);
            return;
        }

        setChanged(
            !compareArrays(
                userRoles.map((roles) => roles.id),
                user.roles
            )
        );
    }, [user, userRoles]);

    const updateUser = async () => {
        if (loading || !user) {
            return;
        }

        if (!userRoles.length) {
            dispatchError('You must select at least one role.');
            return;
        }

        try {
            setLoading(true);

            const updatedUser = await userService.update(user.id, {
                ...user,
                roles: userRoles.map((roles) => roles.id),
            });

            dispatchSuccess("Updated user's roles.");
            setChanged(false);
            setUser(updatedUser);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    const deleteUser = async () => {
        if (loading || !user) {
            return;
        }

        try {
            setLoading(true);
            await userService.delete(user.id);
            dispatchSuccess('Deleted user.');
            history.replace(UserRoutes.HOME);
        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    };

    // TODO: Make a component that takes a service which has a `get` method
    // and fetches the data and displays this loader. It will take children that
    // will be rendered after data has appeared, possibly render as as function.
    // Stops the copy-pasta across pages and allows it to be consistent
    if (!user || fetching) {
        return (
            <>
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <CircularProgress style={{ alignSelf: 'center' }} />
                </GridPaper>
            </>
        );
    }

    // TODO: Handle permissions to see if we have the ability to write to users

    const rolesDisabled = user.pendingInvite;
    return (
        <>
            <TopNav />
            <ConfirmDeleteDialog
                open={deleteOpen}
                onClose={toggleDeleteModal}
                title="Delete User"
                text="Are you sure you want to delete this user?"
                confirm={deleteUser}
            />
            <GridPaper
                direction="column"
                spacing={2}
                data-testid="view-user-page"
            >
                <PageHeader title="User Details" hideAlert>
                    {!user.pendingInvite && (
                        <Grid container item spacing={2} alignItems="center">
                            {user.id !== authState.user?.id && (
                                <Grid item>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        onClick={toggleDeleteModal}
                                    >
                                        Delete User
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={updateUser}
                                    disabled={
                                        !userRoles.length ||
                                        !changed ||
                                        (userRoles.length === 1 &&
                                            userRoles[0].name === 'Live')
                                    }
                                    data-testid="update-role-button"
                                >
                                    Update Roles
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </PageHeader>
                {user.pendingInvite && <PendingInvitedUser user={user} />}
                <Grid item xs={12}>
                    <UserInfo user={user} />
                </Grid>
                <Grid item xs={12}>
                    <DetailWrapper>
                        <Grid item xs={12}>
                            <SelectUserRoles
                                roles={roles}
                                setRoles={setUserRoles}
                                selectedRoles={userRoles}
                                disabled={rolesDisabled}
                                disabledMessage={
                                    'You can only change the roles of users who have finished the invite process.'
                                }
                                userPermissions={org?.userPermissions ?? false}
                            />
                        </Grid>
                    </DetailWrapper>
                </Grid>
                <Grid item xs={12}>
                    <RawData obj={user} />
                </Grid>
            </GridPaper>
        </>
    );
};

export default ViewUser;
