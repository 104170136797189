import React from 'react';
import { CreateChequeProvider } from './CreateCheque';
import { CreateLetterProvider } from './CreateLetter';
import { CreatePostcardProvider } from './CreatePostcard';
import { StoreProvider } from '../pages/CreateSelfMailer';

/**
 * This is used as a bundle of contexts in order to preserve the state of the order pages.
 * This is bundled here for simplicitys sake.
 * This needs to be added at the `App` level because adding it in the `Dashboard`
 * level will cause the state stored here to re-render on every page.
 */
export const PersistOrderStore = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <CreateChequeProvider>
            <CreatePostcardProvider>
                <CreateLetterProvider>
                    <StoreProvider>{children}</StoreProvider>
                </CreateLetterProvider>
            </CreatePostcardProvider>
        </CreateChequeProvider>
    );
};
