import React from 'react';
import AutoNumeric, { Options as AutoNumericOptions } from 'autonumeric';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { omitFromObj } from '../services/util';

type EventType = typeof EventTypes[keyof typeof EventTypes];
const EventTypes = {
    ON_CHANGE: 'onChange',
    ON_FOCUS: 'onFocus',
    ON_BLUR: 'onBlur',
    ON_KEY_PRESS: 'onKeyPress',
    ON_KEY_UP: 'onKeyUp',
    ON_KEY_DOWN: 'onKeyDown',
} as const;

type EventHandlerFns = {
    [Key in typeof EventTypes[keyof typeof EventTypes]]: (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLDivElement>,
        value: number | string
    ) => void;
};

/*
 * MOST OF THE BELOW IS COPY-PASTAD FROM HERE: https://github.com/alext9586/material-ui-currency-textfield/blob/master/src/components/CurrencyTextField/CurrencyTextField.js
 */

/**
 * CurrencyTextField is a [react](https://reactjs.org/) component with automated currency and number format, and with [Material-ui](https://material-ui.com/) look and feel.
 *
 * CurrencyTextField is a wrapper component for <a href="https://github.com/autoNumeric/autoNumeric">autonumeric</a> and based on <a href="https://github.com/mkg0/react-numeric">react-numeric</a>.
 *
 * Main features:
 * * Adds thousands separator automatically.
 * * Adds automatically the decimals on blur.
 * * Smart input. User can only type the accepted characters depending on the current value.
 * * Lots of config options...
 * * It accepts all the `props` and `classes` of Material-Ui <a href="https://material-ui.com/api/text-field/#textfield-api">TextField API</a> (Ex: classes, label, helperText, variant).
 * * And also all the `options` from <a href="http://autonumeric.org/guide">AutoNumeric</a>
 */

type CurrencyTextFieldProps = Omit<
    TextFieldProps,
    keyof EventHandlerFns | 'value'
> &
    AutoNumericOptions & {
        preDefined?: object;
        textAlign?: string;
        value?: string | number;
    } & Partial<EventHandlerFns>;

class CurrencyTextField extends React.Component<CurrencyTextFieldProps> {
    autonumeric: AutoNumeric | null;
    input: any;

    constructor(props: CurrencyTextFieldProps) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.callEventHandler = this.callEventHandler.bind(this);
        this.autonumeric = null;
    }

    componentDidMount() {
        const others = omitFromObj(this.props, [
            'currencySymbol',
            'onChange',
            'onFocus',
            'onBlur',
            'onKeyPress',
            'onKeyUp',
            'onKeyDown',
        ]);

        this.autonumeric = new AutoNumeric(this.input, this.props.value, {
            ...this.props.preDefined,
            ...others,
            watchExternalChanges: false,
        });
    }
    componentWillUnmount() {
        this.autonumeric?.remove();
    }

    UNSAFE_componentWillReceiveProps(newProps: CurrencyTextFieldProps) {
        const isValueChanged =
            this.props.value !== newProps.value &&
            this.getValue() !== newProps.value;

        if (isValueChanged) {
            this.autonumeric?.set(newProps.value ?? 0);
        }
    }

    getValue() {
        if (!this.autonumeric) {
            return '';
        }

        const outputFormat = this.props.outputFormat;

        if (outputFormat === 'string') {
            return this.autonumeric.getNumericString() ?? '';
        }

        return this.autonumeric.getNumber() ?? 0;
    }

    callEventHandler(
        event:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLDivElement>,
        eventType: EventType
    ) {
        const eventFn = this.props[eventType];

        if (!eventFn) {
            return;
        }

        eventFn(event, this.getValue());
    }

    render() {
        // eslint-disable-next-line
        const { classes, currencySymbol, inputProps, InputProps, ...others } =
            this.props;

        const otherProps: Partial<CurrencyTextFieldProps> = {};

        [
            'id',
            'label',
            'className',
            'autoFocus',
            'variant',
            'style',
            'error',
            'disabled',
            'type',
            'name',
            'defaultValue',
            'tabIndex',
            'fullWidth',
            'rows',
            'select',
            'required',
            'helperText',
            'unselectable',
            'margin',
            'SelectProps',
            'multiline',
            'size',
            'FormHelperTextProps',
            'placeholder',
            // eslint-disable-next-line
            // @ts-ignore
        ].forEach((prop) => (otherProps[prop] = this.props[prop]));

        return (
            <TextField
                inputRef={(ref) => (this.input = ref)}
                {...otherProps}
                onChange={(e) => this.callEventHandler(e, 'onChange')}
                onFocus={(e) => this.callEventHandler(e, 'onFocus')}
                onBlur={(e) => this.callEventHandler(e, 'onBlur')}
                onKeyPress={(e) => this.callEventHandler(e, 'onKeyPress')}
                onKeyUp={(e) => this.callEventHandler(e, 'onKeyUp')}
                onKeyDown={(e) => this.callEventHandler(e, 'onKeyDown')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {currencySymbol}
                        </InputAdornment>
                    ),
                    ...InputProps,
                }}
                inputProps={{
                    style: {
                        textAlign: this.props.textAlign ?? ('right' as any),
                    },
                    ...inputProps,
                }}
            />
        );
    }
}
export default CurrencyTextField;
