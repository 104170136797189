import React from 'react';
import type { User } from '../context/Auth';
import Box from '@mui/material/Box';

interface UserInviteStatusProps {
    user: Pick<User, 'pendingInvite'>;
}

const UserInviteStatus = ({ user }: UserInviteStatusProps) => {
    const color = user.pendingInvite ? 'primary.main' : 'success.main';
    const text = user.pendingInvite ? 'Invited' : 'Registered';

    return (
        <Box color={color} fontWeight={500}>
            {text}
        </Box>
    );
};

export default UserInviteStatus;
