import { useMemo } from 'react';
import { Order, Service as OrdersService, undefinedIfToday } from './Orders';

import { formFlatten } from './util';
import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
    OrderExtraService,
} from './Base';

import { Contact } from '../services/Contacts';

export enum ChequeSize {
    US_LETTER = 'us_letter',
    US_LEGAL = 'us_legal',
}

export enum CurrencyCode {
    CAD = 'CAD',
    USD = 'USD',
}

export interface ChequeCapabilities {
    sizes: ChequeSize[];
}

export interface LetterSettings {
    color: boolean;
}

export interface Cheque extends Order {
    object: 'cheque';

    // is required in cheques and not in others
    from: Contact;

    bankAccount: string;

    amount: number;

    currencyCode: CurrencyCode;

    // Cheque number
    number: number;

    memo?: string;
    message?: string;

    letterTemplate?: string;
    letterPDF?: File;
    letterSettings?: LetterSettings;

    extraService?: OrderExtraService;

    size: ChequeSize;
}

export type CreateParams = Omit<
    Cheque,
    | 'sendDate'
    | 'to'
    | 'from'
    | 'status'
    | 'number'
    | 'carrierTracking'
    | keyof Resource
> &
    ResourceCreateParams & {
        to: string;
        from: string;
        sendDate?: Date;
        number?: number;
    };

export class Service extends OrdersService<Cheque> {
    async create(data: CreateParams) {
        if (data.letterPDF) {
            const formData = new FormData();
            formData.set('metadata[postgrid_dashboard]', '');

            data.sendDate = undefinedIfToday(data.sendDate);

            for (const [key, value] of Object.entries(formFlatten(data))) {
                if (value === undefined) {
                    continue;
                }

                formData.set(key, value as any);
            }

            formData.set('letterPDF', data.letterPDF);

            return await this.base.fetchAPI<Cheque>(`/${this.route}`, {
                method: 'POST',
                body: formData,
            });
        }

        return await this.base.fetchAPI<Cheque>(`/${this.route}`, {
            method: 'POST',
            body: { ...data, metadata: { postgrid_dashboard: '' } },
        });
    }

    async capabilities(destinationCountryCode: string) {
        const params = new URLSearchParams({
            destinationCountryCode,
        });

        return await this.base.fetchAPI<ChequeCapabilities>(
            `/${this.route}/capabilities?${params.toString()}`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'cheques'), [base]);
};
