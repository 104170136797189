import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import { ReturnEnvelopeOrderStatus as Status } from '../services/ReturnEnvelopes';

interface ReturnEnvelopeOrderStatusCellProps {
    status: Status;
}

const useStyles = makeStyles<Theme, ReturnEnvelopeOrderStatusCellProps>(
    (theme) => ({
        root: {
            margin: theme.spacing(2),
        },
        bubble: {
            backgroundColor: ({ status }) => getCellBackgroundColor(status),
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            color: ({ status }) => getCellColor(theme, status),
            borderRadius: 5,
            textTransform: 'capitalize',
        },
    })
);

const getCellColor = (theme: Theme, status: Status) => {
    switch (status) {
        case Status.PLACED:
            return theme.palette.primary.main;
        case Status.FILLED:
            return theme.palette.success.main;
        case Status.CANCELLED:
            return theme.palette.error.main;
        default:
            return theme.palette.text.primary;
    }
};

const getCellBackgroundColor = (status: Status) => {
    switch (status) {
        case Status.PLACED:
            return '#d6e4ff';
        case Status.FILLED:
            return '#e3ffe4';
        case Status.CANCELLED:
            return '#ffdedb';
        default:
            return 'transparent';
    }
};

export const ReturnEnvelopeOrderStatus = ({
    status,
}: ReturnEnvelopeOrderStatusCellProps) => {
    const classes = useStyles({ status });
    return <span className={classes.bubble}>{status}</span>;
};

const ReturnEnvelopeOrderStatusCell = ({
    status,
}: ReturnEnvelopeOrderStatusCellProps) => {
    const classes = useStyles({ status });
    return (
        <TableCell className={classes.root}>
            <ReturnEnvelopeOrderStatus status={status} />
        </TableCell>
    );
};

export default ReturnEnvelopeOrderStatusCell;
