import React from 'react';
// Components
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const EmbedError = ({
    title,
    body,
}: {
    title?: string;
    body: React.ReactNode;
}) => {
    return (
        <Box padding={4} height="100vh" display="flex">
            <Box width="100%">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Alert severity="error" style={{ width: '100%' }}>
                            {title && <AlertTitle>{title}</AlertTitle>}
                            {body}
                        </Alert>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

// HACK: Copied from AV dashboard
const InvalidEmbeddedRoute = () => {
    return (
        <EmbedError
            title="Invalid Integration"
            body={
                <>
                    This integration does not exist. Ensure your iframe URL is
                    set correctly. If this problem persists contact us at{' '}
                    <Link href="mailto:support@postgrid.com" component="a">
                        support@postgrid.com
                    </Link>
                    .
                </>
            }
        />
    );
};

export default InvalidEmbeddedRoute;
