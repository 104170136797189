import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { OrderExtraService } from '../services/Base';
import EnumSelector from './EnumSelect';
import TooltipWrapper from './TooltipWrapper';
import ExpressExtraServiceToolTip from './ExpressExtraServiceToolTip';

type ExtraServiceSelectorProps = Omit<GridProps, 'onChange'> & {
    extraService: OrderExtraService | '';
    onChange: (service: OrderExtraService | '') => void;
    disabled?: boolean;
    selectTestID?: string;
};

const ExtraServiceSelector = ({
    extraService,
    onChange,
    disabled,
    selectTestID,
    ...props
}: ExtraServiceSelectorProps) => {
    return (
        <TooltipWrapper
            title={<ExpressExtraServiceToolTip />}
            disabled={disabled}
        >
            <Grid item {...props}>
                <EnumSelector
                    title="Extra Service"
                    valueLabels={[
                        [OrderExtraService.CERTIFIED, 'Certified'],
                        [
                            OrderExtraService.CERTIFIED_RETURN_RECEIPT,
                            'Certified with Return Receipt',
                        ],
                        [OrderExtraService.REGISTERED, 'Registered'],
                    ]}
                    selectTestID={selectTestID}
                    undefinedLabel="None"
                    onChange={onChange}
                    value={extraService}
                    disabled={disabled}
                />
            </Grid>
        </TooltipWrapper>
    );
};

export default ExtraServiceSelector;
