import React, {
    createContext,
    useReducer,
    PropsWithChildren,
    Dispatch,
} from 'react';

import { convertDatesInPlace } from '../services/util';
import { Resource } from '../services/Base';

interface APIKey {
    value: string;
    activeUntil?: Date;
}

export enum OrderPreviewSendPreference {
    DO_NOT_SEND = 'do_not_send',
    SEND_LIVE_ONLY = 'send_live_only',
    SEND_LIVE_AND_TEST = 'send_live_and_test',
}

export interface EmailPreferences {
    orderPreviewSendPreference?: OrderPreviewSendPreference;
}

export interface User extends Resource {
    id: string;
    name: string;
    email: string;
    pendingInvite: boolean;
    apiKeys: APIKey[];
    auth0User?: string;
    phoneNumber?: string;
    roles: string[];
    createdAt: Date;
    updatedAt: Date;
    isPGAdmin?: boolean;
    emailPreferences?: EmailPreferences;
}

interface Tokens {
    test: string;
    live: string;
}

export enum ActionType {
    LOGIN = 'login',
    UPDATE_USER = 'update',
    LOGOUT = 'logout',
}

export interface LoginPayload {
    user: User;
    tokens: Tokens;
}

interface LoginAction {
    type: ActionType.LOGIN;
    payload: LoginPayload;
}

interface UpdateUserAction {
    type: ActionType.UPDATE_USER;
    user: User;
}

interface LogoutAction {
    type: ActionType.LOGOUT;
}

type Action = LoginAction | UpdateUserAction | LogoutAction;

export interface State {
    user?: User;
    tokens?: Tokens;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionType.LOGIN:
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem(
                'tokens',
                JSON.stringify(action.payload.tokens)
            );

            return {
                ...state,
                ...action.payload,
            };

        case ActionType.UPDATE_USER:
            localStorage.setItem(
                'user',
                JSON.stringify({
                    ...state.user,
                    ...action.user,
                })
            );

            return {
                ...state,
                user: action.user,
            };

        case ActionType.LOGOUT:
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            if ((window as any).Intercom) {
                (window as any).Intercom('shutdown');
            }
            return {};
    }
};

export const Context = createContext<{
    state: State;
    dispatch: Dispatch<Action>;
}>({
    state: {},
    dispatch: () => {},
});

export const Store = (props: PropsWithChildren<{}>) => {
    const existingUser: User = JSON.parse(
        localStorage.getItem('user') || 'null'
    );

    convertDatesInPlace(existingUser ?? {});

    const existingTokens: Tokens = JSON.parse(
        localStorage.getItem('tokens') || 'null'
    );

    const [state, dispatch] = useReducer(reducer, {
        user: existingUser,
        tokens: existingTokens,
    });

    return (
        <Context.Provider value={{ state, dispatch }}>
            {props.children}
        </Context.Provider>
    );
};
