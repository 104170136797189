import React, { useCallback, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { usePagination } from '../hooks/usePagination';
import { useModal } from '../hooks/useModal';

import { CreateContactProvider } from '../context/CreateContact';
import { CreateOrderOriginProvider } from '../context/CreateOrderOrigin';
import {
    fullName,
    useService as useContactsService,
} from '../services/Contacts';
import { formatTableDate, ListParams } from '../services/util';
import { ContactRoutes } from '../routes';

import InfoIcon from '@mui/icons-material/Info';
import LinearProgress from '@mui/material/LinearProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import CreateContact from './CreateContact';
import ViewContact from './ViewContact';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import UploadContactsDialog, {
    CompletedUploadData,
} from '../components/UploadContactsDialog';
import PageHeader from '../components/PageHeader';
import TableDisplay from '../components/TableDisplay';
import ToolTipIconButton from '../components/ToolTipIconButton';

const Contacts = () => {
    const history = useHistory();

    const service = useContactsService();

    // state to hold results received from server after uploading contacts
    const [uploadResults, setUploadResults] =
        useState<CompletedUploadData | null>(null);

    const paginationFunc = useCallback(
        (params: ListParams) => service.list(params),
        [service]
    );

    const {
        data: contacts,
        loading,
        searchQuery,
        searchText,
        getData: getContacts,
        pagination,
    } = usePagination(paginationFunc);

    const {
        isModalOpen: uploadContactsOpen,
        toggleModal: toggleUploadContactsModal,
    } = useModal();

    const handleUploadComplete = async (uploadData: CompletedUploadData) => {
        // Refresh contact list
        await getContacts();
        setUploadResults(uploadData);
    };

    const handleDownloadReport = async () => {
        if (!uploadResults?.mailingListImport.reportURL) {
            return;
        }

        // Downloads the report URL
        const blob = await fetch(
            uploadResults.mailingListImport.reportURL
        ).then((res) => res.blob());
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = uploadResults.mailingListImport.reportURL;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    };

    const errorCount = uploadResults?.mailingListImport.invalidRowCount ?? 0;
    const totalCount =
        errorCount + (uploadResults?.mailingListImport.validRowCount ?? 0);

    return (
        <>
            <UploadContactsDialog
                open={uploadContactsOpen}
                onClose={toggleUploadContactsModal}
                onCompleted={handleUploadComplete}
            />

            <CreateContactProvider>
                <Switch>
                    <Route exact path={ContactRoutes.HOME}>
                        <TopNav
                            showSearch
                            searchText={searchText}
                            searchQuery={searchQuery}
                        />
                        <GridPaper
                            direction="column"
                            spacing={2}
                            data-testid="contacts-page"
                        >
                            <PageHeader title="Contacts">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            history.push(ContactRoutes.CREATE);
                                        }}
                                    >
                                        Create Contact
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toggleUploadContactsModal}
                                    >
                                        Upload Contacts
                                    </Button>
                                </Grid>
                            </PageHeader>

                            <Grid item>
                                <TableDisplay
                                    columns={[
                                        'Name',
                                        'Company',
                                        'Address',
                                        <>
                                            Address Status
                                            <ToolTipIconButton
                                                icon={InfoIcon}
                                                title="US and Canadian contacts with a 'failed' status are likely undeliverable"
                                                size="small"
                                            />
                                        </>,
                                        'Created At',
                                    ]}
                                    show={loading}
                                    pagination={pagination}
                                    showEmptyTable
                                >
                                    {contacts.map((contact) => {
                                        return (
                                            <TableRow
                                                key={contact.id}
                                                hover
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    history.push(
                                                        `${ContactRoutes.HOME}/${contact.id}`
                                                    );
                                                }}
                                            >
                                                <TableCell>
                                                    {fullName(contact)}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.companyName || ''}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.addressChange && (
                                                        <ToolTipIconButton
                                                            icon={InfoIcon}
                                                            title="The address of this contact has changed."
                                                            color="#edb818"
                                                            size="small"
                                                        />
                                                    )}
                                                    {contact.addressLine1}
                                                </TableCell>
                                                <TableCell>
                                                    {contact.addressStatus}
                                                </TableCell>
                                                <TableCell>
                                                    {formatTableDate(
                                                        contact.createdAt
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableDisplay>
                            </Grid>
                            {uploadResults && (
                                <Paper variant="outlined">
                                    <Box margin={2}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item xs={9}>
                                                <LinearProgress
                                                    color={
                                                        errorCount > 0
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="determinate"
                                                    value={
                                                        ((totalCount -
                                                            errorCount) /
                                                            totalCount) *
                                                        100
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        disabled={
                                                            !uploadResults
                                                        }
                                                        onClick={
                                                            handleDownloadReport
                                                        }
                                                    >
                                                        Download Report
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            )}
                        </GridPaper>
                    </Route>
                    <Route
                        path={ContactRoutes.CREATE}
                        render={() => (
                            <CreateOrderOriginProvider>
                                <CreateContact />
                            </CreateOrderOriginProvider>
                        )}
                    />
                    <Route path={ContactRoutes.VIEW} component={ViewContact} />
                </Switch>
            </CreateContactProvider>
        </>
    );
};

export default Contacts;
