import React from 'react';
import BaseEnumSelect, { BaseEnumSelectProps } from './BaseEnumSelect';

type StrictEnumSelectProps<T> = Omit<
    BaseEnumSelectProps<T>,
    'onChange' | 'value'
> & {
    onChange: (val: T) => void;
    value: T;
};

/**
 * Requires a value to always be present.  If you wish to allow for null values use `EnumSelect`.
 */
const StrictEnumSelect = <T extends string>({
    onChange,
    ...props
}: StrictEnumSelectProps<T>) => {
    const handleChange = (e: string) => {
        onChange(e as T);
    };

    return <BaseEnumSelect onChange={handleChange} {...props} />;
};

export default StrictEnumSelect;
