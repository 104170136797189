import React, { ReactNode, useCallback, useMemo, useEffect } from 'react';

import {
    Switch,
    Route,
    RouteProps,
    useRouteMatch,
    useHistory,
} from 'react-router-dom';

import posthog from 'posthog-js';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';

import { usePagination } from '../hooks/usePagination';
import { useModal } from '../hooks/useModal';

import { formatTableDate, ListParams } from '../services/util';
import {
    Order,
    OrderObjects,
    OrderStatus,
    Service as OrdersService,
} from '../services/Orders';

import { BulkCancelOrdersProvider } from '../context/BulkCancelOrders';
import { ReFetchProvider, useReFetchContext } from '../context/ReFetchContext';

import TopNav from './TopNav';
import ViewPDFLink from './ViewPDFLink';
import GridPaper from './GridPaper';
import Button from './Button';
import PageHeader from './PageHeader';
import TableDisplay from './TableDisplay';
import BulkCancelOrdersDialog from './BulkCancelOrdersDialog';
import ToolTipIconButton from './ToolTipIconButton';
import CSVDownloadButton from './CSVDownloadButton';
import OrderStatusCell from './OrderStatusCell';

const ORDER_POLL_TIMEOUT = 5000;
const PDF_LINK_EXPIRY = 600_000;

type OrdersPageProps<T extends Order> = {
    object: OrderObjects;
    service: OrdersService<T>;
    headings?: ReactNode[];
    getCells?: (value: T) => ReactNode;
    Create: RouteProps['component'];
    View: RouteProps['component'];
};

function getDefaultTableCells<T extends Order>(
    order: T,
    service: OrdersService<T>
) {
    return (
        <>
            <TableCell>{order.description}</TableCell>
            <TableCell>
                {order.to.firstName
                    ? `${order.to.firstName} ${order.to.lastName || ''}`
                    : order.to.companyName || ''}
            </TableCell>
            <TableCell>
                {order.to.addressChange && (
                    <ToolTipIconButton
                        icon={InfoIcon}
                        title="The address of this recipient has changed."
                        color="#edb818"
                        size="small"
                    />
                )}
                {order.to.addressLine1}
            </TableCell>
            <OrderStatusCell {...order} />
            <TableCell>{formatTableDate(order.createdAt)}</TableCell>
            <TableCell>
                <ViewPDFLink order={order} service={service} />
            </TableCell>
        </>
    );
}

function OrdersPage<T extends Order>(props: OrdersPageProps<T>) {
    const headings = props.headings || [
        'Description',
        'Recipient',
        'Address',
        'Status',
        'Created At',
        <>
            Preview
            <ToolTipIconButton
                icon={HelpIcon}
                title="View PDF previews to ensure accurate printing results"
                size="small"
            />
        </>,
    ];

    const match = useRouteMatch();
    const history = useHistory();
    const { isModalOpen, closeModal, openModal } = useModal();
    const { reFetch } = useReFetchContext();

    const paginationFunc = useCallback(
        (params: ListParams) => {
            return props.service.list(params);
        },
        [props.service]
    );

    // FIXME This is not necessary, the `usePagination` hook should expand the
    // dependencies rather than relying on the object identity of the array i.e.
    // inside `usePagination`, in the `useEffect` where we've supplied `dependencies`
    // we should supply `...dependencies` instead.
    const dependencies = useMemo(() => [reFetch], [reFetch]);

    const {
        data: orders,
        setData,
        setTotalCount,
        loading,
        searchQuery,
        searchText,
        search,
        pagination,
    } = usePagination<T>(paginationFunc, { dependencies });

    useEffect(() => {
        if (orders.length > 0) {
            posthog.capture('Viewed Orders', {
                $set: {
                    hasTestOrders: !orders[0].live,
                    hasLiveOrders: orders[0].live,
                },
            });
        }
    }, [orders]);

    useEffect(() => {
        let isMounted = true;
        let fetchOrders: number | undefined;
        let refreshPDFLinks: number | undefined;

        let ordersToRefetch = orders.filter(
            (order) => !order.url && order.status !== OrderStatus.CANCELLED
        ).length;

        const refetchOrders = async () => {
            if (!ordersToRefetch) {
                // Set an interval to fetch orders every 10min to refresh PDF links
                refreshPDFLinks = window.setInterval(async () => {
                    const orders = await paginationFunc({
                        skip: pagination.page * pagination.rowsPerPage,
                        limit: pagination.rowsPerPage,
                        search,
                    });

                    if (!isMounted) {
                        return;
                    }

                    setData(orders.data);
                    setTotalCount(orders.totalCount);
                }, PDF_LINK_EXPIRY);
                return;
            }

            fetchOrders = window.setTimeout(async () => {
                const { data: refetchedOrders, totalCount: count } =
                    await paginationFunc({
                        skip: pagination.page * pagination.rowsPerPage,
                        limit: pagination.rowsPerPage,
                        search,
                    });

                const ordersWithoutPreview = refetchedOrders.filter(
                    (order) =>
                        !order.url && order.status !== OrderStatus.CANCELLED
                ).length;

                if (!isMounted) {
                    return;
                }

                if (ordersWithoutPreview < ordersToRefetch) {
                    setData(refetchedOrders);
                    setTotalCount(count);
                    ordersToRefetch = ordersWithoutPreview;
                } else {
                    refetchOrders();
                }
            }, ORDER_POLL_TIMEOUT);
        };

        refetchOrders();

        return () => {
            isMounted = false;
            clearTimeout(fetchOrders);
            clearInterval(refreshPDFLinks);
        };
    }, [
        orders,
        search,
        pagination.page,
        pagination.rowsPerPage,
        setData,
        setTotalCount,
        paginationFunc,
    ]);

    const orderObject = useMemo(() => {
        return props.object.replaceAll(/_/g, ' ');
    }, [props.object]);

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <BulkCancelOrdersProvider
                    service={props.service}
                    object={orderObject}
                    open={isModalOpen}
                >
                    <BulkCancelOrdersDialog
                        open={isModalOpen}
                        onClose={closeModal}
                    />
                </BulkCancelOrdersProvider>
                <TopNav
                    showSearch
                    searchText={searchText}
                    searchQuery={searchQuery}
                    showDateRange
                />
                <GridPaper direction="column" spacing={2}>
                    <PageHeader title={`${orderObject}s`}>
                        <Grid item>
                            <CSVDownloadButton
                                fileName={`${orderObject}s`}
                                label={`Download ${orderObject}s`}
                                retrievalFunction={(
                                    searchParams: ListParams
                                ) => {
                                    return props.service.list({
                                        ...searchParams,
                                        search,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={openModal}
                            >
                                Cancel {orderObject}s
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.push(`${match.path}/create`);
                                }}
                            >
                                Create {orderObject}
                            </Button>
                        </Grid>
                    </PageHeader>
                    <Grid item>
                        <TableDisplay
                            columns={headings}
                            show={loading}
                            pagination={pagination}
                            showEmptyTable
                        >
                            {orders.map((order) => {
                                return (
                                    <TableRow
                                        hover
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `${match.path}/${order.id}`
                                            );
                                        }}
                                        key={order.id}
                                    >
                                        {props.getCells
                                            ? props.getCells(order as T)
                                            : getDefaultTableCells(
                                                  order as T,
                                                  props.service
                                              )}
                                    </TableRow>
                                );
                            })}
                        </TableDisplay>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={`${match.path}/create`} component={props.Create} />
            <Route path={`${match.path}/:orderID`} component={props.View} />
        </Switch>
    );
}

const OrdersPageWithRefetch = <T extends Order>(props: OrdersPageProps<T>) => {
    return (
        <ReFetchProvider>
            <OrdersPage {...props} />
        </ReFetchProvider>
    );
};

export default OrdersPageWithRefetch;
