import React from 'react';

import { useService as usePostcardsService } from '../services/Postcards';

import OrderDetailsPage from '../components/OrderDetailsPage';

const ViewPostcard = () => {
    const service = usePostcardsService();

    return (
        <OrderDetailsPage title="Postcard Details" service={service} hasPDF />
    );
};

export default ViewPostcard;
