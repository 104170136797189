import { Resource } from './Base';
import { Service as ResourceService } from './Resources';
import { DeleteResponse } from './util';

export class Service<T extends Resource> extends ResourceService<T> {
    async delete(id: string) {
        return await this.base.fetchAPI<DeleteResponse>(
            `/${this.route}/${id}`,
            {
                method: 'DELETE',
            }
        );
    }
}
