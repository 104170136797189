import React, { useState, useCallback } from 'react';

import { Bar } from '@reactchartjs/react-chart.js';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import { type DefaultTheme } from '@mui/styles/defaultTheme';
import { lighten } from '@mui/material/styles';

import {
    useService,
    LogisticsAnalytics,
    PeriodKey,
    VolumeByCountryView,
} from '../services/LogisticsAnalytics';

import AddViewDialog from '../components/AddViewDialog';
import TableDisplay from '../components/TableDisplay';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import Button from '../components/Button';

const allUniqueCountries = (data: VolumeByCountryView[]): string[] => {
    const entries = new Set<string>();

    for (const dateCountries of data) {
        for (const countryCount of Object.entries(
            dateCountries.countryVolumes
        )) {
            entries.add(countryCount[0]);
        }
    }

    return Array.from(entries.keys());
};

const chartData = (viewData: LogisticsAnalytics, theme: DefaultTheme) => {
    const { view, data } = viewData;
    switch (view) {
        case 'order_pipeline':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Total Orders',
                        data: data.map((v) => v.totalOrders),
                        backgroundColor: theme.palette.primary.main,
                    },
                    {
                        label: 'Sent orders',
                        data: data.map((v) => v.sentOrders),
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.2
                        ),
                    },
                    {
                        label: 'Paused Orders',
                        data: data.map((v) => v.pausedOrders),
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.4
                        ),
                    },
                    {
                        label: 'Cancelled Orders',
                        data: data.map((v) => v.cancelledOrders),
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.6
                        ),
                    },
                    {
                        label: 'Tracked Orders',
                        data: data.map((v) => v.trackedOrders),
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.8
                        ),
                    },
                ],
            };
        case 'volume':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Volume',
                        data: data.map((v) => v.volume),
                        backgroundColor: theme.palette.primary.main,
                    },
                ],
            };
        case 'volume_by_country':
            const countries = allUniqueCountries(data);

            return {
                labels: data.map((v) => v.date),
                datasets: countries.map((country, index) => {
                    return {
                        label: country,
                        data: data.map((v) => v.countryVolumes[country] ?? 0),
                        backgroundColor: lighten(
                            theme.palette.primary.main,
                            (index + 1) / countries.length
                        ),
                    };
                }),
            };
        case 'total_customers':
        case 'new_customers':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Total',
                        data: data.map((v) => v.total),
                        backgroundColor: theme.palette.primary.main,
                    },
                ],
            };
        case 'revenue':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Revenue',
                        data: data.map((v) => v.revenue),
                        backgroundColor: theme.palette.primary.main,
                    },
                ],
            };
        case 'profit_margin':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Profit Margin',
                        data: data.map((v) => v.profitMargin),
                        backgroundColor: theme.palette.primary.main,
                    },
                ],
            };
        case 'profit_or_loss':
            return {
                labels: data.map((v) => v.date),
                datasets: [
                    {
                        label: 'Profit or Loss',
                        data: data.map((v) => v.profitOrLoss),
                        backgroundColor: theme.palette.primary.main,
                    },
                ],
            };
    }
};

const chartOptions = (data: LogisticsAnalytics) => {
    return {
        responsive: true,
        maintainAspectRatio: true,
        title: {
            display: false,
        },
        legend: {
            display: true,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                    type:
                        // We want the scale for countries to be logarithmic because
                        // otherwise it is practically useless because the US dominates the chart by multiple
                        // orders of magnitude.
                        data.view === 'volume_by_country'
                            ? 'logarithmic'
                            : undefined,
                },
            ],
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: data.period,
                        displayFormats: {
                            day: 'DD-MM-YYYY',
                            month: 'MM-YYYY',
                            year: 'YYYY',
                        },
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 14,
                    },
                    offset: true,
                },
            ],
        },
    };
};

const tableLabels = (data: LogisticsAnalytics) => {
    switch (data.view) {
        case 'order_pipeline':
            return [
                'Date',
                'Total Orders',
                'Sent orders',
                'Paused Orders',
                'Cancelled Orders',
                'Tracked Orders',
            ];
        case 'volume':
            return ['Date', 'Total'];
        case 'volume_by_country':
            return ['Date'].concat(allUniqueCountries(data.data));
        case 'total_customers':
            return ['Date', 'Total Clients'];
        case 'new_customers':
            return ['Date', 'New Clients'];
        case 'revenue':
            return ['Date', 'Revenue'];
        case 'profit_margin':
            return ['Date', 'Profit Margin'];
        case 'profit_or_loss':
            return ['Date', 'Profit Or Loss'];
    }
};

const tableRows = (data: LogisticsAnalytics) => {
    switch (data.view) {
        case 'order_pipeline':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.totalOrders}</TableCell>
                    <TableCell>{v.sentOrders}</TableCell>
                    <TableCell>{v.pausedOrders}</TableCell>
                    <TableCell>{v.cancelledOrders}</TableCell>
                    <TableCell>{v.trackedOrders}</TableCell>
                </TableRow>
            ));
        case 'volume':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.volume}</TableCell>
                </TableRow>
            ));
        case 'volume_by_country':
            const countries = allUniqueCountries(data.data);

            return data.data.map((v) => {
                return (
                    <TableRow key={v.date}>
                        <TableCell>{v.date}</TableCell>
                        {countries.map((country) => (
                            <TableCell>
                                {v.countryVolumes[country] ?? 0}
                            </TableCell>
                        ))}
                    </TableRow>
                );
            });
        case 'total_customers':
        case 'new_customers':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.total}</TableCell>
                </TableRow>
            ));
        case 'revenue':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.revenue}</TableCell>
                </TableRow>
            ));
        case 'profit_margin':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.profitMargin}</TableCell>
                </TableRow>
            ));
        case 'profit_or_loss':
            return data.data.map((v) => (
                <TableRow key={v.date}>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>{v.profitOrLoss}</TableCell>
                </TableRow>
            ));
    }
};

const View = (props: {
    data: LogisticsAnalytics;
    graphView: boolean;
    setGraphView: (v: boolean) => void;
    onDeleteClicked: () => void;
}) => {
    const theme = useTheme();

    return (
        <Grid container spacing={2} style={{ paddingTop: '15px' }}>
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                xs={12}
            >
                <Grid item>
                    <Typography variant="h6">
                        {props.data.view} from{' '}
                        {props.data.startDate.toDateString()} to{' '}
                        {props.data.endDate.toDateString()} by{' '}
                        {props.data.period}
                        {props.data.planName
                            ? ` on the '${props.data.planName}' plan`
                            : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography>Graph View</Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={props.graphView}
                                        onChange={(event) =>
                                            props.setGraphView(
                                                event.target.checked
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={props.onDeleteClicked}
                                size="large"
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {props.graphView ? (
                    <Bar
                        type="line"
                        data={chartData(props.data, theme)}
                        options={chartOptions(props.data)}
                        height={80}
                    />
                ) : (
                    <TableDisplay
                        columns={tableLabels(props.data)}
                        show={false}
                        showEmptyTable
                    >
                        {tableRows(props.data)}
                    </TableDisplay>
                )}
            </Grid>
        </Grid>
    );
};

interface ViewState {
    data: LogisticsAnalytics;
    graphView: boolean;
}

const useJSONLocalStorage = <T extends Record<string, any>>(
    key: string,
    init: T
) => {
    const [value, setValue] = useState<T>(() => {
        const item = localStorage.getItem(key);

        if (item) {
            return JSON.parse(item, (key, value) => {
                // If the key is `startDate`, `endDate`, etc. But not date, which should remain a string.
                if (key.includes('Date')) {
                    return new Date(value);
                }

                return value;
            });
        }

        return init;
    });

    const setValuePersist = useCallback(
        (newValue: T | ((oldValue: T) => T)) => {
            const replacer = (_key: string, value: any) => {
                if (value instanceof Date) {
                    return value.toISOString();
                }

                return value;
            };

            if (typeof newValue === 'function') {
                setValue((oldValue) => {
                    const res = newValue(oldValue);
                    localStorage.setItem(key, JSON.stringify(res, replacer));

                    return res;
                });
            } else {
                setValue(newValue);
                localStorage.setItem(key, JSON.stringify(newValue, replacer));
            }
        },
        [key]
    );

    return [value, setValuePersist] as const;
};

const LogisticsAnalyticsViews = () => {
    const service = useService();

    const [addViewOpen, setAddViewOpen] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [views, setViews] = useJSONLocalStorage<ViewState[]>(
        'logistics_analytics',
        []
    );

    const deleteView = (index: number) => {
        setViews((views) =>
            views.filter((_view, currentIndex) => currentIndex !== index)
        );
    };

    const createAnalyticsView = async (params: {
        view: string;
        startDate: Date;
        endDate: Date;
        period: PeriodKey;
        planName: string;
    }) => {
        const newView = await service.getView({
            ...params,
            planName: params.planName === '' ? undefined : params.planName,
        });

        setViews((views) => [
            ...views,
            {
                data: newView,
                graphView: false,
            },
        ]);
    };

    const setGraphView = (i: number, graphView: boolean) => {
        setViews((views) =>
            views.map((oldView, idx) =>
                idx === i
                    ? {
                          ...oldView,
                          graphView,
                      }
                    : oldView
            )
        );
    };

    return (
        <>
            <TopNav
                showSearch
                searchText={searchText}
                searchQuery={setSearchText}
            />
            <AddViewDialog
                open={addViewOpen}
                onClose={() => setAddViewOpen(false)}
                createAnalyticsView={createAnalyticsView}
            />
            <GridPaper direction="column" spacing={2}>
                <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">
                            Logistics Analytics
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ textTransform: 'none' }}
                                    onClick={() => {
                                        setAddViewOpen(true);
                                    }}
                                >
                                    Add View
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {views.map((v, i) => (
                    <Grid key={i} item xs={12}>
                        <View
                            data={v.data}
                            graphView={v.graphView}
                            setGraphView={(v) => setGraphView(i, v)}
                            onDeleteClicked={() => deleteView(i)}
                        />
                    </Grid>
                ))}
            </GridPaper>
        </>
    );
};

export default LogisticsAnalyticsViews;
