import { useMemo } from 'react';

import { useService as useBaseService, ResourceCreateParams } from './Base';
import {
    convertPDFForRequest,
    Order,
    OrderCreationFields,
    Service as OrdersService,
    undefinedIfToday,
} from './Orders';
import { Contact } from './Contacts';
import {
    APIErrorResponse,
    asyncMapChunks,
    requestFormData,
    retryBatchWrapper,
} from './util';

export enum SelfMailerSize {
    'BIFOLD_8.5X11' = '8.5x11_bifold',
}

export interface SelfMailer extends Order {
    from: Contact;
    insideHTML?: string;
    outsideHTML?: string;
    insideTemplate?: string;
    outsideTemplate?: string;
    size: SelfMailerSize;
}

type CreateParams = {
    sendDate?: Date;
    pdf?: File;
} & ResourceCreateParams &
    OrderCreationFields<SelfMailer>;

type RequiredBatchKeys =
    | 'insideHTML'
    | 'outsideHTML'
    | 'insideTemplate'
    | 'outsideTemplate'
    | 'size'
    | 'express'
    | 'sendDate'
    | 'pdf';

type CreateBatchDatum = Omit<CreateParams, RequiredBatchKeys> & {
    to: string;
    from: string;
};

type CreateBatchParams = Pick<CreateParams, RequiredBatchKeys> & {
    pdf?: File | string;
    data: CreateBatchDatum[];
    handleProgress: (count: number) => void;
};

interface CreateBatchResponse {
    object: 'batch';
    data: (SelfMailer | APIErrorResponse)[];
}

class Service extends OrdersService<SelfMailer> {
    async create(params: CreateParams): Promise<SelfMailer> {
        return await this.base.fetchAPI(`/${this.route}`, {
            method: 'POST',
            body: requestFormData({
                ...params,
                sendDate: undefinedIfToday(params.sendDate),
                metadata: { postgrid_dashboard: '' },
            }),
        });
    }

    async createBatch(params: CreateBatchParams) {
        const batchRequest = async (batch: CreateBatchDatum[]) => {
            const requestBody = {
                ...params,
                sendDate: undefinedIfToday(params.sendDate),
                pdf: await convertPDFForRequest(params.pdf),
                data: batch,
                handleProgress: undefined,
            };

            const res = await this.base.fetchAPI<CreateBatchResponse>(
                `/${this.route}/batch`,
                {
                    method: 'post',
                    body: requestBody,
                }
            );

            params.handleProgress(batch.length);
            return res;
        };

        const chunks = await asyncMapChunks(
            params.data,
            250,
            retryBatchWrapper(batchRequest)
        );

        return chunks.flatMap((d) => d.data);
    }
}

export const useService = () => {
    const base = useBaseService();
    return useMemo(() => new Service(base, 'self_mailers'), [base]);
};
