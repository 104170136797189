import { useMemo } from 'react';

import { MessageType } from '../context/Notification';

import { Service as BaseService, useService as useBaseService } from './Base';
import { APIRequestError } from './util';

export class Service {
    private route = 'images';
    constructor(private base: BaseService) {}

    public async uploadWithRetries(
        file: File | string,
        retryCount = 3,
        headers?: Record<string, string>,
        forceLive: boolean = false
    ): Promise<string> {
        const data = new FormData();
        data.set('file', file);

        for (;;) {
            try {
                const res = await this.base.fetchAPI<{ url: string }>(
                    `/${this.route}`,
                    {
                        headers,
                        method: 'POST',
                        body: data,
                        silent: true,
                        forceLive,
                    }
                );

                return res.url;
            } catch (e: any) {
                if (
                    !retryCount ||
                    // Do not retry in the event that it's a 400 error
                    (e instanceof APIRequestError && e.status === 400)
                ) {
                    this.base.notify({
                        message: e.message,
                        type: MessageType.ERROR,
                    });

                    throw e;
                }

                --retryCount;
            }
        }
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
