import React from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { UserRoutes } from '../routes';
import { useOrganization } from '../services/Organization';
// Pages
import InviteUser from './InviteUser';
import ViewUser from './ViewUser';
// Components
// MUI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// Our Components
import ButtonDisabledTooltip from '../components/ButtonDisabledTooltip';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import UsersTable from '../components/UsersTable';

const Users = () => {
    const match = useRouteMatch();
    const history = useHistory();

    const org = useOrganization([history.location]);

    return (
        <Switch>
            <Route exact path={UserRoutes.HOME}>
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5">Users</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <ButtonDisabledTooltip
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                history.push(
                                                    `${match.path}/create`
                                                );
                                            }}
                                            disabled={
                                                !org ||
                                                (!org.stripeSubscription &&
                                                    !org.allowInvitingUsersWithoutSubscribing)
                                            }
                                            TooltipProps={{
                                                title: 'Subscribe to invite users',
                                            }}
                                        >
                                            Invite User
                                        </ButtonDisabledTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <UsersTable />
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={UserRoutes.INVITE} component={InviteUser} />
            <Route path={UserRoutes.VIEW} component={ViewUser} />
        </Switch>
    );
};

export default Users;
