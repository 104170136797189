import React from 'react';

import { PaperProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 10,
        height: '100%',
        width: '100%',
    },
}));

const PaperWrapper = ({ children, ...props }: PaperProps) => {
    const classes = useStyles();
    return (
        <Paper
            variant="outlined"
            data-testid="paper-wrapper"
            className={`${classes.root} ${props.className}`}
            {...props}
        >
            {children}
        </Paper>
    );
};

export default PaperWrapper;
