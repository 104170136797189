import React from 'react';

import signupGraphic from '../img/login-image.png';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
    graphicContainer: {
        height: '100vh',
    },
    graphic: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    container: {
        padding: theme.spacing(2),
    },
}));

const GraphicWrapper = ({ children }: { children: React.ReactNode }) => {
    const classes = useStyles();
    return (
        <Grid container direction="row">
            <Grid item xs={6} className={classes.graphicContainer}>
                <img
                    src={signupGraphic}
                    alt="Signup Graphic"
                    className={classes.graphic}
                />
            </Grid>
            <Grid
                item
                container
                xs={6}
                alignItems="center"
                className={classes.container}
            >
                {children}
            </Grid>
        </Grid>
    );
};

export default GraphicWrapper;
