import React, { ReactNode, useContext } from 'react';

import { Context as ModeContext } from '../context/Mode';

import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

export type PageHeaderProps = Omit<
    GridProps,
    'spacing' | 'container' | 'direction' | 'item'
> & {
    /** Title of the header.  `required` */
    title: string;
    /** Display the alert in warning the color.  `optional` */
    liveWarning?: boolean;
    /** Custom text for the alert box in test mode.  `optional` */
    testAlertText?: string | ReactNode;
    /** Custom text for the alert box in live mode.  `optional` */
    liveAlertText?: string | ReactNode;
    /** Does not display the test mode display.  `optional` */
    hideAlert?: boolean;
};

/**
 *
 * @param title The title of the header
 * @param liveWarningText Indicates if the alert should be in warning style
 * @param testAlertText An optional specified message to display in the alert in test mode.
 * @param liveAlertText An optional specified message to display in the alert in live mode.
 * @param hideAlert Hides the test mode alert.
 * @param children A list of children to be displayed to the right of the header.
 * Each child should be wrapped in a MUI Grid item.
 * @param GridProps Any prop you could pass to a MUI Grid component except for `container`, `spacing`, `item` and `direction`
 */
const PageHeader = ({
    title,
    liveWarning,
    testAlertText,
    liveAlertText,
    hideAlert,
    children,
    ...props
}: PageHeaderProps) => {
    const { live } = useContext(ModeContext);
    return (
        <Grid
            item
            container
            direction="column"
            spacing={2}
            {...props}
            data-testid="page-header"
        >
            <Grid item container justifyContent="space-between">
                <Grid item>
                    <Typography
                        variant="h5"
                        style={{ textTransform: 'capitalize' }}
                    >
                        {title}
                    </Typography>
                </Grid>
                {children && (
                    <Grid item>
                        <Grid container justifyContent="flex-end" spacing={2}>
                            {children}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {hideAlert || (live && !liveAlertText) ? (
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            ) : (
                <Grid item>
                    <Alert
                        variant="outlined"
                        color={liveWarning ? 'warning' : 'info'}
                    >
                        {live && liveAlertText ? (
                            liveAlertText
                        ) : testAlertText ? (
                            testAlertText
                        ) : (
                            <span>
                                You are in test mode, so you will only see test
                                mode {title.toLocaleLowerCase()}.
                            </span>
                        )}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default PageHeader;
