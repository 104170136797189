export enum ContactRoutes {
    HOME = '/dashboard/contacts',
    CREATE = '/dashboard/contacts/create',
    VIEW = '/dashboard/contacts/:id',
}

export enum TemplateRoutes {
    HOME = '/dashboard/templates',
    CREATE = '/dashboard/templates/create',
    VIEW = '/dashboard/templates/:templateID',
    EDIT = '/dashboard/templates/:templateID/edit',
}

export enum BankAccountRoutes {
    HOME = '/dashboard/bank_accounts',
    CREATE = '/dashboard/bank_accounts/create',
    VIEW = '/dashboard/bank_accounts/:accountID',
}

export enum TrackerRoutes {
    HOME = '/dashboard/trackers',
    CREATE = '/dashboard/trackers/create',
    VIEW = '/dashboard/trackers/:trackerID',
}

export enum PDFWizardRoutes {
    HOME = '/dashboard/pdf_wizard',
}

export enum LetterRoutes {
    HOME = '/dashboard/letters',
    CREATE = '/dashboard/letters/create',
    VIEW = '/dashboard/letters/:orderID',
}

export enum PostcardRoutes {
    HOME = '/dashboard/postcards',
    CREATE = '/dashboard/postcards/create',
    VIEW = '/dashboard/postcards/:orderID',
}

export enum ChequeRoutes {
    HOME = '/dashboard/cheques',
    CREATE = '/dashboard/cheques/create',
    VIEW = '/dashboard/postcards/:orderID',
}

export enum SelfMailerRoutes {
    HOME = '/dashboard/self_mailers',
    CREATE = '/dashboard/self_mailers/create',
    VIEW = '/dashboard/self_mailers/:orderID',
}

export enum ReturnEnvelopeRoutes {
    HOME = '/dashboard/return_envelopes',
    CREATE = '/dashboard/return_envelopes/create',
    VIEW = '/dashboard/return_envelopes/:id',
    VIEW_ORDER = '/dashboard/return_envelopes/:id/orders/:orderId',
}

export enum APILogRoutes {
    HOME = '/dashboard/api_logs',
    VIEW = '/dashboard/api_logs/:id',
}

export enum WebhookRoutes {
    HOME = '/dashboard/webhooks',
    CREATE = '/dashboard/webhooks/create',
    VIEW = '/dashboard/webhooks/:id',
}

export enum UpgradeRoutes {
    HOME = '/dashboard/upgrade',
}

export enum UserRoutes {
    HOME = '/dashboard/users',
    INVITE = '/dashboard/users/create',
    VIEW = '/dashboard/users/:id',
}

export enum SSORoutes {
    LOGIN = '/login-sso',
    SIGNUP = '/signup-sso',
    ENTER_SIGNUP_INFO = '/signup-sso/complete',
    COMPLETE_SIGNUP = '/signup-sso/complete/:id',
    VERIFY_EMAIL = '/verify_email/sso/:token',
}

export enum IntegrationRoutes {
    OVERVIEW = '/dashboard/integrations',
    ZAPIER = '/dashboard/integrations/zapier',
}

export enum ErrorRoutes {
    UNAUTHORIZED = '/dashboard/unauthorized',
}

export enum EmbeddedRoutes {
    BASE = '/embed',
    TEMPLATE_EDITOR_SESSIONS = '/embed/template_editor_sessions/:sessionID',
    FALLBACK = '/embed/*',
}

export enum LogisticsRoutes {
    ANALYTICS = '/dashboard/logistics/analytics',
}
