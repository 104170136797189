import { useMemo } from 'react';

import {
    type Resource,
    ResourceCreateParams,
    useService as useBaseService,
} from './Base';
import { Service as DeletableResourceService } from './DeletableResource';

export enum ProcessStatus {
    ADDING_CONTACTS = 'creating_contacts',
    REMOVING_CONTACTS = 'removing_contacts',
    COMPLETED = 'completed',
}

enum ProcessErrorType {
    MAILING_LIST_IMPORTS_NOT_FOUND_ERROR = 'mailing_list_imports_not_found_error',
    DOWNLOAD_FILE_ERROR = 'download_file_error',
    OPERATIONAL_ERROR = 'operational_error',
    INTERNAL_SERVICE_ERROR = 'internal_service_error',
}

type CreateParams = ResourceCreateParams;
type UpdateParams = ResourceCreateParams;

interface ProcessError {
    type: ProcessErrorType;
    message: string;
}

export interface MailingList extends Resource {
    object: 'mailing_list';
    status: ProcessStatus;
    errors?: ProcessError[];
}

type CreateJobParams =
    | { addContacts: string[] }
    | { removeContacts: string[] }
    | { addMailingListImports: string[] }
    | { removeMailingListImports: string[] };

export class Service extends DeletableResourceService<MailingList> {
    create(data: CreateParams) {
        return this.base.fetchAPI<MailingList>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    update(id: string, data: UpdateParams) {
        return this.base.fetchAPI<MailingList>(`/${this.route}/${id}`, {
            method: 'POST',
            body: data,
        });
    }

    createJob(id: string, data: CreateJobParams) {
        return this.base.fetchAPI<MailingList>(`/${this.route}/${id}/jobs`, {
            method: 'POST',
            body: data,
        });
    }
}

export const useService = () => {
    const base = useBaseService();
    return useMemo(() => new Service(base, 'mailing_lists'), [base]);
};
