import React from 'react';

import { Theme, useTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles/';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

import {
    CancelReason,
    Order,
    orderStatusLabel,
    OrderStatus as Status,
} from '../services/Orders';

interface OrderStatusCellProps {
    status: Order['status'];
    imbStatus?: Order['imbStatus'];
    carrierTracking?: Order['carrierTracking'];
    updatedAt?: Order['updatedAt'];
    cancellation?: Order['cancellation'];
}

const getCellColor = (theme: Theme, status: Status) => {
    switch (status) {
        case Status.READY:
            return theme.palette.primary.main;
        case Status.PROCESSED_FOR_DELIVERY:
            return theme.palette.warning.main;
        case Status.COMPLETED:
            return theme.palette.success.main;
        case Status.PRINTING:
            return '#969696';
        case Status.CANCELLED:
            return theme.palette.error.main;
        default:
            return theme.palette.text.primary;
    }
};

const getCellBackgroundColor = (status: Status) => {
    switch (status) {
        case Status.READY:
            return '#d6e4ff';
        case Status.PROCESSED_FOR_DELIVERY:
            return '#ffecd1';
        case Status.COMPLETED:
            return '#e3ffe4';
        case Status.PRINTING:
            return '#f2f2f2';
        case Status.CANCELLED:
            return '#ffdedb';
        default:
            return 'transparent';
    }
};

export const OrderStatus = ({
    status,
    imbStatus,
    carrierTracking,
    updatedAt,
    cancellation,
}: OrderStatusCellProps) => {
    const theme = useTheme();

    const ORDER_STATUS_STYLE: CSSProperties = {
        backgroundColor: getCellBackgroundColor(status),
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        color: getCellColor(theme, status),
        borderRadius: 5,
        textTransform: 'capitalize',
    };

    if (status === Status.CANCELLED && cancellation) {
        return (
            <Tooltip
                title={
                    cancellation.reason === CancelReason.USER_INITIATED
                        ? 'Initiated by user.'
                        : cancellation.note
                }
                placement="top"
            >
                <span style={ORDER_STATUS_STYLE}>
                    {status}&nbsp;
                    <ErrorIcon
                        fontSize="small"
                        style={{ marginBottom: '-5px' }}
                    />
                </span>
            </Tooltip>
        );
    }

    return (
        <span style={ORDER_STATUS_STYLE}>
            {orderStatusLabel(
                { status, imbStatus, carrierTracking, updatedAt },
                true
            )}
        </span>
    );
};

const OrderStatusCell = (orderInfo: OrderStatusCellProps) => {
    return (
        <TableCell>
            <OrderStatus {...orderInfo} />
        </TableCell>
    );
};

export default OrderStatusCell;
