import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import LocationOn from '@mui/icons-material/LocationOn';
import ObjectTable from './ObjectTable';

import { Address } from '../services/Contacts';
import AccordionSummary from './AccordionSummary';

export interface OldContactDetailsProps {
    moveYearMonth: string;
    processedOn: Date;
    oldAddress: Address;
}

const OldContactDetails = (props: OldContactDetailsProps) => {
    return (
        <Accordion
            variant="outlined"
            style={{ borderRadius: 10 }}
            data-testid="raw-data"
        >
            <AccordionSummary label="Previous Address" icon={LocationOn} />
            <AccordionDetails
                style={{
                    padding: '20px 20px 30px',
                    borderTop: '1px solid #EFEFEF',
                }}
            >
                <ObjectTable
                    obj={{
                        'Moved On (MM/YYYY)': props.moveYearMonth,
                        'Contact Updated On': props.processedOn,
                        'Line 1': props.oldAddress.addressLine1,
                        'Line 2': props.oldAddress.addressLine2,
                        City: props.oldAddress.city,
                        'Postal or Zip': props.oldAddress.postalOrZip,
                        'Province or State': props.oldAddress.provinceOrState,
                        'Country Code': props.oldAddress.countryCode,
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default OldContactDetails;
