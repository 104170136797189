import { useMemo } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';
import { OrderStatus } from './Orders';

interface OrdersByDate {
    date: string;
    count: number;
}

export interface OrdersByStatus {
    status: OrderStatus;
    count: number;
}

export interface Analytics {
    startDate: Date;
    endDate: Date;
    search?: string;

    lettersByDate: OrdersByDate[];
    lettersByStatus: OrdersByStatus[];

    postcardsByDate: OrdersByDate[];
    postcardsByStatus: OrdersByStatus[];

    chequesByDate: OrdersByDate[];
    chequesByStatus: OrdersByStatus[];
}

interface GetParams {
    startDate?: Date;
    endDate?: Date;
    search?: string;

    // Add zero count to dates with no orders
    padZeros?: boolean;

    tzName?: string;
}

export class Service {
    service: BaseService;

    constructor(service: BaseService) {
        this.service = service;
    }

    async get(data: GetParams = {}) {
        const params = new URLSearchParams();

        if (data.startDate) {
            params.set('startDate', data.startDate.toUTCString());
        }

        if (data.endDate) {
            params.set('endDate', data.endDate.toUTCString());
        }

        if (data.search) {
            params.set('search', data.search);
        }

        if (data.padZeros) {
            params.set('padZeros', 'true');
        }

        params.set(
            'tzName',
            data.tzName || Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        return await this.service.fetchAPI<Analytics>(
            `/analytics?${params.toString()}`
        );
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
