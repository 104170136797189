import { useMemo } from 'react';
import { Service as BaseService, useService as useBaseService } from './Base';
import { ListParams, ListResponse, createURLParams } from './util';

export interface Role {
    id: string;
    name: string;
    permissions: string[];
    description: string;
    liveAccess?: boolean;
}

class Service {
    constructor(private base: BaseService, private route: string) {}

    async list(params: ListParams) {
        const urlSearchParams = createURLParams(params);

        return this.base.fetchAPI<ListResponse<Role>>(
            `/${this.route}?${urlSearchParams.toString()}`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'roles'), [base]);
};
