import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import copy from 'copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import CopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';

interface CopyToClipboardProps {
    value: string;
    dim?: boolean;
}

const CopyToClipboard = ({ value, dim }: CopyToClipboardProps) => {
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        let cancel = false;
        if (clicked) {
            setTimeout(() => {
                if (cancel) return;
                setClicked(false);
            }, 1000);
        }
        return () => {
            cancel = true;
        };
    }, [clicked, setClicked]);

    const handleClick = () => {
        setClicked(true);
        copy(value);
    };

    return (
        <Tooltip title="Copy to clipboard">
            <IconButton
                onClick={handleClick}
                size="small"
                data-testid="copy-to-clipboard"
            >
                <Box display="grid" lineHeight={0}>
                    <Box gridColumn={1} gridRow={1}>
                        <Grow
                            in={clicked}
                            unmountOnExit
                            data-testid="copied-to-clipboard"
                        >
                            <CheckIcon style={{ color: 'green' }} />
                        </Grow>
                    </Box>
                    <Box gridColumn={1} gridRow={1}>
                        <Grow in={!clicked}>
                            <CopyIcon color={dim ? 'disabled' : undefined} />
                        </Grow>
                    </Box>
                </Box>
            </IconButton>
        </Tooltip>
    );
};

export default CopyToClipboard;
