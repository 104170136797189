import React, { FormEvent, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import ContactInput from '../components/ContactInput';
import CreateResourceActions from '../components/CreateResourceActions';
import Field from '../components/Field';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import TopNav from '../components/TopNav';
import { Contact } from '../services/Contacts';
import { useService } from '../services/ReturnEnvelopes';
import { Context, MessageType } from '../context/Notification';
import { useHistory } from 'react-router-dom';
import { ReturnEnvelopeRoutes } from '../routes';

const CreateReturnEnvelope = () => {
    const service = useService();
    const { dispatch } = useContext(Context);
    const history = useHistory();

    const [to, setTo] = useState<Contact | null>(null);
    const [description, setDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // For testing because JSDOM does not handle HTML5 validation
        if (!to) return;
        try {
            setLoading(true);
            await service.create({
                to: to.id,
                description: description ? description : undefined,
            });
            dispatch({
                type: MessageType.SUCCESS,
                message: 'Created return envelope.',
            });
            history.push(ReturnEnvelopeRoutes.HOME);
        } catch (err: any) {
            console.error('Create Return Envelope Error:', err);
            dispatch({
                type: MessageType.ERROR,
                message: err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper
                data-testid="create-return-envelope-page"
                direction="column"
                spacing={2}
            >
                <PageHeader
                    title="Create Return Envelope"
                    testAlertText="You are in test mode.  This return envelope can only be used for test orders."
                />
                <Grid item>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Field
                                    setValue={setDescription}
                                    value={description}
                                    label="Description"
                                    name="description"
                                    InputProps={{
                                        inputProps: {
                                            'data-testid':
                                                'create-envelope-description',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <ContactInput
                                    label="To Contact"
                                    contact={to}
                                    setContact={setTo}
                                    required
                                    textFieldTestId="create-envelope-to-contact"
                                />
                            </Grid>
                            <CreateResourceActions disabled={loading} />
                        </Grid>
                    </form>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateReturnEnvelope;
