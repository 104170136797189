import React, { PropsWithoutRef, useState } from 'react';

// TODO account for timezones
import dayjs from 'dayjs';

// order is important
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';

import Backdrop from '@mui/material/Backdrop';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid, { GridProps } from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { formatDate } from '../services/util';

import Button from './Button';
import SubscribeDialog from './SubscribeDialog';

const useStyles = makeStyles(() => ({
    previewButton: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px #9a9a9a33',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    dateRange: {
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: '0 auto',
    },
}));

export type DateSelectorProps = Omit<GridProps, 'onChange'> &
    PropsWithoutRef<{
        label: string;
        onChange: (date: Date) => void;
        value: Date;
        minDate: Date;
        maxDate: Date;
        displayFormat?: string;
        todayButtonDisabled?: boolean;
        showSubscriptionPopup?: boolean;
    }>;

const DateSelector = ({
    label,
    onChange,
    value,
    minDate,
    maxDate,
    displayFormat = 'MMMM DD, YYYY',
    todayButtonDisabled = false,
    showSubscriptionPopup = false,
    ...props
}: DateSelectorProps) => {
    const classes = useStyles();

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [paymentPopup, setPaymentPopup] = useState(false);

    const handleChange = (date: Date) => {
        const selectedDate = dayjs(date).endOf('day').toDate();
        onChange(selectedDate);
        setShowDatePicker(false);
    };

    return (
        <Grid item {...props}>
            <SubscribeDialog popup={paymentPopup} setPopup={setPaymentPopup} />
            <TextField
                variant="outlined"
                label={label}
                value={value ? formatDate(value, displayFormat) : 'YYYY-MM-DD'}
                onClick={() => {
                    showSubscriptionPopup
                        ? setPaymentPopup(true)
                        : setShowDatePicker(true);
                }}
                InputProps={{
                    endAdornment: <DateRangeIcon />,
                    readOnly: true,
                    inputProps: {
                        'data-testid': 'date-selector-input',
                        style: { cursor: 'pointer' },
                    },
                }}
                size="medium"
                data-testid="date-selector"
                fullWidth
            />
            <Backdrop
                open={showDatePicker}
                style={{ zIndex: 10000 }}
                data-testid="date-selector-backdrop"
            >
                <Grid container direction="column" alignItems="center">
                    <ClickAwayListener
                        onClickAway={() => setShowDatePicker(false)}
                        mouseEvent="onMouseUp"
                    >
                        <Grid item className={classes.dateRange}>
                            <Calendar
                                onChange={handleChange}
                                months={1}
                                direction="horizontal"
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={1}
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleChange(
                                                dayjs().endOf('day').toDate()
                                            );
                                        }}
                                        disabled={todayButtonDisabled}
                                        data-testid="today-button"
                                    >
                                        Today
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ClickAwayListener>
                </Grid>
            </Backdrop>
        </Grid>
    );
};

export default DateSelector;
