import React, { useContext, useState, useRef, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import InfoIcon from '@mui/icons-material/Info';
import Person from '@mui/icons-material/Person';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

import { Context as AuthContext } from '../context/Auth';
import useLogout from '../hooks/useLogout';

import { API_DOCS_URL } from '../pages/Settings';

import ListItemLink from './ListItemLink';
import NavItemLink from './NavItemLink';
import DateRangeSelector from './DateRangeSelector';
import {
    BlogIcon,
    Support,
    Subscribe,
    LogoutIcon,
    SettingsIcon,
    CallIcon,
} from './Icons';
import { createOrderStartDateQuery } from '../services/util';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px #9a9a9a33',
        width: '275px',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    notchedOutline: {
        borderColor: 'transparent',
    },
    listItemRoot: {
        padding: '8px 30px',
        fontFamily: 'Poppins',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
}));

const TopNav = (props: {
    showSearch?: boolean;
    searchQuery?: (query: string) => void;
    searchText?: String;
    showDateRange?: boolean;
}) => {
    const logout = useLogout();
    const { state } = useContext(AuthContext);
    const [informationMenu, setInformationMenu] = useState<boolean>(false);
    const [accountMenu, setAccountMenu] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const [navBackground, setNavBackground] = useState(false);
    const [dateQuery, setDateQuery] = useState('');
    const navRef = useRef<boolean>();
    navRef.current = navBackground;

    const [displayDatePreview, setDisplayDatePreview] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 50;
            if (navRef.current !== show) {
                setNavBackground(show);
            }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const classes = useStyles();

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (!props.searchQuery) {
            return;
        }

        if (startDate && endDate) {
            const q = createOrderStartDateQuery(startDate, endDate);
            setDisplayDatePreview(true);
            setDateQuery(q);
            props.searchQuery(q);
        } else {
            props.searchQuery('');
            setDateQuery('');
        }
    };

    return (
        <AppBar
            style={{
                backgroundColor: navBackground ? '#fff' : 'transparent',
                color: '#000',
                width: 'auto',
                marginLeft: '265px',
                left: 0,
                padding: '0 20px 0 55px',
                borderBottom: '1px solid',
                borderColor: '#EFF3F8',
                height: '61px',
            }}
            elevation={0}
            position="fixed"
            data-testid="navbar"
        >
            <Toolbar
                disableGutters
                style={{
                    height: '60px',
                }}
            >
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={3}
                        >
                            {props.showSearch && (
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Search"
                                        fullWidth
                                        value={props.searchText}
                                        onChange={(e) => {
                                            if (props.searchQuery) {
                                                props.searchQuery(
                                                    e.target.value
                                                );
                                                setDisplayDatePreview(false);
                                            }
                                        }}
                                        disabled={dateQuery !== ''}
                                        classes={{ root: classes.root }}
                                        style={{
                                            backgroundColor:
                                                informationMenu || accountMenu
                                                    ? 'transparent'
                                                    : '#fff',
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            'data-testid': 'search',
                                        }}
                                    />
                                </Grid>
                            )}
                            {props.showDateRange && (
                                <Grid item>
                                    <DateRangeSelector
                                        onChange={handleDateChange}
                                        showPreview={displayDatePreview}
                                        disabled={
                                            props.searchText !== '' &&
                                            dateQuery === ''
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            justifyContent="flex-end"
                            alignItems="center"
                            container
                        >
                            <Grid item>
                                <Button
                                    target="_blank"
                                    href="https://docs.postgrid.com"
                                    style={{
                                        textTransform: 'none',
                                        fontWeight: 400,
                                    }}
                                    color="inherit"
                                >
                                    API Documentation
                                </Button>
                                <Button
                                    target="_blank"
                                    href="https://postgrid.com/guides/print-mail/"
                                    style={{
                                        textTransform: 'none',
                                        fontWeight: 400,
                                    }}
                                    color="inherit"
                                >
                                    Guides
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    onClick={(e) => {
                                        setInformationMenu(!informationMenu);
                                        setAnchorEl(e.currentTarget);
                                    }}
                                    size="large"
                                >
                                    <InfoIcon />
                                </IconButton>
                                <Backdrop
                                    open={informationMenu}
                                    onClick={() => {
                                        setInformationMenu(false);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <Popover
                                        open={informationMenu}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <List>
                                            <NavItemLink
                                                icon={SettingsIcon}
                                                iconColor="#575e71"
                                                primary="API Docs"
                                                to={API_DOCS_URL}
                                            />

                                            <Divider />

                                            <NavItemLink
                                                icon={CallIcon}
                                                primary="Contact us"
                                                to="https://postgrid.com/contact"
                                            />

                                            <Divider />

                                            <NavItemLink
                                                icon={BlogIcon}
                                                primary="Blogs"
                                                to="https://postgrid.com/blog/"
                                            />
                                        </List>
                                    </Popover>
                                </Backdrop>
                            </Grid>
                            <Grid
                                item
                                onClick={(e) => {
                                    setAccountMenu(!accountMenu);
                                    setAnchorEl(e.currentTarget);
                                }}
                            >
                                <ListItem button disableGutters>
                                    <ListItemIcon style={{ minWidth: '35px' }}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText primary={state.user?.name} />
                                    <ExpandMore />
                                </ListItem>
                                <Backdrop
                                    open={accountMenu}
                                    onClick={() => {
                                        setAccountMenu(false);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <Popover
                                        open={accountMenu}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <List>
                                            <NavItemLink
                                                to="https://postgrid.com/help-support/"
                                                primary="Support"
                                                icon={Support}
                                            />

                                            <Divider />

                                            <ListItemLink
                                                icon={Subscribe}
                                                to="/dashboard/upgrade"
                                                primary="Subscribe"
                                                topNav
                                            />

                                            <Divider />

                                            <ListItemLink
                                                icon={SettingsIcon}
                                                to="/dashboard/settings"
                                                primary="Settings"
                                                topNav
                                            />

                                            <Divider />

                                            <ListItem
                                                button
                                                disableRipple
                                                onClick={() => logout()}
                                                classes={{
                                                    root: classes.listItemRoot,
                                                }}
                                            >
                                                <ListItemIcon
                                                    style={{
                                                        marginBottom: '-5px',
                                                    }}
                                                >
                                                    <LogoutIcon />
                                                </ListItemIcon>
                                                <ListItemText disableTypography>
                                                    Logout
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Popover>
                                </Backdrop>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
export default TopNav;
