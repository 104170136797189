import React, { useState, ChangeEvent, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { unstable_registerToolbarComponent } from 'polotno/config';
import {
    Navbar,
    InputGroup,
    FormGroup,
    Button,
    Popover,
    PopoverPosition,
    Icon,
    NumericInput,
    Collapse,
    Switch,
} from '@blueprintjs/core';
import { MERGE_VARIABLE_IMAGE } from './MergeVariableImageElement';

const PopoverContentWrapper = ({
    children,
}: {
    children?: ReactNode | ReactNode[];
}) => {
    return (
        <div
            key="text"
            style={{
                padding: 12,
                paddingTop: 18,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: '250px',
                gap: 8,
            }}
        >
            {children}
        </div>
    );
};

const MaintainAspectRatioSwitch = ({ element }: { element: any }) => {
    const [checked, setChecked] = useState(element.maintainAspectRatio);
    const handleChange = () => {
        setChecked(!element.maintainAspectRatio);
        element.set({ maintainAspectRatio: !element.maintainAspectRatio });
    };

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            label="Maintain Aspect Ratio"
            alignIndicator="right"
        />
    );
};

interface DimensionInputProps {
    label: string;
    id: string;
    value: number;
    onChange: (n: number) => void;
    maxValue?: number;
    minValue?: number;
}

const dimensionVal = (num: number, minValue: number, maxValue: number) => {
    if (num <= minValue) return minValue;
    if (num >= maxValue) return maxValue;
    return num;
};

const DimensionInput = ({
    minValue = 0,
    maxValue = 1200,
    onChange,
    value,
    label,
    id,
}: DimensionInputProps) => {
    const okay = (num: number) => {
        if (!isNaN(num)) {
            onChange(dimensionVal(num, minValue, maxValue));
        }
    };

    return (
        <FormGroup label={label} labelFor={id}>
            <NumericInput
                id={id}
                min={minValue}
                value={value}
                max={maxValue}
                onValueChange={okay}
                allowNumericCharactersOnly
            />
        </FormGroup>
    );
};

const position = (valNew: number, valBefore: number, pos: number) => {
    if (valNew === valBefore) {
        return pos;
    }
    return 0 - valNew + (pos + valBefore);
};

const Dimensions = ({ element, store }: { element: any; store: any }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [width, setWidth] = useState(element.width);
    const [height, setHeight] = useState(element.height);

    const confirm = () => {
        setIsOpen(false);
        element.set({
            height,
            width,
            x: position(width, element.width, element.x),
            y: position(height, element.height, element.y),
        });
    };

    const cancel = () => {
        setIsOpen(false);
        setWidth(element.width);
        setHeight(element.height);
    };

    return (
        <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <div
                onClick={() => setIsOpen((p) => !p)}
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
            >
                <p>Dimensions</p>
                <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
            </div>
            <Collapse isOpen={isOpen}>
                <div
                    style={{
                        padding: 4,
                        borderRadius: 5,
                        backgroundColor: '#f7f7f7',
                    }}
                >
                    <DimensionInput
                        label="Width"
                        id="width"
                        value={width}
                        onChange={setWidth}
                        maxValue={store.width}
                    />
                    <DimensionInput
                        label="Height"
                        id="height"
                        value={height}
                        onChange={setHeight}
                        maxValue={store.height}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >
                        <Button
                            intent="danger"
                            outlined
                            style={{ display: 'flex', flex: 1 }}
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            intent="primary"
                            outlined
                            style={{ display: 'flex', flex: 1 }}
                            onClick={confirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

const Properties = ({ element, store }: { element: any; store: any }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <Popover isOpen={isPopoverOpen} position={PopoverPosition.BOTTOM}>
            <Button onClick={() => setIsPopoverOpen((p) => !p)} minimal>
                Properties
            </Button>
            <PopoverContentWrapper>
                <MaintainAspectRatioSwitch element={element} />
                <Dimensions element={element} store={store} />
            </PopoverContentWrapper>
        </Popover>
    );
};

const MergeVariableImageToolbar = observer(({ store }: { store: any }) => {
    const element = store.selectedElements[0];

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        element.set({
            // TODO: More exhaustive replacements
            // Do not allow spaces in mergeVariable name
            mergeVar: e.target.value.replaceAll(/ /gi, '_'),
        });
    };

    return (
        <Navbar.Group>
            <FormGroup label="Merge Variable:" style={{ margin: 2 }} inline>
                <InputGroup
                    type="text"
                    id="merge"
                    placeholder="Merge Variable"
                    onChange={handleChange}
                    value={element.mergeVar}
                />
            </FormGroup>
            <Properties key={element.id} element={element} store={store} />
        </Navbar.Group>
    );
});

unstable_registerToolbarComponent(
    MERGE_VARIABLE_IMAGE,
    MergeVariableImageToolbar
);
export default MergeVariableImageToolbar;
