import React, { ElementType, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

import SvgIcon from './SvgIcon';
import PaperWrapper from './PaperWrapper';

type DetailWrapperProps = GridProps & {
    /** A title. `optional` */
    title?: string;
    /** An icon to display along side the title. `optional` */
    icon?: ElementType;
    /** Optional `ReactNode` to display on the right side of the header. `optional` */
    adornment?: ReactNode;
    /** A tool tip helper to be displayed beside the title.  `optional`*/
    tooltip?: string;
};

const useStyles = makeStyles((theme) => ({
    flexCenterChild: {
        display: 'flex',
        alignSelf: 'center',
    },
    title: {
        textAlign: 'center',
    },
    titleContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

/**
 * A wrapper for showing details.  If no title is passed then a bounding box is displayed.
 * The children should be grid elements as they are displayed within a `<Grid container/>`.
 */
const DetailWrapper = ({
    icon,
    title,
    adornment,
    tooltip,
    children,
    ...props
}: DetailWrapperProps) => {
    const classes = useStyles();
    return (
        <PaperWrapper>
            <Box margin={2}>
                {title || adornment ? (
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                spacing={1}
                                className={classes.titleContainer}
                            >
                                <Grid
                                    container
                                    justifyContent={
                                        title ? 'space-between' : 'flex-end'
                                    }
                                >
                                    {title && (
                                        <Grid item>
                                            <Grid
                                                container
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                {icon && (
                                                    <Grid item>
                                                        <SvgIcon
                                                            icon={icon}
                                                            className={
                                                                classes.flexCenterChild
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                <Grid item>
                                                    <Typography
                                                        className={
                                                            classes.title
                                                        }
                                                    >
                                                        {title}
                                                    </Typography>
                                                </Grid>

                                                {tooltip && (
                                                    <Grid item>
                                                        <Tooltip
                                                            className={
                                                                classes.flexCenterChild
                                                            }
                                                            title={tooltip}
                                                        >
                                                            <HelpIcon fontSize="small" />
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    {adornment && <Grid item>{adornment}</Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item container {...props}>
                            {children}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container {...props}>
                        {children}
                    </Grid>
                )}
            </Box>
        </PaperWrapper>
    );
};

export default DetailWrapper;
