import React from 'react';

import { Organization } from '../services/Organization';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import CircularProgressWithLabel from './CircularProgressWithLabel';

import paymentLogo from '../img/icons/payment.png';
import liveVolumeLogo from '../img/icons/live-volume.png';

interface LiveInfoCardsProps {
    org?: Organization;
}

const useStyles = makeStyles(() => ({
    root: {
        '&:last-child': {
            paddingBottom: '7px',
        },
        padding: '7px',
    },
}));

const Container = ({ children, ...props }: GridProps) => {
    const cardClass = useStyles();
    return (
        <Grid item {...props}>
            <Card>
                <CardContent classes={{ root: cardClass.root }}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {children}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const LiveInfoCards = ({ org }: LiveInfoCardsProps) => {
    const monthName = new Date().toLocaleString(undefined, {
        month: 'long',
    });

    let volume = org ? (org.usage / org.limit) * 100 : 0;

    return (
        <Grid container spacing={2} data-testid="live-info-cards">
            <Container data-testid="live-volume">
                <Grid item>
                    <Box
                        style={{
                            backgroundImage: liveVolumeLogo,
                            height: '144px',
                            minWidth: '133px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <CircularProgressWithLabel value={volume} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box px={2}>
                        <Typography variant="subtitle1">
                            {monthName} Live Volume
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 300 }}
                        >
                            Current Month Live
                        </Typography>
                        {org ? (
                            <Box
                                fontSize={20}
                                fontWeight={300}
                                data-testid="org-live-usage"
                            >
                                <span
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '30px',
                                    }}
                                >
                                    {org.usage}
                                </span>
                                {' out of '}
                                {org.limit.toLocaleString()}
                            </Box>
                        ) : (
                            <Skeleton />
                        )}
                    </Box>
                </Grid>
            </Container>

            <Container data-testid="live-spend">
                <Grid item xs={5}>
                    <Box height="144px">
                        <img src={paymentLogo} alt="Live Spend Icon" />
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box px={2}>
                        <Typography variant="subtitle1">
                            {monthName} Live Spend
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 300 }}
                        >
                            Current payment
                        </Typography>
                        {org ? (
                            <Typography>
                                <span
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '30px',
                                    }}
                                >
                                    {(org.spend / 100).toLocaleString(
                                        undefined,
                                        {
                                            style: 'currency',
                                            currency: 'usd',
                                        }
                                    )}
                                </span>
                            </Typography>
                        ) : (
                            <Skeleton />
                        )}
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
};

export default LiveInfoCards;
