import React from 'react';

import { Contact } from '../services/Contacts';

import Grid from '@mui/material/Grid';

import AddressInfo from './AddressInfo';
import PersonalInfo from './PersonalInfo';

interface ContactInfoProps {
    contact: Contact;
    addressTitle?: string;
    personalTitle?: string;
}

const ContactInfo = ({
    contact,
    addressTitle,
    personalTitle,
}: ContactInfoProps) => {
    return (
        <Grid item container spacing={2} data-testid="contact-info">
            <Grid item xs={5}>
                <PersonalInfo contact={contact} title={personalTitle} />
            </Grid>
            <Grid item xs>
                <AddressInfo contact={contact} title={addressTitle} />
            </Grid>
        </Grid>
    );
};

export default ContactInfo;
