import React from 'react';

import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import TableBody, { TableBodyProps } from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export type TableBodyLoaderProps = TableBodyProps & {
    show: boolean;
    columns: number;
    SkeletonProps?: SkeletonProps;
};

const TableBodyLoader = (props: TableBodyLoaderProps) => {
    const { show, columns, SkeletonProps, ...otherProps } = props;

    if (!props.children) {
        return null;
    }

    const cells = [];

    for (let i = 0; i < columns; ++i) {
        cells.push(
            <TableCell key={i}>
                <Skeleton {...SkeletonProps} />
            </TableCell>
        );
    }

    if (show) {
        return (
            <TableBody>
                <TableRow>{cells}</TableRow>
            </TableBody>
        );
    }

    return <TableBody {...otherProps} />;
};

export default TableBodyLoader;
