import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import GoBackButton from '../components/GoBackButton';

const Unauthorized = () => {
    return (
        <Grid container style={{ height: '80vh' }}>
            <Grid
                container
                item
                style={{
                    flexDirection: 'column',
                    margin: 'auto',
                    minHeight: '100%',
                }}
                xs={12}
            >
                <Grid style={{ margin: 'auto', textAlign: 'center' }}>
                    <Typography
                        style={{ textAlign: 'center' }}
                        display="block"
                        variant="h5"
                    >
                        You do not have the necessary permissions to access this
                        page. <br />
                        Please contact your administrator to request access.
                    </Typography>
                    <GoBackButton
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{ marginTop: '24px' }}
                    >
                        Go Back
                    </GoBackButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Unauthorized;
