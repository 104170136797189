import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import TooltipCheckbox from './TooltipCheckbox';
import ExpressExtraServiceToolTip from './ExpressExtraServiceToolTip';

type ExpressDeliveryCheckboxProps = GridProps & {
    checked?: boolean;
    setChecked: (val: boolean) => void;
    disabled?: boolean;
    checkboxTestID?: string;
};

const ExpressDeliveryCheckbox = ({
    setChecked,
    checked,
    disabled,
    checkboxTestID,
    ...props
}: ExpressDeliveryCheckboxProps) => {
    return (
        <Grid
            item
            {...props}
            style={{
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <TooltipCheckbox
                disabled={disabled}
                tooltip={{
                    title: <ExpressExtraServiceToolTip />,
                }}
                label="Express Delivery"
                setChecked={setChecked}
                checked={checked}
                checkboxTestID={checkboxTestID}
            />
        </Grid>
    );
};

export default ExpressDeliveryCheckbox;
