// TODO Show preview of bank account info on cheque and then put raw data beneath that

import React, { useState, useContext, useEffect, FormEvent } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box, { BoxProps } from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {
    BankAccount,
    BankAccountCountryCode,
    useService as useBankAccountsService,
} from '../services/BankAccounts';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import RawData from '../components/RawData';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';

const ChequeLinePlaceholder = (props: BoxProps) => {
    const theme = useTheme();

    return (
        <Box
            width="100%"
            height="1em"
            style={{
                backgroundColor: theme.palette.grey['200'],
                marginBottom: theme.spacing(1),
                borderRadius: '4px',
            }}
            {...props}
        />
    );
};

const ChequePreview = (props: { account: BankAccount }) => {
    return (
        <Paper
            variant="outlined"
            style={{
                position: 'relative',
                width: '8.4in',
                height: '2.8in',
                fontFamily: 'Roboto',
                fontSize: '14px',
                textTransform: 'uppercase',
                borderRadius: 10,
                margin: '25px 0',
            }}
        >
            <Box position="absolute" left="0.3in" top="0.2in" width="2in">
                <ChequeLinePlaceholder />
                <ChequeLinePlaceholder />
                <ChequeLinePlaceholder />
            </Box>

            <Box position="absolute" left="0.8in" bottom="0.6in" width="2in">
                <ChequeLinePlaceholder />
                <ChequeLinePlaceholder />
                <ChequeLinePlaceholder />
            </Box>

            <Box position="absolute" left="0.3in" top="1in">
                PAY
            </Box>
            <Box position="absolute" left="0.7in" top="1in" width="5in">
                <ChequeLinePlaceholder />
            </Box>

            <Box
                position="absolute"
                left="0.35in"
                bottom="0.7in"
                fontSize="10px"
                width="0.2in"
            >
                To the order of
            </Box>

            <Box position="absolute" left="5.2in" top="0.2in">
                {props.account.bankName}
                <br />
                {props.account.bankPrimaryLine}
                <br />
                {props.account.bankSecondaryLine}
            </Box>

            <Box
                position="absolute"
                left="2in"
                bottom="0.1in"
                fontFamily="MICR"
                fontSize="24px"
                padding="0"
            >
                C001000C
                {props.account.bankCountryCode === BankAccountCountryCode.CA
                    ? ` A${props.account.transitNumber}D${props.account.routeNumber}A`
                    : ` A${props.account.routingNumber}A`}
                {` ${props.account.accountNumberLast4.padStart(10, '0')}C`}
            </Box>

            <Box position="absolute" right="0.7in" bottom="0.5in" width="1.5in">
                <ChequeLinePlaceholder height="0.6in" />
            </Box>

            <Box position="absolute" right="0.3in" top="0.2in">
                001000
            </Box>
        </Paper>
    );
};

const ViewBankAccount = () => {
    const history = useHistory();

    const params = useParams<{ accountID: string }>();

    const service = useBankAccountsService();

    const { dispatch } = useContext(NotificationContext);

    const [account, setAccount] = useState<BankAccount>();

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    useEffect(() => {
        (async () => {
            try {
                setAccount(undefined);

                const account = await service.tryGet(params.accountID);

                if (!account) {
                    dispatch({
                        type: MessageType.ERROR,
                        message: 'This bank account no longer exists.',
                    });

                    history.goBack();
                } else {
                    setAccount(account);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, params.accountID, dispatch, history]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        toggleDeleteModal();
    };

    const deleteAccount = async () => {
        setAccount(undefined);

        await service.delete(params.accountID);

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Deleted bank account.',
        });

        history.push('/dashboard/bank_accounts');
    };

    return (
        <>
            <TopNav />
            <GridPaper container={false}>
                <form onSubmit={onSubmit}>
                    <ConfirmDeleteDialog
                        open={deleteOpen}
                        onClose={toggleDeleteModal}
                        title="Delete Bank Account"
                        text="Are you sure you want to delete this bank account?"
                        confirm={deleteAccount}
                    />
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h5">
                                        Bank Account Details
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Delete Account
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {account ? (
                            <Grid container item direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container justifyContent="center">
                                        <ChequePreview account={account} />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <RawData obj={account} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                style={{ minHeight: '20vh' }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default ViewBankAccount;
