import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { Context as AuthContext, State as AuthState } from '../context/Auth';
import { Context as ModeContext } from '../context/Mode';
import {
    MessageType,
    Action as NotificationAction,
    Context as NotificationContext,
} from '../context/Notification';
import useLogout from '../hooks/useLogout';
import { ErrorRoutes, SSORoutes } from '../routes';

import {
    APIRequestError,
    APIErrorType,
    APIRequestInit,
    fetchAPI,
} from './util';

export enum OrderExtraService {
    CERTIFIED = 'certified',
    CERTIFIED_RETURN_RECEIPT = 'certified_return_receipt',
    REGISTERED = 'registered',
}

export type ExtraServiceMailingClass = {
    // Dashboard eslint rules do not like `_` prefixing unused vars
    [K in keyof typeof OrderMailingClass]: Lowercase<K> extends  // eslint-disable-next-line
        | `${infer _start}certified${infer _end}`
        | `${infer _start}registered${infer _end}`
        ? typeof OrderMailingClass[K]
        : never;
}[keyof typeof OrderMailingClass];

// HACK: Copy-pastad from PM
export enum OrderMailingClass {
    /* GENERIC OPTIONS */
    FIRST_CLASS = 'first_class',
    STANDARD_CLASS = 'standard_class',
    EXPRESS = 'express',
    CERTIFIED = 'certified',
    CERTIFIED_RETURN_RECEIPT = 'certified_return_receipt',
    REGISTERED = 'registered',
    /* US OPTIONS */
    USPS_FIRST_CLASS = 'usps_first_class',
    USPS_STANDARD_CLASS = 'usps_standard_class',
    USPS_EDDM = 'usps_eddm',
    // USPS EXPRESS OPTIONS
    USPS_EXPRESS_2_DAY = 'usps_express_2_day',
    USPS_EXPRESS_3_DAY = 'usps_express_3_day',
    /* EXTRA SERVICE OPTIONS (US only) */
    USPS_FIRST_CLASS_CERTIFIED = 'usps_first_class_certified',
    USPS_FIRST_CLASS_CERTIFIED_RETURN_RECEIPT = 'usps_first_class_certified_return_receipt',
    USPS_FIRST_CLASS_REGISTERED = 'usps_first_class_registered',
    /* USPS EXPRESS WITH EXTRA SERVICES */
    USPS_EXPRESS_3_DAY_SIGNATURE_CONFIRMATION = 'usps_express_3_day_signature_confirmation',
    USPS_EXPRESS_3_DAY_CERTIFIED = 'usps_express_3_day_certified',
    USPS_EXPRESS_3_DAY_CERTIFIED_RETURN_RECEIPT = 'usps_express_3_day_certified_return_receipt',
    /* CA OPTIONS */
    // First-classish?
    CA_POST_LETTERMAIL = 'ca_post_lettermail',
    // Standard-classish?
    CA_POST_PERSONALIZED = 'ca_post_personalized',
    // EDDM?-ish?
    CA_POST_NEIGHBOURHOOD_MAIL = 'ca_post_neighbourhood_mail',
    /**
     * UPS Mailing options
     * Available for both US and CA
     */
    UPS_EXPRESS_OVERNIGHT = 'ups_express_overnight',
    UPS_EXPRESS_2_DAY = 'ups_express_2_day',
    UPS_EXPRESS_3_DAY = 'ups_express_3_day',
    /* UK OPTIONS */
    ROYAL_MAIL_FIRST_CLASS = 'royal_mail_first_class',
    ROYAL_MAIL_SECOND_CLASS = 'royal_mail_second_class',
    /* AU OPTIONS */
    AU_POST_SECOND_CLASS = 'au_post_second_class',
}

export interface Resource {
    object:
        | 'letter'
        | 'self_mailer'
        | 'postcard'
        | 'cheque'
        | 'bank_account'
        | 'template'
        | 'template_editor_session'
        | 'return_envelope'
        | 'return_envelope_order'
        | 'contact'
        | 'api_log'
        | 'webhook'
        | 'webhook_invocation'
        | 'tracker'
        | 'logistics_analytics'
        | 'mailing_list'
        | 'mailing_list_import'
        | 'letter_profile'
        | 'postcard_profile'
        | 'cheque_profile'
        | 'self_mailer_profile'
        | 'custom_envelope'
        | 'campaign';

    id: string;
    description?: string;
    live: boolean;
    metadata?: Record<string, any>;

    createdAt: Date;
    updatedAt: Date;
}

export type ResourceCreateParams = Omit<
    Resource,
    'id' | 'live' | 'createdAt' | 'updatedAt' | 'object'
>;

export class Service {
    history: ReturnType<typeof useHistory>;
    notify: (action: NotificationAction) => void;
    live: boolean;
    logout: () => void;
    authState?: AuthState;
    redirectRoute: string;

    constructor(
        history: Service['history'],
        notify: Service['notify'],
        live: boolean,
        logout: () => void,
        redirectRoute: string,
        authState?: AuthState
    ) {
        this.history = history;
        this.notify = notify;
        this.live = live;
        this.logout = logout;
        this.authState = authState;
        this.redirectRoute = redirectRoute;
    }

    // TODO Make generic 'fetch' wrapper here which handles errors as well
    async internalFetchAPI(
        path: string,
        init?: APIRequestInit & {
            forceLive?: boolean;
            allow404?: boolean;
            silent?: boolean;
        }
    ): Promise<any> {
        if (this.authState && this.authState.tokens) {
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `Bearer ${
                        this.live || init?.forceLive
                            ? this.authState.tokens.live
                            : this.authState.tokens.test
                    }`,
                },
            };
        }

        try {
            return await fetchAPI(path, init);
        } catch (err: any) {
            if (err instanceof APIRequestError) {
                if (
                    err.type === APIErrorType.TOKEN_EXPIRED ||
                    err.type === APIErrorType.AUTHENTICATION_MISSING ||
                    err.type === APIErrorType.INVALID_TOKEN
                ) {
                    this.logout();
                    this.history.push(this.redirectRoute);
                }

                if (
                    err.type === APIErrorType.PERMISSION_MISSING ||
                    err.type === APIErrorType.LIVE_PERMISSION_MISSING
                ) {
                    this.history.replace(ErrorRoutes.UNAUTHORIZED);
                }

                if (init && init.allow404 && err.status === 404) {
                    return null;
                }

                if (err.status === 503 && err.type === 'maintenance_error') {
                    this.history.push(`/maintenance?msg=${err.message}`);
                }
            }

            if (!init?.silent) {
                this.notify({
                    type: MessageType.ERROR,
                    message: err.message,
                });
            }

            throw err;
        }
    }

    async fetchAPI<T>(
        path: string,
        init?: APIRequestInit & { silent?: boolean; forceLive?: boolean }
    ): Promise<T> {
        return this.internalFetchAPI(path, init);
    }

    async fetchAPIAllow404<T>(
        path: string,
        init?: APIRequestInit
    ): Promise<T | null> {
        return this.internalFetchAPI(path, {
            ...init,
            allow404: true,
        });
    }
}

export const useService = () => {
    const history = useHistory();
    const { isAuthenticated } = useAuth0();

    const logout = useLogout();
    const { dispatch } = useContext(NotificationContext);
    const { state } = useContext(AuthContext);
    const { live } = useContext(ModeContext);

    return useMemo(
        () =>
            new Service(
                history,
                dispatch,
                live,
                logout,
                isAuthenticated ? SSORoutes.LOGIN : '/login',
                state
            ),
        [dispatch, history, live, state, logout, isAuthenticated]
    );
};
