import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import { default as MUIAccordionSummary } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderIcon from './HeaderIcon';

import { SvgIconComponent } from '@mui/icons-material';

export interface AccordionSummaryProps {
    icon: SvgIconComponent;
    label: string;
}

const useStyle = makeStyles({
    root: {
        outline: 'none',
        borderRadius: 10,
        '&.Mui-expanded': { borderRadius: '10px 10px 0 0' },
    },
});

const AccordionSummary = (props: AccordionSummaryProps) => {
    const classes = useStyle();

    return (
        <MUIAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.root}
        >
            <HeaderIcon icon={props.icon} />
            <Typography variant="h6">{props.label}</Typography>
        </MUIAccordionSummary>
    );
};

export default AccordionSummary;
