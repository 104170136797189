import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RawDataField from './RawDataField';

interface ObjectTableProps {
    obj: Record<string, any>;
}

const ObjectTable = ({ obj }: ObjectTableProps) => {
    const entries = Object.entries(obj);

    // HACK For the metadata
    return (
        <Table size="small">
            <TableBody>
                {entries.map(([key, value]) => (
                    <TableRow key={key}>
                        <TableCell component="th">
                            <Typography variant="body1" data-testid={`${key}`}>
                                {key}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Grid container spacing={1}>
                                <RawDataField objKey={key} value={value} />
                            </Grid>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ObjectTable;
