import React from 'react';
import { useModal } from '../hooks/useModal';
import type { Role } from '../services/Roles';

// Components
import Spinner from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SelectableUserRole from './SelectableUserRole';
import ConfirmActionDialog from './ConfirmActionDialog';

const RoleList = ({
    setRoles,
    roles,
    selectedRoles,
    disabled,
    disabledMessage,
    userPermissions,
}: SelectUserRolesProps) => {
    const { isModalOpen, openModal, closeModal } = useModal();

    if (!roles) {
        return (
            <Grid item xs={12} style={{ padding: 4 }}>
                <Spinner color="primary" />
            </Grid>
        );
    }

    const handleToggleRole = (role: Role, checked: boolean) => {
        if (!userPermissions) {
            openModal();
            return;
        }

        if (checked) {
            setRoles((prev: Role[]): Role[] => [...prev, role]);
        } else {
            setRoles((prev: Role[]): Role[] =>
                prev.filter((oldRole) => oldRole.id !== role.id)
            );
        }
    };

    return (
        <Grid container xs={12} direction="column" alignItems="center">
            <ConfirmActionDialog
                open={isModalOpen}
                onClose={closeModal}
                title="Missing Access"
                text="Please contact sales to access roles."
                actionLabel="Contact Sales"
                confirm={() =>
                    window.open('https://postgrid.com/contact-us', '_blank')
                }
            />
            {roles.map((role) => (
                <Grid item style={{ width: '100%' }}>
                    <SelectableUserRole
                        key={role.id}
                        role={role}
                        onRoleChecked={handleToggleRole}
                        selected={selectedRoles.includes(role)}
                        disabled={disabled}
                        disabledMessage={disabledMessage}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

interface SelectUserRolesProps {
    roles: Role[];
    setRoles: React.Dispatch<React.SetStateAction<Role[]>>;
    selectedRoles: Role[];
    disabled?: boolean;
    disabledMessage?: string;
    userPermissions: boolean;
}

const SelectUserRoles = (props: SelectUserRolesProps) => {
    const errorMessage = (() => {
        const isLiveOnlyRoleSelected =
            props.selectedRoles.length === 1 &&
            props.selectedRoles[0].name === 'Live';

        if (isLiveOnlyRoleSelected) {
            return 'You must select another role in addition to granting live access';
        } else if (!props.selectedRoles.length) {
            return 'At least 1 role must be selected';
        }
    })();

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>
                <Typography variant="h6">Roles</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <RoleList {...props} />
            </Grid>
            <Grid item xs={12}>
                <Alert
                    variant="outlined"
                    color={errorMessage ? 'error' : 'info'}
                    data-testid={`role-alert-${
                        errorMessage ? 'error' : 'info'
                    }`}
                >
                    {errorMessage ??
                        'The user will get a combination of the permissions granted by each of these roles.'}
                </Alert>
            </Grid>
        </Grid>
    );
};

export default SelectUserRoles;
