import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AuthorizationWrapper, {
    AuthorizationWrapperProps,
} from './AuthorizationWrapper';

interface LoginWrapperProps extends AuthorizationWrapperProps {
    signupLink: string;
}

const useStyles = makeStyles((theme) => ({
    signupLink: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
}));

const LoginWrapper = ({ signupLink, ...props }: LoginWrapperProps) => {
    const classes = useStyles();
    return (
        <Grid container direction="column" alignItems="center">
            <AuthorizationWrapper {...props} />
            <Grid item className={classes.signupLink}>
                <Typography align="center" variant="body1">
                    Don't have an account?{' '}
                    <Link component={RouterLink} to={signupLink}>
                        Sign up now
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LoginWrapper;
