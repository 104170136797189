import React, { PropsWithoutRef, useCallback, useEffect } from 'react';

import {
    CustomEnvelope,
    STANDARD_ENVELOPE,
    label,
    useService as useCustomEnvelopeService,
} from '../services/CustomEnvelopes';
import { Organization } from '../services/Organization';
import { useModeContext } from '../context/Mode';

import SearchSelect, { SearchSelectProps } from './SearchSelect';

const SelectCustomEnvelope = (
    props: PropsWithoutRef<{
        label: string;
        customEnvelope: CustomEnvelope | null;
        setCustomEnvelope: (customEnvelope: CustomEnvelope) => void;
        required?: boolean;
        size?: SearchSelectProps['size'];
        textFieldTestID?: string;
        org?: Organization;
        disabled: boolean;
        setLoading: (loading: boolean) => void;
    }>
) => {
    const service = useCustomEnvelopeService();
    const { live } = useModeContext();

    useEffect(() => {
        const getDefaultEnvelope = async () => {
            props.setLoading(true);

            if (!props.org) return;

            try {
                if (!live || !props.org.defaultCustomEnvelope) return;

                const envelope = await service.get(
                    props.org.defaultCustomEnvelope
                );

                props.setCustomEnvelope(envelope);
            } catch (err) {
                console.error(err);
            } finally {
                props.setLoading(false);
            }
        };

        getDefaultEnvelope();
    }, [props.org]);

    const search = useCallback(
        async (text?: string) => {
            const res = await service.list({
                skip: 0,
                limit: 10,
                search: text,
            });

            if (!live || !props.org?.defaultCustomEnvelope) {
                res.data.push(STANDARD_ENVELOPE);
            }

            return res.data;
        },
        [props.org]
    );

    return (
        <SearchSelect
            label={props.label}
            value={props.customEnvelope}
            setValue={props.setCustomEnvelope}
            getOptionLabel={(value) =>
                label(value, props.org?.defaultCustomEnvelope)
            }
            getOptionSelected={(option, value) => option.id === value.id}
            required={props.required}
            size={props.size}
            search={search}
            textFieldTestId={props.textFieldTestID}
            disabled={props.disabled}
        />
    );
};

export default SelectCustomEnvelope;
