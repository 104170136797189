import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';
import { Service as ResourceService } from './DeletableResource';

export interface Template extends Resource {
    html: string;
}

type CreateParams = Omit<Template, keyof Resource> & ResourceCreateParams;

export interface UpdateParams {
    description?: string;
    html?: string;
    metadata?: object;
}

export class Service extends ResourceService<Template> {
    async create(data: CreateParams, forceLive?: boolean): Promise<Template> {
        return await this.base.internalFetchAPI(`/${this.route}`, {
            method: 'POST',
            body: data,
            forceLive,
        });
    }

    async update(id: string, params: UpdateParams) {
        return await this.base.fetchAPI<Template>(`/${this.route}/${id}`, {
            method: 'POST',
            body: params,
        });
    }
}

export const findVariables = (html: string) => {
    const vars = new Set<string>();
    const matches = html.match(/{{(.*?)}}/g);

    if (!matches) {
        return [];
    }

    for (const match of matches) {
        vars.add(match.substr(2, match.length - 4));
    }

    return Array.from(vars);
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'templates'), [base]);
};
