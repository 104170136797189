import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TooltipWrapper from './TooltipWrapper';
import { TooltipProps } from '@mui/material';

type TooltipCheckboxProps = {
    checked?: boolean;
    setChecked: (val: boolean) => void;
    label: string;
    disabled?: boolean;
    tooltip: Omit<TooltipProps, 'children'>;
    checkboxTestID?: string;
};

const TooltipCheckbox = ({
    setChecked,
    checked,
    disabled,
    label,
    tooltip,
    checkboxTestID,
}: TooltipCheckboxProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    };

    return (
        <TooltipWrapper disabled={disabled} {...tooltip}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleChange}
                        disabled={disabled}
                        inputProps={
                            {
                                'data-testid': checkboxTestID || 'checkbox',
                            } as React.InputHTMLAttributes<HTMLInputElement>
                        }
                    />
                }
                label={label}
            />
        </TooltipWrapper>
    );
};

export default TooltipCheckbox;
