import React from 'react';
import { useHistory } from 'react-router-dom';

import Button, { ButtonProps } from './Button';

const GoBackButton = (props: ButtonProps & { onClick?: () => void }) => {
    const history = useHistory();

    const handleClick = () => {
        history.goBack();
        if (props.onClick) {
            props.onClick();
        }
    };

    return <Button {...props} onClick={handleClick} />;
};

export default GoBackButton;
