import React, { PropsWithoutRef } from 'react';
//
import { Contact } from '../services/Contacts';
//
import makeStyles from '@mui/styles/makeStyles';
import type { TransitionProps } from '@mui/material/transitions';
import Grid, { GridProps } from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import SelectContact from './SelectContact';
import AddressPreview from './AddressPreview';

const useStyles = makeStyles((theme) => ({
    collapse: {
        marginTop: theme.spacing(1),
    },
}));

const GridItem = React.forwardRef(
    (
        props: GridProps & TransitionProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => <Grid item {...props} ref={ref} />
);

const ContactInput = (
    props: PropsWithoutRef<{
        label: string;
        contact: Contact | null;
        setContact: (contact: Contact) => void;
        required?: boolean;
        disabled?: boolean;
        textFieldTestId?: string;
    }>
) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            spacing={1}
            data-testid="contact-input"
        >
            <Grid item>
                <SelectContact
                    label={props.label}
                    contact={props.contact}
                    setContact={props.setContact}
                    required={props.required}
                    disabled={props.disabled}
                    resourceName="contact"
                    textFieldTestId={props.textFieldTestId}
                />
            </Grid>
            <Collapse
                in={!!props.contact}
                component={GridItem}
                className={props.contact ? classes.collapse : undefined}
                data-testid="contact-input-collapse"
            >
                {props.contact && <AddressPreview contact={props.contact} />}
            </Collapse>
        </Grid>
    );
};

export default ContactInput;
