import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';
import { useService as useUsersService } from '../services/Users';
import useLogout from '../hooks/useLogout';

import Box from '@mui/material/Box';

interface VerifyEmailProps {
    redirectRoute: string;
}

const VerifyEmail = ({ redirectRoute }: VerifyEmailProps) => {
    const history = useHistory();
    const service = useUsersService();

    const { token } = useParams<{ token: string }>();
    const { dispatch } = useContext(NotificationContext);

    const logout = useLogout();

    useEffect(() => {
        (async () => {
            try {
                await service.verifyEmail(token);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Verified email successfully. Please log in.',
                });

                // if user is logged in, log them out
                logout();

                history.replace(redirectRoute);
            } catch (err) {
                console.error(err);
            }
        })();
        // Don't re-render when history or dispatch changes
        // Not including them in dependency array
        // eslint-disable-next-line
    }, [service, token]);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100vw"
            height="100vh"
        >
            <p>Please wait...</p>
        </Box>
    );
};

export default VerifyEmail;
