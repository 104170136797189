import React from 'react';

const MailingClassExpressTooltip = () => {
    return (
        <span>
            You cannot select both a <strong>mailing class</strong> and{' '}
            <strong>express delivery.</strong>
        </span>
    );
};

export default MailingClassExpressTooltip;
