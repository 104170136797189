import React from 'react';

import {
    Order,
    OrderStatus,
    Service as OrdersService,
} from '../services/Orders';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

const ViewPDFLink = <T extends Order>(props: {
    order: Order;
    service: OrdersService<T>;
}) => {
    return (
        <Grid
            container
            alignItems="center"
            spacing={1}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {
                // If order is cancelled, show 'Cancelled', but if preview
                // was generated show the preview anyways
            }
            {props.order.url || props.order.status === OrderStatus.CANCELLED ? (
                <Grid item>
                    <Link href={props.order.url} target="_blank">
                        {props.order.url ? 'View PDF' : 'Cancelled'}
                    </Link>
                </Grid>
            ) : (
                <Grid item style={{ paddingLeft: '3ch' }}>
                    <CircularProgress size={15} />
                </Grid>
            )}
        </Grid>
    );
};

export default ViewPDFLink;
