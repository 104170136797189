import { useMemo } from 'react';
import { findVariables, Template } from '../services/Templates';
import { Contact } from '../services/Contacts';
import { flatten } from '../services/util';

// TODO: use in other order pages
export const useTemplateVars = (
    template: Template | null,
    secondTemplate?: Template | null
) => {
    const templateVars = useMemo<string[]>(
        () =>
            Array.from(
                new Set(
                    [template, secondTemplate]
                        .filter((t) => t)
                        .map((t) => findVariables(t!.html))
                        .flat()
                        .filter((key) => !key.startsWith('tracker_'))
                )
            ),
        [template, secondTemplate]
    );

    return templateVars;
};

export const useDefaultVars = (to: Contact[], from?: Contact | null) => {
    const defaultVars = useMemo(
        () =>
            flatten({
                to: to.length > 0 ? to[0] : {},
                from,
                // Also, if there's more than one contact, this is probably a bulk send so incorporate
                // the contact metadata
                ...(to.length > 1 ? to[0].metadata : undefined),
            }),
        [to, from]
    );

    return defaultVars;
};
