import React, { ChangeEvent } from 'react';
import type { Role } from '../services/Roles';
// Components
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TooltipWrapper from './TooltipWrapper';
import Chip from '@mui/material/Chip';

interface SelectableUserRoleProps {
    role: Role;
    onRoleChecked: (role: Role, checked: boolean) => void;
    selected: boolean;
    disabled?: boolean;
    disabledMessage?: string;
}

const SelectableUserRole = ({
    role,
    onRoleChecked,
    selected,
    disabled,
    disabledMessage,
}: SelectableUserRoleProps) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onRoleChecked(role, e.target.checked);
    };

    return (
        <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            alignItems="center"
            style={{ padding: 8 }}
            data-testid={`role-${role.id}`}
        >
            <Grid item xs={10} container direction="column">
                <Grid item>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography>
                                <Box
                                    fontWeight="500"
                                    fontSize="16px"
                                    color={disabled ? 'grey' : 'black'}
                                    component="span"
                                >
                                    {role.name}
                                </Box>
                            </Typography>
                        </Grid>

                        {role.liveAccess && (
                            <Grid item style={{ paddingLeft: 8 }}>
                                <Chip
                                    label="Live Mode"
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography style={{ paddingTop: '8px' }}>
                        {role.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
                <TooltipWrapper
                    title={disabledMessage || ''}
                    disabled={disabled && !!disabledMessage}
                >
                    <Grid item>
                        <Checkbox
                            color="primary"
                            checked={selected}
                            onChange={handleChange}
                            disabled={disabled}
                            inputProps={
                                {
                                    'data-testid': `role-checkbox-${role.id}`,
                                } as React.InputHTMLAttributes<HTMLInputElement>
                            }
                        />
                    </Grid>
                </TooltipWrapper>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    );
};

export default SelectableUserRole;
