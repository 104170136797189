import { Service as BaseService, Resource } from './Base';
import { createURLParams, ListParams, ListResponse } from './util';

export type ResourceCreateBody = {
    description?: string;
    metadata?: Record<string, any>;
};

export class Service<T extends Resource> {
    base: BaseService;
    route: string;

    constructor(service: BaseService, route: string) {
        this.base = service;
        this.route = route;
    }

    async list(params: ListParams) {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI<ListResponse<T>>(
            `/${this.route}?${urlParams.toString()}`
        );
    }

    async get(id: string) {
        return await this.base.fetchAPI<T>(`/${this.route}/${id}`);
    }

    async tryGet(id: string) {
        return await this.base.fetchAPIAllow404<T>(`/${this.route}/${id}`);
    }
}
