import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface CircularProgressWithLabelProps {
    value: number;
}

export const useStyles = makeStyles(() => ({
    bottom: {
        color: '#E5F0FF',
    },
    top: {
        position: 'absolute',
        left: 0,
    },
    postcard: {
        '& >.MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#94BFFF',
        },
    },
    cheques: {
        '& >.MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#7C8DB5',
        },
    },
    bold: {
        fontWeight: 'bold',
    },
    rotate: {
        transform: 'rotate(-90deg)',
    },
}));

const CircularProgressWithLabel = ({
    value,
}: CircularProgressWithLabelProps) => {
    const classes = useStyles();

    return (
        <Box
            position="relative"
            display="flex"
            margin="0 auto"
            justifyContent="center"
            data-testid="circular-progress-label"
        >
            <CircularProgress
                variant="determinate"
                value={100}
                size={85}
                thickness={6}
                className={classes.bottom}
            />
            <CircularProgress
                variant="determinate"
                value={value}
                size={85}
                thickness={6}
                className={`${classes.top} ${classes.rotate}`}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="subtitle1"
                    component="div"
                    color="textSecondary"
                    className={classes.bold}
                >
                    {Math.round(value * 100) / 100}%
                </Typography>
            </Box>
        </Box>
    );
};

export default CircularProgressWithLabel;
