import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import { useModal } from '../hooks/useModal';
import { Order, Service } from '../services/Orders';
import {
    addToSet,
    createOrderStartDateQuery,
    removeFromSet,
    useDebouncedValue,
} from '../services/util';
import { useNotificationContext } from './Notification';
import { useReFetchContext } from './ReFetchContext';

interface BulkCancelOrdersStateProps {
    search: string;
    searchText: string;
    count: number;
    fetching: boolean;
    orders: Order[];
    setFetching: (bool: boolean) => void;
    setCount: (num: number) => void;
    handleSearchText: (
        val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    setOrders: (orders: Order[]) => void;
    page: number;
    setPage: (num: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (num: number) => void;
    preserveOrder: Set<string>;
    handlePreserveOrder: (id: string) => void;
    cancelling: boolean;
    cancellingProgress: number;
    cancelOrders: () => void;
    cancelledCount: number;
    didCancel: boolean;
    resetState: () => void;
    object: string;
    cancellable: boolean;
    openModal: () => void;
    handleDateChange: (startDate?: Date, endDate?: Date) => void;
    dateQuery: string;
    expandAccordion: boolean;
    handleExpandAccordion: () => void;
}

export const BulkCancelOrdersContext =
    createContext<BulkCancelOrdersStateProps>({} as BulkCancelOrdersStateProps);

export const useBulkCancelOrdersContext = () => {
    return useContext(BulkCancelOrdersContext);
};

type BulkCancelOrdersProviderProps<T extends Order> = PropsWithChildren<{
    service: Service<T>;
    object: string;
    open: boolean;
}>;

export const BulkCancelOrdersProvider = <T extends Order>({
    service,
    object,
    children,
    open, // This controls opening the main modal to choose orders to delete, NOT the confirmation modal
}: BulkCancelOrdersProviderProps<T>) => {
    // Controls modal for the "Cancel {order-type}" confirmation pop up
    const { isModalOpen, openModal, closeModal } = useModal();
    const [expandAccordion, setExpandAccordion] = useState(false);
    const { toggleReFetch } = useReFetchContext();

    const { dispatchSuccess, dispatchError } = useNotificationContext();

    const [dateQuery, setDateQuery] = useState('');
    const [searchText, setSearchText] = useState('');
    const [fetching, setFetching] = useState(false);
    const [count, setCount] = useState(0);
    const [orders, setOrders] = useState<Order[]>([]);
    // cancel states
    const [cancelledCount, setCancelledCount] = useState(0);
    const [cancelling, setCancelling] = useState(false);
    const [didCancel, setDidCancel] = useState(false);
    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // a set to store ids of order's
    // allows users to keep certain orders from cancelling
    const [preserveOrder, setPreserveOrder] = useState(new Set<string>());
    // only get orders that have ready status
    const search = useDebouncedValue(dateQuery || searchText, 200);

    const resetPagination = () => {
        setPage(0);
        setRowsPerPage(10);
        setOrders([]);
        setCount(0);
    };

    const resetState = useCallback(() => {
        setSearchText('');
        setFetching(false);
        resetPagination();
        setCancelledCount(0);
        setCancelling(false);
        setDateQuery('');
        setExpandAccordion(false);
        setDidCancel(false);
        setPreserveOrder(new Set<string>());
    }, []);

    const isSearchedOrder = useCallback(
        (order: T) => {
            // General search text, check every field and case-insensitive search
            // any string fields for this value
            if (searchText) {
                const regex = new RegExp(searchText, 'gi');
                const orderFieldsToIgnore = ['live', 'object', 'status', 'url'];

                const recursiveTextSearch = (data: unknown): boolean => {
                    // Does not account for numeric/boolean/date/etc fields
                    if (typeof data === 'object' && data !== null) {
                        for (const [key, value] of Object.entries(data)) {
                            if (
                                !orderFieldsToIgnore.includes(key) &&
                                recursiveTextSearch(value)
                            ) {
                                return true;
                            }
                        }
                    } else if (typeof data === 'string') {
                        if (regex.test(data)) {
                            return true;
                        }
                    } else if (Array.isArray(data)) {
                        if (data.some(recursiveTextSearch)) {
                            return true;
                        }
                    }

                    return false;
                };

                return recursiveTextSearch(order);
            }

            // Any order, nothing specific to search by
            return true;
        },
        // We want this value to update only when the debounced value updates
        // which is reliant of both `searchText` and `dateQuery` which is what
        // this function needs to rely on
        // eslint-disable-next-line
        [search]
    );

    useEffect(() => {
        if (!open) {
            // No need to fetch orders if picking order modal is closed
            return;
        }

        let cancel = false;

        (async () => {
            const data: T[] = [];
            let tempPage = page;
            let hasMore = false;

            // HACK: See ENG-2483 for more information
            //
            // We no longer can rely on the `totalCount` returned from the
            // list. This is because we may not be filtering by `status: ready`
            // as we cannot accomodate this with a full text search at the moment.
            //
            // We have two options it seems:
            //  1. Fetch every order with our search text being the users text
            //     and we will filter the returned orders based on if their status
            //     is `ready` or not.
            //
            //  2. Fetch every order whose status is `ready` and filter here
            //     based on the search text from the user, checking every field
            //     for a generic text match.
            //
            //  Option 2 is probably less likely to call the API.
            setFetching(true);

            const searchQuery = JSON.stringify({
                ...JSON.parse(dateQuery || '{}'),
                status: 'ready',
            });

            for (;;) {
                try {
                    const { data: newOrders } = await service.list({
                        limit: rowsPerPage,
                        skip: tempPage * rowsPerPage,
                        search: searchQuery,
                    });

                    // We will fetch until we either have no more resources to
                    // look at, or until we have filled our desired data's length.
                    if (!newOrders.length || data.length >= rowsPerPage) {
                        // If we are at the total length of the data and there
                        // are more orders to potentially look at, mark us as
                        // being able to paginate through more orders
                        if (
                            data.length >= rowsPerPage &&
                            newOrders.filter(isSearchedOrder).length
                        ) {
                            hasMore = true;
                        }

                        break;
                    }

                    // Go through all of the fetched orders and add each order
                    // which matches either of our searches (or if no search
                    // was provided) to our orders list
                    for (const order of newOrders) {
                        if (
                            isSearchedOrder(order) &&
                            data.length < rowsPerPage
                        ) {
                            data.push(order);
                        }
                    }

                    ++tempPage;

                    // If we have less than the max limit of orders, do not query
                    // for any more (as there won't be any)
                    if (newOrders.length < rowsPerPage) {
                        break;
                    }
                } catch (err) {
                    console.error(err);

                    // Authentication errors should be caught in internalFetchAPI function in Base.tsx
                    dispatchError(
                        'Error retrieving orders for deletion. Please refresh the page and try again.'
                    );

                    // HACK(Apaar): This was previously just looping which resulted in some infinite
                    // loops that lasted quite a long time.
                    //
                    // Now we clear out the data because there was an error and force user to refetch.
                    data.length = 0;
                    break;
                }
            }

            if (cancel) {
                return;
            }

            setFetching(false);
            const count =
                data.length < rowsPerPage
                    ? data.length
                    : // A fake count to simulate we have at least one more order
                      // to look at so we can paginate.
                      //
                      // Since we do not actually know the true length, we just fake
                      // it
                      // TODO: This still gets a bit funky on the very last page
                      data.length * tempPage + (hasMore && data.length ? 1 : 0);
            setCount(count);
            setOrders(data);
        })();

        return () => {
            cancel = true;
        };
    }, [search, service, rowsPerPage, page, isSearchedOrder, open, dateQuery]);

    const cancelOrders = useCallback(async () => {
        const trueCount = count - preserveOrder.size;
        if (trueCount <= 0) {
            return;
        }

        try {
            setCancelling(true);
            setExpandAccordion(false);
            let cancelled = 0;
            let tempPage = 0;
            // Max limit per API docs
            const limit = 100;

            const searchQuery = JSON.stringify({
                ...JSON.parse(dateQuery || '{}'),
                status: 'ready',
            });
            while (true) {
                await new Promise((r) => setTimeout(r, 300));
                const { data, totalCount } = await service.list({
                    search: searchQuery,
                    // The orders are being deleted so
                    // we don't need to skip any
                    skip: tempPage,
                    limit,
                });

                // We need to now see _every_ order and verify that it is within
                // our search criteria. We can no longer rely on the `trueCount`
                // as we did before as the `count` variable is no longer accurate
                if (!data.length) {
                    break;
                }

                let skippedCount = 0;
                for (const order of data) {
                    // Only delete orders which match our current search criteria
                    if (
                        !isSearchedOrder(order) ||
                        preserveOrder.has(order.id)
                    ) {
                        ++skippedCount;
                        continue;
                    }

                    await service.cancel(order.id);

                    ++cancelled;
                    // try to limit the amount of re-renders a lil
                    /** TODO: Do not need this in at the moment since we are not 
                     * showing a value progress
                    if (cancelled % 10 === 0) {
                        setCancelledCount(cancelled);
                    }
                    **/
                }

                // We only want to go to the next page if we did not delete any
                // orders
                if (data.length - skippedCount === 0) {
                    ++tempPage;

                    // Check if we can stop querying for data as we have hit
                    // the end of orders
                    if (
                        // If we are on the first or last page and we do not fill
                        // the entire limited data length
                        //
                        // If we have say, 5 orders and we deleted none of them,
                        // if we query again we won't see anything more.
                        //
                        // Or,
                        // If we have an exact data length of say 500 and we are on
                        // the last page
                        // 128 total, we are currently on page 1 -> we only saw
                        // 28 orders.
                        totalCount <=
                        limit * tempPage
                    ) {
                        break;
                    }
                }
            }
            setCancelledCount(cancelled);
            dispatchSuccess(`Successfully cancelled ${cancelled} ${object}s`);
            setDidCancel(true);
            toggleReFetch();
        } catch (err) {
            console.error(err);
        } finally {
            setCancelling(false);
        }
    }, [
        dateQuery,
        service,
        count,
        preserveOrder,
        dispatchSuccess,
        object,
        toggleReFetch,
        isSearchedOrder,
    ]);

    const prepQueryChange = () => {
        // allow them to cancel different orders by typing something new
        if (didCancel) {
            resetState();
        } else {
            if (preserveOrder.size > 0) {
                // clear out ids
                setPreserveOrder(new Set());
            }
            if (page > 0) {
                setPage(0);
            }
            if (expandAccordion) {
                setExpandAccordion(false);
            }
        }
    };

    const handleSearchText = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        if (cancelling) {
            return;
        }
        prepQueryChange();
        setSearchText(e.target.value);
    };

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (cancelling) {
            return;
        }
        prepQueryChange();
        if (!startDate || !endDate) {
            setDateQuery('');
        } else {
            const query = createOrderStartDateQuery(startDate, endDate);
            setDateQuery(query);
        }
    };

    const handlePreserveOrder = (id: string) => {
        // Remove from the set if it has it since
        // this set is about orders that we do NOT
        // want to cancel
        if (preserveOrder.has(id)) {
            removeFromSet(id, setPreserveOrder);
        } else {
            addToSet(id, setPreserveOrder);
        }
    };

    const handleExpandAccordion = () => {
        if (fetching) {
            return;
        }
        setExpandAccordion((prev) => !prev);
    };

    return (
        <BulkCancelOrdersContext.Provider
            value={{
                searchText,
                search,
                handleSearchText,
                fetching,
                setFetching,
                count,
                setCount,
                orders,
                setOrders,
                setPage,
                page,
                rowsPerPage,
                setRowsPerPage,
                preserveOrder,
                handlePreserveOrder,
                cancelling,
                cancellingProgress:
                    (cancelledCount / (count - preserveOrder.size)) * 100,
                cancelOrders,
                cancelledCount,
                didCancel,
                resetState,
                openModal, // Opens confirmation modal
                object,
                cancellable: orders.length - preserveOrder.size > 0,
                handleDateChange,
                dateQuery,
                handleExpandAccordion,
                expandAccordion,
            }}
        >
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={closeModal}
                confirm={cancelOrders}
                // We no onger know the true count of orders, display a generic
                // message
                text="Are you sure you want to cancel these orders?"
                title={`Cancel ${object}s`}
                actionLabel="Confirm"
            />
            {children}
        </BulkCancelOrdersContext.Provider>
    );
};
