import logo from '../logo.png';

import React, { useState, useContext, FormEvent } from 'react';

import { useHistory } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useService as useUsersService } from '../services/Users';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import GoBackButton from '../components/GoBackButton';
import Button from '../components/Button';
import loginGraphic from '../img/login-image.png';

// TODO Mostly copied from Login page. Maybe make a CenteredCard component for this.
const ForgotPassword = () => {
    const history = useHistory();

    const service = useUsersService();

    const { dispatch } = useContext(NotificationContext);

    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            await service.createPasswordReset({
                email,
            });

            dispatch({
                type: MessageType.SUCCESS,
                message: `Sent password reset email to ${email}`,
            });

            history.push('/login');
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12} md={6} style={{ height: '100vh' }}>
                    <img
                        src={loginGraphic}
                        alt="Forgot password"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box px={7}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={3}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Box mt={2}>
                                    <Typography variant="h4">
                                        <Box fontSize={38}>Forgot Password</Box>
                                    </Typography>

                                    <Typography variant="subtitle1">
                                        <Box fontSize={23}>
                                            Enter your email address
                                        </Box>
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Typography color="textSecondary">
                                    Email Address
                                </Typography>
                                <Box mr={6}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        fullWidth
                                        size="small"
                                        autoFocus
                                    />
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box mt={2} mr={6}>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            size="large"
                                            fullWidth
                                            style={{ height: '55px' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Box mt={3}>
                                            <GoBackButton
                                                variant="outlined"
                                                color="primary"
                                                disabled={loading}
                                                size="large"
                                                fullWidth
                                                style={{ height: '55px' }}
                                            >
                                                Cancel
                                            </GoBackButton>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default ForgotPassword;
