import React from 'react';

import { useService as useLettersService } from '../services/Letters';

import OrderDetailsPage from '../components/OrderDetailsPage';

const ViewLetter = () => {
    const service = useLettersService();

    return <OrderDetailsPage title="Letter Details" service={service} hasPDF />;
};

export default ViewLetter;
