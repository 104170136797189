import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { useHistory } from '../hooks/useHistory';
import { useService as useSelfMailersService } from '../services/SelfMailers';
import { useOrganization } from '../services/Organization';

import OrdersPage from '../components/OrdersPage';
import ConfirmActionDialog from '../components/ConfirmActionDialog';

import CreateSelfMailer from './CreateSelfMailer';
import ViewSelfMailer from './ViewSelfMailer';

const SelfMailers = () => {
    const service = useSelfMailersService();

    const history = useHistory();
    const org = useOrganization([history.location]);

    const [showDialog, setShowDialog] = useState(true);

    if (!org) {
        return (
            <Grid item xs={12}>
                <Grid
                    container
                    style={{ minHeight: '30vh' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return org?.enableSelfMailers ? (
        <OrdersPage
            service={service}
            object="self_mailer"
            Create={CreateSelfMailer}
            View={ViewSelfMailer}
        />
    ) : (
        <ConfirmActionDialog
            open={showDialog}
            onClose={() => {
                setShowDialog(false);
                history.goBack();
            }}
            confirm={() =>
                window.open('https://postgrid.com/contact-us', '_blank')
            }
            actionLabel="Contact Sales"
            title="Self Mailers are not enabled for your organization"
            text="Please contact sales to enable Self Mailers for your organization."
        />
    );
};

export default SelfMailers;
