import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
    Service as BaseService,
} from './Base';

import { Template, UpdateParams as TemplateUpdateParams } from './Templates';
import { Organization } from './Organization';

import {
    createURLParams,
    ListParams,
    ListResponse,
    fetchAPI,
    DeleteResponse,
} from './util';

interface TemplateEditorSession extends Resource {
    template: string;
    backURL?: string;
    title?: string;
}

interface TemplateEditorSessionWithDetails
    extends Omit<TemplateEditorSession, 'template'> {
    template: Template;
    organization: Pick<Organization, 'templateEditorSessionBrandingSettings'>;
}

type CreateParams = Omit<
    TemplateEditorSession,
    keyof Resource | 'title' | 'backURL'
> &
    ResourceCreateParams & {
        backURL?: string;
        title?: string;
    };

// Exposes the authless functionality of template editor sessions.
// Pertains to a single session.
export class SingleSessionService {
    constructor(private id: string) {}

    async getWithDetails(): Promise<TemplateEditorSessionWithDetails> {
        // TODO(Apaar): Handle errors
        return await fetchAPI(
            `/template_editor_sessions/${this.id}/editor_details`
        );
    }

    async updateTemplate(params: TemplateUpdateParams): Promise<Template> {
        return await fetchAPI(`/template_editor_sessions/${this.id}/template`, {
            method: 'POST',
            body: params,
        });
    }
}

class Service {
    constructor(private base: BaseService) {}

    async get(id: string): Promise<TemplateEditorSession> {
        return await this.base.fetchAPI(`/template_editor_sessions/${id}`);
    }

    async getWithDetails(
        id: string
    ): Promise<TemplateEditorSessionWithDetails> {
        const service = new SingleSessionService(id);

        return await service.getWithDetails();
    }

    async list(
        params: ListParams
    ): Promise<ListResponse<TemplateEditorSession>> {
        const urlParams = createURLParams(params);

        return await this.base.fetchAPI(
            `/template_editor_sessions?${urlParams.toString()}`
        );
    }

    async create(body: CreateParams): Promise<TemplateEditorSession> {
        return await this.base.fetchAPI(`/template_editor_sessions`, {
            method: 'POST',
            body,
        });
    }

    async updateTemplate(
        id: string,
        params: TemplateUpdateParams
    ): Promise<Template> {
        const service = new SingleSessionService(id);

        return service.updateTemplate(params);
    }

    async delete(id: string) {
        return await this.base.fetchAPI<DeleteResponse>(
            `/template_editor_sessions/${id}`,
            {
                method: 'DELETE',
            }
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
