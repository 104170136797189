import React, {
    Dispatch,
    createContext,
    useReducer,
    useEffect,
    PropsWithChildren,
} from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Contact } from '../services/Contacts';

interface Order {
    id: string;
}

interface CompletedSend {
    to: Contact;
    status: string;

    order?: Order;
}

interface State {
    send: (c: Contact) => Promise<Order>;

    pending: Contact[];
    completed: CompletedSend[];
}

export enum ActionType {
    SEND = 'send',
    CANCEL = 'cancel',
    SHIFT_ONE = 'shift_one',
    COMPLETE_ONE = 'complete_one',
}

interface SendAction {
    type: ActionType.SEND;

    send: (c: Contact) => Promise<Order>;
    to: Contact[];
}

interface CancelAction {
    type: ActionType.CANCEL;
}

interface ShiftOneAction {
    type: ActionType.SHIFT_ONE;
}

interface CompleteOneAction {
    type: ActionType.COMPLETE_ONE;
    completedSend: CompletedSend;
}

export type Action =
    | SendAction
    | CancelAction
    | ShiftOneAction
    | CompleteOneAction;

const reducer = (state: State, action: Action): State => {
    if (action.type === ActionType.CANCEL) {
        return {
            ...state,
            pending: [],
        };
    }

    if (action.type === ActionType.SHIFT_ONE) {
        const newPending = [...state.pending];

        newPending.pop();

        return {
            ...state,
            pending: newPending,
        };
    }

    if (action.type === ActionType.COMPLETE_ONE) {
        return {
            ...state,
            completed: [...state.completed, action.completedSend],
        };
    }

    // TODO Create error class for this
    if (state.pending.length > 0) {
        throw new Error('There is already a send in progress.');
    }

    return {
        ...state,
        send: action.send,
        pending: action.to,
    };
};

interface IContext {
    state: State;
    dispatch: Dispatch<Action>;
}

export const Context = createContext<IContext>({} as any);

export const Display = (props: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, {
        send: async () => ({ id: '' }),
        pending: [],
        completed: [],
    });

    const pendingOrders = state.pending.length > 0;

    useEffect(() => {
        const pending = [...state.pending];

        // This is the driver for the background send
        (async () => {
            while (pending.length > 0) {
                dispatch({ type: ActionType.SHIFT_ONE });

                const to = pending.pop();

                try {
                    const order = await state.send(to!);

                    dispatch({
                        type: ActionType.COMPLETE_ONE,
                        completedSend: {
                            to: to!,
                            status: 'Success',
                            order,
                        },
                    });
                } catch (err: any) {
                    dispatch({
                        type: ActionType.COMPLETE_ONE,
                        completedSend: {
                            to: to!,
                            status: `Error: ${err.message}`,
                        },
                    });
                }
            }
        })();
        // Ignore 'state' in dependency array
        // we are updating state with 'dispatch' and would continue
        // to re-render
        // eslint-disable-next-line
    }, [pendingOrders]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            <Snackbar open={state.pending.length > 0}>
                <Alert>
                    Sent to {state.completed.length}/
                    {state.pending.length + state.completed.length} recipients.
                </Alert>
            </Snackbar>
            {props.children}
        </Context.Provider>
    );
};
