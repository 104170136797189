import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

export type HideableTextFieldProps = TextFieldProps & {
    showInitially?: boolean;
};

const HideableTextField = ({
    InputProps,
    type,
    showInitially = false,
    ...props
}: HideableTextFieldProps) => {
    const [hide, setHide] = useState(!showInitially);

    const toggleHide = () => {
        setHide((prev) => !prev);
    };

    return (
        <TextField
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleHide} size="small">
                            {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        {InputProps?.endAdornment}
                    </InputAdornment>
                ),
            }}
            type={hide ? 'password' : type}
            {...props}
        />
    );
};

export default HideableTextField;
