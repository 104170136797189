import React, { ElementType } from 'react';

import MuiSvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps as MuiSvgIconProps } from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';

type SvgIconProps = MuiSvgIconProps & {
    icon: ElementType;
};

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.grey.A700,
    },
}));

const SvgIcon = ({ icon, ...props }: SvgIconProps) => {
    const classes = useStyles();
    return (
        <MuiSvgIcon
            component={icon}
            {...props}
            className={`${classes.root} ${props.className}`}
        />
    );
};

export default SvgIcon;
