import React, { PropsWithChildren, PropsWithoutRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export type BaseEnumSelectProps<T> = PropsWithoutRef<
    PropsWithChildren<{
        title: string;
        onChange: (value: T) => void;
        value: T;
        valueLabels: readonly (readonly [T, string])[];
        disabled?: boolean;
        required?: boolean;
        selectTestID?: string;
    }>
>;

const formatId = (str: string, end?: string) =>
    `${str.toLocaleLowerCase().replace(/ /g, '-')}${end ? `-${end}` : ''}`;

const BaseEnumSelect = <T extends string>({
    onChange,
    title,
    disabled,
    required,
    valueLabels,
    value,
    children,
    selectTestID,
}: BaseEnumSelectProps<T>) => {
    const labelId = formatId(title, 'label');
    const selectId = formatId(title, 'select');

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id={labelId}>{title}</InputLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value as T)}
                label={title}
                id={selectId}
                labelId={labelId}
                disabled={disabled}
                required={required}
                inputProps={{
                    'data-testid': selectTestID ?? 'select',
                }}
            >
                {children}
                {valueLabels.map(([value, text]) => {
                    return (
                        <MenuItem
                            value={value}
                            id={formatId(value)}
                            key={value}
                        >
                            {text}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default BaseEnumSelect;
