import React from 'react';

import logo from '../logo.png';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export interface AuthorizationHeaderProps {
    headerText?: string;
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(2),
    },
}));

const AuthorizationHeader = ({ headerText }: AuthorizationHeaderProps) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className={classes.header}
        >
            <Grid item>
                <img src={logo} alt="logo" />
            </Grid>

            {headerText && (
                <Grid item>
                    <Typography variant="h4">
                        <Box fontSize={24}>{headerText}</Box>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default AuthorizationHeader;
