import React from 'react';
import { Link } from 'react-router-dom';

import {
    APILogRoutes,
    BankAccountRoutes,
    ChequeRoutes,
    ContactRoutes,
    LetterRoutes,
    PostcardRoutes,
    ReturnEnvelopeRoutes,
    TemplateRoutes,
    WebhookRoutes,
    SelfMailerRoutes,
} from '../routes';

import { ClassNameMap } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { green, red, orange, purple } from '@mui/material/colors';

export interface JSONDisplayProps {
    code?: string | Record<string, unknown>;
    title?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[50],
        display: 'flex',
        width: '100%',
        counterReset: 'line',
        '& pre': {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            position: 'relative',
        },
        '& pre div::before': {
            color: theme.palette.grey[400],
            counterIncrement: 'line',
            content: 'counter(line)',
            userSelect: 'none',
            position: 'absolute',
            left: 0,
            display: 'inline-block',
            width: theme.spacing(3),
            marginRight: '.5rem',
        },
    },
    valueBase: {
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
    },
    number: {
        color: red[500],
    },
    string: {
        color: green[500],
    },
    boolean: {
        color: orange[800],
    },
    null: {
        color: purple[500],
    },
}));

// do not account for webhook_invocations as we do not
// have a page for them.
// do not account for return_envelope_orders as the
// dashboard and api route rely on the return_envelope id
// as well as the order id to retrieve it
export const objectMap = new Map<string, string>([
    ['contact', ContactRoutes.HOME],
    ['template', TemplateRoutes.HOME],
    ['letter', LetterRoutes.HOME],
    ['cheque', ChequeRoutes.HOME],
    ['postcard', PostcardRoutes.HOME],
    ['self_mailer', SelfMailerRoutes.HOME],
    ['return_envelope', ReturnEnvelopeRoutes.HOME],
    ['api_log', APILogRoutes.HOME],
    ['webhook', WebhookRoutes.HOME],
    ['bank', BankAccountRoutes.HOME],
]);

const getValueElement = (v: string, classes: ClassNameMap) => {
    const value = v.trim();
    if (parseInt(value))
        return (
            <span className={`${classes.valueBase} ${classes.number}`}>
                {value}
            </span>
        );
    if (value === 'false' || value === 'true')
        return (
            <span className={`${classes.valueBase} ${classes.boolean}`}>
                {value}
            </span>
        );
    if (value === 'null')
        return (
            <span className={`${classes.valueBase} ${classes.null}`}>
                {value}
            </span>
        );
    // the short uuid has 22 characters on it
    // remove the last 24 characters
    //      ->  22 for the uuid, 1 for the ending ", and 1 for the _
    //          used to separate the object type from the uuid
    //      ->  Start at index 1 to account for the beginning "
    // and check if the value is in the set
    const object = value.substring(1, value.length - 24);
    const objectRoute = objectMap.get(object);
    if (objectRoute)
        return (
            <span className={`${classes.valueBase}`}>
                <Link to={`${objectRoute}/` + value.replace(/"/g, '')}>
                    {value}
                </Link>
            </span>
        );

    if (value.startsWith('"') && value.endsWith('"'))
        return (
            <span className={`${classes.valueBase} ${classes.string}`}>
                {value}
            </span>
        );

    return <span className={classes.valueBase}>{value}</span>;
};

const checkData = (line: string, classes: ClassNameMap) => {
    const regex = /".*?":/;
    let value = line.split(regex)[1];
    const name = regex.exec(line);
    if (!name) return line;
    let commaFlag = false;
    // don't want the comma to be colored with the value
    if (value[value.length - 1] === ',') {
        commaFlag = true;
        value = value.substring(0, value.length - 1);
    }
    // get everything until the value of the property (includes spaces)
    const beginning = line.substring(
        0,
        line.length - (commaFlag ? value.length : value.length - 1)
    );
    return (
        <>
            <span>{beginning}</span>
            {getValueElement(value, classes)}
            {commaFlag && <span>,</span>}
        </>
    );
};

const JSONDisplay = ({ title, code: c }: JSONDisplayProps) => {
    const classes = useStyles();
    if (!c) return null;
    const code = JSON.stringify(c, null, 4).split('\n');

    return (
        <div>
            {title && (
                <Typography component="div" variant="h6">
                    {title}
                </Typography>
            )}
            <Box className={classes.root} data-testid="root">
                <pre>
                    {code.map((line, i) => {
                        return <Box key={i}>{checkData(line, classes)}</Box>;
                    })}
                </pre>
            </Box>
        </div>
    );
};

export default JSONDisplay;
