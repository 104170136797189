import React from 'react';

import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

const TermsAndConditons = () => {
    return (
        <FormControlLabel
            control={<Checkbox required color="primary" />}
            label={
                <Typography>
                    I have read and agree to PostGrid's{' '}
                    <Link href="https://postgrid.com/legal">
                        Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href="https://postgrid.com/legal">
                        Privacy Policy
                    </Link>
                    .
                </Typography>
            }
        />
    );
};

export default TermsAndConditons;
