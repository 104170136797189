import React, {
    Dispatch,
    createContext,
    useReducer,
    PropsWithChildren,
    useCallback,
    useContext,
} from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export enum MessageType {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

interface State {
    open: boolean;
    type?: MessageType;
    message?: string;
}

interface MessageAction {
    type: MessageType;
    message: string;
}

interface HideAction {
    type: 'hide';
}

export type Action = MessageAction | HideAction;

const reducer = (state: State, action: Action): State => {
    if (action.type === 'hide') {
        return {
            ...state,
            open: false,
        };
    }

    return {
        open: true,
        type: action.type,
        message: action.message,
    };
};

interface IContext {
    state: State;
    dispatch: Dispatch<Action>;
    dispatchSuccess: Dispatch<string>;
    dispatchError: Dispatch<string>;
    dispatchInfo: Dispatch<string>;
}

export const Context = createContext<IContext>({
    state: { open: false },
    dispatch: () => {},
    dispatchSuccess: () => {},
    dispatchError: () => {},
    dispatchInfo: () => {},
});

export const Display = (props: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, { open: false });
    const dispatchSuccess = useCallback((msg: string) => {
        return dispatch({ type: MessageType.SUCCESS, message: msg });
    }, []);
    const dispatchError = useCallback((msg: string) => {
        return dispatch({ type: MessageType.ERROR, message: msg });
    }, []);
    const dispatchInfo = useCallback((msg: string) => {
        return dispatch({ type: MessageType.INFO, message: msg });
    }, []);

    return (
        <Context.Provider
            value={{
                state,
                dispatch,
                dispatchSuccess,
                dispatchError,
                dispatchInfo,
            }}
        >
            <Snackbar
                open={state.open}
                onClose={() => {
                    dispatch({ type: 'hide' });
                }}
                autoHideDuration={6000}
                data-testid="alert-snackbar"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                key={state.message}
            >
                <Alert
                    severity={state.type}
                    data-testid={`alert-${state.type}`}
                >
                    {state.message}
                </Alert>
            </Snackbar>
            {props.children}
        </Context.Provider>
    );
};

export const useNotificationContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useNotificationContext can only be used within a <Display /> provider.'
        );
    }

    return context;
};
