import React, {
    Dispatch,
    createContext,
    useReducer,
    useEffect,
    PropsWithChildren,
    useContext,
} from 'react';

import { ListResponse, flatten, downloadData } from '../services/util';

import { Context as NotificationContext, MessageType } from './Notification';

class NoResourcesFoundError extends Error {
    constructor() {
        super('Nothing was found to download.');
    }
}

class DownloadInProgressError extends Error {
    constructor() {
        super('A download is currently in progress.');
    }
}

type ListFunction = (params: {
    skip: number;
    limit: number;
}) => Promise<ListResponse<any>>;

interface State {
    active: boolean;
    fileName: string;
    list: ListFunction;
    downloadCount: number;
    total: number;
}

export enum ActionType {
    INITIATE = 'initiate',
    SET_DOWNLOAD_COUNT = 'set_download_count',
    COMPLETE = 'complete',
    SET_TOTAL = 'set_total',
}

interface InitiateAction {
    type: ActionType.INITIATE;
    fileName: string;
    list: ListFunction;
}

interface SetDownloadCountAction {
    type: ActionType.SET_DOWNLOAD_COUNT;
    partialDownloadCount: number;
}

interface CompleteAction {
    type: ActionType.COMPLETE;
}

interface SetTotalAction {
    type: ActionType.SET_TOTAL;
    total: number;
}

export type Action =
    | InitiateAction
    | SetDownloadCountAction
    | CompleteAction
    | SetTotalAction;

const reducer = (state: State, action: Action): State => {
    if (action.type === ActionType.INITIATE) {
        if (state.active) {
            throw new DownloadInProgressError();
        }

        return {
            active: true,
            fileName: action.fileName,
            list: action.list,
            downloadCount: 0,
            total: Number.POSITIVE_INFINITY,
        };
    }

    if (action.type === ActionType.SET_DOWNLOAD_COUNT) {
        return {
            ...state,
            downloadCount: action.partialDownloadCount,
        };
    }

    if (action.type === ActionType.SET_TOTAL) {
        return {
            ...state,
            total: action.total,
        };
    }

    return {
        ...state,
        active: false,
    };
};

interface IContext {
    state: State;
    dispatch: Dispatch<Action>;
}

export const Context = createContext<IContext>({} as any);

export const Display = (props: PropsWithChildren<{}>) => {
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const initialState: State = {
        active: false,
        fileName: '',
        list: async () => {
            return {
                skip: 0,
                limit: 0,
                totalCount: 0,
                data: [],
            };
        },
        downloadCount: 0,
        total: 0,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const proceed = state.active && state.fileName.endsWith('.csv');

        const executeDownload = async () => {
            let total = Number.POSITIVE_INFINITY;

            let skip = 0;
            const limit = 100;

            const downloadedResources = [];

            while (proceed && skip < total) {
                const listResponse = await state.list!({
                    skip,
                    limit,
                });

                if (!isFinite(total)) {
                    dispatch({
                        type: ActionType.SET_TOTAL,
                        total: listResponse.totalCount,
                    });
                }

                total = listResponse.totalCount;

                downloadedResources.push(
                    ...listResponse.data.map((v) => flatten(v))
                );

                dispatch({
                    type: ActionType.SET_DOWNLOAD_COUNT,
                    partialDownloadCount: downloadedResources.length,
                });

                skip += limit;
            }

            if (downloadedResources.length === 0) {
                dispatch({
                    type: ActionType.COMPLETE,
                });
                throw new NoResourcesFoundError();
            }

            const keys = new Set<string>();

            for (const resource of downloadedResources) {
                for (const key of Object.keys(resource)) {
                    keys.add(key);
                }
            }

            // We'll put 'id' as the first header and sort the rest
            keys.delete('id');

            // We delete some keys that are undesriable in the output CSV file due to their
            // verbose/temporary nature
            keys.delete('html');
            keys.delete('frontHTML');
            keys.delete('backHTML');
            keys.delete('url');
            keys.delete('uploadedPDF');
            keys.delete('message');
            keys.delete('letterHTML');
            keys.delete('letterUploadedPDF');

            const headers = ['id'].concat(Array.from(keys).sort());
            const headerRow = headers.join(',');

            const createResourceRow = (resource: any) =>
                headers
                    .map((fieldName): string => {
                        const value = resource[fieldName];

                        if (
                            typeof value !== 'boolean' &&
                            typeof value !== 'number' &&
                            !value
                        ) {
                            return '';
                        }

                        // HACK These assume that the fields with the following names are of a certain type.
                        // Might be wise to check via instanceof
                        if (value instanceof Date) {
                            return value.toLocaleDateString();
                        }

                        if (
                            fieldName === 'amount' &&
                            typeof value === 'number'
                        ) {
                            return (value / 100).toLocaleString(undefined, {
                                style: 'currency',
                                currency: resource?.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });
                        }

                        return value.toString();
                    })
                    .map((v) => `"${v.replace(/"/g, '\\"')}"`)
                    .join(',');

            const payload =
                headerRow +
                '\n' +
                downloadedResources.map(createResourceRow).join('\n');

            downloadData(payload, state.fileName, 'text/csv');

            dispatch({
                type: ActionType.COMPLETE,
            });
        };

        if (proceed) {
            executeDownload().catch((err) => {
                // Handle case of nothing found or some API error
                notificationDispatch({
                    type:
                        err instanceof NoResourcesFoundError
                            ? MessageType.WARNING
                            : MessageType.ERROR,
                    message: err.message,
                });
            });
        }
    }, [state.active, state.fileName, state.list, notificationDispatch]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {props.children}
        </Context.Provider>
    );
};
