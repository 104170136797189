import React from 'react';

import { useService as useSelfMailersService } from '../services/SelfMailers';

import OrderDetailsPage from '../components/OrderDetailsPage';

const ViewSelfMailer = () => {
    const service = useSelfMailersService();

    return (
        <OrderDetailsPage
            title="Self Mailer Details"
            service={service}
            hasPDF
        />
    );
};

export default ViewSelfMailer;
