import React, { useContext, createContext, useState, useCallback } from 'react';
import { useExtraService } from '../hooks/useExtraService';
import { OrderExtraService, OrderMailingClass } from '../services/Base';
import { Contact } from '../services/Contacts';
import { LetterSize } from '../services/Letters';
import { ReturnEnvelope } from '../services/ReturnEnvelopes';
import { Template } from '../services/Templates';
import { minDate } from '../components/SendDate';
import { CustomEnvelope, STANDARD_ENVELOPE } from '../services/CustomEnvelopes';

interface State {
    description: string;
    setDescription: (s: string) => void;
    perforateFirstPage: boolean;
    setPerforateFirstPage: (b: boolean) => void;
    toContacts: Contact[];
    setToContacts: (c: Contact[]) => void;
    fromContact: Contact | null;
    setFromContact: (c: Contact | null) => void;
    template: Template | null;
    setTemplate: (t: Template | null) => void;
    file: File | null;
    setFile: (f: File | null) => void;
    mergeVars: Record<string, string>;
    setMergeVars: (v: Record<string, string>) => void;
    uploadedCSV: boolean;
    setUploadedCSV: (f: boolean) => void;
    returnEnvelope: ReturnEnvelope | null;
    setReturnEnvelope: (r: ReturnEnvelope | null) => void;
    envelope: CustomEnvelope;
    setEnvelope: (ce: CustomEnvelope) => void;
    mailingClass: OrderMailingClass;
    setMailingClass: (m: OrderMailingClass) => void;
    color: boolean;
    setColor: (c: boolean) => void;
    doubleSided: boolean;
    setDoubleSided: (f: boolean) => void;
    insertBlankPage: boolean;
    setInsertBlankPage: (f: boolean) => void;
    express: boolean;
    setExpress: (f: boolean) => void;
    sendDate: Date;
    setSendDate: (d: Date) => void;
    loading: boolean;
    setLoading: (f: boolean) => void;
    extraService: OrderExtraService | undefined;
    extraServiceForm: OrderExtraService | '';
    setExtraService: (e: '' | OrderExtraService) => void;
    size: LetterSize | '';
    setSize: (l: LetterSize | '') => void;
    resetState: () => void;
}

const CreateLetterContext = createContext<State | undefined>(undefined);

export const CreateLetterProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [description, setDescription] = useState<string>('');
    const [toContacts, setToContacts] = useState<Contact[]>([]);
    const [fromContact, setFromContact] = useState<Contact | null>(null);
    const [template, setTemplate] = useState<Template | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [mergeVars, setMergeVars] = useState<Record<string, string>>({});
    const [uploadedCSV, setUploadedCSV] = useState<boolean>(false);
    const [color, setColor] = useState<boolean>(false);
    const [doubleSided, setDoubleSided] = useState<boolean>(false);
    const [insertBlankPage, setInsertBlankPage] = useState<boolean>(false);
    const [perforateFirstPage, setPerforateFirstPage] = useState(false);
    const [returnEnvelope, setReturnEnvelope] = useState<ReturnEnvelope | null>(
        null
    );
    const [envelope, setEnvelope] = useState<CustomEnvelope>(STANDARD_ENVELOPE);
    const [mailingClass, setMailingClass] = useState<OrderMailingClass>(
        OrderMailingClass.FIRST_CLASS
    );
    const [express, setExpress] = useState<boolean>(false);
    const [sendDate, setSendDate] = useState(minDate());
    const [loading, setLoading] = useState<boolean>(false);
    const [size, setSize] = useState<LetterSize | ''>('');

    const { extraService, extraServiceForm, setExtraService } =
        useExtraService();

    const resetState = useCallback(() => {
        setDescription('');
        setToContacts([]);
        setFromContact(null);
        setTemplate(null);
        setPerforateFirstPage(false);
        setFile(null);
        setMergeVars({});
        setUploadedCSV(false);
        setColor(false);
        setDoubleSided(false);
        setInsertBlankPage(false);
        setExtraService('');
        setEnvelope(STANDARD_ENVELOPE);
        setMailingClass(OrderMailingClass.FIRST_CLASS);
        setReturnEnvelope(null);
        setExpress(false);
        setSendDate(minDate());
        setLoading(false);
        setSize('');
        // Don't care about updating the reset function
        // eslint-disable-next-line
    }, []);

    const value = {
        description,
        setDescription,
        perforateFirstPage,
        setPerforateFirstPage,
        toContacts,
        setToContacts,
        fromContact,
        setFromContact,
        template,
        setTemplate,
        file,
        setFile,
        mergeVars,
        setMergeVars,
        uploadedCSV,
        setUploadedCSV,
        color,
        setColor,
        doubleSided,
        setDoubleSided,
        insertBlankPage,
        setInsertBlankPage,
        returnEnvelope,
        setReturnEnvelope,
        envelope,
        setEnvelope,
        mailingClass,
        setMailingClass,
        express,
        setExpress,
        loading,
        setLoading,
        extraService,
        extraServiceForm,
        setExtraService,
        resetState,
        sendDate,
        setSendDate,
        size,
        setSize,
    };
    return (
        <CreateLetterContext.Provider value={value}>
            {children}
        </CreateLetterContext.Provider>
    );
};

export const useCreateLetterContext = () => {
    const context = useContext(CreateLetterContext);
    if (context === undefined) {
        throw new Error(
            'useCreateLetterContext must be used within a CreateLetterProvider'
        );
    }
    return context;
};
