import React, { ReactElement } from 'react';
import { useBulkCancelOrdersContext } from '../context/BulkCancelOrders';
import { OrderStatus } from './OrderStatusCell';
import { OrderStatus as Status } from '../services/Orders';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
// Switched out for MUI linear progress while we client-side filter for searched
// orders
import LinearProgress from '@mui/material/LinearProgress';
import Button from './Button';
import BulkCancelOrdersAccordion from './BulkCancelOrdersAccordion';
import DeleteButton from './DeleteButton';
import DateRangeSelector from './DateRangeSelector';
import { Alert } from '@mui/material';

export interface BulkCancelOrdersDialogProps {
    open: boolean;
    onClose: () => void;
}

const BoxGrid = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box style={{ gridRowStart: 1, gridColumnStart: 1 }}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width="100%"
                height="100%"
            >
                {children}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr',
    },
}));

const BulkCancelOrdersDialog = ({
    onClose,
    open,
}: BulkCancelOrdersDialogProps): ReactElement => {
    const classes = useStyles();
    const {
        resetState,
        searchText,
        handleSearchText,
        cancelling,
        object,
        didCancel,
        cancellable,
        openModal, // controls opening the confirmation modal
        cancelledCount,
        dateQuery,
        handleDateChange,
    } = useBulkCancelOrdersContext();

    const showCancelled = cancelling || didCancel;
    const showAccordion = !showCancelled;

    const handleClose = () => {
        if (cancelling) {
            return;
        }
        resetState();
        onClose();
    };

    const handleText = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        handleSearchText(e);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flex={1}>
                    <DialogTitle style={{ textTransform: 'capitalize' }}>
                        Cancel {object}s
                    </DialogTitle>
                </Box>
                <Box display="flex" flex={1}>
                    <DialogContent>
                        <DialogContentText component="div">
                            Set a search criteria to find {object}s. All
                            selected {object}s found will be cancelled. Please
                            note that only {object}s with a status of{' '}
                            <OrderStatus status={Status.READY} /> are eligible
                            to be cancelled.
                        </DialogContentText>
                    </DialogContent>
                </Box>
                <Box display="flex" flex={1}>
                    <DialogContent>
                        <Box
                            display="grid"
                            gridTemplateColumns="2fr 1fr"
                            gap="3rem"
                        >
                            <Box display="grid" gridColumn="1 / span 2">
                                <TextField
                                    variant="outlined"
                                    placeholder={`Search for ${object}s`}
                                    value={searchText}
                                    onChange={handleText}
                                    disabled={cancelling || dateQuery !== ''}
                                    fullWidth
                                    inputProps={{
                                        'data-testid':
                                            'cancel-orders-textfield',
                                    }}
                                />
                            </Box>

                            <Box display="grid" gridColumn="3">
                                <DateRangeSelector
                                    onChange={handleDateChange}
                                    disabled={searchText !== ''}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
                <Box
                    flexGrow={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    position="relative"
                >
                    <Box className={classes.grid}>
                        <BoxGrid>
                            <Grow in={showCancelled}>
                                <DialogContent
                                    className={classes.container}
                                    data-testid="cancel-orders-cancelled"
                                >
                                    <Paper variant="outlined">
                                        <Box p={2}>
                                            {/** we no longer know the true
                                            count of orders, so do not show a 
                                            progress bar with values **/}
                                            {didCancel ? (
                                                <Alert>
                                                    Cancelled {cancelledCount}{' '}
                                                    {object}
                                                    {cancelledCount === 1
                                                        ? ''
                                                        : 's'}
                                                </Alert>
                                            ) : (
                                                <LinearProgress />
                                            )}
                                        </Box>
                                    </Paper>
                                </DialogContent>
                            </Grow>
                        </BoxGrid>

                        <BoxGrid>
                            <Grow in={showAccordion}>
                                <div
                                    className={classes.container}
                                    data-testid="cancel-orders-accordion"
                                >
                                    <DialogContent>
                                        <BulkCancelOrdersAccordion />
                                    </DialogContent>
                                    <DialogContent>
                                        <DeleteButton
                                            variant="outlined"
                                            disabled={!cancellable}
                                            onClick={openModal}
                                            fullWidth
                                            data-testid="cancel-orders-button"
                                        >
                                            Cancel {object}s
                                        </DeleteButton>
                                    </DialogContent>
                                </div>
                            </Grow>
                        </BoxGrid>
                    </Box>
                </Box>
                <Box display="flex" flex={1} justifyContent="flex-end">
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            disabled={cancelling}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
};

export default BulkCancelOrdersDialog;
