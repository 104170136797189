import React, { useRef, useState, useLayoutEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { PREFIX_TO_OBJECT } from './RawData';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CopyRawData from './CopyRawData';

const MAX_WIDTH = 400;

export interface RawDataFieldProps {
    objKey: string;
    value: any;
}

export const display = (key: string, value: any) => {
    if (value === null || value === undefined) {
        return '';
    }

    // TODO Do not hack this in
    if (key === 'amount') {
        return `${value} cents`;
    }

    if (key !== 'id' && typeof value === 'string') {
        if (value.startsWith('http') || value.startsWith('https')) {
            try {
                const url = new URL(value);
                return (
                    <Link href={url.href} target="_blank">
                        External Link
                    </Link>
                );
            } catch (e) {
                return value;
            }
        }
        // If it's a known object ID prefix, link to it
        for (const [prefix, object] of Object.entries(PREFIX_TO_OBJECT)) {
            if (value.startsWith(prefix)) {
                return (
                    <Link
                        component={RouterLink}
                        to={`/dashboard/${object}s/${value}`}
                    >
                        {value}
                    </Link>
                );
            }
        }

        return value;
    }

    if (value instanceof Date) {
        return value.toISOString();
    }

    // TODO Refactor to use objectIDPrefixes
    if (value.id && value.object) {
        return (
            <Link
                component={RouterLink}
                to={`/dashboard/${value.object}s/${value.id}`}
            >
                {value.id}
            </Link>
        );
    }

    if (Array.isArray(value)) {
        return `Array with ${value.length} elements`;
    }

    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
    }

    return value.toString();
};

const useStyles = makeStyles({
    root: {
        maxWidth: MAX_WIDTH,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

const RawDataField = ({ objKey: key, value }: RawDataFieldProps) => {
    const classes = useStyles();

    const typoRef = useRef<HTMLDivElement>(null);
    const [showCopy, setShowCopy] = useState(false);

    useLayoutEffect(() => {
        if (typoRef.current) {
            setShowCopy(
                (typoRef.current.getBoundingClientRect().width ?? 0) >=
                    MAX_WIDTH
            );
        }
    }, []);

    return (
        <>
            <Grid item>
                <Typography
                    ref={typoRef}
                    component="div"
                    variant="body1"
                    className={classes.root}
                    data-testid={`${key}-${value}`}
                >
                    {display(key, value)}
                </Typography>
            </Grid>
            <CopyRawData value={value} show={showCopy} />
        </>
    );
};

export default RawDataField;
