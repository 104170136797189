import { useState } from 'react';
import { OrderExtraService } from '../services/Base';

export const useExtraService = () => {
    const [extraService, setExtraService] = useState<OrderExtraService | ''>(
        ''
    );

    const handleChange = (val: OrderExtraService | '') => {
        setExtraService(val);
    };

    const extraServiceVal = (() => {
        if (extraService === '') return undefined;
        return extraService;
    })();

    return {
        extraService: extraServiceVal,
        extraServiceForm: extraService,
        setExtraService: handleChange,
    };
};
