import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { OrderMailingClass } from '../services/Base';
import TooltipWrapper from './TooltipWrapper';
import StrictEnumSelect from './StrictEnumSelect';
import MailingClassExpressTooltip from './MailingClassExpressTooltip';

type MailingClassSelectorProps = Omit<GridProps, 'onChange'> & {
    mailingClass: OrderMailingClass;
    onChange: (service: OrderMailingClass) => void;
    disabled?: boolean;
    selectTestID?: string;
};

const MailingClassSelector = ({
    mailingClass,
    onChange,
    disabled,
    selectTestID,
    ...props
}: MailingClassSelectorProps) => {
    return (
        <TooltipWrapper
            title={<MailingClassExpressTooltip />}
            disabled={disabled}
        >
            <Grid item {...props}>
                <StrictEnumSelect
                    title="Mailing Class"
                    valueLabels={[
                        [
                            OrderMailingClass.FIRST_CLASS,
                            'First Class (7-10 business days)',
                        ],
                        [
                            OrderMailingClass.STANDARD_CLASS,
                            'Standard Class (10-14 business days)',
                        ],
                    ]}
                    selectTestID={selectTestID}
                    onChange={onChange}
                    value={mailingClass}
                    disabled={disabled}
                />
            </Grid>
        </TooltipWrapper>
    );
};

export default MailingClassSelector;
