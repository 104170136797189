import React from 'react';

import { useService as useLettersService } from '../services/Letters';

import OrdersPage from '../components/OrdersPage';

import CreateLetter from './CreateLetter';
import ViewLetter from './ViewLetter';

const Letters = () => {
    const service = useLettersService();

    return (
        <OrdersPage
            service={service}
            object="letter"
            Create={CreateLetter}
            View={ViewLetter}
        />
    );
};

export default Letters;
