import React, { FormEvent, useState } from 'react';

import { useHistory } from '../hooks/useHistory';

import {
    ExpiryDuration,
    useService as useTrackersService,
} from '../services/Trackers';

import { TrackerRoutes } from '../routes';

import { useNotificationContext } from '../context/Notification';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Field from '../components/Field';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import CreateResourceActions from '../components/CreateResourceActions';
import PageHeader from '../components/PageHeader';
import StrictEnumSelect from '../components/StrictEnumSelect';

const CreateTracker = () => {
    const history = useHistory();
    const service = useTrackersService();

    const [description, setDescription] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [expiry, setExpiry] = useState<ExpiryDuration>(
        ExpiryDuration.DAYS_30
    );
    const [loading, setLoading] = useState(false);

    const { dispatchSuccess } = useNotificationContext();
    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            await service.create({
                description: description,
                redirectURLTemplate: redirectUrl,
                urlExpireAfterDays: expiry,
            });

            dispatchSuccess('Created tracker.');
            history.push(TrackerRoutes.HOME);
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <PageHeader
                    title="Create a Tracker"
                    testAlertText="You are in test mode. This tracker will only be available in test mode."
                />
                <Grid item>
                    <form onSubmit={onSubmit}>
                        <Box py={4}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Field
                                        value={description}
                                        setValue={setDescription}
                                        label="Description"
                                        id="description"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <StrictEnumSelect
                                        title="Expiry Duration"
                                        valueLabels={Object.values(
                                            ExpiryDuration
                                        ).map((v) => {
                                            return [v.toString(), `${v} Days`];
                                        })}
                                        onChange={(val: string) => {
                                            setExpiry(
                                                parseInt(val) as ExpiryDuration
                                            );
                                        }}
                                        value={expiry.toString()}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        value={redirectUrl}
                                        setValue={setRedirectUrl}
                                        label="Redirect URL Template"
                                        id="redirect-url-template"
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CreateResourceActions
                                        disabled={loading}
                                        onCancel={() =>
                                            history.push(TrackerRoutes.HOME)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateTracker;
