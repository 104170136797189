import { useMemo } from 'react';
import { useService as useBaseService, Resource } from './Base';
import { Service as ResourceService } from './Resources';

export interface CustomEnvelope extends Resource {
    available: number;
}

export class Service extends ResourceService<CustomEnvelope> {
    // TODO Add more functions as needed
}

export const STANDARD_ENVELOPE: CustomEnvelope = {
    id: 'standard',
    description: 'standard',
    object: 'custom_envelope',
    available: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    live: false,
};

export const label = (
    customEnvelope: CustomEnvelope,
    defaultOrgCustomEnvelopeID?: string
) => {
    if (customEnvelope.description === 'standard') {
        return 'Standard';
    }

    return `${customEnvelope.description}${
        defaultOrgCustomEnvelopeID === customEnvelope.id
            ? ' - default envelope'
            : ''
    } (${
        customEnvelope.available > 0
            ? `${customEnvelope.available} available`
            : 'Out of stock but can be selected. May delay mailing.'
    })`;
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'custom_envelopes'), [base]);
};
