import { useCallback, useMemo } from 'react';
import { usePagination } from './usePagination';
import { useService as useRolesService } from '../services/Roles';
import { ListParams } from '../services/util';

const useRoles = () => {
    const rolesService = useRolesService();

    const rolePagination = useCallback(
        (params: ListParams) => rolesService.list(params),
        [rolesService]
    );

    const { data } = usePagination(rolePagination);

    const roles = useMemo(
        () => data.sort((a, b) => (a.name > b.name ? 1 : -1)),

        [data]
    );

    return roles;
};

export default useRoles;
