import React, { PropsWithoutRef, useState, useEffect, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useModal } from '../hooks/useModal';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import CheckCircle from '@mui/icons-material/CheckCircle';

import { useService as useBillingService, Price } from '../services/Billing';

import { useOrganizationLoader } from '../services/Organization';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Button, { ButtonProps } from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

const amountString = (price: Price) => {
    let divisor = 100;

    if (price.recurring.interval === 'year') {
        divisor *= 12;
    }

    const amount = (() => {
        if (price.metadata?.presentment_amount) {
            return parseInt(price.metadata.presentment_amount);
        }

        return price.unit_amount;
    })();

    return (amount / divisor).toLocaleString('en-US', {
        style: 'currency',
        currency: price.metadata?.presentment_currency ?? price.currency,
    });
};

const PriceCard = (
    props: PropsWithoutRef<{
        price?: Price;
        onCheckout?: ButtonProps['onClick'];
        isCurrentPrice?: boolean;
    }>
) => {
    // If no price is supplied, we treat this as an enterprise price card
    const { price, onCheckout, isCurrentPrice } = props;

    const Feature = ({ label }: { label: string }) => {
        return (
            <ListItem disableGutters>
                <ListItemIcon>
                    <CheckCircle
                        style={{
                            color: '#407BFF',
                        }}
                    />
                </ListItemIcon>
                <ListItemText style={{ textTransform: 'capitalize' }}>
                    {label}
                </ListItemText>
            </ListItem>
        );
    };

    return (
        <GridPaper
            style={{
                border: `1px solid ${isCurrentPrice ? '#407bff' : '#e3e3e3'}`,
                borderRadius: '16px',
                padding: '48px 32px',
                width: 338,
                boxShadow: 'none',
                minHeight: isCurrentPrice ? 592 : 554,
                background: isCurrentPrice ? '#f9fbff' : 'none',
            }}
            direction="column"
        >
            <Grid item>
                <Typography
                    style={{
                        fontSize: '29px',
                    }}
                >
                    {price
                        ? price.nickname.replace(/\(.*\)/, '')
                        : 'Custom Plan'}
                </Typography>
            </Grid>
            <Grid item>
                <Box mt={1} />
            </Grid>
            <Grid item>
                {price ? (
                    <>
                        <Typography
                            style={{
                                fontSize: '42px',
                            }}
                            display="inline"
                        >
                            {amountString(price)}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '25px',
                            }}
                            display="inline"
                        >
                            /month
                        </Typography>
                    </>
                ) : (
                    <Typography style={{ fontSize: '42px' }}>
                        Custom Price
                    </Typography>
                )}
            </Grid>

            <Grid item>
                <Box mt={3} />
            </Grid>

            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        price
                            ? onCheckout
                            : () => {
                                  window.open(
                                      'https://www.postgrid.com/talk-to-sales/',
                                      '_blank'
                                  );
                              }
                    }
                    size="large"
                    disabled={isCurrentPrice}
                >
                    {price ? 'Checkout' : 'Talk to Sales'}
                </Button>
            </Grid>

            <Grid item>
                <Box mt={5} mb={3}>
                    <Divider />
                </Box>
            </Grid>

            <Grid item>
                <Typography style={{ fontSize: '19px' }}>Features</Typography>
            </Grid>

            <Grid item>
                <List dense>
                    <Feature
                        label={
                            price
                                ? `${parseInt(
                                      price.metadata.monthly_limit
                                  ).toLocaleString()} Monthly Mailings`
                                : 'Flexible Mailing Limits'
                        }
                    />
                    <Feature
                        label={
                            price
                                ? price.metadata.sla
                                    ? price.metadata.sla
                                    : '2 Day SLA'
                                : 'Same Day SLA'
                        }
                    />
                    <Feature
                        label={`${
                            price ? price.metadata.pdn_type : 'Priority'
                        } Print Delivery Network`}
                    />
                    {!price && <Feature label="Dedicated Support" />}
                </List>
            </Grid>
        </GridPaper>
    );
};

const Upgrade = () => {
    const history = useHistory();
    const location = useLocation();

    const service = useBillingService();

    const { org, load: reloadOrg } = useOrganizationLoader([history.location]);

    const { dispatch } = useContext(NotificationContext);

    const [prices, setPrices] = useState<Price[]>([]);
    const [currentPriceID, setCurrentPriceID] = useState<string>();
    const [interval, setInterval] =
        useState<Price['recurring']['interval']>('month');

    const {
        isModalOpen: confirmChangeOpen,
        openModal: showConfirmChange,
        closeModal: closeConfirmChange,
    } = useModal();

    const [newPrice, setNewPrice] = useState<Price>();

    const [loading, setLoading] = useState(true);

    const checkout = (price: Price) => {
        if (!org) {
            return;
        }

        let newLoading = false;

        (async () => {
            try {
                setLoading(true);

                if (org.stripeSubscription) {
                    if (!org.stripeSubscriptionPaymentMethod) {
                        console.error(
                            'No payment method on file. Invalid state.'
                        );
                        return;
                    }

                    const sub = await service.createOrUpdateSubscription({
                        stripePrice: price.id,
                        stripeSubscriptionPaymentMethod:
                            org.stripeSubscriptionPaymentMethod!,
                    });

                    setCurrentPriceID(sub.plan.id);

                    dispatch({
                        type: MessageType.SUCCESS,
                        message: 'Successfully updated subscription.',
                    });
                } else {
                    // Continue to show loader while we wait for new URL to open
                    newLoading = true;

                    const session = await service.createSubscriptionSession({
                        stripePrice: price.id,
                        successURL: `${window.location.href}?session={CHECKOUT_SESSION_ID}`,
                        cancelURL:
                            window.location.origin + window.location.pathname,
                    });

                    window.open(session.url, '_self');
                }
            } catch (err) {
                console.error(err);
            }

            setLoading(newLoading);
        })();
    };

    useEffect(() => {
        setCurrentPriceID(org?.stripeSubscriptionPrice);
    }, [org]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const prices = await service.listPrices();

                prices.data = prices.data.sort(
                    (a, b) => a.unit_amount - b.unit_amount
                );

                setPrices(prices.data);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service, history.location]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionID = params.get('session');

        if (!sessionID) {
            return;
        }

        (async () => {
            try {
                setLoading(true);

                const session = await service.completeSubscriptionSession(
                    sessionID
                );

                // Force a reload of the organization
                await reloadOrg();

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Successfully subscribed.',
                });

                if (!session.subscription) {
                    console.error('Missing subscription value in session.');
                } else {
                    setCurrentPriceID(session.subscription.plan.id);
                }
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service, location, setLoading, dispatch, reloadOrg]);

    const Prices = (props: { prices: Price[] }) => {
        return (
            <>
                {props.prices.map((price) => (
                    <Grid item key={price.id}>
                        <PriceCard
                            price={price}
                            isCurrentPrice={price.id === currentPriceID}
                            onCheckout={() => {
                                if (currentPriceID) {
                                    setNewPrice(price);
                                    showConfirmChange();
                                    return;
                                }

                                // HACK(Apaar): This is a little awkward because checkout is a little too overloaded.
                                // Don't feel like explaining right now but you'll get it if you work on it.
                                checkout(price);
                            }}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <PriceCard />
                </Grid>
            </>
        );
    };

    return (
        <>
            <TopNav />

            <ConfirmActionDialog
                confirm={() => checkout(newPrice!)}
                title="Change Plan"
                text="Are you sure you want to change your plan?"
                open={confirmChangeOpen}
                onClose={closeConfirmChange}
                actionLabel="Confirm"
            />

            <GridPaper container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">Upgrade</Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        alignItems="center"
                        direction="column"
                        spacing={2}
                    >
                        {!loading && (
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography>Monthly</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            color="primary"
                                            checked={interval === 'year'}
                                            onChange={(e) =>
                                                setInterval(
                                                    e.target.checked
                                                        ? 'year'
                                                        : 'month'
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>Yearly</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                {loading ? (
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            style={{ minHeight: '30vh' }}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <>
                                        <Prices
                                            prices={prices.filter(
                                                (p) =>
                                                    p.recurring &&
                                                    p.recurring.interval ===
                                                        interval
                                            )}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default Upgrade;
