import React, { useEffect, useContext } from 'react';

import TableCell from '@mui/material/TableCell';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';

import { Context as LiveApprovedContext } from '../context/LiveApproved';

import { useService as useChequesService } from '../services/Cheques';
import { formatTableDate } from '../services/util';
import { label as contactLabel } from '../services/Contacts';

import OrdersPage from '../components/OrdersPage';
import ToolTipIconButton from '../components/ToolTipIconButton';
import ViewPDFLink from '../components/ViewPDFLink';
import OrderStatusCell from '../components/OrderStatusCell';

import CreateCheque from './CreateCheque';
import ViewCheque from './ViewCheque';

const Cheques = () => {
    const service = useChequesService();
    const { checkLiveApproved } = useContext(LiveApprovedContext);

    useEffect(() => {
        checkLiveApproved(
            'Fill out our KYC form to get access to cheques in live mode.'
        );
    }, [checkLiveApproved]);

    return (
        <OrdersPage
            service={service}
            object="cheque"
            headings={[
                'Description',
                'Recipient',
                'Amount',
                'Status',
                'Created At',
                <>
                    Preview
                    <ToolTipIconButton
                        icon={HelpIcon}
                        title="View PDF previews to ensure accurate printing results"
                        size="small"
                    />
                </>,
            ]}
            getCells={(value) => {
                return (
                    <>
                        <TableCell>{value.description}</TableCell>
                        <TableCell>
                            {value.to.addressChange && (
                                <ToolTipIconButton
                                    icon={InfoIcon}
                                    title="The address of this recipient has changed."
                                    color="#edb818"
                                    size="small"
                                />
                            )}
                            {contactLabel(value.to)}
                        </TableCell>
                        <TableCell>
                            {(value.amount / 100).toLocaleString(undefined, {
                                style: 'currency',
                                currency: value.currencyCode,
                            })}
                        </TableCell>
                        <OrderStatusCell {...value} />
                        <TableCell>
                            {formatTableDate(value.createdAt)}
                        </TableCell>
                        <TableCell>
                            <ViewPDFLink order={value} service={service} />
                        </TableCell>
                    </>
                );
            }}
            Create={CreateCheque}
            View={ViewCheque}
        />
    );
};

export default Cheques;
