import { useEffect } from 'react';
import {
    BankAccountRoutes,
    ContactRoutes,
    TemplateRoutes,
    UpgradeRoutes,
} from '../routes';
import { useHistory } from './useHistory';

export const useRegisterCreateOrderResetFunction = (
    resetState: () => void,
    includeBankAccounts?: boolean
) => {
    const history = useHistory();

    useEffect(() => {
        return () => {
            // Reset the state when not going to a targeted
            // route
            switch (history.location.pathname) {
                case ContactRoutes.CREATE: {
                    break;
                }
                case TemplateRoutes.CREATE: {
                    break;
                }
                case UpgradeRoutes.HOME: {
                    break;
                }
                case BankAccountRoutes.CREATE: {
                    if (!includeBankAccounts) {
                        resetState();
                    }
                    break;
                }
                default: {
                    resetState();
                    break;
                }
            }
        };
    }, [history.location.pathname, includeBankAccounts, resetState]);
};
