import React, { useState, useContext, useEffect, FormEvent } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useModal } from '../hooks/useModal';
import { useCreateContactContext } from '../context/CreateContact';

import {
    Contact,
    useService as useContactsService,
} from '../services/Contacts';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import { ContactRoutes } from '../routes';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import RawData from '../components/RawData';
import OldContactDetails from '../components/OldContactDetails';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import ContactInfo from '../components/ContactInfo';

const ViewContact = () => {
    const history = useHistory();

    const params = useParams<{ id: string }>();

    const service = useContactsService();

    const { dispatch } = useContext(NotificationContext);

    const [contact, setContact] = useState<Contact>();

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    useEffect(() => {
        let cancel = false;
        (async () => {
            try {
                setContact(undefined);

                const contact = await service.tryGet(params.id);
                if (cancel) return;
                if (!contact) {
                    dispatch({
                        type: MessageType.ERROR,
                        message: 'This contact no longer exists.',
                    });

                    history.replace(ContactRoutes.HOME);
                } else {
                    setContact(contact);
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: MessageType.ERROR,
                    message:
                        "Something went wrong when getting the contact's details.",
                });

                history.replace(ContactRoutes.HOME);
            }
        })();
        return () => {
            cancel = true;
        };
    }, [service, params.id, dispatch, history]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        toggleDeleteModal();
    };

    const {
        setDescription,
        setName,
        setCompanyName,
        setJobTitle,
        setAddressLine1,
        setAddressLine2,
        setCity,
        setProvinceOrState,
        setPostalOrZip,
        setCountryCode,
        setPhoneNumber,
        setEmail,
        setSkipVerification,
    } = useCreateContactContext();

    const copyContact = () => {
        if (!contact) {
            return;
        }
        setDescription(contact.description ?? '');
        setName(
            [contact.firstName, contact.lastName].filter((v) => !!v).join(' ')
        );
        setCompanyName(contact.companyName ?? '');
        setJobTitle(contact.jobTitle ?? '');
        setAddressLine1(contact.addressLine1);
        setAddressLine2(contact.addressLine2 ?? '');
        setCity(contact.city ?? '');
        setProvinceOrState(contact.provinceOrState ?? '');
        setPostalOrZip(contact.postalOrZip ?? '');
        setCountryCode(contact.countryCode ?? '');
        setPhoneNumber(contact.phoneNumber ?? '');
        setEmail(contact.email ?? '');
        setSkipVerification(contact.skipVerification ?? false);
        history.push(ContactRoutes.CREATE);
    };

    const deleteContact = async () => {
        // Hide the contact
        setContact(undefined);

        await service.delete(params.id);

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Deleted contact.',
        });

        history.replace(ContactRoutes.HOME);
    };

    return (
        <>
            <ConfirmDeleteDialog
                open={deleteOpen}
                onClose={toggleDeleteModal}
                title="Delete Contact"
                text="Are you sure you want to delete this contact?"
                confirm={deleteContact}
            />
            <TopNav />
            <GridPaper container={false} data-testid="view-contact-page">
                <form onSubmit={onSubmit}>
                    <Grid container direction="column" spacing={2}>
                        <PageHeader
                            title="Contact Details"
                            testAlertText="This is a test mode contact. We do not verify test mode addresses but their statuses will appear as verified."
                            liveAlertText={
                                contact?.addressChange
                                    ? [
                                          'This address was changed because the person at the address has moved. Click ',
                                          <a
                                              href="https://postgrid.readme.io/reference/address-changes"
                                              target="_blank"
                                              rel="noreferrer"
                                          >
                                              here
                                          </a>,
                                          ' to learn why.',
                                      ]
                                    : undefined
                            }
                            liveWarning
                            hideAlert={!contact}
                        >
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={copyContact}
                                >
                                    Copy Contact
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Delete Contact
                                </Button>
                            </Grid>
                        </PageHeader>

                        {contact ? (
                            <Grid container item direction="column" spacing={2}>
                                <ContactInfo contact={contact} />
                                {contact?.addressChange && (
                                    <Grid item>
                                        <OldContactDetails
                                            {...contact.addressChange}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <RawData obj={contact} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                style={{ minHeight: '20vh' }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default ViewContact;
