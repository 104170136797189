import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

type GridFormProps = {
    grid?: GridProps;
    form?: React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >;
    children: React.ReactNode;
};

const GridForm = ({ form, grid, children }: GridFormProps) => {
    return (
        <Grid item>
            <form {...form}>
                <Grid {...grid}>{children}</Grid>
            </form>
        </Grid>
    );
};

export default GridForm;
