import React from 'react';
import { useHistory } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

interface TableRowLinkProps {
    route: string;
    children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
    root: {
        '& :hover': {
            cursor: 'pointer',
        },
    },
}));

const TableRowLink = ({ route, children }: TableRowLinkProps) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => history.push(route);

    return (
        <TableRow onClick={handleClick} hover className={classes.root}>
            {children}
        </TableRow>
    );
};

export default TableRowLink;
