import React, { useState, useContext, FormEvent, useCallback } from 'react';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import { Context as ModeContext } from '../context/Mode';
import { useCreateLetterContext } from '../context/CreateLetter';

import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import { useHistory } from '../hooks/useHistory';

import {
    AddressPlacement,
    CreateParams,
    Letter,
    useService as useLettersService,
} from '../services/Letters';
import { Contact } from '../services/Contacts';
import {
    emptyKeysRemoved,
    formatMergeVariables,
    unflatten,
} from '../services/util';
import { useOrganization } from '../services/Organization';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import SelectTemplate from '../components/SelectTemplate';
import GoBackButton from '../components/GoBackButton';
import FileUpload from '../components/FileUpload';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ContactOrCSV from '../components/ContactOrCSV';
import ContactInput from '../components/ContactInput';
import SelectReturnEnvelope from '../components/SelectReturnEnvelope';
import SelectLetterSize from '../components/SelectLetterSize';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import ExtraServiceSelector from '../components/ExtraServiceSelector';
import BatchSend, {
    BatchSendProps,
    BatchSendState,
    BatchSendStatus,
} from '../components/BatchSend';
import MailingClassSelector from '../components/MailingClassSelector';
import SendDate from '../components/SendDate';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';

import { LetterRoutes } from '../routes';
import SelectCustomEnvelope from '../components/SelectCustomEnvelope';
import { STANDARD_ENVELOPE } from '../services/CustomEnvelopes';

const CreateLetter = () => {
    const history = useHistory();

    const org = useOrganization([history.location]);

    const service = useLettersService();

    const { live } = useContext(ModeContext);
    const { dispatch } = useContext(NotificationContext);

    const {
        color,
        description,
        doubleSided,
        envelope,
        express,
        file,
        fromContact,
        insertBlankPage,
        loading,
        mailingClass,
        mergeVars,
        returnEnvelope,
        template,
        toContacts,
        uploadedCSV,
        extraService,
        extraServiceForm,
        perforateFirstPage,
        size,
        setPerforateFirstPage,
        setExtraService,
        resetState,
        setColor,
        setDescription,
        setDoubleSided,
        setEnvelope,
        setExpress,
        setFile,
        setFromContact,
        setInsertBlankPage,
        setLoading,
        setMailingClass,
        setMergeVars,
        setReturnEnvelope,
        setTemplate,
        setToContacts,
        setUploadedCSV,
        sendDate,
        setSendDate,
        setSize,
    } = useCreateLetterContext();

    useRegisterCreateOrderResetFunction(resetState);

    // TODO: Fix this overwriting already entered values for merge vars
    const defaultVars = useDefaultVars(toContacts, fromContact);
    const templateVars = useTemplateVars(template);

    const [batchSendState, setBatchSendState] = useState<
        BatchSendState<Letter>
    >({
        processedCount: 0,
        status: BatchSendStatus.READY,
        results: [],
    });

    const createBatch = useCallback(
        async (
            params: Parameters<BatchSendProps<Letter>['createBatch']>[0]
        ) => {
            const mergeVariables = mergeVars && unflatten(mergeVars);
            const addressPlacement = insertBlankPage
                ? AddressPlacement.INSERT_BLANK_PAGE
                : AddressPlacement.TOP_FIRST_PAGE;

            return await service.createBatch({
                template: template ? template.id : undefined,
                pdf: file ?? undefined,
                express,
                extraService,
                envelope:
                    envelope!.id === STANDARD_ENVELOPE.id
                        ? undefined
                        : envelope!.id,
                sendDate,
                returnEnvelope: returnEnvelope?.id,
                size: size !== '' ? size : undefined,
                data: params.toContacts.map((c) => ({
                    description: description || c.description,
                    addressPlacement,
                    doubleSided,
                    color,
                    perforatedPage: perforateFirstPage ? 1 : undefined,
                    from: fromContact!.id,
                    to: c.id,
                    mergeVariables: emptyKeysRemoved({
                        ...mergeVariables,
                        ...c.metadata,
                        to: {
                            ...mergeVariables?.to,
                            ...c,
                        },
                    }),
                    mailingClass: express ? undefined : mailingClass,
                    metadata: { postgrid_dashboard: '' },
                })),
                handleProgress: params.handleProgress,
            });
        },
        [
            mergeVars,
            service,
            template,
            file,
            express,
            description,
            fromContact,
            color,
            doubleSided,
            perforateFirstPage,
            insertBlankPage,
            extraService,
            returnEnvelope,
            envelope,
            mailingClass,
            sendDate,
            size,
        ]
    );

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            (async () => {
                if (
                    toContacts.length === 0 ||
                    !fromContact ||
                    (!template && !file) ||
                    !size
                ) {
                    // This should be impossible given that both fields are required
                    return;
                }

                try {
                    setLoading(true);

                    const addressPlacement: AddressPlacement = insertBlankPage
                        ? AddressPlacement.INSERT_BLANK_PAGE
                        : AddressPlacement.TOP_FIRST_PAGE;

                    const send = async (to: Contact) => {
                        const letter: CreateParams = {
                            description: description || to.description,
                            to: to.id,
                            from: fromContact.id,
                            color,
                            doubleSided,
                            addressPlacement,
                            envelope:
                                envelope?.id === STANDARD_ENVELOPE.id
                                    ? undefined
                                    : envelope?.id,
                            returnEnvelope: returnEnvelope?.id,
                            extraService,
                            express,
                            mailingClass: express ? undefined : mailingClass,
                            perforatedPage: perforateFirstPage ? 1 : undefined,
                            sendDate,
                            size,
                        };

                        const mergeVariables = formatMergeVariables(mergeVars, {
                            toMetadata: to.metadata,
                            uploadedCSV,
                        });

                        if (template) {
                            return await service.create({
                                ...letter,
                                template: template.id,
                                mergeVariables,
                            });
                        }

                        return await service.createUploadPDF({
                            ...letter,
                            pdf: file!,
                        });
                    };

                    if (toContacts.length === 1) {
                        await send(toContacts[0]);

                        dispatch({
                            type: MessageType.SUCCESS,
                            message: 'Created letter.',
                        });

                        history.push(LetterRoutes.HOME);
                    } else {
                        setBatchSendState((state) => ({
                            ...state,
                            status: BatchSendStatus.IN_PROGRESS,
                        }));
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC">
                        <Typography variant="h5" gutterBottom>
                            Create a Letter
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item direction="column">
                    <Grid item>
                        <Box my={2}>
                            {live ? (
                                <Alert variant="outlined" color="warning">
                                    You are in live mode so this letter will be
                                    printed and delivered.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" color="info">
                                    You are in test mode so this letter will not
                                    actually get sent out.
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid item>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Description"
                                        fullWidth
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        inputProps={{
                                            'data-testid': 'letter-description',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactOrCSV
                                        label="To Contact"
                                        contacts={toContacts}
                                        setContacts={setToContacts}
                                        setUploadedCSV={setUploadedCSV}
                                        textFieldTestId="letter-to-contact"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ContactInput
                                        label="From Contact"
                                        contact={fromContact}
                                        setContact={setFromContact}
                                        textFieldTestId="letter-from-contact"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectTemplate
                                        label="Select a Template"
                                        template={template}
                                        setTemplate={setTemplate}
                                        required={!file}
                                        disabled={file ? true : false}
                                        textFieldTestId="letter-template"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FileUpload
                                        accept="application/pdf"
                                        label="Upload a PDF"
                                        file={file}
                                        setFile={setFile}
                                        required={!template}
                                        disabled={template ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectReturnEnvelope
                                        label="Select a Return Envelope"
                                        returnEnvelope={returnEnvelope}
                                        setReturnEnvelope={setReturnEnvelope}
                                        textFieldTestID="letter-return-envelope"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectCustomEnvelope
                                        label="Select Envelope"
                                        customEnvelope={envelope}
                                        setCustomEnvelope={setEnvelope}
                                        textFieldTestID="letter-custom-envelope"
                                        org={org}
                                        required={true}
                                        disabled={loading}
                                        setLoading={setLoading}
                                    />
                                </Grid>
                                <SelectLetterSize
                                    xs={3}
                                    size={size}
                                    onChange={setSize}
                                    destinationCountryCode={
                                        toContacts[0]?.countryCode
                                    }
                                    selectTestID="letter-size"
                                />
                                <ExtraServiceSelector
                                    xs={3}
                                    extraService={extraServiceForm}
                                    onChange={setExtraService}
                                    disabled={!!express}
                                    selectTestID="letter-extra-service"
                                />
                                <MailingClassSelector
                                    xs={3}
                                    mailingClass={mailingClass}
                                    onChange={setMailingClass}
                                    disabled={!!express}
                                    selectTestID="letter-mailing-class"
                                />
                                <SendDate
                                    xs={3}
                                    setSendDate={setSendDate}
                                    sendDate={sendDate}
                                    showSubscriptionPopup={
                                        !org?.stripeSubscription
                                    }
                                />
                                <Grid item xs={12}>
                                    <Collapse in={templateVars.length > 0}>
                                        <MergeVariablesInput
                                            templateVars={templateVars}
                                            mergeVars={mergeVars}
                                            setMergeVars={setMergeVars}
                                            defaultVars={defaultVars}
                                        />
                                    </Collapse>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={color}
                                                    onChange={(e) => {
                                                        setColor(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    inputProps={
                                                        {
                                                            'data-testid':
                                                                'letter-color',
                                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                                    }
                                                />
                                            }
                                            label="Color"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={doubleSided}
                                                    onChange={(e) => {
                                                        setDoubleSided(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    inputProps={
                                                        {
                                                            'data-testid':
                                                                'letter-double-sided',
                                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                                    }
                                                />
                                            }
                                            label="Double Sided"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={insertBlankPage}
                                                    onChange={(e) => {
                                                        setInsertBlankPage(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    inputProps={
                                                        {
                                                            'data-testid':
                                                                'letter-blank-page',
                                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                                    }
                                                />
                                            }
                                            label="Insert Blank Page for Address"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={perforateFirstPage}
                                                    onChange={(e) => {
                                                        setPerforateFirstPage(
                                                            e.target.checked
                                                        );
                                                    }}
                                                    inputProps={
                                                        {
                                                            'data-testid':
                                                                'letter-perforate',
                                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                                    }
                                                />
                                            }
                                            label="Perforate First Page"
                                        />
                                    </Grid>
                                    <ExpressDeliveryCheckbox
                                        checked={express}
                                        setChecked={setExpress}
                                        disabled={!!extraService}
                                        checkboxTestID="letter-express"
                                    />
                                </Grid>
                                {batchSendState.status !==
                                    BatchSendStatus.READY && (
                                    <Grid item xs={12}>
                                        <BatchSend
                                            state={batchSendState}
                                            setState={setBatchSendState}
                                            toContacts={toContacts}
                                            createBatch={createBatch}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {batchSendState.status ===
                                            BatchSendStatus.READY && (
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    fullWidth
                                                    size="large"
                                                >
                                                    Create
                                                </Button>
                                            </Grid>
                                        )}

                                        <Grid item xs={2}>
                                            <GoBackButton
                                                color="primary"
                                                variant="outlined"
                                                disabled={
                                                    loading ||
                                                    batchSendState.status ===
                                                        BatchSendStatus.IN_PROGRESS
                                                }
                                                fullWidth
                                                size="large"
                                            >
                                                {batchSendState.status ===
                                                    BatchSendStatus.COMPLETED ||
                                                batchSendState.status ===
                                                    BatchSendStatus.ERROR
                                                    ? 'Back'
                                                    : 'Cancel'}
                                            </GoBackButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateLetter;
