import React from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';

import { ZAPIER_CLIENT_ID } from '../config';
import { IntegrationRoutes } from '../routes';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import Button from '../components/Button';
import cardBg from '../img/card-bg.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: cardBg,
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px',
        borderColor: theme.palette.primary.main,
        padding: '18px 18px 25px',
        '&>.MuiCardContent-root': {
            padding: '0 16px',
        },
    },
}));

interface IntegrationMethodPreviewProps {
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
}

const INTEGRATIONS_LIST: IntegrationMethodPreviewProps[] = [
    {
        title: 'Zapier',
        description:
            'Zapier allows you to connect PostGrid with 1000s of other apps without writing any code.',
        buttonText: 'Get Started',
        buttonLink: IntegrationRoutes.ZAPIER,
    },
    {
        title: 'HubSpot',
        description:
            'Use our HubSpot native integration to add direct mail automation to the industry leading CRM.',
        buttonText: 'Get Started',
        buttonLink:
            'https://ecosystem.hubspot.com/marketplace/apps/marketing/marketing-automation/postgrid-844214',
    },
    {
        title: 'Salesforce',
        description: `Request access to our Salesforce integration to add offline capabilities to the enterprise-ready CRM.`,
        buttonText: 'Contact Us',
        buttonLink: 'https://www.postgrid.com/contact-us',
    },
    {
        title: 'ActiveCampaign',
        description: `Request access to our ActiveCampaign integration to automate both digital and offline campaigns with ease.`,
        buttonText: 'Contact Us',
        buttonLink: 'https://www.postgrid.com/contact-us',
    },
];

const Zapier = () => {
    return (
        <GridPaper
            style={{
                border: '1px solid #e3e3e3',
                borderRadius: '16px',
                padding: '24px 32px',
                width: '100%',
                boxShadow: 'none',
            }}
            direction="column"
        >
            <Grid container justifyContent="space-between">
                <Grid item style={{ width: '100%' }}>
                    <zapier-full-experience
                        client-id={ZAPIER_CLIENT_ID}
                        theme="light"
                        app-search-bar-display="show"
                        zap-limit="0"
                        zap-create-from-scratch-display="hide"
                    />
                </Grid>
            </Grid>
        </GridPaper>
    );
};

const IntegrationMethodPreview = ({
    title,
    description,
    buttonText,
    buttonLink,
}: IntegrationMethodPreviewProps) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <Card variant="outlined" classes={classes}>
            <CardContent>
                <Typography variant="subtitle1" style={{ fontSize: '24px' }}>
                    {title}
                </Typography>
                <Box my={1}>
                    <Divider />
                </Box>
                <Typography>{description}</Typography>
                <Grid
                    container
                    style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        paddingTop: '16px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (buttonLink.startsWith('https://')) {
                                window.open(buttonLink, '_blank');
                            } else {
                                history.push(buttonLink);
                            }
                        }}
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </CardContent>
        </Card>
    );
};

const IntegrationOverview = () => {
    return (
        <GridPaper direction="column" spacing={2}>
            <Grid item>
                <Box mb={3}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">Integrations</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    {INTEGRATIONS_LIST.map((integration) => {
                        return (
                            <Grid item xs={4}>
                                <IntegrationMethodPreview {...integration} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </GridPaper>
    );
};

const Integrations = () => {
    return (
        <>
            <TopNav />
            <Switch>
                <Route
                    exact
                    path={IntegrationRoutes.OVERVIEW}
                    component={IntegrationOverview}
                />
                <Route
                    exact
                    path={IntegrationRoutes.ZAPIER}
                    component={Zapier}
                />
            </Switch>
        </>
    );
};

export default Integrations;
