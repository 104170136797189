import React, { PropsWithoutRef, ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from './Button';

export type ConfirmActionDialogProps = PropsWithoutRef<{
    open: boolean;
    onClose: () => void;
    title: string;
    text: ReactNode;
    actionLabel: string;
    cancelLabel?: string;
    confirm: () => void;
    image?: string;
    demoVideo?: string;
    demoVideoWidth?: string;
    maxWidth?: string;
    disabled?: boolean;
}>;

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px 70px',
            },
        },
    },
}));

const ConfirmActionDialog = ({
    actionLabel,
    confirm,
    onClose,
    open,
    text,
    title,
    cancelLabel,
    image,
    demoVideo,
    demoVideoWidth,
    maxWidth,
    disabled,
}: ConfirmActionDialogProps) => {
    const classes = useStyles();

    const handleConfirm = () => {
        confirm();
        handleClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            classes={classes}
            open={open}
            onClose={handleClose}
            data-testid="confirm-action-dialog"
            sx={{
                '&>.MuiDialog-scrollPaper': {
                    '&>.MuiDialog-paper': {
                        ...(maxWidth && {
                            maxWidth,
                        }),
                    },
                },
            }}
        >
            <DialogContent>
                {image && (
                    <img
                        src={image}
                        alt={title}
                        style={{ margin: '10px auto', display: 'block' }}
                    />
                )}
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContentText>{text}</DialogContentText>

                {demoVideo && (
                    <video
                        style={{
                            width: demoVideoWidth,
                            height: '450px',
                            margin: '10px auto',
                            display: 'block',
                        }}
                        autoPlay
                        loop
                        muted
                    >
                        <source src={demoVideo} type="video/webm"></source>
                    </video>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                            size="large"
                            fullWidth
                            data-testid="cancel-action"
                        >
                            {cancelLabel || 'Cancel'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            size="large"
                            fullWidth
                            style={{ padding: '8px 14px' }}
                            data-testid="confirm-action"
                            disabled={disabled}
                        >
                            {actionLabel}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmActionDialog;
