import React, { useState, useMemo, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
    ExpiryDuration,
    Tracker,
    useService as useTrackersService,
} from '../services/Trackers';

import { useNotificationContext } from '../context/Notification';
import { useFetchResource } from '../hooks/useFetchResource';
import { useModal } from '../hooks/useModal';

import { TrackerRoutes } from '../routes';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CompassCalibration from '@mui/icons-material/CompassCalibration';

import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import DetailWrapper from '../components/DetailWrapper';
import DetailCell from '../components/DetailCell';
import RawData from '../components/RawData';
import TrackerVisits from '../components/TrackerVisits';
import { ReFetchProvider } from '../context/ReFetchContext';
import StrictEnumSelect from '../components/StrictEnumSelect';
import Field from '../components/Field';

const ViewTracker = () => {
    const history = useHistory();
    const params = useParams<{ trackerID: string }>();
    const service = useTrackersService();
    const { dispatchSuccess } = useNotificationContext();

    const [updating, setUpdating] = useState(false);
    const [description, setDescription] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [expiry, setExpiry] = useState<ExpiryDuration>(
        ExpiryDuration.DAYS_30
    );

    const fetch = useCallback(
        (trackerID: string) => service.tryGet(trackerID),
        [service]
    );

    const refreshLocalTracker = useCallback((tracker: Tracker) => {
        // update local state with the fetched tracker
        if (!tracker) return;
        setDescription(tracker.description ?? '');
        setRedirectUrl(tracker.redirectURLTemplate);
        setExpiry(tracker.urlExpireAfterDays);
    }, []);

    const { data: tracker, setData: setTracker } = useFetchResource(
        TrackerRoutes.HOME,
        fetch,
        refreshLocalTracker
    );

    const deleteTracker = async () => {
        const tmpTracker = tracker;
        try {
            setTracker(null); // attempt to delete tracker
            await service.delete(params.trackerID);
            dispatchSuccess('Deleted tracker.');
            history.replace(TrackerRoutes.HOME);
        } catch (err) {
            // if the delete fails, we need to put the tracker back
            setTracker(tmpTracker);
            console.error(err);
        }
    };

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    // Checks if the tracker details have been altered
    const hasUpdates = useMemo(() => {
        if (!tracker) return false;
        if (redirectUrl !== tracker.redirectURLTemplate) return true;
        if (description !== tracker.description) return true;
        if (expiry !== tracker.urlExpireAfterDays) return true;
        return false;
    }, [expiry, description, redirectUrl, tracker]);

    const update = async () => {
        setUpdating(true);

        try {
            const updatedTracker = await service.update(tracker!.id, {
                redirectURLTemplate: redirectUrl,
                description: description,
                urlExpireAfterDays: expiry,
            });

            setTracker(updatedTracker);
            dispatchSuccess('Successfully saved tracker.');
        } catch (err) {
            console.error('Error while updating tracker:', err);
        } finally {
            setUpdating(false);
        }
    };

    return (
        <ReFetchProvider>
            <ConfirmDeleteDialog
                open={deleteOpen}
                onClose={toggleDeleteModal}
                title="Delete Tracker"
                text="Are you sure you want to delete this tracker?"
                confirm={deleteTracker}
            />
            <TopNav />
            <GridPaper
                container
                direction="column"
                spacing={2}
                data-testid="view-tracker-page"
            >
                <PageHeader title="Tracker Details">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={updating || !hasUpdates}
                            onClick={update}
                        >
                            Save Tracker
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={toggleDeleteModal}
                            disabled={!tracker || updating}
                        >
                            Delete Tracker
                        </Button>
                    </Grid>
                </PageHeader>

                {tracker ? (
                    <Grid container item direction="column" spacing={2}>
                        <Grid item xs={5}>
                            <DetailWrapper
                                icon={CompassCalibration}
                                title="Tracker Details"
                                direction="column"
                                spacing={3}
                            >
                                <DetailCell
                                    right="Description"
                                    left={
                                        <Field
                                            label="Description"
                                            value={description}
                                            setValue={setDescription}
                                            id="description"
                                        />
                                    }
                                    testIdPrefix="description"
                                />
                                <DetailCell
                                    right="Redirect URL Template"
                                    left={
                                        <Field
                                            label="Redirect URL Template"
                                            value={redirectUrl}
                                            setValue={setRedirectUrl}
                                            id="redirect-url-template"
                                        />
                                    }
                                    testIdPrefix="redirect-url-template"
                                />
                                <DetailCell
                                    right="URL Expiry Duration"
                                    left={
                                        <StrictEnumSelect
                                            title="Expiry Duration"
                                            valueLabels={Object.values(
                                                ExpiryDuration
                                            ).map((v) => {
                                                return [
                                                    v.toString(),
                                                    `${v} Days`,
                                                ];
                                            })}
                                            onChange={(val: string) => {
                                                setExpiry(
                                                    parseInt(
                                                        val
                                                    ) as ExpiryDuration
                                                );
                                            }}
                                            value={expiry.toString()}
                                            required
                                        />
                                    }
                                    testIdPrefix="url-expiry-duration"
                                />
                                <DetailCell
                                    right="ID"
                                    left={tracker.id}
                                    testIdPrefix="id"
                                />
                                <DetailCell
                                    right="Total Visits"
                                    left={tracker.visitCount}
                                    testIdPrefix="visits"
                                />
                                <DetailCell
                                    right="Unique Visits"
                                    left={tracker.uniqueVisitCount}
                                    testIdPrefix="unique-visits"
                                />
                            </DetailWrapper>
                        </Grid>
                        <Grid item>
                            <TrackerVisits tracker={tracker} />
                        </Grid>
                        <Grid item>
                            <RawData obj={tracker} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        item
                        style={{ minHeight: '20vh' }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Grid>
                )}
            </GridPaper>
        </ReFetchProvider>
    );
};

export default ViewTracker;
