import React, { useState } from 'react';
import CopyToClipboard from './CopyToClipboard';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';

interface WebhookSecretProps {
    secret: string;
}

const Text = ({ show, secret }: { show: boolean; secret: string }) => (
    <Typography
        style={{
            whiteSpace: 'pre-line',
            width: '100%',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
        }}
    >
        {show ? secret : secret.replaceAll(/./gi, '\u2022')}
    </Typography>
);

const WebhookSecret = ({ secret }: WebhookSecretProps) => {
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow((prev) => !prev);
    };

    return (
        <Grid container spacing={2}>
            <Grid
                item
                style={{ display: 'flex' }}
                xs={9}
                lg={4}
                alignItems="center"
            >
                <Text secret={secret} show={show} />
            </Grid>
            <Grid container item xs={3} md={2}>
                <Grid item>
                    <CopyToClipboard value={secret} />
                </Grid>
                <Grid item>
                    <IconButton onClick={toggleShow} size="small">
                        {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WebhookSecret;
