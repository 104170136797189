import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { createAnalyticsEvent, handleSignup } from '../services/util';
import { Context } from '../context/Notification';
import { Context as AuthContext, ActionType } from '../context/Auth';
import { useService as useUsersService } from '../services/Users';
import { useService as useSessionsService } from '../services/Sessions';
import { SSORoutes } from '../routes';

import FormField from '../components/FormField';
import CountrySelect from '../components/CountrySelect';
import SignupWrapper from '../components/SignupWrapper';
import TermsAndConditons from '../components/TermsAndConditions';

// TODO:  Write name parsing function to test if the name returned from
// the Auth0 user is an email.  If they cannot find a name for a user,
// they fill it with their email address
const EnterSignupInfoSSO = () => {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const service = useUsersService();
    const sessionsService = useSessionsService();
    const { dispatchSuccess } = useContext(Context);
    const { dispatch: dispatchAuth } = useContext(AuthContext);
    const history = useHistory();

    const [name, setName] = useState(user?.name || '');
    const [companyName, setCompanyName] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || '');
    const [loading, setLoading] = useState(false);
    const emailRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!isAuthenticated) {
            history.replace(SSORoutes.SIGNUP);
        }
    }, [isAuthenticated, history]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isAuthenticated || !user || !emailRef.current) {
            // TODO:  Display error saying you must be logged into
            // Auth0 to continue
            return;
        }
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        try {
            await handleSignup({
                name,
                email: emailRef.current.value,
                organizationName: companyName,
                phoneNumber,
                countryCode,
            });

            createAnalyticsEvent({
                event: 'signup',
                eventProps: {
                    category: 'pm-signup',
                    action: 'signup',
                    label: 'self-serve-signup',
                },
            });

            // TODO:  Is there a possibility where an email from a user signing up
            // is empty?  Auth0 shows all fields as optional.
            await service.createWithOrganization({
                name,
                organizationName: companyName,
                email: emailRef.current.value,
                accessToken,
                countryCode,
                phoneNumber,
            });

            dispatchSuccess('Successfully created account');

            // Just login the user since we don't need to send them any verification
            // emails or anything
            const res = await sessionsService.login({ accessToken });

            dispatchAuth({
                type: ActionType.LOGIN,
                payload: res,
            });

            history.push('/dashboard');
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <SignupWrapper
            onSubmit={handleSubmit}
            buttonText="Sign Up With SSO"
            submitDisabled={loading}
            loginLink={SSORoutes.LOGIN}
        >
            <FormField
                label="Full Name"
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ siqatrib: 'name' }}
                autoFocus
            />
            <FormField
                label="Company Name"
                required
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                inputProps={{ siqatrib: 'company' }}
            />
            <FormField
                label="Email"
                required
                type="text"
                value={user?.email || undefined}
                inputRef={emailRef}
                disabled={!!user?.email}
                inputProps={{ siqatrib: 'email' }}
            />
            <FormField
                label="Phone Number"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                inputProps={{ siqatrib: 'phone' }}
            />
            <CountrySelect
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                size="small"
                field={FormField}
            />
            <TermsAndConditons />
        </SignupWrapper>
    );
};

export default EnterSignupInfoSSO;
