import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Context } from '../context/Notification';

export type ServiceType<T> = (...params: string[]) => Promise<T | null>;
export type UpdateState<T> = (obj: T) => void;

type FetchResourceType<T> = {
    fetching: boolean;
    data: T | null;
    setData: React.Dispatch<React.SetStateAction<T | null>>;
};

const useMemoizedParams = () => {
    const params = useParams();
    // params change on render causing the below hook
    // to re-render unnecessarily
    // eslint-disable-next-line
    return useMemo(() => Object.values(params) as string[], []);
};

/**
 *
 * @param {string} fallbackRoute A location to route to after an error has been found
 * @param {ServiceType} fetch The function which you want to fetch.  Usually a `service.get` or `service.tryGet`
 * @param {UpdateState} updateState A callback function that you can pass to update local state to what ever you need from the returned object.  This can be useful when you are editing on the page and need to set default values for fields
 * @returns {(boolean|(T|null))} A fetching boolean and the object
 */
export const useFetchResource = <T>(
    fallbackRoute: string,
    fetch: ServiceType<T>,
    updateState?: UpdateState<T>
): FetchResourceType<T> => {
    const params = useMemoizedParams();
    const history = useHistory();
    const [fetching, setFetching] = useState<boolean>(false);
    const [data, setData] = useState<T | null>(null);
    const { dispatchError } = useContext(Context);

    useEffect(() => {
        let cancel = false;

        (async () => {
            try {
                setFetching(true);
                const fetchedObj = await fetch(...params);
                if (cancel) return;
                if (!fetchedObj) {
                    dispatchError('This resource no longer exists.');
                    history.replace(fallbackRoute);
                } else {
                    setData(fetchedObj);
                    if (updateState) updateState(fetchedObj);
                }
            } catch (err: any) {
                console.error(err);
                dispatchError(err.message);
                history.replace(fallbackRoute);
            } finally {
                if (cancel) return;
                setFetching(false);
            }
        })();

        return () => {
            cancel = true;
        };
    }, [fallbackRoute, fetch, updateState, history, params, dispatchError]);

    return { fetching, data, setData };
};
