import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    FormEvent,
} from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useModal } from '../hooks/useModal';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import AddressPreview from './AddressPreview';
import RawData from '../components/RawData';
import TopNav from '../components/TopNav';
import PageHeader from './PageHeader';
import GridPaper from './GridPaper';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

import Button from './Button';
import { ReceiverIcon, SendIcon } from './Icons';
import {
    Order,
    OrderIMBStatus,
    OrderStatus,
    Service as OrdersService,
    orderStatusLabel,
} from '../services/Orders';

import { estimatedDeliveryTimeDays } from '../services/util';
import { OrderMailingClass } from '../services/Base';

const MAX_ORDER_POLL_COUNT = 5;
const ORDER_POLL_TIMEOUT = 3000;
const STATUS_TO_VALUE = {
    ready: 25,
    printing: 50,
    processed_for_delivery: 75,
    completed: 100,
    cancelled: 100,
};

const IMB_STATUS_TO_VALUE = {
    entered_mail_stream: 75,
    out_for_delivery: 100,
    returned_to_sender: 100,
};

const useProgressStyles = makeStyles((theme) => ({
    bottom: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey.A100,
        overflow: 'hidden',
    },
    top: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
        fontSize: 40,
        lineHeight: 80,
        textAlign: 'center',
        margin: '7px auto',
    },
    progressText: {
        fontSize: 35,
        lineHeight: '70px',
        color: theme.palette.common.white,
    },
    statusText: {
        position: 'absolute',
        textAlign: 'center',
        textTransform: 'capitalize',
        left: '-50%',
        minWidth: '170px',
    },
}));

const NumberedCircle = (props: {
    number: string;
    order?: Order;
    highlightNumber: boolean;
}) => {
    const theme = useTheme();

    const description =
        props.order &&
        (() => {
            if (props.order.status === 'ready') {
                return props.order.live
                    ? 'Will print on next business day.'
                    : 'Test order created successfully. Will not send.';
            }

            if (!props.order.live) {
                return 'This was a test order so it was not sent out.';
            }

            if (props.order.status === 'printing') {
                return `Will be processed within 2 business days.`;
            }

            if (props.order.carrierTracking?.trackingUpdates?.length) {
                const updates = props.order.carrierTracking.trackingUpdates;
                return updates[updates.length - 1].message;
            }

            if (props.order.imbStatus === OrderIMBStatus.ENTERED_MAIL_STREAM) {
                if (props.order.to.countryCode?.toLowerCase() === 'us') {
                    return `This order has passed through a USPS facility.`;
                }

                // NOTE(Apaar): International orders occasionally receive IMB updates and in this
                // case we want to let the customer know that it's on its way but we don't want to
                // give them false hope that it's delivered.
                //
                // We use the `completed` status as an approximation still, but the assumption is that
                // international orders will have an extended `completed` timeline anyways.
                if (props.order.status === 'completed') {
                    return 'This international order has likely delivered.';
                } else {
                    return 'This international order is on its way.';
                }
            }

            if (props.order.imbStatus === OrderIMBStatus.OUT_FOR_DELIVERY) {
                return `This order has left its final USPS facility.`;
            }

            if (props.order.status === 'processed_for_delivery') {
                const [minDays, maxDays] = estimatedDeliveryTimeDays(
                    props.order.mailingClass ?? OrderMailingClass.FIRST_CLASS
                );
                return `Will be delivered within ${minDays}-${maxDays} business days.`;
            }

            if (props.order.status === 'cancelled') {
                return 'We will not send this order or charge for it';
            }

            return 'The recipient has most likely received this order.';
        })();

    const header = props.order && orderStatusLabel(props.order, true);

    const circleClasses = useProgressStyles();

    return (
        <Box position="relative">
            <Box className={circleClasses.bottom}>
                <Box
                    className={circleClasses.top}
                    style={{
                        backgroundColor: props.highlightNumber
                            ? theme.palette.primary.main
                            : theme.palette.info.light,
                    }}
                >
                    <Typography
                        variant="body1"
                        className={circleClasses.progressText}
                    >
                        {props.number}
                    </Typography>
                </Box>
            </Box>
            {props.order && (
                <Box className={circleClasses.statusText}>
                    <Typography variant="h6">{header}</Typography>
                    <Typography variant="body1" style={{ fontSize: '12px' }}>
                        {description}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const ProgressTimeline = (props: { order: Order }) => {
    return (
        // Offset by -1em so that this is centered on the linear progress rather than the bounds
        // of the progress and the text
        <Grid container justifyContent="center" direction="column" spacing={1}>
            <Grid item style={{ position: 'relative' }}>
                <LinearProgress
                    variant="determinate"
                    value={
                        props.order.imbStatus
                            ? IMB_STATUS_TO_VALUE[props.order.imbStatus] - 1
                            : STATUS_TO_VALUE[props.order.status] - 1
                    }
                />
                <Box
                    style={{
                        position: 'absolute',
                        top: '-37px',
                        left: 0,
                        right: 0,
                    }}
                >
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <NumberedCircle
                                order={
                                    STATUS_TO_VALUE[props.order.status] === 25
                                        ? props.order
                                        : undefined
                                }
                                highlightNumber={
                                    STATUS_TO_VALUE[props.order.status] >= 25
                                }
                                number="01"
                            />
                        </Grid>
                        <Grid item>
                            <NumberedCircle
                                order={
                                    STATUS_TO_VALUE[props.order.status] === 50
                                        ? props.order
                                        : undefined
                                }
                                highlightNumber={
                                    STATUS_TO_VALUE[props.order.status] >= 50
                                }
                                number="02"
                            />
                        </Grid>
                        <Grid item>
                            <NumberedCircle
                                order={
                                    STATUS_TO_VALUE[props.order.status] ===
                                        75 &&
                                    props.order.imbStatus !==
                                        OrderIMBStatus.OUT_FOR_DELIVERY
                                        ? props.order
                                        : undefined
                                }
                                highlightNumber={
                                    STATUS_TO_VALUE[props.order.status] >= 75
                                }
                                number="03"
                            />
                        </Grid>
                        <Grid item>
                            <NumberedCircle
                                order={
                                    STATUS_TO_VALUE[props.order.status] ===
                                        100 ||
                                    props.order.imbStatus ===
                                        OrderIMBStatus.OUT_FOR_DELIVERY
                                        ? props.order
                                        : undefined
                                }
                                highlightNumber={
                                    STATUS_TO_VALUE[props.order.status] >=
                                        100 ||
                                    props.order.imbStatus ===
                                        OrderIMBStatus.OUT_FOR_DELIVERY
                                }
                                number="04"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

// When the user clicks this button, if the PDF
// is not present yet, we start polling the order
// up to 5 times.
const ViewPDFButton = (props: { order?: Order; retrieveOrder: () => void }) => {
    const { order, retrieveOrder } = props;

    const [pollCount, setPollCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const scheduleLoad = useCallback(() => {
        // User must click again to initate another set of loads
        if (pollCount > MAX_ORDER_POLL_COUNT) {
            setLoading(false);
            setPollCount(0);
            return null;
        }

        return window.setTimeout(() => {
            retrieveOrder();
            setPollCount(pollCount + 1);
        }, ORDER_POLL_TIMEOUT);
    }, [retrieveOrder, pollCount, setPollCount, setLoading]);

    // Stop showing loader once the order has a URL
    useEffect(() => {
        if (loading && order?.url) {
            setLoading(false);
            window.open(order.url, '_blank');
        }
    }, [order, loading, setLoading]);

    useEffect(() => {
        // Do not schedule loads until the user clicks
        if (!loading) {
            return;
        }

        // Scheduling a load will do nothing if we've hit the max poll count
        const timeout = scheduleLoad();

        if (timeout) {
            return () => clearTimeout(timeout);
        }
    }, [order, loading, scheduleLoad]);

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={!order || loading}
            onClick={() => {
                // This if is technically not required but it eliminates the retrieveOrder lag that
                // happens otherwise (feels weird)
                if (order?.url) {
                    window.open(order.url, '_blank');
                } else {
                    retrieveOrder();
                    setLoading(true);
                }
            }}
        >
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <span>View PDF</span>
                </Grid>
                {loading && (
                    <Grid item>
                        <CircularProgress size={15} />
                    </Grid>
                )}
            </Grid>
        </Button>
    );
};

const OrderDetailsPage = <T extends Order>(props: {
    title: string;
    hasPDF: boolean;
    service: OrdersService<T>;
}) => {
    const { title, service } = props;

    const history = useHistory();

    const params = useParams<{ orderID: string }>();

    const { dispatch } = useContext(NotificationContext);

    const [order, setOrder] = useState<Order>();

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    const retrieveOrder = useCallback(async () => {
        try {
            const order = await service.tryGet(params.orderID);

            if (order) {
                setOrder(order);
            } else {
                dispatch({
                    type: MessageType.ERROR,
                    message: 'This order no longer exists.',
                });

                history.goBack();
            }
        } catch (err) {
            console.error(err);
        }
    }, [history, dispatch, service, params.orderID]);

    useEffect(() => {
        retrieveOrder();

        // NOTE(Apaar): We automatically refresh the order every 5
        // minutes because the PDF preview link it has (if any) expires
        // after 15 minutes.
        const REFRESH_INTERVAL = 60_000 * 5;

        const timeout = setTimeout(() => {
            retrieveOrder();
        }, REFRESH_INTERVAL);

        return () => {
            clearTimeout(timeout);
        };
    }, [retrieveOrder]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        toggleDeleteModal();
    };

    const deleteLetter = async () => {
        // Show loading spinner
        setOrder(undefined);

        setOrder(await service.cancel(params.orderID));

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Cancelled order.',
        });
    };

    return (
        <>
            <TopNav />
            <GridPaper container={false}>
                <form onSubmit={onSubmit}>
                    <ConfirmDeleteDialog
                        open={deleteOpen}
                        onClose={toggleDeleteModal}
                        title="Cancel Order"
                        text="Are you sure you want to cancel this order?"
                        cancelLabel="No"
                        actionLabel="Yes"
                        confirm={deleteLetter}
                    />
                    <Grid container direction="column" spacing={2}>
                        <PageHeader
                            title={title}
                            liveAlertText={[
                                'This address was changed because the person at the address has moved. Click ',
                                <a
                                    href="https://postgrid.readme.io/reference/address-changes"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    here
                                </a>,
                                ' to learn why.',
                            ]}
                            hideAlert={!order?.to.addressChange}
                            liveWarning
                        >
                            {props.hasPDF && (
                                <Grid item>
                                    <ViewPDFButton
                                        order={order}
                                        retrieveOrder={retrieveOrder}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    onClick={async () => {
                                        setOrder(undefined);
                                        setOrder(
                                            await service.progress(order!.id)
                                        );
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !order ||
                                        order.live ||
                                        order.status ===
                                            OrderStatus.COMPLETED ||
                                        order.status === OrderStatus.CANCELLED
                                    }
                                >
                                    Progress
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={
                                        !order || order.status !== 'ready'
                                    }
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </PageHeader>

                        {order ? (
                            <Grid container item direction="column" spacing={2}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={3}>
                                        <AddressPreview
                                            title="Sender"
                                            contact={order.from}
                                            link
                                            icon={SendIcon}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            height="100%"
                                            p={4}
                                        >
                                            <ProgressTimeline order={order} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AddressPreview
                                            title="Recipient"
                                            contact={order.to}
                                            link
                                            icon={ReceiverIcon}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item xs={12}>
                                        <RawData obj={order} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                style={{ minHeight: '20vh' }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default OrderDetailsPage;
