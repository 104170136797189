import React, { useContext } from 'react';
import {
    Context as DownloadOrdersContext,
    ActionType as BackgroundDownloadActionType,
} from '../context/BackgroundDownload';
import { useNotificationContext } from '../context/Notification';

import { CircularProgress } from '@mui/material';
import Button from './Button';
import { ListParams } from '../services/util';

interface CSVDownloadButtonProps {
    label: string;
    fileName: string;
    retrievalFunction: (searchParams: ListParams) => Promise<any>;
}

const CSVDownloadButton: React.FC<CSVDownloadButtonProps> = (
    props: CSVDownloadButtonProps
) => {
    const {
        state: backgroundDownloadState,
        dispatch: backgroundDownloadDispatch,
    } = useContext(DownloadOrdersContext);

    const { dispatchError } = useNotificationContext();

    const handleDownload = () => {
        try {
            backgroundDownloadDispatch({
                type: BackgroundDownloadActionType.INITIATE,
                fileName: `${props.fileName}.csv`,
                list: async ({
                    skip,
                    limit,
                }: {
                    skip: number;
                    limit: number;
                }) => {
                    return await props.retrievalFunction({
                        skip,
                        limit,
                    });
                },
            });
        } catch (err: any) {
            // Handle case of download already occurring
            dispatchError(err.message);
        }
    };
    if (backgroundDownloadState.active) {
        // If we are performing a background download
        return (
            <CircularProgress
                variant={
                    Number.isFinite(backgroundDownloadState.total) &&
                    backgroundDownloadState.total > 0
                        ? 'determinate'
                        : 'indeterminate'
                }
                value={
                    Number.isFinite(backgroundDownloadState.total) &&
                    backgroundDownloadState.total > 0
                        ? (backgroundDownloadState.downloadCount /
                              backgroundDownloadState.total) *
                          100
                        : 0
                }
            />
        );
    }
    return (
        <Button
            variant="outlined"
            color="primary"
            disabled={backgroundDownloadState.active}
            onClick={handleDownload}
        >
            {props.label}
        </Button>
    );
};

export default CSVDownloadButton;
