import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useReFetchContext } from '../context/ReFetchContext';
import { usePagination } from '../hooks/usePagination';
import { Tracker, useService } from '../services/Trackers';
import { formatTableDate, ListParams } from '../services/util';
import TableDisplay from './TableDisplay';
import CSVDownloadButton from './CSVDownloadButton';

interface TrackerVisitsProps {
    tracker: Tracker;
}

const TrackerVisits = ({ tracker }: TrackerVisitsProps) => {
    const service = useService();
    const { reFetch } = useReFetchContext();

    const fetchTrackerVisits = useCallback(
        (params: ListParams) => {
            return service.getVisits(tracker.id, params);
        },
        [service, tracker]
    );

    const dependencies = useMemo(() => [reFetch], [reFetch]);

    const { loading, data, search, pagination } = usePagination(
        fetchTrackerVisits,
        { dependencies }
    );

    return (
        <Grid item container direction="column" data-testid="tracker-visits">
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                paddingBottom={2}
            >
                <Grid item>
                    <Typography variant="h5">Visits</Typography>
                </Grid>
                <Grid item>
                    <CSVDownloadButton
                        fileName={`tracker_${tracker.description}_visits`}
                        label="Download Visits"
                        retrievalFunction={(searchParams: ListParams) => {
                            return service.getVisits(tracker.id, {
                                ...searchParams,
                                search,
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <TableDisplay
                    columns={[
                        'Visit ID',
                        'Order ID',
                        'Device',
                        'IP Address',
                        'Scanned At',
                    ]}
                    show={loading}
                    showEmptyTable
                    pagination={pagination}
                    wrapped
                >
                    {data.map((visit) => {
                        return (
                            <TableRow key={visit.id} hover>
                                <TableCell>{visit.id}</TableCell>
                                <TableCell>{visit.orderID}</TableCell>
                                <TableCell>{visit.device}</TableCell>
                                <TableCell>{visit.ipAddress}</TableCell>
                                <TableCell>
                                    {formatTableDate(visit.updatedAt)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableDisplay>
            </Grid>
        </Grid>
    );
};

export default TrackerVisits;
