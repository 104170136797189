import React, { useCallback, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import GridPaper from '../components/GridPaper';
import PageHeader from '../components/PageHeader';
import RawData from '../components/RawData';
import TopNav from '../components/TopNav';
import { useFetchResource } from '../hooks/useFetchResource';
import { CreateOrderBody, useService } from '../services/ReturnEnvelopes';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ContactInfo from '../components/ContactInfo';
import Alert from '@mui/material/Alert';
import ReturnEnvelopeOrders from '../components/ReturnEnvelopeOrders';
import Button from '../components/Button';
import { useModal } from '../hooks/useModal';
import CreateReturnEnvelopeOrders from '../components/CreateReturnEnvelopeOrders';
import { ReFetchProvider } from '../context/ReFetchContext';
import Box from '@mui/material/Box';
import { ReturnEnvelopeRoutes } from '../routes';
import { Context } from '../context/Notification';

const ViewReturnEnvelope = () => {
    const service = useService();
    const [loading, setLoading] = useState(false);
    const { dispatchSuccess } = useContext(Context);

    const fetchEnvelope = useCallback(
        (id: string) => {
            return service.tryGet(id);
        },
        [service]
    );

    const { fetching, data } = useFetchResource(
        ReturnEnvelopeRoutes.HOME,
        fetchEnvelope
    );
    const { toggleModal, isModalOpen } = useModal();

    const handleSubmit = async (orderDetails: CreateOrderBody, id: string) => {
        try {
            setLoading(true);
            await service.createOrder(orderDetails, id);
            dispatchSuccess('Order created successfully.');
        } catch (err) {
            console.error('Creating Return Envelope Error:', err);
        } finally {
            setLoading(false);
        }
    };

    // TODO: Create Skeleton Page component for smoother loading
    if (fetching || !data) {
        return (
            <>
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <Grid container item justifyContent="center">
                        <CircularProgress
                            size={100}
                            data-testid="view-envelope-loading"
                        />
                    </Grid>
                </GridPaper>
            </>
        );
    }

    return (
        <ReFetchProvider>
            <CreateReturnEnvelopeOrders
                open={isModalOpen}
                onClose={toggleModal}
                envelope={data}
                loading={loading}
                onSubmit={handleSubmit}
            />
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <PageHeader
                    title="Return Envelope Details"
                    testAlertText="You are in test mode.  This return envelope can only be used for test orders."
                >
                    <Grid item>
                        <Button
                            onClick={toggleModal}
                            data-testid="create-order-button"
                        >
                            Create an Order
                        </Button>
                    </Grid>
                </PageHeader>
                <Grid item>
                    <Alert
                        severity={data.available > 0 ? 'success' : 'error'}
                        data-testid="available-alert"
                    >
                        <Typography component={'div'}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Box fontWeight="semibold" fontSize={18}>
                                        {data.available}
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box>Available for immediate use</Box>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Alert>
                </Grid>
                <Grid item container>
                    <ContactInfo
                        contact={data.to}
                        addressTitle="Return Address"
                        personalTitle="Return To"
                    />
                </Grid>
                <ReturnEnvelopeOrders envelope={data} />
                <Grid item>
                    <RawData obj={data} />
                </Grid>
            </GridPaper>
        </ReFetchProvider>
    );
};

export default ViewReturnEnvelope;
