import React, { FormEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Contexts/Services/Hooks
import { formatMergeVariables, unflatten } from '../services/util';
import { useOrganization } from '../services/Organization';
import { useNotificationContext } from '../context/Notification';
import { useModeContext } from '../context/Mode';
import { OrderMailingClass } from '../services/Base';
import createObjRefContext from '../context/RefContext';
import type { Contact } from '../services/Contacts';
import type { Template } from '../services/Templates';
import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import {
    SelfMailer,
    SelfMailerSize,
    useService as useSelfMailersService,
} from '../services/SelfMailers';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';
import { batchContactData } from '../services/Orders';
// Constants
import { SelfMailerRoutes } from '../routes';
// Components
// MUI components
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Grid, { GridSize } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
// Our components
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ContactOrCSV from '../components/ContactOrCSV';
import ContactInput from '../components/ContactInput';
import SelectTemplate from '../components/SelectTemplate';
import FileUpload from '../components/FileUpload';
import Button from '../components/Button';
import MailingClassSelector from '../components/MailingClassSelector';
import SendDateComponent, { minDate } from '../components/SendDate';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import GoBackButton from '../components/GoBackButton';
import MergeVariablesInput from '../components/MergeVariablesInput';
import BatchSend, {
    BatchSendProps,
    BatchSendState,
    BatchSendStatus,
} from '../components/BatchSend';

const initialState = {
    description: '',
    uploadedCSV: false,
    express: false,
    sendDate: minDate(),
    mailingClass: OrderMailingClass.FIRST_CLASS,
    size: SelfMailerSize['BIFOLD_8.5X11'],
    to: [] as Contact[],
    from: null as Contact | null,
    insideTemplate: null as Template | null,
    outsideTemplate: null as Template | null,
    file: null as File | null,
    mergeVariables: {} as Record<string, string>,
};

export const { StoreProvider, useStore, useGetStoreSnapshot } =
    createObjRefContext(initialState);

const DescriptionField = () => {
    const [description, setStore] = useStore((store) => store.description);
    return (
        <TextField
            variant="outlined"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setStore({ description: e.target.value })}
        />
    );
};

const ToContacts = () => {
    const [toContacts, setStore] = useStore((store) => store.to);
    return (
        <ContactOrCSV
            label="To Contact"
            contacts={toContacts}
            setContacts={(contacts) => setStore({ to: contacts })}
            setUploadedCSV={(isUploaded) =>
                setStore({ uploadedCSV: isUploaded })
            }
            required
        />
    );
};

const FromContact = () => {
    const [from, setStore] = useStore((store) => store.from);
    return (
        <ContactInput
            label="From Contact"
            contact={from}
            setContact={(contact) => setStore({ from: contact })}
            required
        />
    );
};

const InsideTemplate = () => {
    const [{ insideTemplate, file }, setStore] = useStore((store) => ({
        insideTemplate: store.insideTemplate,
        file: store.file,
    }));

    return (
        <SelectTemplate
            label="Inside Template"
            template={insideTemplate}
            setTemplate={(t) => setStore({ insideTemplate: t })}
            required={!file}
            disabled={Boolean(file)}
        />
    );
};
const OutsideTemplate = () => {
    const [{ outsideTemplate, file }, setStore] = useStore((store) => ({
        outsideTemplate: store.outsideTemplate,
        file: store.file,
    }));

    return (
        <SelectTemplate
            label="Outside Template"
            template={outsideTemplate}
            setTemplate={(t) => setStore({ outsideTemplate: t })}
            required={!file}
            disabled={Boolean(file)}
        />
    );
};

const PDF = () => {
    const [{ insideTemplate, file, outsideTemplate }, setStore] = useStore(
        (store) => ({
            insideTemplate: store.insideTemplate,
            outsideTemplate: store.outsideTemplate,
            file: store.file,
        })
    );

    return (
        <FileUpload
            accept="application/pdf"
            label="Upload a PDF"
            file={file}
            setFile={(f) => setStore({ file: f })}
            required={!insideTemplate && !outsideTemplate}
            disabled={!!insideTemplate || !!outsideTemplate}
        />
    );
};

const MailingClass = ({ xs }: { xs: GridSize }) => {
    const [{ mailingClass, express }, setStore] = useStore((store) => ({
        mailingClass: store.mailingClass,
        express: store.express,
    }));

    return (
        <MailingClassSelector
            xs={xs}
            mailingClass={mailingClass}
            onChange={(mc) => setStore({ mailingClass: mc })}
            disabled={express}
        />
    );
};

const SendDate = ({ xs }: { xs: GridSize }) => {
    const [sendDate, setStore] = useStore((store) => store.sendDate);
    const history = useHistory();
    const org = useOrganization([history.location]);

    return (
        <SendDateComponent
            xs={xs}
            setSendDate={(sd) => setStore({ sendDate: sd })}
            sendDate={sendDate}
            showSubscriptionPopup={!org?.stripeSubscription}
        />
    );
};

const Size = () => {
    const [size, setStore] = useStore((store) => store.size);

    return (
        <FormControl component="fieldset">
            <Typography>Size in Inches (Width x Height)</Typography>
            <RadioGroup
                row
                value={size}
                onChange={(_e, v) => setStore({ size: v as SelfMailerSize })}
            >
                <FormControlLabel
                    value={SelfMailerSize['BIFOLD_8.5X11']}
                    control={<Radio color="primary" />}
                    label="8.5x11 (Bifold)"
                />
            </RadioGroup>
        </FormControl>
    );
};

const Express = () => {
    const [express, setStore] = useStore((store) => store.express);

    return (
        <ExpressDeliveryCheckbox
            checked={express}
            setChecked={(c) => setStore({ express: c })}
        />
    );
};

const MergeVariables = () => {
    const [
        { insideTemplate, outsideTemplate, mergeVariables, to, from },
        setStore,
    ] = useStore((store) => ({
        insideTemplate: store.insideTemplate,
        outsideTemplate: store.outsideTemplate,
        mergeVariables: store.mergeVariables,
        to: store.to,
        from: store.from,
    }));

    const templateVars = useTemplateVars(insideTemplate, outsideTemplate);
    const defaultVars = useDefaultVars(to, from);

    return (
        <Collapse in={templateVars.length > 0}>
            <MergeVariablesInput
                templateVars={templateVars}
                mergeVars={mergeVariables}
                setMergeVars={(v) => setStore({ mergeVariables: v })}
                defaultVars={defaultVars}
            />
        </Collapse>
    );
};

const CreateSelfMailerForm = () => {
    const [loading, setLoading] = useState(false);
    const [batchSendState, setBatchSendState] = useState<
        BatchSendState<SelfMailer>
    >({
        processedCount: 0,
        status: BatchSendStatus.READY,
        results: [],
    });
    const [toContacts, setStore] = useStore((store) => store.to);

    const { dispatchSuccess } = useNotificationContext();
    const service = useSelfMailersService();
    const getSnapshot = useGetStoreSnapshot();
    const history = useHistory();

    const resetState = useCallback(() => setStore(initialState), [setStore]);
    useRegisterCreateOrderResetFunction(resetState);

    const createBatch = useCallback(
        async (
            params: Parameters<BatchSendProps<SelfMailer>['createBatch']>[0]
        ) => {
            const state = getSnapshot();
            const mergeVariables =
                state.mergeVariables && unflatten(state.mergeVariables);

            // TODO: Validate, however HTML validation should ensure necessary
            // fields are present.
            return await service.createBatch({
                ...state,
                data: batchContactData(params.toContacts, state.from!, {
                    description: state.description,
                    mergeVariables,
                }),
                pdf: state.file ?? undefined,
                insideTemplate: state.insideTemplate?.id,
                outsideTemplate: state.outsideTemplate?.id,
                handleProgress: params.handleProgress,
            });
        },
        [getSnapshot, service]
    );

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (toContacts.length > 1) {
            setBatchSendState((prev) => ({
                ...prev,
                status: BatchSendStatus.IN_PROGRESS,
            }));
            return;
        }

        try {
            setLoading(true);
            const state = getSnapshot();
            await service.create({
                ...state,
                pdf: state.file ?? undefined,
                insideTemplate: state.insideTemplate?.id,
                outsideTemplate: state.outsideTemplate?.id,
                from: state.from!.id,
                to: state.to[0].id,
                mergeVariables: formatMergeVariables(state.mergeVariables),
            });

            dispatchSuccess('Created Self Mailer');
            setLoading(false);
            history.push(SelfMailerRoutes.HOME);
        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    };

    const isBackButtonDisabled =
        loading || batchSendState.status === BatchSendStatus.IN_PROGRESS;
    const backButtonText =
        batchSendState.status === BatchSendStatus.COMPLETED ||
        batchSendState.status === BatchSendStatus.ERROR
            ? 'Back'
            : 'Cancel';

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {/* HACK(from CreatePostcard): Lil box for padding for sizes */}
                    <Box height={13}></Box>
                    <DescriptionField />
                </Grid>
                <Grid item xs={6}>
                    <Size />
                </Grid>
                <Grid item xs={6}>
                    <ToContacts />
                </Grid>
                <Grid item xs={6}>
                    <FromContact />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <InsideTemplate />
                        </Grid>
                        <Grid item xs>
                            <OutsideTemplate />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <PDF />
                </Grid>
                <Grid item xs={12}>
                    <MergeVariables />
                </Grid>
                {/* Has a `<Grid />` in the underlying component */}
                <MailingClass xs={6} />
                {/* Has a `<Grid />` in the underlying component */}
                <SendDate xs={6} />
                <Grid item>
                    <Express />
                </Grid>
                {batchSendState.status !== BatchSendStatus.READY && (
                    <Grid item xs={12}>
                        <BatchSend
                            state={batchSendState}
                            setState={setBatchSendState}
                            toContacts={toContacts}
                            createBatch={createBatch}
                        />
                    </Grid>
                )}
                <Grid container spacing={2} item xs={12}>
                    {batchSendState.status === BatchSendStatus.READY && (
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                size="large"
                                fullWidth
                            >
                                Create
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={2}>
                        <GoBackButton
                            color="primary"
                            variant="outlined"
                            disabled={isBackButtonDisabled}
                            size="large"
                            fullWidth
                        >
                            {backButtonText}
                        </GoBackButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const CreateSelfMailer = () => {
    const { live } = useModeContext();

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC">
                        <Typography variant="h5" gutterBottom>
                            Create a Self Mailer
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item direction="column">
                    <Grid item>
                        <Box my={2}>
                            {live ? (
                                <Alert variant="outlined" color="warning">
                                    You are in live mode so this self mailer
                                    will be printed and delivered.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" color="info">
                                    You are in test mode so this self mailer
                                    will not actually get sent out.
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CreateSelfMailerForm />
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateSelfMailer;
