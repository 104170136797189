import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// Our Hooks/Context/Services
import { usePagination } from '../hooks/usePagination';
import { useService as useUsersService } from '../services/Users';
import { ListParams } from '../services/util';
import { User } from '../context/Auth';
import { UserRoutes } from '../routes';
// MUI
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// Our Components
import TableDisplay from './TableDisplay';
import UserInviteStatus from './UserInviteStatus';

const UsersTable = () => {
    const service = useUsersService();
    const history = useHistory();

    const paginationFunc = useCallback(
        (params: ListParams) => {
            return service.list(params);
        },
        [service]
    );

    const { data: users, loading, pagination } = usePagination(paginationFunc);

    const handleRowClick = (user: User) => {
        history.push(`${UserRoutes.HOME}/${user.id}`);
    };

    return (
        <TableDisplay
            columns={['Name', 'Email', 'Phone Number', 'Status', 'Created At']}
            show={loading}
            pagination={pagination}
            showEmptyTable
        >
            {users.map((user) => {
                return (
                    <TableRow
                        key={user.id}
                        hover
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => handleRowClick(user)}
                    >
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phoneNumber || ''}</TableCell>
                        <TableCell>
                            <UserInviteStatus user={user} />
                        </TableCell>
                        <TableCell>{user.createdAt.toLocaleString()}</TableCell>
                    </TableRow>
                );
            })}
        </TableDisplay>
    );
};

export default UsersTable;
