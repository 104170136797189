import React, {
    Dispatch,
    PropsWithoutRef,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { pdfjs, Document, Page } from 'react-pdf';

// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Region } from '../pages/PDFWizard';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

enum EditingStates {
    NONE = 'NONE',
    MOVE = 'MOVE',
    RESIZING_NW = 'RESIZING_NW',
    RESIZING_NE = 'RESIZING_NE',
    RESIZING_SW = 'RESIZING_SW',
    RESIZING_SE = 'RESIZING_SE',
}

const PDFOverlay = forwardRef<
    HTMLCanvasElement,
    {
        width: string;
        height: string;
        handleMouseMove: (e: React.MouseEvent<HTMLCanvasElement>) => void;
        handleMouseDown: (e: React.MouseEvent<HTMLCanvasElement>) => void;
        handleMouseUp: (e: React.MouseEvent<HTMLCanvasElement>) => void;
    }
>((props, ref) => {
    return (
        <canvas
            style={{
                width: props.width,
                height: props.height,
                position: 'absolute',
                top: '0',
                left: '0',
            }}
            ref={ref}
            onMouseMove={props.handleMouseMove}
            onMouseDown={props.handleMouseDown}
            onMouseUp={props.handleMouseUp}
        ></canvas>
    );
});

const getMousePos = (
    canvas: HTMLCanvasElement,
    e: React.MouseEvent<HTMLCanvasElement>
) => {
    const rect = canvas.getBoundingClientRect(), // abs. size of element
        scaleX = canvas.width / rect.width, // relationship bitmap vs. element for x
        scaleY = canvas.height / rect.height; // relationship bitmap vs. element for y

    return {
        x: (e.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
        y: (e.clientY - rect.top) * scaleY, // been adjusted to be relative to element
    };
};

const PDFRegionSelect = (
    props: PropsWithoutRef<{
        PDF: File[] | null;
        region: Region;
        setRegion: Dispatch<SetStateAction<Region>>;
        bgColor: string;
    }>
) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const overlayCanvasRef = useRef<HTMLCanvasElement>(null);

    const [state, setState] = useState(EditingStates.NONE);
    const [prevMousePos, setPrevMousePos] = useState({ x: 0, y: 0 });

    const POINTS_PER_INCH = 72;
    const regionLeft = props.region.left * POINTS_PER_INCH;
    const regionTop = props.region.top * POINTS_PER_INCH;
    const regionWidth = props.region.width * POINTS_PER_INCH;
    const regionHeight = props.region.height * POINTS_PER_INCH;

    const RADIUS = 5;
    const ne = useMemo(() => {
        const path = new Path2D();
        path.arc(regionLeft + regionWidth, regionTop, RADIUS, 0, 2 * Math.PI);
        return path;
    }, [regionLeft, regionWidth, regionTop]);

    const sw = useMemo(() => {
        const path = new Path2D();
        path.arc(regionLeft, regionTop + regionHeight, RADIUS, 0, 2 * Math.PI);
        return path;
    }, [regionLeft, regionHeight, regionTop]);

    const nw = useMemo(() => {
        const path = new Path2D();
        path.arc(regionLeft, regionTop, RADIUS, 0, 2 * Math.PI);
        return path;
    }, [regionLeft, regionTop]);

    const se = useMemo(() => {
        const path = new Path2D();
        path.arc(
            regionLeft + regionWidth,
            regionTop + regionHeight,
            RADIUS,
            0,
            2 * Math.PI
        );
        return path;
    }, [regionLeft, regionWidth, regionTop, regionHeight]);

    const box = useMemo(() => {
        const path = new Path2D();
        path.rect(regionLeft, regionTop, regionWidth, regionHeight);
        return path;
    }, [regionLeft, regionWidth, regionTop, regionHeight]);

    const drawBoundingBox = useCallback(() => {
        const canvas = overlayCanvasRef.current;
        if (!canvas) {
            return;
        }
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        // Clear canvas
        ctx.fillStyle = '#00000000';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Selection box
        ctx.fillStyle = props.bgColor;
        ctx.strokeStyle = '#101010';
        ctx.fill(box);
        ctx.stroke(box);

        // Control Handles
        ctx.fillStyle = '#101010';
        ctx.fill(ne);
        ctx.fill(sw);
        ctx.fill(nw);
        ctx.fill(se);
    }, [box, ne, se, nw, sw, props.bgColor]);

    useEffect(() => {
        drawBoundingBox();
    }, [drawBoundingBox]);

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = overlayCanvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        const mousePos = getMousePos(canvas, e);

        if (
            ctx.isPointInPath(ne, mousePos.x, mousePos.y) ||
            ctx.isPointInPath(sw, mousePos.x, mousePos.y)
        ) {
            canvas.style.cursor = 'nesw-resize';
        } else if (
            ctx.isPointInPath(nw, mousePos.x, mousePos.y) ||
            ctx.isPointInPath(se, mousePos.x, mousePos.y)
        ) {
            canvas.style.cursor = 'nwse-resize';
        } else if (ctx.isPointInPath(box, mousePos.x, mousePos.y)) {
            canvas.style.cursor = 'move';
        } else {
            canvas.style.cursor = 'unset';
        }

        if (state === EditingStates.NONE) {
            return;
        }

        const edited = { ...props.region };

        if (state === EditingStates.RESIZING_NE) {
            edited.width = (mousePos.x - regionLeft) / POINTS_PER_INCH;
            edited.top = mousePos.y / POINTS_PER_INCH;
            edited.height =
                (regionTop + regionHeight - mousePos.y) / POINTS_PER_INCH;
        } else if (state === EditingStates.RESIZING_SE) {
            edited.width = (mousePos.x - regionLeft) / POINTS_PER_INCH;
            edited.height = (mousePos.y - regionTop) / POINTS_PER_INCH;
        } else if (state === EditingStates.RESIZING_SW) {
            edited.left = mousePos.x / POINTS_PER_INCH;
            edited.height = (mousePos.y - regionTop) / POINTS_PER_INCH;
            edited.width =
                (regionLeft + regionWidth - mousePos.x) / POINTS_PER_INCH;
        } else if (state === EditingStates.RESIZING_NW) {
            edited.left = mousePos.x / POINTS_PER_INCH;
            edited.top = mousePos.y / POINTS_PER_INCH;
            edited.width =
                (regionLeft + regionWidth - mousePos.x) / POINTS_PER_INCH;
            edited.height =
                (regionTop + regionHeight - mousePos.y) / POINTS_PER_INCH;
        } else if (state === EditingStates.MOVE) {
            edited.left =
                (mousePos.x - prevMousePos.x + regionLeft) / POINTS_PER_INCH;
            edited.top =
                (mousePos.y - prevMousePos.y + regionTop) / POINTS_PER_INCH;
            setPrevMousePos(mousePos);
        }

        edited.left = Math.max(0, edited.left);
        edited.top = Math.max(0, edited.top);
        edited.width = Math.max(0, edited.width);
        edited.height = Math.max(0, edited.height);

        props.setRegion(edited);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = overlayCanvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const mousePos = getMousePos(canvas, e);
        setPrevMousePos(mousePos);

        if (ctx.isPointInPath(ne, mousePos.x, mousePos.y)) {
            setState(EditingStates.RESIZING_NE);
        } else if (ctx.isPointInPath(se, mousePos.x, mousePos.y)) {
            setState(EditingStates.RESIZING_SE);
        } else if (ctx.isPointInPath(sw, mousePos.x, mousePos.y)) {
            setState(EditingStates.RESIZING_SW);
        } else if (ctx.isPointInPath(nw, mousePos.x, mousePos.y)) {
            setState(EditingStates.RESIZING_NW);
        } else if (ctx.isPointInPath(box, mousePos.x, mousePos.y)) {
            setState(EditingStates.MOVE);
        } else {
            setState(EditingStates.NONE);
        }
    };

    const handleMouseUp = () => {
        setState(EditingStates.NONE);
    };

    return (
        <Paper
            variant="outlined"
            style={{
                userSelect: 'none',
            }}
        >
            <Grid
                item
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                {!props.PDF?.length ? (
                    <Grid
                        item
                        style={{ padding: '1rem' }}
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Typography variant="h6">No file selected</Typography>
                    </Grid>
                ) : (
                    <Grid item>
                        <Document file={props.PDF[0]}>
                            <Page
                                renderTextLayer={false}
                                canvasRef={canvasRef}
                                pageIndex={0}
                                onRenderSuccess={drawBoundingBox}
                            >
                                <PDFOverlay
                                    width="100%"
                                    height="100%"
                                    ref={overlayCanvasRef}
                                    handleMouseMove={handleMouseMove}
                                    handleMouseDown={handleMouseDown}
                                    handleMouseUp={handleMouseUp}
                                />
                            </Page>
                        </Document>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default PDFRegionSelect;
