import React, { ElementType, useMemo, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRight: '2px solid transparent',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
        borderRightColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
}));

const useIconStyles = makeStyles({
    root: {
        fontSize: '24px',
        margin: '0 auto',
    },
});

const ListItemLink = (props: {
    icon: ElementType;
    primary?: string;
    to: string;
    topNav?: boolean;
    muiIcon?: boolean;
}) => {
    const { pathname } = useLocation();

    const classes = useStyles();
    const iconClass = useIconStyles();

    const { primary, to, topNav } = props;

    const CustomLink = useMemo(
        () =>
            forwardRef((linkProps, ref) => (
                <Link ref={ref as any} to={to} {...linkProps} />
            )),
        [to]
    );

    const selected = topNav
        ? false
        : to === '/dashboard'
        ? pathname === to
        : pathname.startsWith(to);

    return (
        <ListItemButton
            disableRipple
            selected={selected}
            component={CustomLink}
            classes={classes}
            sx={{
                '&.Mui-selected': {
                    outline: 'transparent',
                },
                '&.Mui-focusVisible': {
                    outline: 'transparent',
                },
                ':active': {
                    outline: 'transparent',
                },
                ':focus': {
                    outline: 'transparent',
                },
            }}
            style={{
                padding: topNav ? '10px 28px' : '0 16px 0 28px',
                margin: topNav ? '0' : '2px 0',
            }}
        >
            {props.muiIcon ? (
                <ListItemIcon style={{ marginLeft: '-1px' }}>
                    <props.icon
                        style={{
                            width: '17px',
                            height: '17px',
                            fill: selected ? '#A2C8FF' : '#b5b7be',
                        }}
                        color={selected ? 'primary' : 'inherit'}
                    />
                </ListItemIcon>
            ) : (
                <ListItemIcon
                    style={{ marginBottom: topNav ? '-5px' : '-10px' }}
                >
                    <SvgIcon
                        classes={iconClass}
                        style={{ margin: topNav ? '0' : '0 auto' }}
                        color={selected ? 'primary' : 'inherit'}
                    >
                        <props.icon
                            secondColor={selected ? '#A2C8FF' : 'inherit'}
                            color={topNav ? '#575e71' : null}
                        />
                    </SvgIcon>
                </ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography
                        variant="body2"
                        style={{
                            // TODO Refactor
                            fontFamily: topNav ? 'Poppins' : undefined,
                        }}
                    >
                        {primary}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default ListItemLink;
