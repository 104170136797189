import React, { useState, useContext, FormEvent, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { UserRoutes } from '../routes';
import { useService as useInvitesService } from '../services/Invites';
import type { Role } from '../services/Roles';

import { Context as NotificationContext } from '../context/Notification';
import useRoles from '../hooks/useRoles';

import GoBackButton from '../components/GoBackButton';
import Field from '../components/Field';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import SelectUserRoles from '../components/SelectUserRoles';
import { useOrganization } from '../services/Organization';
import DetailWrapper from '../components/DetailWrapper';

const InviteUser = () => {
    const history = useHistory();

    const service = useInvitesService();

    const { dispatchSuccess, dispatchError } = useContext(NotificationContext);
    const org = useOrganization([]);

    const userRoles = useRoles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);

    useEffect(() => {
        const adminRole = userRoles.find((role) => role.name === 'Admin');
        if (adminRole) {
            setRoles([adminRole]);
        }
    }, [userRoles]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        if (!roles.length) {
            dispatchError('You must select at least one role.');
            return;
        }

        try {
            setLoading(true);

            await service.create({
                name,
                email,
                roles: roles.map((role) => role.id),
            });

            dispatchSuccess('Invited user.');

            history.push(UserRoutes.HOME);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Invite a User
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container direction="column" spacing={2}>
                    <Box my={2}>
                        <Grid item>
                            <Box my={2}>
                                <Alert variant="outlined" color="info">
                                    The user will receive an email to complete
                                    their signup.
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={4}>
                                <form onSubmit={onSubmit}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Field
                                                variant="outlined"
                                                fullWidth
                                                value={name}
                                                setValue={setName}
                                                label="Name"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                setValue={setEmail}
                                                label="Email Address"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DetailWrapper direction="column">
                                                <SelectUserRoles
                                                    roles={userRoles}
                                                    selectedRoles={roles}
                                                    setRoles={setRoles}
                                                    userPermissions={
                                                        org?.userPermissions ??
                                                        false
                                                    }
                                                />
                                            </DetailWrapper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={loading}
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        Invite
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <GoBackButton
                                                        color="primary"
                                                        variant="outlined"
                                                        disabled={loading}
                                                        size="large"
                                                        fullWidth
                                                    >
                                                        Cancel
                                                    </GoBackButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default InviteUser;
